import React, { useState, useRef, useEffect } from 'react';

import 'lazysizes';

// Components
import { Menu } from '../../components/Extensivo/Menu';
import { Logo } from '../../components/Extensivo/Menu/Logo';
import { BoxMenuMobile } from '../../components/Extensivo/Menu/BoxMenuMobile';
import { BoxMenu } from '../../components/Extensivo/Menu/BoxMenu';
import { Link } from '../../components/Extensivo/Menu/Link';
import { Button } from '../../components/Extensivo/Button';
import { Card } from '../../components/Extensivo/Card';
import { H2 } from '../../components/Extensivo/Card/H2';
import { H3 } from '../../components/Extensivo/Card/H3';
import { H4 } from '../../components/Extensivo/Card/H4';
import { Courses } from '../../components/Extensivo/Card/Course';
// import { Message } from '../../components/Extensivo/Card/Message';
import { Price } from '../../components/Extensivo/Card/Price';
import { Paragraph } from '../../components/Extensivo/Card/Paragraph';
import { Separator } from '../../components/Extensivo/Card/Separator';
import { Image } from '../../components/Extensivo/Card/Image';
import { WarrantyLine } from '../../components/Extensivo/WarrantLine';
import { Modal } from '../../components/Extensivo/Modal';
import { Testemonial } from '../../components/Extensivo/Testemonial';
import { BoxItem } from '../../components/Extensivo/Testemonial/BoxItem';
import { BoxVideo } from '../../components/Extensivo/Testemonial/BoxVideo';
import { BoxContent } from '../../components/Extensivo/Testemonial/BoxContent';
import { TestemonialH3 } from '../../components/Extensivo/Testemonial/H3';
import { TestemonialDescription } from '../../components/Extensivo/Testemonial/Description';
import { Banner } from '../../components/Vendas/Banner';
import { Faq } from '../../components/Extensivo/Faq';
import Loading from '../../components/Loading';
import AddressAndPolicies from '../../components/AddressAndPolicies';
import { AdditionalContent } from '../../components/Extensivo/AdditionalContent';

// Services
import apiExtensivo from '../../services/Extensivo/api';

// Assets

import Extensivo from '../../assets/logo-extensivo-sao-paulo-2023.svg';
import MenuIcon from '../../assets/menu-icon.png';
import CloseMenuIcon from '../../assets/close-menu-icon.png';
// import logoIntensivoSP from '../../assets/logo-intensivo-sp.png';
// import ImgTableMobile from '../../assets/Extensivo/tabela-extensivo-mobile.jpg';
// import logoIntensivoSP from '../../assets/logo-intensivo-sp.png';

// Styles
import {
  Container,
  BoxHero,
  BoxPillars,
  BoxWaranty,
  BoxBuy,
  BoxVideoTestemonial,
  BoxFaq,
  Footer,
} from './styles';

// Interface
interface Botao {
  title: string;
  url: string;
  target: string;
}
interface Pilares {
  imagem_pilar: {
    title: string;
    url: string;
  };
  titulo_pilar: string;
  subtitulo_pilar: string;
  paragrafo_pilar: string;
  conteudo_pilar: string;
  botao_pilar: Botao;
}
interface SubgrupoDuvidas {
  titulo_subgrupo: string;
  texto_subgrupo: string;
}

interface GrupoDuvidas {
  titulo_grupo: string;
  texto_grupo: string;
  subgrupo: [SubgrupoDuvidas];
}

interface Video {
  url_video: string;
  nome_video: string;
  aprovacao_video: string;
}

interface Incluso {
  nome_incluso: string;
  valor_incluso: string;
}

interface ExtraContent {
  logo_image: string;
  logo_alt_text: string;
  text_above_button: string;
  modal_text: string;
  button_text: string;
}

interface Dados {
  extensivo_sp: {
    hero: {
      status_hero: boolean;
      video_hero: string;
      titulo_hero: string;
      texto_hero: string;
      botao_hero: Botao;
    };

    pilar: {
      status_pilar: boolean;
      titulo_pilares: string;
      descricao_pilares: string;
      pilares: [Pilares];
      extra_content: {
        modal_title: string;
        contents: [ExtraContent]
      };
    };

    garantia: {
      status_garantia: boolean;
      titulo_garantia: string;
      subtitulo_garantia: string;
      data_garantia: string;
      texto_garantia: string;
    };

    sobre: {
      status_sobre: boolean;
      video_sobre: string;
      titulo: string;
    };

    valores: {
      status_valores: boolean;
      promocional_valores: boolean;
      titulo_valores: string;
      subtitulo_valores: string;
      inclusos_valores: [Incluso];
      mensagem_valores: string;
      preco_anterior_valores: string;
      preco_valores: string;
      parcelas_valores: string;
      boleto_valores: string;
      botao_valores: Botao;
    };

    banner_alume: {
      status_banner: boolean;
      titulo_banner: string;
      subtitulo_banner: string;
      botao_banner: Botao;
    };

    depoimentos: {
      status_depoimentos: boolean;
      titulo_depoimentos: string;
      videos_depoimentos: [Video];
    };

    duvidas: {
      status_duvidas: boolean;
      titulo_duvidas: string;
      grupo_duvidas: [GrupoDuvidas];
    };

    footer: {
      status_footer: boolean;
      titulo_footer: string;
      email_footer: Botao;
    };
  };
}

const ExtensivoSP: React.FC = () => {
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState('');
  const [menuMobile, setMenuMobile] = useState(false);
  const [dados, setDados] = useState<Dados | null>(null);
  const hostName = window.location.hostname;
  const url_traffic_source = window.location.search;

  useEffect(() => {
    apiExtensivo
      .get(`pages/${hostName === 'extensivo.medway.com.br' ? `6125` : `8607`}`)
      .then(response => {
        setDados(response.data.acf);
      });
  }, []);

  // RefsToMenu
  const inscrevaBloco = useRef(null);
  const pilaresBloco = useRef(null);
  const garantiaBloco = useRef(null);
  const depoimentosBloco = useRef(null);
  const duvidasBloco = useRef(null);

  return (
    <>
      {dados ? (
        <>
          <Menu>
            <Logo src={Extensivo} alt="Extensivo Medway" />
            <BoxMenuMobile
              onClick={() => {
                setMenuMobile(!menuMobile);
              }}
              src={menuMobile ? CloseMenuIcon : MenuIcon}
            />
            <BoxMenu menuMobile={menuMobile}>
              <Link
                href="https://medway.com.br"
                menuRef={inscrevaBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                O Extensivo SP
              </Link>
              <Link
                href="https://medway.com.br"
                menuRef={pilaresBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Pilares
              </Link>
              <Link
                href="https://medway.com.br"
                menuRef={garantiaBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Garantia
              </Link>
              <Link
                href="https://medway.com.br"
                menuRef={depoimentosBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Depoimentos
              </Link>
              <Link
                href="https://medway.com.br"
                menuRef={duvidasBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Dúvidas
              </Link>
              <Button fill="contained" size="small" pattern="base" href="#Buy">
                Quero Comprar
              </Button>
            </BoxMenu>
          </Menu>

          {dados.extensivo_sp.hero.status_hero && (
            <BoxHero ref={inscrevaBloco}>
              <div className="boxContent">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: dados.extensivo_sp.hero.titulo_hero,
                  }}
                />
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: dados.extensivo_sp.hero.texto_hero,
                  }}
                />
                {/* <p className="text-discount">
                  Desconto de R$ 500 para as primeiras 500 compras!
                </p> */}
                <Button
                  fill="contained"
                  size="small"
                  pattern="base"
                  href="#Buy"
                >
                  {dados.extensivo_sp.hero.botao_hero.title}
                </Button>
              </div>
            </BoxHero>
          )}

          {dados.extensivo_sp.pilar.status_pilar && (
            <BoxPillars ref={pilaresBloco}>
              <div className="box-title">
                <h2 className="title">
                  {dados.extensivo_sp.pilar.titulo_pilares}
                </h2>
                <p className="description">
                  {dados.extensivo_sp.pilar.descricao_pilares}
                </p>
              </div>
              <div className="box-content">
                {dados.extensivo_sp.pilar.pilares.map((item, key) => (
                  <Card align="center" key={key}>
                    <Image
                      className="lazyload"
                      src=""
                      data-srcset={`${item.imagem_pilar.url} 1x`}
                      alt={item.imagem_pilar.url}
                    />
                    <H3 align="center">{item.titulo_pilar}</H3>
                    <H4 align="center">{item.subtitulo_pilar}</H4>
                    <Paragraph align="center" weight={400}>
                      {item.paragrafo_pilar}
                    </Paragraph>
                    <Separator />
                    <Button
                      onClick={() => {
                        setStatusModal(true);
                        setModalContent(item.conteudo_pilar);
                      }}
                      fill="outlined"
                      size="small"
                      pattern="base"
                    >
                      {item.botao_pilar.title}
                    </Button>
                  </Card>
                ))}
              </div>
              {(dados.extensivo_sp.pilar.extra_content.modal_title ||
              dados.extensivo_sp.pilar.extra_content.contents.length > 0) && (
                <section className="callToAction">
                  {dados.extensivo_sp.pilar.extra_content.modal_title && (
                  <div className="box-additional-content">
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: dados.extensivo_sp.pilar.extra_content.modal_title,
                      }}
                    />
                  </div>
                  )}
                  {dados.extensivo_sp.pilar.extra_content.contents.map((item, key) => (
                    <AdditionalContent
                      key={key}
                      logo_image={item.logo_image}
                      logo_alt_text={item.logo_alt_text}
                      text_above_button={item.text_above_button}
                      modal_text={item.modal_text}
                      button_text={item.button_text}
                    />
                  ))}
                </section>
              )}
            </BoxPillars>
          )}

          {dados.extensivo_sp.garantia.status_garantia && (
            <BoxWaranty ref={garantiaBloco}>
              <WarrantyLine
                title={dados.extensivo_sp.garantia.titulo_garantia}
                subtitle={dados.extensivo_sp.garantia.subtitulo_garantia}
                date={dados.extensivo_sp.garantia.data_garantia}
                text={dados.extensivo_sp.garantia.texto_garantia}
              />
            </BoxWaranty>
          )}

          {dados.extensivo_sp.depoimentos.status_depoimentos && (
            <BoxVideoTestemonial ref={depoimentosBloco}>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.extensivo_sp.depoimentos.titulo_depoimentos}
                  </h2>
                </div>
                <Testemonial nav dots loop={false}>
                  {dados.extensivo_sp.depoimentos.videos_depoimentos.map(
                    item => (
                      <BoxItem type="video">
                        <BoxVideo
                          dangerouslySetInnerHTML={{
                            __html: item.url_video,
                          }}
                        />
                        <BoxContent>
                          <TestemonialH3>{item.nome_video}</TestemonialH3>
                          <TestemonialDescription type="video">
                            {item.aprovacao_video}
                          </TestemonialDescription>
                        </BoxContent>
                      </BoxItem>
                    )
                  )}
                </Testemonial>
              </Container>
            </BoxVideoTestemonial>
          )}

          {dados.extensivo_sp.valores.status_valores && (
            <BoxBuy id="Buy">
              <Card align="center" buy>
                <H2 align="center" buy>
                  {dados.extensivo_sp.valores.titulo_valores}
                </H2>
                <H4 align="center" buy>
                  {dados.extensivo_sp.valores.subtitulo_valores}
                </H4>
                {dados.extensivo_sp.valores.inclusos_valores &&
                  dados.extensivo_sp.valores.inclusos_valores.map(item => (
                    <Courses
                      course={item.nome_incluso}
                      value={item.valor_incluso}
                    />
                  ))}
                {/* <Message type="sucess">
                  Desconto especial para as primeiras 500 vagas: R$500,00
                </Message> */}
                <Price type="old">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        dados.extensivo_sp.valores.preco_anterior_valores,
                    }}
                  />
                </Price>
                <Price type="current">
                  {dados.extensivo_sp.valores.preco_valores}
                </Price>
                <Price type="instalments">
                  {dados.extensivo_sp.valores.parcelas_valores}
                </Price>
                <H4 align="center" buy>
                  {dados.extensivo_sp.valores.boleto_valores}
                </H4>
                <Button
                  fill="contained"
                  size="big"
                  pattern="base"
                  fluid
                  href={`${dados.extensivo_sp.valores.botao_valores.url}${url_traffic_source}`}
                  target="_blank"
                >
                  {dados.extensivo_sp.valores.botao_valores.title}
                </Button>
              </Card>
            </BoxBuy>
          )}

          {dados.extensivo_sp.banner_alume.status_banner && (
            <Banner
              title={dados.extensivo_sp.banner_alume.titulo_banner}
              subtitle={dados.extensivo_sp.banner_alume.subtitulo_banner}
              button={dados.extensivo_sp.banner_alume.botao_banner}
            />
          )}

          {dados.extensivo_sp.duvidas.status_duvidas && (
            <BoxFaq ref={duvidasBloco}>
              <Faq
                title={dados.extensivo_sp.duvidas.titulo_duvidas}
                data={dados.extensivo_sp.duvidas.grupo_duvidas}
              />
            </BoxFaq>
          )}

          {dados.extensivo_sp.footer.status_footer && (
            <Footer>
              <div className="boxFooter">
                <p className="titleFooter">
                  {dados.extensivo_sp.footer.titulo_footer}{' '}
                  <a
                    className="mailto"
                    href={dados.extensivo_sp.footer.email_footer.url}
                  >
                    {dados.extensivo_sp.footer.email_footer.title}
                  </a>
                </p>
              </div>
              <AddressAndPolicies background = '' />
            </Footer>
          )}
          <Modal
            open={statusModal}
            setStatusModal={setStatusModal}
            modalContent={modalContent}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ExtensivoSP;
