import React, { useState, useRef, useEffect } from 'react';

import 'lazysizes';

// Components
import { Menu } from '../../../components/Vendas/Menu';
import { Logo } from '../../../components/Vendas/Menu/Logo';
import { BoxMenuMobile } from '../../../components/Vendas/Menu/BoxMenuMobile';
import { BoxMenu } from '../../../components/Vendas/Menu/BoxMenu';
import { Anchor } from '../../../components/Vendas/Menu/Anchor';
import { Button } from '../../../components/Vendas/Button';
import { Card } from '../../../components/Vendas/Card';
import { H2 } from '../../../components/Vendas/Card/H2';
import { H3 } from '../../../components/Vendas/Card/H3';
import { Message } from '../../../components/Vendas/Card/Message';
import { Price } from '../../../components/Vendas/Card/Price';
import { Paragraph } from '../../../components/Vendas/Card/Paragraph';
import { Image } from '../../../components/Vendas/Card/Image';
import { Modal } from '../../../components/Vendas/Modal';
import PopupExit from '../../../components/Vendas/PopUp/popup';
import { Gallery } from '../../../components/Vendas/Gallery';
import { Testemonial } from '../../../components/Vendas/Testemonial';
import { BoxItem } from '../../../components/Vendas/Testemonial/BoxItem';
import { BoxVideo } from '../../../components/Vendas/Testemonial/BoxVideo';
import { BoxContent } from '../../../components/Vendas/Testemonial/BoxContent';
import { TestemonialH3 } from '../../../components/Vendas/Testemonial/H3';
import { TestemonialDescription } from '../../../components/Vendas/Testemonial/Description';
import { Warranty } from '../../../components/Vendas/Waranty';
import { Faq } from '../../../components/Vendas/Faq';
import Loading from '../../../components/Loading';
import AddressAndPolicies from '../../../components/AddressAndPolicies';

// Services
import apiCRMedway from '../../../services/CRMedway/api';

// Assets
import CRLogo from '../../../assets/crmedway.svg';
import MenuIcon from '../../../assets/menu-icon.png';
import CloseMenuIcon from '../../../assets/close-menu-icon.png';

// Styles
import {
  Container,
  BoxHero,
  BoxPillars,
  BoxExperience,
  BoxVideoTestemonial,
  BoxWaranty,
  BoxBuy,
  BoxFaq,
  BoxFooter,
} from './styles';

// Interface

interface Botao {
  title: string;
  url: string;
  target: string;
}
interface Pilar {
  icone_pilar: {
    title: string;
    url: string;
  };
  titulo_pilar: string;
  texto_pilar: string;
  conteudo: string;
  botao_pilar: Botao;
}

interface VideoDepoimento {
  url_video: string;
  nome_video: string;
  aprovacao_video: string;
}

interface SubgrupoDuvidas {
  titulo_subgrupo: string;
  texto_subgrupo: string;
}

interface GrupoDuvidas {
  titulo_grupo: string;
  texto_grupo: string;
  subgrupo: [SubgrupoDuvidas];
}

interface Galeria {
  imagem_experiencia: {
    title: string;
    url: string;
  };
}

interface Dados {
  popup_exit: {
    status_popup: boolean;
    descricao: string;
    botao_pop_up: Botao;
  };

  vendas: {
    hero: {
      status_hero: boolean;
      video_hero: string;
      titulo_hero: string;
      texto_hero: string;
      botao_hero: Botao;
    };

    sobre: {
      status_sobre: boolean;
      titulo_sobre: string;
      pilares: [Pilar];
      botao_sobre: Botao;
    };

    experiencia: {
      status_experiencia: boolean;
      titulo_experiencia: string;
      subtitulo_experiencia: string;
      evento_experiencia: string;
      descricao_experiencia: string;
      imagens_experiencia: [Galeria];
    };

    depoimentos: {
      status_depoimentos: boolean;
      titulo_depoimentos: string;
      videos_depoimentos: [VideoDepoimento];
    };

    garantia: {
      status_garantia: boolean;
      titulo_garantia: string;
      subtitulo_garantia: string;
      data_garantia: string;
      texto_garantia: string;
    };

    valores: {
      status_valores: boolean;
      promocional_valores: boolean;
      titulo_valores: string;
      subtitulo_valores: string;
      inclusos_valores: boolean;
      mensagem_valores: string;
      preco_anterior_valores: string;
      preco_valores: string;
      parcelas_valores: string;
      boleto_valores: string;
      botao_valores: Botao;
    };

    duvidas: {
      status_duvidas: boolean;
      titulo_duvidas: string;
      grupo_duvidas: [GrupoDuvidas];
    };
  };
}
const CRMedwayVendas: React.FC = () => {
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState('');
  const [menuMobile, setMenuMobile] = useState(false);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [dados, setDados] = useState<Dados | null>(null);
  const hostName = window.location.hostname;
  const url_traffic_source = window.location.search;

  useEffect(() => {
    apiCRMedway
      .get(`pages/${hostName === 'cr.medway.com.br' ? `4901` : `10308`}`)
      .then(response => {
        setDados(response.data.acf);
      });
  }, []);

  // Refs to Menu
  const scrollTo = (e: any) => window.scrollTo(0, e.current.offsetTop - 50);
  const boxHeroRef = useRef(null);
  const boxPillarsRef = useRef(null);
  const boxExperienceRef = useRef(null);
  const boxTestemonialRef = useRef(null);
  const boxWarantyRef = useRef(null);
  const boxBuyRef = useRef(null);
  const boxFaqRef = useRef(null);

  return (
    <>
      {dados ? (
        <>
          <Menu>
            <Logo src={CRLogo} alt="CRMedway" />
            <BoxMenuMobile
              onMouseLeave={() => setIsPopUpVisible(true)}
              onClick={() => {
                setMenuMobile(!menuMobile);
              }}
              src={menuMobile ? CloseMenuIcon : MenuIcon}
            />
            <BoxMenu menuMobile={menuMobile}>
              <Anchor
                onMouseLeave={() => setIsPopUpVisible(true)}
                menuRef={boxHeroRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                O CRMedway
              </Anchor>
              <Anchor
                onMouseLeave={() => setIsPopUpVisible(true)}
                menuRef={boxPillarsRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Metodologia
              </Anchor>
              <Anchor
                onMouseLeave={() => setIsPopUpVisible(true)}
                menuRef={boxExperienceRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Experiência Presencial
              </Anchor>
              <Anchor
                onMouseLeave={() => setIsPopUpVisible(true)}
                menuRef={boxTestemonialRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Depoimentos
              </Anchor>
              <Anchor
                onMouseLeave={() => setIsPopUpVisible(true)}
                menuRef={boxWarantyRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Garantia
              </Anchor>
              <Anchor
                onMouseLeave={() => setIsPopUpVisible(true)}
                menuRef={boxFaqRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Dúvidas
              </Anchor>
              <Button
                fill="contained"
                size="small"
                pattern="base"
                onClick={() => {
                  scrollTo(boxBuyRef);
                  setMenuMobile(false);
                }}
              >
                Quero Comprar
              </Button>
            </BoxMenu>
          </Menu>

          {dados.vendas.hero.status_hero && (
            <BoxHero ref={boxHeroRef}>
              <section className="callToAction">
                <div className="boxContent">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas.hero.titulo_hero,
                    }}
                  />
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas.hero.texto_hero,
                    }}
                  />
                  <Button
                    fill="contained"
                    size="small"
                    pattern="base"
                    className="buttonHero"
                    onClick={() => {
                      scrollTo(boxBuyRef);
                    }}
                  >
                    {dados.vendas.hero.botao_hero.title}
                  </Button>
                </div>
                <div
                  className="boxVideoHero"
                  dangerouslySetInnerHTML={{
                    __html: dados.vendas.hero.video_hero,
                  }}
                />
              </section>
            </BoxHero>
          )}

          {dados.vendas.sobre.status_sobre && (
            <BoxPillars ref={boxPillarsRef}>
              <div className="box-title">
                <h2 className="title">{dados.vendas.sobre.titulo_sobre}</h2>
              </div>
              <div className="box-content">
                {dados.vendas.sobre.pilares.map((item, key) => (
                  <Card align="center" key={key}>
                    <Image
                      className="lazyload"
                      src=""
                      data-srcset={`${item.icone_pilar.url} 1x`}
                      alt={item.icone_pilar.title}
                    />
                    <H3 align="center">{item.titulo_pilar}</H3>
                    <Paragraph align="center" weight={400}>
                      {item.texto_pilar}
                    </Paragraph>
                    <Button
                      onClick={() => {
                        setStatusModal(true);
                        setModalContent(item.conteudo);
                      }}
                      fill="outlined"
                      size="small"
                      pattern="base"
                    >
                      {item.botao_pilar.title}
                    </Button>
                  </Card>
                ))}
              </div>
              <div className="box-buttons">
                <Button
                  href={dados.vendas.sobre.botao_sobre.url}
                  target={dados.vendas.sobre.botao_sobre.target}
                  fill="outlined"
                  size="small"
                  pattern="variant"
                >
                  {dados.vendas.sobre.botao_sobre.title}
                </Button>
              </div>
            </BoxPillars>
          )}

          {dados.vendas.experiencia.status_experiencia && (
            <BoxExperience ref={boxExperienceRef}>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.vendas.experiencia.titulo_experiencia}
                  </h2>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas.experiencia.subtitulo_experiencia,
                    }}
                  />
                </div>

                <div className="box-event-title">
                  <h3 className="title">
                    {dados.vendas.experiencia.evento_experiencia}
                  </h3>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas.experiencia.descricao_experiencia,
                    }}
                  />
                </div>
              </Container>
              <div className="box-gallery">
                <Gallery nav dots loop={false}>
                  {dados.vendas.experiencia.imagens_experiencia.map(item => (
                    <img
                      className="lazyload"
                      src=""
                      data-srcset={`${item.imagem_experiencia.url} 1x`}
                      alt={item.imagem_experiencia.title}
                    />
                  ))}
                </Gallery>
              </div>
            </BoxExperience>
          )}

          {dados.popup_exit.status_popup &&
            (isPopUpVisible ? (
              <PopupExit
                botao_pop_up={dados.popup_exit.botao_pop_up}
                onClose={() => setIsPopUpVisible(false)}
              >
                {dados.popup_exit.descricao}
              </PopupExit>
            ) : null)}

          {dados.vendas.depoimentos.status_depoimentos && (
            <BoxVideoTestemonial ref={boxTestemonialRef}>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.vendas.depoimentos.titulo_depoimentos}
                  </h2>
                </div>
                <Testemonial nav dots loop={false}>
                  {dados.vendas.depoimentos.videos_depoimentos.map(item => (
                    <BoxItem type="video">
                      <BoxVideo
                        dangerouslySetInnerHTML={{
                          __html: item.url_video,
                        }}
                      />
                      <BoxContent>
                        <TestemonialH3>{item.nome_video}</TestemonialH3>
                        <TestemonialDescription type="video">
                          {item.aprovacao_video}
                        </TestemonialDescription>
                      </BoxContent>
                    </BoxItem>
                  ))}
                </Testemonial>
              </Container>
            </BoxVideoTestemonial>
          )}

          {dados.vendas.garantia.status_garantia && (
            <BoxWaranty ref={boxWarantyRef}>
              <Warranty
                title={dados.vendas.garantia.titulo_garantia}
                subtitle={dados.vendas.garantia.subtitulo_garantia}
                date={dados.vendas.garantia.data_garantia}
                text={dados.vendas.garantia.texto_garantia}
              />
            </BoxWaranty>
          )}

          {dados.vendas.valores.status_valores && (
            <BoxBuy ref={boxBuyRef} id="buy">
              <Card
                align="center"
                buy
                flag={dados.vendas.valores.promocional_valores}
              >
                <H2 align="center" buy>
                  {dados.vendas.valores.titulo_valores}
                </H2>
                <H3 align="center" buy>
                  {dados.vendas.valores.subtitulo_valores}
                </H3>
                {dados.vendas.valores.mensagem_valores && (
                  <Message
                    type="sucess"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas.valores.mensagem_valores,
                    }}
                  />
                )}
                <Price
                  type="old"
                  dangerouslySetInnerHTML={{
                    __html: dados.vendas.valores.preco_anterior_valores,
                  }}
                />
                <Price type="current">
                  {dados.vendas.valores.preco_valores}
                </Price>
                <Price type="percentage">
                  {dados.vendas.valores.boleto_valores}
                </Price>
                <Price type="instalments">
                  {dados.vendas.valores.parcelas_valores}
                </Price>
                <Button
                  href={`${dados.vendas.valores.botao_valores.url}${url_traffic_source}`}
                  fill="contained"
                  target={dados.vendas.valores.botao_valores.target}
                  size="big"
                  pattern="base"
                  fluid
                >
                  {dados.vendas.valores.botao_valores.title}
                </Button>
              </Card>
            </BoxBuy>
          )}

          {dados.vendas.duvidas.status_duvidas && (
            <BoxFaq ref={boxFaqRef}>
              <Faq
                title={dados.vendas.duvidas.titulo_duvidas}
                data={dados.vendas.duvidas.grupo_duvidas}
              />
            </BoxFaq>
          )}

          <BoxFooter>
            <div className="boxFooter">
              <p className="titleFooter">
                Ainda tem dúvidas? Entre em contato com a gente{' '}
                <a className="mailto" href="mailto:duvidas@medway.com.br">
                  duvidas@medway.com.br
                </a>
              </p>
            </div>
            <AddressAndPolicies background = '' />
          </BoxFooter>

          <Modal
            open={statusModal}
            setStatusModal={setStatusModal}
            modalContent={modalContent}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CRMedwayVendas;
