import React from 'react';

import { Container } from './styles';

interface Props extends HTMLButtonElement {
  title: string;
  url: string;
  color?: string;
  background?: string;
  paddingTopBottom?: number;
  paddingLeftRight?: number;
}

export function Button({
  title,
  url,
  color,
  background,
  paddingTopBottom,
  paddingLeftRight,
  ...rest
}: Props) {
  return (
    <Container
      href={url}
      color={color}
      background={background}
      paddingTopBottom={paddingTopBottom}
      paddingLeftRight={paddingLeftRight}
      {...rest}
    >
      {title}
    </Container>
  );
}
