import styled, { css } from 'styled-components';

// Assets

// Interface
interface Props {
  type: 'video' | 'text';
}

export const Container = styled.div<Props>`
  color: #00205b;
  font-size: 14px;
  font-weight: 400;

  ${({ type }) =>
    type === 'text' &&
    css`
      margin-bottom: 20px;
      font-weight: 300;
    `}
`;
