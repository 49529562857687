import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.h3`
  color: #00205b;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;
