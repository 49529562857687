import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';

// Components
import Loading from '../../components/Loading';

// Services
// import apiExtensivo from '../../services/Extensivo/api';
// import apiIntensivo from '../../services/Intensivo/api';
import apiMentoria from '../../services/Mentoria/api';
// import apiCRMedway from '../../services/CRMedway/api';
// import apiPSMedway from '../../services/PSMedway/api';

import apiAnaliseDeDados from '../../services/Analise-de-dados/api';
// Assets

// Styles
import {
  Container,
  ModalContainer,
  ModalContent,
  ModalBoxText,
} from './styles';

// Interface
interface Logo {
  title: string;
  alt: string;
  sizes: {
    '1920x1080': string;
  };
}
interface Background {
  title: string;
  sizes: {
    '1920x1080': string;
  };
}
interface Botao {
  url: string;
  title: string;
  target: string;
}
interface Lista {
  nome_aula: string;
  logo_lista: Logo;
  background_lista: Background;
  link_lista: Botao;
  semana_lista: [];
}

const NovaListaDePresenca: React.FC = () => {
  const ID = Cookies.get('pageID');
  const [email, setEmail] = useState('');
  const [aula, setAula] = useState<Lista>();
  const [section, setSection] = useState(false);
  const [modal, setModal] = useState(false);
  const hostName = window.location.hostname;

  console.log(ID);

  useEffect(() => {
    apiMentoria
    .get(`acf/v3/posts/${ID}`)
    .then((response: AxiosResponse) => {
      setAula(response.data.acf.lista);
    });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const params = new URLSearchParams(window.location.search);
    const course = window.location.pathname;
    const utm_source = params.get('utm_source');
    const utm_medium = params.get('utm_medium');
    const utm_campaign = params.get('utm_campaign');
    const utm_term = params.get('utm_term');
    const utm_content = params.get('utm_content');

    switch (hostName) {
      case 'mentoria.medway.com.br':
        apiAnaliseDeDados.post('/lista-de-presenca/mentoria', {
          email,
          aula: aula?.link_lista.title,
          url: aula?.link_lista.url,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
        });
        break;
      case 'extensivo.medway.com.br':
        const courseName = course.split('/')[1];
        if (courseName !== 'lista-de-presenca') {
          apiAnaliseDeDados.post('/lista-de-presenca/type-course', {
            curso: `extensivo-${courseName}`,
            email,
            aula: aula?.link_lista.title,
            url: aula?.link_lista.url,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
          });
        } else {
          apiAnaliseDeDados.post('/lista-de-presenca/extensivo', {
            email,
            aula: aula?.link_lista.title,
            url: aula?.link_lista.url,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
          });
        }
        break;
      case 'cr.medway.com.br':
        apiAnaliseDeDados.post('/lista-de-presenca/crmedway', {
          email,
          aula: aula?.link_lista.title,
          url: aula?.link_lista.url,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
        });
        break;
      case 'ps.medway.com.br':
        apiAnaliseDeDados.post('/lista-de-presenca/psmedway', {
          email,
          aula: aula?.link_lista.title,
          url: aula?.link_lista.url,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
        });
        break;
      default:
        break;
    }
    setSection(true);
  };

/*  const setSemana = (response: AxiosResponse) => {
    aula?.semana_lista.map((item: any) => {
      if (item) {
        setAula({
          nome_aula: response.data.acf.lista.nome_aula,
          logo_lista: response.data.acf.lista.logo_lista,
          background_lista:response.data.acf.lista.backgound_lista,
          link_lista: response.data.acf.lista.link_lista,
          semana_lista: item,
        });
      }
    })
   
  };  */

  

  useEffect(() => {
    if (!aula) {
      setTimeout(() => {
        setModal(true);
      }, 2000);
    }
  }, [aula]);

  return (
    <>
      {aula ? (
        <Container bg={aula.background_lista.sizes['1920x1080']}>
          <img
            src={aula.logo_lista.sizes['1920x1080']}
            alt={aula.logo_lista.title}
          />
          <h1
            dangerouslySetInnerHTML={{
              __html: aula.nome_aula,
            }}
          />
          {section ? (
            <>
              <a href={aula.link_lista.url} target="_blank" rel="noreferrer">
                {aula.link_lista.title}
              </a>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <label htmlFor="InputEmail">
                  Seu E-mail cadastrado na Medway
                </label>
                <input
                  id="InputEmail"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="alguem@exemplo.com"
                  required
                />
                <button type="submit">Enviar</button>
              </form>
            </>
          )}
        </Container>
      ) : (
        <>
          <Loading />
          <ModalContainer open={modal}>
            <ModalContent>
              <ModalBoxText>
                <h2>Oopss...</h2>
                <p>Aparemente o link que você acessou não está correto.</p>
                <p>
                  Para solucionar este problema, entre em contato com nossa
                  equipe de suporte para obter um novo link.
                </p>
              </ModalBoxText>
            </ModalContent>
          </ModalContainer>
        </>
      )}
    </>
  );
};

export default NovaListaDePresenca;
