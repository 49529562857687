import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.section`
    display: flex;
    align-items: center;
`;

export const Image = styled.img`
    height: 60px;

    @media only screen and (max-width: 1024px) {
        max-width: 200px;
    }
`;
