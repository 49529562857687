import React from "react";
import { Button } from '../../../components/Vendas/Button';


// Styles
import { Container, Content, Close, BoxText } from './styles';

// interfaces

interface Botao {
    title: string;
    url: string;
    target: string;
  }

interface PopupProps {
    children: any;
    onClose: any;
    id?: any;
    botao_pop_up: Botao;


}
 
const Popup = ({id="popup", onClose = () => {}, children, botao_pop_up}: PopupProps) => {
    const handleOutSideClick = (e: { target: any; }) => {
        if(e.target.id === id) onClose();
    }
    
    return (
        <Container
            id={id}
            onClick={handleOutSideClick}
        >
            <Content>
                <Close
                   onClick={onClose}
                   type="button"
                />
                <BoxText
                    dangerouslySetInnerHTML={{
                        __html: children,
                    }}
                />
                <Button
                    className="button-link"
                    href= {botao_pop_up.url}
                    fill="contained"
                    size="small"
                    target={botao_pop_up.target}
                    pattern="base"
                    
                >
                   {botao_pop_up.title}
                </Button>
            </Content>
        </Container>
    );
};

export default Popup;