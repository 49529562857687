import styled from 'styled-components';

// Assets

// Interface
export const Container = styled.section`

  .accordion {
    margin: auto;
    max-width: 900px;
    width: 100%;
  }

  /* display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
  width: 100%;

  .accordion {
    width: 100%;
    max-width: 540px;
    margin: 18px;
    color: #fff;
  } */

  /* display: flex;
  justify-content: center;

  .titleAcordiao {
    display: flex;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
  }

  .container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }



  .accordingHeading {
    background: tomato;
    padding: 14px 0;
  }

  .accordingHeading .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .accordingHeading p {
    letter-spacing: 1.2px;
    font-weight: 600;
  }

  .accordingHeading span {
    cursor: pointer;
  }

  .accordionContent {
    background: blue;
    /* height: 0;
      opacity: 0; */
    display: flex;
    overflow: hidden;
    transition: all 600ms ease-in-out;
  }

  .accordionContent.show {
    height: 180px;
    opacity: 1;
  }

  .accordionContent p {
    font-size: 15px;
    line-height: 1.6;
  } */
`;

export const Box = styled.div``;

export const AC = styled.div`
  margin: 15px 0;
`;
