import React from 'react';

// Styles
import {
  Container,
  BoxText,
  Title,
  Subtitle,
  Section,
  ButtonBox
} from './styles';

// Interface
interface Props {
  reference: any;
  free_trial_info: {
    background_color: string;
    activate_background_image: boolean;
    background_image: string;
    margin_bottom_color: string;
    text_color: string;
    title: string;
    subtitle: string;
    activate_button: boolean;
    button_text_color: string;
    button_background_color: string;
    button_hover_background_color: string;
    button_text: string;
    button_hover_text_color: string;
    button_link: string;
  }
}

export function FreeTrialBanner(
  { reference, free_trial_info}: Props) {
  return (
    <Section ref={reference} margin_bottom_color={free_trial_info.margin_bottom_color}>
      <Container
        background_color = {free_trial_info.background_color}
        activate_background_image = {free_trial_info.activate_background_image}
        background_image = {free_trial_info.background_image}>
        {(free_trial_info.title || free_trial_info.subtitle) && (
          <BoxText
            text_color = {free_trial_info.text_color}>
            {free_trial_info.title && (
              <Title>{free_trial_info.title}</Title>
            )}
            {free_trial_info.subtitle  && (
              <Subtitle
                dangerouslySetInnerHTML={{
                  __html: free_trial_info.subtitle,
              }}/>
            )}
          </BoxText>
        )}
        {free_trial_info.activate_button && (free_trial_info.button_text
          && free_trial_info.button_link) && (
          <ButtonBox
            button_text_color = {free_trial_info.button_text_color}
            button_background_color = {free_trial_info.button_background_color}
            button_hover_background_color = {free_trial_info.button_hover_background_color}
            button_hover_text_color = {free_trial_info.button_hover_text_color}
            href = {free_trial_info.button_link}
            fill = "contained"
            size = "small"
            pattern = "base"
            fluid
          >
            {free_trial_info.button_text}
          </ButtonBox>
        )}
      </Container>
    </Section>
  );
}
