import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.h3`
  border-bottom: 4px solid #00cfb4;
  color: #00205b;
  font-size: 60px;
  font-weight: 700;
  margin: 80px auto 38px;
  padding-bottom: 12px;
  width: fit-content;

  @media (orientation: portrait) {
    font-size: 32px;
    margin: 36px auto 28px;
  }
`;
