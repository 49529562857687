import styled from 'styled-components';

interface ContainerProps {
  title: string;
  url: string;
  color?: string;
  background?: string;
  paddingTopBottom?: number;
  paddingLeftRight?: number;
}

export const Container = styled.a<ContainerProps>`
  display: inline-block;

  font-size: 24px;
  color: ${({ color }) => color || '#FFFFFF'};
  background-color: ${({ background }) => background || '#00CFB4'};
  text-transform: uppercase;
  font-weight: bold;

  padding: ${({ paddingTopBottom }) => paddingTopBottom || 20}px
    ${({ paddingLeftRight }) => paddingLeftRight || 40}px;

  border-radius: 50px;

  text-decoration: none;

  transition: all 350ms ease-in-out;

  &:hover {
    color: ${({ color }) => color || '#FFFFFF'};
    text-decoration: none;

    opacity: 0.7;
  }
`;
