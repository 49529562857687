import axios from 'axios';

let url = '';
const { protocol, hostname } = window.location;

switch (hostname) {
  case 'medway.com.br':
  case 'www.medway.com.br':
    url = 'https://www.medway.com.br/wp-json/';
    break;
  case 'fmedway.com.br':
    url = `${protocol}//fmedway.com.br/wp-json/`;
    break;
  case 'ps.medway.com.br':
    url = `${protocol}//ps.medway.com.br/wp-json/`;
    break;
  default:
    break;
}

const apiLinktree = axios.create({
  baseURL: url,
});

export default apiLinktree;
