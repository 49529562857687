import styled, { css } from 'styled-components';

// Assets

// Interface
interface Props {
  type: 'alert' | 'error' | 'sucess';
}

export const Container = styled.div<Props>`
  margin-bottom: 35px;
  margin-top: 35px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;

  @media (orientation: portrait) {
    margin-bottom: 28px;
    margin-top: 28px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
  }

  p {
    font-size: 16px;
    font-weight: 600;

    @media (orientation: portrait) {
      font-size: 14px;
    }
  }

  ${({ type }) =>
    type === 'sucess' &&
    css`
      background-color: #ddfbff;
      color: #00cfb4;
    `}

  p {
    margin: 0;
  }
`;
