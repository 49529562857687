import styled from 'styled-components';

// Assets
import Next from '../../../assets/green-next-arrow.svg';
import Prev from '../../../assets/green-prev-arrow.svg';

// Interface

export const Container = styled.div`
  .owl-theme {
    @media (orientation: portrait) {
      margin: 0px 2px 0px 0px !important;
    }
    .owl-dots {
      margin-top: 60px !important;

      @media (orientation: portrait) {
        margin: 32px !important;
      }

      .owl-dot {
        span {
          background-color: #dadada;
          border-radius: 20px;
          height: 8px;
          margin-left: 8px;
          margin-right: 8px;
          transition: background-color ease-in-out 0.3s;
          width: 55px;

          @media (orientation: portrait) {
            height: 8px;
            margin-left: 5px;
            margin-right: 5px;
            width: 36px;
          }
        }

        &.active {
          span {
            background-color: #00cfb4;
            transition: background-color ease-in-out 0.3s;
          }
        }
      }
    }

    .owl-nav {
      font-size: 0;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 35px);
      transform: translateY(-50%);

      span {
        display: none;
      }

      .owl-prev {
        height: 36px;
        left: -61px;
        margin: 0;
        opacity: 1;
        position: absolute;
        transition: opacity ease-in-out 0.3s;
        width: 21px;

        @media (orientation: portrait) {
          height: 33px !important;
          left: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Prev});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 36px;
          position: absolute;
          right: 0;
          width: 21px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }

      .owl-next {
        height: 36px;
        margin: 0;
        opacity: 1;
        position: absolute;
        right: -61px;
        transition: opacity ease-in-out 0.3s;
        width: 21px;

        @media (orientation: portrait) {
          height: 33px !important;
          right: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Next});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 36px;
          position: absolute;
          right: 0;
          width: 21px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }
    }
  }
`;
