import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ListaDePresenca from '../pages/ListaDePresenca';
import Aplicativo from '../pages/Aplicativo';
import Checkouts from '../pages/Checkouts';
import QRCodeMedway from '../pages/QRCode';
import Cursos from '../pages/Cursos';
import TermoDeCompromissoMensageiro from '../pages/TermoDeCompromissoMensageiro/index.js';
import ProjetoR1 from '../pages/ProjetoR1/index.js';
import ProjetoR1Interna from '../pages/ProjetoR1Interna/index.js';
import FinalmenteResidente from '../pages/FinalmenteResidente/index.js';
import FinalmenteResidenteInterna from '../pages/FinalmenteResidenteInterna/index.js';
import SejaNossoParceiro from '../pages/SejaNossoParceiro';
import NovaHome from '../pages/NewHome';
import ListaDePresencaMinicurso from '../pages/ListaDePresencaMinicurso';
import Certificado from '../pages/Certificado';
import Eventos from '../pages/Eventos';
import { Guides } from '../pages/Guides';
import Experimental from '../pages/Experimental';
import ListaDePresencaV2 from '../pages/ListaDePresencaV2';
import ExtensivoHub from '../pages/ExtensivoHub';
import ExtensivoSP from '../pages/ExtensivoSP';
import ExtensivoProgramado from '../pages/ExtensivoProgramado';
import ExtensivoBase from '../pages/ExtensivoBase';
import MentoriaVendas from '../pages/Vendas/Mentoria';
import R3Clinica from '../pages/Vendas/R3Clinica';
import CRMedwayVendas from '../pages/Vendas/CRMedway';
import AntimicrobianoVendas from '../pages/Vendas/PapoAntimicrobiano';
import R3Cirurgia from '../pages/Vendas/R3Cirurgia';
import NovaListaDePresenca from '../pages/NovaListaDePresenca';
import ECG from '../pages/Gratis/Ecg';
import Internato from '../pages/Gratis/Internato';
import LinkTree from '../pages/LinkTree';
import IntensivoSP from '../pages/Vendas/IntensivoSP';
import PSMedwayVendas from '../pages/Vendas/PSMedway';

const Routes: React.FC = () => {
  const hostName = window.location.hostname;

  return (
    <Switch>
      {/* Medway */}
      <Route path="/aplicativo" component={Aplicativo} />
      <Route path="/lista-de-presenca/:slug" component={NovaListaDePresenca} />
      <Route path="/lista-de-presenca" component={ListaDePresenca} />
      <Route path="/checkouts" component={Checkouts} />
      <Route path="/qrcode" component={QRCodeMedway} />
      <Route path="/cursos" component={Cursos} />
      <Route
        path="/termo-de-compromisso-menssageiros"
        component={TermoDeCompromissoMensageiro}
      />
      <Route path="/projeto-r1-sp" exact component={ProjetoR1} />
      <Route path="/projeto-r1-sp/:ep" component={ProjetoR1Interna} />
      <Route
        path="/finalmente-residente"
        exact
        component={FinalmenteResidente}
      />
      <Route
        path="/finalmente-residente/:ep"
        component={FinalmenteResidenteInterna}
      />
      <Route path="/embaixadores" component={SejaNossoParceiro} />
      <Route path="/nova-home" component={NovaHome} />
      <Route path="/guias" exact component={Guides} />
      <Route path="/curso-de-eletrocardiograma" component={ECG} />
      <Route path="/curso-internato" component={Internato} />
      <Route
        path="/lista-de-presenca-minicurso/certificado"
        exact
        component={Certificado}
      />
      <Route
        path="/lista-de-presenca-minicurso/:aula"
        component={ListaDePresencaMinicurso}
      />
      <Route path="/parceria-em-eventos" component={Eventos} />
      <Route path="/experimental/:aula" component={Experimental} />
      <Route path="/lista-de-presenca-v2/:aula" component={ListaDePresencaV2} />
      <Route
        path="/lista-de-presenca-gasometria/certificado"
        exact
        component={Certificado}
      />
      <Route
        path="/lista-de-presenca-gasometria/:aula"
        component={ListaDePresencaV2}
      />
      <Route
        path="/lista-de-presenca-ecg/certificado"
        exact
        component={Certificado}
      />
      <Route
        path="/lista-de-presenca-ecg/:aula"
        component={ListaDePresencaV2}
      />
      <Route
        path="/lista-de-presenca-iot/certificado"
        exact
        component={Certificado}
      />
      <Route
        path="/lista-de-presenca-iot/:aula"
        component={ListaDePresencaV2}
      />
      <Route
        path="/lista-de-presenca-vm-2021/certificado"
        exact
        component={Certificado}
      />
      <Route
        path="/lista-de-presenca-vm-2021/:aula"
        component={ListaDePresencaV2}
      />
      <Route
        path="/lista-de-presenca-trauma-2021/certificado"
        exact
        component={Certificado}
      />
      <Route
        path="/lista-de-presenca-trauma-2021/:aula"
        component={ListaDePresencaV2}
      />

      {/* Linktree */}
      <Route path="/linktree" component={LinkTree} />
      <Route path="/linkdabio" component={LinkTree} />
      {hostName === 'ps.medway.com.br' && (
        <Route path="/linkdabio-psmeday" component={LinkTree} />
      )}

      {/* PSMedway */}
      {hostName === 'ps.medway.com.br' ? (
        <Route path="/" component={PSMedwayVendas} />
      ) : (
        <Route path="/psmedway-vendas" component={PSMedwayVendas} />
      )}
      {hostName === 'ps.medway.com.br' && (
        <Route path="/psmedway-vendas" component={PSMedwayVendas} />
      )}

      {/* Extensivo */}
      {// Home Extensivo
      hostName === 'extensivo.medway.com.br' ? (
        <Route path="/" exact component={ExtensivoHub} />
      ) : (
        <Route path="/hub" exact component={ExtensivoHub} />
      )}
      {// Extensivo São Paulo
      hostName === 'extensivo.medway.com.br' ? (
        <Route path="/sao-paulo" exact component={ExtensivoSP} />
      ) : (
        <Route path="/extensivo-sao-paulo" exact component={ExtensivoSP} />
      )}
      <Route path="/sao-paulo/lista-de-presenca" component={ListaDePresenca} />
      <Route path="/programado" exact component={ExtensivoProgramado} />
      <Route path="/programado/lista-de-presenca" component={ListaDePresenca} />
      <Route path="/base" exact component={ExtensivoBase} />
      <Route path="/base/lista-de-presenca" component={ListaDePresenca} />

      {/* Mentoria */}
      {// Home Mentoria
      hostName === 'mentoria.medway.com.br' ? (
        <Route path="/" component={MentoriaVendas} />
      ) : (
        <Route path="/mentoria-vendas" component={MentoriaVendas} />
      )}

      {/* R3 */}
      <Route path="/clinica-medica" component={R3Clinica} />
      <Route path="/cirurgia" component={R3Cirurgia} />

      {/* CRMedway */}
      {hostName === 'cr.medway.com.br' ? (
        <Route path="/" component={CRMedwayVendas} />
      ) : (
        <Route path="/crmedway-vendas" component={CRMedwayVendas} />
      )}
      {hostName === 'cr.medway.com.br' && (
        <Route path="/crmedway-2022" component={CRMedwayVendas} />
      )}

      {/* Cursos */}
      <Route path="/papo-de-antimicrobianos" component={AntimicrobianoVendas} />

      {/* Intensivo */}
      {hostName === 'intensivo.medway.com.br' ? (
        <Route path="/sao-paulo" component={IntensivoSP} />
      ) : (
        <Route path="/intensivo-sao-paulo" component={IntensivoSP} />
      )}
    </Switch>
  );
};
export default Routes;
