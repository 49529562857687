import React from 'react';

// Plugins
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  nav: boolean;
  dots: boolean;
  loop: boolean;
}

function Carousel({ children, nav, dots, loop=true }: Props) {
  return (
    <Container>
      <OwlCarousel
        className="owl-theme"
        margin={0}
        responsive={{
          0: {
            items: 2,
            margin:0,
            loop,
            autoplay: true,
            autoplayTimeout: 2500,
            nav,
            dots,
          },
          654: {
            items: 4,
            margin: 5,
            autoplay: false,
            autoplayTimeout: 2500,
            nav,
            dots,
          },
        }}
      >
        {children}
      </OwlCarousel>
    </Container>
  );
}
export default Carousel;