import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface BoxProps {
  children: React.ReactNode;
  menuMobile: boolean;
}

export function BoxMenu({ children, menuMobile }: BoxProps) {
  return <Container menuMobile={menuMobile}>{children}</Container>;
}
