import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.section`
  display: flex;
  align-items: center;
  background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%);
  color: #ffffff;
  gap: 0 80px;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 60px;

  @media (orientation: portrait) {
    flex-direction: column;
    padding-top: 28px;
    padding-bottom: 28px;
  }
`;

export const BoxTitle = styled.div`
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 40px;
  font-weight: 700;
  margin: 0;

  @media (orientation: portrait) {
    font-size: 24px;
  }
`;

export const Line = styled.hr`
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 2px solid #00cfb4;
  margin: 20px auto;
  opacity: 1;
  width: 220px;

  @media (orientation: portrait) {
    margin: 12px auto 24px;
    width: 123px;
  }
`;

export const Subtitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 6px;

  @media (orientation: portrait) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Date = styled.span`
  font-size: 40px;
  font-weight: 700;

  @media (orientation: portrait) {
    font-size: 22px;
  }
`;

export const BoxText = styled.div`
  flex-basis: 480px;
  text-align: center;

  @media (orientation: portrait) {
    flex-basis: auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 16px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin: 0;

  @media (orientation: portrait) {
    font-size: 12px;
  }
`;
