import styled from 'styled-components';

// Assets
import iconCalendar from '../../../../assets/icone-calendario.png';
import iconTime from '../../../../assets/icone-tempo.png';

// Interface

export const Container = styled.section`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(240, 244, 255, 1) 50%
  );

  @media (orientation: portrait) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%), #407ec9;
  border-radius: 20px;
  justify-content: center;
  margin-left: 70px;
  margin-right: 70px;
  padding-top: 52px;
  padding-bottom: 52px;

  @media (orientation: portrait) {
    margin-left: 0;
    margin-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
`;

export const BoxLogos = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 50%;
  justify-content: space-evenly;
  border-right: 1px solid #ffffff;

  @media (orientation: portrait) {
    flex-direction: column;
    border-right: 0;
    border-bottom: 1px solid #ffffff;
  }
`;

export const Logo = styled.img`
  height: 96px;

  @media (orientation: portrait) {
    height: auto;
    margin-bottom: 24px;
    width: 160px;
  }
`;

export const BoxDates = styled.div`
  display: flex;
  align-items: center;
  flex-basis: calc(50% - 1px);
  justify-content: space-evenly;

  @media (orientation: portrait) {
    display: initial;
  }
`;

export const StartDate = styled.span`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  text-align: left;

  @media (orientation: portrait) {
    margin: 32px 0;
    font-size: 14px;
    display: block;
  }

  &::before {
    background-image: url(${iconCalendar});
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 38px;
    left: -40px;
    position: absolute;
    top: 0;
    width: 38px;
  }

  span {
    display: block;
  }
`;

export const EndDate = styled.span`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  text-align: left;

  @media (orientation: portrait) {
    font-size: 14px;
    display: block;
  }

  &::before {
    background-image: url(${iconTime});
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 38px;
    left: -40px;
    position: absolute;
    top: 0;
    width: 38px;
  }

  span {
    display: block;
  }
`;
