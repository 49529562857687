import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.div`
  padding-top: 32px;
  text-align: center;
`;
