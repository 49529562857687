import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.section`
  .callToAction {
    display: flex;
    align-items: center;
    background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%);
    border-radius: 20px;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    margin: 72px auto 0;
    max-width: 1000px;
    padding-bottom: 48px;
    padding-top: 48px;
    width: 100%;

    @media (orientation: portrait) {
      gap: 38px;
      padding-left: 38px;
      padding-right: 38px;
    }

    .box-additional-content {
      display: flex;
      align-items: center;
      flex-basis: auto;
      flex-direction: column;
      justify-content: center;

      .text {
        color: #ffffff;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;

        @media (orientation: portrait) {
          text-align: center;
        }
      }
      .logo {
        @media (orientation: portrait) {
          max-width: 160px;
        }
      }
    }

    .box-button {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: 360px;
      width: 100%;

      .text {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 16px;
        text-align: center;

        @media (orientation: portrait) {
          font-size: 16px;
          margin-bottom: 26px;
        }
      }
    }
  }
`;
