import React from 'react';

// Styles
import { Container, Course, Value } from './styles';

// Interface
interface Props {
  course: string;
  value: string;
}

export function Courses({ course, value }: Props) {
  return (
    <Container>
      <Course>{course}</Course>
      <Value>{value}</Value>
    </Container>
  );
}
