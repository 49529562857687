import React from 'react';

// Styles
import {
  Container,
  BoxTitle,
  Title,
  Line,
  Subtitle,
  Date,
  BoxText,
  Text,
} from './styles';

// Interface
interface Props {
  title: string;
  subtitle: string;
  date: string;
  text: string;
}

export function Warranty({ title, subtitle, date, text }: Props) {
  return (
    <Container>
      <BoxTitle>
        <Title>{title}</Title>
        <Line />
        <Subtitle>{subtitle}</Subtitle>
        <Date>{date}</Date>
      </BoxTitle>
      <BoxText>
        <Text
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </BoxText>
    </Container>
  );
}
