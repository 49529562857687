import React, { ImgHTMLAttributes } from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props extends ImgHTMLAttributes<HTMLImageElement> {}

export function BoxMenuMobile({ ...props }: Props) {
  return <Container {...props} />;
}
