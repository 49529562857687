import React from 'react';

// Plugins
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  nav: boolean;
  dots: boolean;
  loop: boolean;
}

export function Gallery({ children, nav, dots, loop }: Props) {
  return (
    <Container>
      <OwlCarousel
        className="owl-theme"
        margin={30}
        responsive={{
          0: {
            items: 1,
            loop,
            autoplay: false,
            autoplayTimeout: 3500,
            nav,
            dots,
          },
          991: {
            items: 1,
            loop,
            autoplay: false,
            autoplayTimeout: 3500,
            nav,
            dots,
          },
        }}
      >
        {children}
      </OwlCarousel>
    </Container>
  );
}
