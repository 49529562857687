import styled from 'styled-components';

// Assets
// Interface
export const Container = styled.div`
  width: 100%;
  max-width: 654px;
  margin: auto;
`;

export const BoxHeader = styled.section`
  display: block;
  max-width: 654px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px 12px;

  img {
    padding: 16px 12px;
    justify-content: center;
    text-align: center;
    margin: auto;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  @media (orientation: landscape) {
    line-height: 2;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    p {
      color: #000000;
      margin: 0 auto;
    }
  }

  @media (orientation: portrait) {
    justify-content: center;
    align-items: center;

    p {
      color: #000000;
      margin: 0 auto;
    }
  }
`;

export const BoxButtons = styled.section`
  display: block;
  max-width: 654px;
  justify-content: center;
  align-items: center;
  margin: 10px 5px;

  @media (orientation: portrait) {
    display: flex;
    flex-wrap: wrap;
    max-width: 654px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 10px 5px 0 0;
  }
`;

export const Title = styled.div`
  width: 100%;
  padding: 6px 8px;
  text-align: center;
  margin: 10px 5px 0 0;
  text-align: center;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  @media (orientation: portrait) {
    text-align: center;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
`;

export const BoxHighlights = styled.section`
  justify-content: center;
  align-items: center;
  padding: 6px 8px;

  img {
    width: 144px !important;
    height: 144px !important;
    padding: 6px 8px;
  }

  @media (orientation: portrait) {
    border-radius: 5px;
    padding: 6px 8px;
    margin: 8px 5px;

    img {
      margin: 0 auto !important;
      width: 136px !important;
      height: 136px !important;
      padding: 6px !important;
    }
  }
`;

export const BoxBanner = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px 12px;
  margin: 30px 5px;

  img {
    max-width: 654px;
    width: 654px;
    height: 256px;
  }

  @media (orientation: portrait) {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 5px;

    img {
      width: 290px;
      height: 114px;
      max-width: 290px;
    }
  }

  @media (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 654px;
      min-width: 290px;
    }
  }
`;

export const BoxContent = styled.section`
  display: block;
  max-width: 654px;
  justify-content: center;
  align-items: center;

  @media (orientation: portrait) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 654px;
  }
`;

export const BoxFooter = styled.section`
  display: block;
  max-width: 654px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px 15px;

  img {
    justify-content: center;
    align-items: center;
    margin: 10px 5px;
  }

  @media (orientation: portrait) {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 2;
    margin: 30px 15px;

    img {
      justify-content: center;
      margin: 10px 5px;
    }
  }
`;

export const Social = styled.div`
  display: flex;
  justify-content: center;
  color: #00cfb4;

  a {
    width: 40px;
    height: 40px;
  }

  @media (orientation: portrait) {
    line-height: 2;
  }
`;
