import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// Components
import Button from '../../components/LinkTree/Button';
import Carousel from '../../components/LinkTree/Carousel';
import SocialIcon from '../../components/LinkTree/SocialIcon';

// Services
import apiLinktree from '../../services/Linktree/api';

// Assets

// Styles
import {
  Container,
  BoxHeader,
  BoxButtons,
  BoxHighlights,
  BoxBanner,
  BoxContent,
  BoxFooter,
  Social,
  Title,
} from './styles';

// Interface
interface IBotao {
  title: string;
  url: string;
  target: string;
}

interface IHeader {
  status_header: boolean;
  img_topo_header: string;
  url_redes_sociais: string;
}
interface ISocials {
  urls_social_media: IBotao;
  icone_social_media: string;
}

interface IButtons {
  botao: IBotao;
  cor_button: string;
  background_button: string;
}

interface ICourosels {
  alt_carousel: string;
  img_carousel: string;
}

interface IBanners {
  alt_banner: string;
  img_banner: string;
}
interface IConteudosRelacionados {
  conteudo: IBotao;
  color_conteudo: string;
  background_conteudo: string;
}
interface Itens {
  tipo: 'botoes' | 'carousels' | 'banners' | 'conteudos';
  botoes: IButtons[];
  title_carousel: string;
  carousel: ICourosels[];
  banner: IBanners[];
  titulo_conteudo_relacionado: string;
  conteudo_relacionado: IConteudosRelacionados[];
}
interface IConteudo {
  status_conteudo: string;
  itens: Itens[];
}
interface IFooter {
  status_footer: boolean;
  icons_social_media: ISocials[];
  image_footer: string;
}
interface IData {
  header: IHeader;
  counteudo: IConteudo;
  footer: IFooter;
}

const LinkTree: React.FC = () => {
  const ID = Cookies.get('pageID');
  const [dados, setDados] = useState<IData>();

  useEffect(() => {
    apiLinktree.get(`acf/v3/pages/${ID}`).then(response => {
      setDados(response.data.acf);
    });
  }, []);
  return (
    <>
      {dados && (
        <Container>
          {dados?.header.status_header && (
            <BoxHeader>
              <img src={dados?.header.img_topo_header} alt="" />
              <Title>{dados?.header.url_redes_sociais}</Title>
            </BoxHeader>
          )}
          {dados?.counteudo.status_conteudo && (
            <>
              {dados?.counteudo.itens.map((item, index) => (
                <>
                  {item.tipo === 'botoes' && (
                    <BoxButtons>
                      {item.botoes.map(bot => (
                        <Button
                          key={index}
                          url={bot.botao.url}
                          target={bot.botao.target}
                          color={bot.cor_button}
                          backgorund={bot.background_button}
                          title={bot.botao.title}
                        />
                      ))}
                    </BoxButtons>
                  )}
                  {item.tipo === 'carousels' && (
                    <BoxHighlights>
                      <Title>{item.title_carousel}</Title>
                      <Carousel nav={false} dots={false} loop>
                        {item.carousel.map(carousel => (
                          <img
                            src={carousel.img_carousel}
                            alt={carousel.alt_carousel}
                          />
                        ))}
                      </Carousel>
                    </BoxHighlights>
                  )}

                  {item.tipo === 'banners' && (
                    <BoxBanner>
                      {item.banner.map(ban => (
                        <img src={ban.img_banner} alt={ban.alt_banner} />
                      ))}
                    </BoxBanner>
                  )}
                  {item.tipo === 'conteudos' && (
                    <BoxContent>
                      <Title>Artigos da Semana</Title>
                      {item.conteudo_relacionado.map(relacionados => (
                        <Button
                          url={relacionados.conteudo.url}
                          target={relacionados.conteudo.target}
                          color={relacionados.color_conteudo}
                          backgorund={relacionados.background_conteudo}
                          title={relacionados.conteudo.title}
                        />
                      ))}
                    </BoxContent>
                  )}
                </>
              ))}
            </>
          )}
          {dados?.footer.status_footer && (
            <BoxFooter>
              <Social>
                {dados.footer.icons_social_media.map((footer, index) => (
                  <SocialIcon
                    key={index}
                    url={footer.urls_social_media.url}
                    target={footer.urls_social_media.target}
                    src={footer.icone_social_media}
                    alt={footer.urls_social_media.title}
                  />
                ))}
              </Social>
              <img src={dados.footer.image_footer} alt="" />
            </BoxFooter>
          )}
        </Container>
      )}
    </>
  );
};

export default LinkTree;
