import React, { ImgHTMLAttributes } from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props extends ImgHTMLAttributes<HTMLImageElement> {}

export function TestemonialImage({ ...rest }: Props) {
  return <Container {...rest} />;
}
