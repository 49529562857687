import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';

import apiExtensivo from '../../services/Extensivo/api';
import apiIntensivo from '../../services/Intensivo/api';
import apiMentoria from '../../services/Mentoria/api';
import apiCRMedway from '../../services/CRMedway/api';
import apiPSMedway from '../../services/PSMedway/api';

import apiAnaliseDeDados from '../../services/Analise-de-dados/api';

import {
  Contanner,
  ModalContainer,
  ModalContent,
  ModalBoxText,
} from './styles';

import Loading from '../../components/Loading';

interface Aula {
  background: {
    title: string;
    sizes: {
      '1920x1080': string;
    };
  };
  logo_do_curso: {
    title: string;
    sizes: {
      '1920x1080': string;
    };
  };
  nome: string;
  url: string;
  nome_botao: string;
}

const ListaDePresenca: React.FC = () => {
  const [email, setEmail] = useState('');
  const [aula, setAula] = useState<Aula>();
  const [section, setSection] = useState(false);
  const [modal, setModal] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const params = new URLSearchParams(window.location.search);
    const course = window.location.pathname;
    const utm_source = params.get('utm_source');
    const utm_medium = params.get('utm_medium');
    const utm_campaign = params.get('utm_campaign');
    const utm_term = params.get('utm_term');
    const utm_content = params.get('utm_content');

    const hostName = window.location.hostname;

    switch (hostName) {
      case 'mentoria.medway.com.br':
        apiAnaliseDeDados.post('/lista-de-presenca/mentoria', {
          email,
          aula: aula?.nome,
          url: aula?.url,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
        });
        break;
      case 'extensivo.medway.com.br':
        const courseName = course.split('/')[1];
        if (courseName !== 'lista-de-presenca') {
          apiAnaliseDeDados.post('/lista-de-presenca/type-course', {
            curso: `extensivo-${courseName}`,
            email,
            aula: aula?.nome,
            url: aula?.url,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
          });
        } else {
          apiAnaliseDeDados.post('/lista-de-presenca/extensivo', {
            email,
            aula: aula?.nome,
            url: aula?.url,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
          });
        }
        break;
      case 'cr.medway.com.br':
        apiAnaliseDeDados.post('/lista-de-presenca/crmedway', {
          email,
          aula: aula?.nome,
          url: aula?.url,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
        });
        break;
      case 'ps.medway.com.br':
        apiAnaliseDeDados.post('/lista-de-presenca/psmedway', {
          email,
          aula: aula?.nome,
          url: aula?.url,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
        });
        break;
      default:
        break;
    }
    setSection(true);
  };

  const setSemana = (semana: string | null, response: AxiosResponse) => {
    switch (semana) {
      case 'Segunda':
        setAula({
          nome: response.data.acf.lista_de_presenca.aula_1.nome_da_aula,
          url: response.data.acf.lista_de_presenca.aula_1.url_do_video_youtube,
          background: response.data.acf.lista_de_presenca.background,
          logo_do_curso: response.data.acf.lista_de_presenca.logo_do_curso,
          nome_botao: response.data.acf.lista_de_presenca.aula_1.nome_botao,
        });
        break;
      case 'Terça':
        setAula({
          nome: response.data.acf.lista_de_presenca.aula_2.nome_da_aula,
          url: response.data.acf.lista_de_presenca.aula_2.url_do_video_youtube,
          background: response.data.acf.lista_de_presenca.background,
          logo_do_curso: response.data.acf.lista_de_presenca.logo_do_curso,
          nome_botao: response.data.acf.lista_de_presenca.aula_2.nome_botao,
        });
        break;
      case 'Quarta':
        setAula({
          nome: response.data.acf.lista_de_presenca.aula_3.nome_da_aula,
          url: response.data.acf.lista_de_presenca.aula_3.url_do_video_youtube,
          background: response.data.acf.lista_de_presenca.background,
          logo_do_curso: response.data.acf.lista_de_presenca.logo_do_curso,
          nome_botao: response.data.acf.lista_de_presenca.aula_3.nome_botao,
        });
        break;
      case 'Quinta':
        setAula({
          nome: response.data.acf.lista_de_presenca.aula_4.nome_da_aula,
          url: response.data.acf.lista_de_presenca.aula_4.url_do_video_youtube,
          background: response.data.acf.lista_de_presenca.background,
          logo_do_curso: response.data.acf.lista_de_presenca.logo_do_curso,
          nome_botao: response.data.acf.lista_de_presenca.aula_4.nome_botao,
        });
        break;
      case 'Sexta':
        setAula({
          nome: response.data.acf.lista_de_presenca.aula_5.nome_da_aula,
          url: response.data.acf.lista_de_presenca.aula_5.url_do_video_youtube,
          background: response.data.acf.lista_de_presenca.background,
          logo_do_curso: response.data.acf.lista_de_presenca.logo_do_curso,
          nome_botao: response.data.acf.lista_de_presenca.aula_5.nome_botao,
        });
        break;
      case 'Sábado':
        setAula({
          nome: response.data.acf.lista_de_presenca.aula_6.nome_da_aula,
          url: response.data.acf.lista_de_presenca.aula_6.url_do_video_youtube,
          background: response.data.acf.lista_de_presenca.background,
          logo_do_curso: response.data.acf.lista_de_presenca.logo_do_curso,
          nome_botao: response.data.acf.lista_de_presenca.aula_6.nome_botao,
        });
        break;
      case 'Domingo':
        setAula({
          nome: response.data.acf.lista_de_presenca.aula_7.nome_da_aula,
          url: response.data.acf.lista_de_presenca.aula_7.url_do_video_youtube,
          background: response.data.acf.lista_de_presenca.background,
          logo_do_curso: response.data.acf.lista_de_presenca.logo_do_curso,
          nome_botao: response.data.acf.lista_de_presenca.aula_7.nome_botao,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const ID = Cookies.get('pageID');
    const hostName = window.location.hostname;

    const params = new URLSearchParams(window.location.search);
    const semana: string | null = params.get('semana');

    console.log(semana);

    switch (hostName) {
      case 'fmedway.com.br':
        apiMentoria
          .get(`acf/v3/pages/10078`)
          .then((response: AxiosResponse) => {
            setSemana(semana, response);
          });
        break;
      case 'mentoria.medway.com.br':
        apiMentoria
          .get(`acf/v3/pages/${ID}`)
          .then((response: AxiosResponse) => {
            setSemana(semana, response);
          });
        break;
      case 'extensivo.medway.com.br':
        apiExtensivo.get(`pages/${ID}`).then((response: AxiosResponse) => {
          setSemana(semana, response);
        });
        break;
      case 'intensivo.medway.com.br':
        apiIntensivo.get(`pages/${ID}`).then((response: AxiosResponse) => {
          setSemana(semana, response);
        });
        break;
      case 'cr.medway.com.br':
        apiCRMedway.get(`pages/${ID}`).then((response: AxiosResponse) => {
          setSemana(semana, response);
        });
        break;
      case 'ps.medway.com.br':
        apiPSMedway
          .get(`acf/v3/pages/${ID}`)
          .then((response: AxiosResponse) => {
            setSemana(semana, response);
          });
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!aula) {
      setTimeout(() => {
        setModal(true);
      }, 2000);
    }
  }, [aula]);

  return (
    <>
      {aula ? (
        <Contanner bg={aula?.background.sizes['1920x1080']}>
          <img
            src={aula?.logo_do_curso.sizes['1920x1080']}
            alt={aula?.logo_do_curso.title}
          />
          <h1>{aula?.nome}</h1>
          {section ? (
            <>
              <a href={aula?.url} target="_blank" rel="noopener noreferrer">
                {aula?.nome_botao}
              </a>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <label htmlFor="InputEmail">
                  Seu E-mail cadastrado na Medway
                </label>
                <input
                  id="InputEmail"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="alguem@exemplo.com"
                  required
                />
                <button type="submit">Enviar</button>
              </form>
            </>
          )}
        </Contanner>
      ) : (
        <>
          <Loading />
          <ModalContainer open={modal}>
            <ModalContent>
              <ModalBoxText>
                <h2>Oopss...</h2>
                <p>Aparemente o link que você acessou não está correto.</p>
                <p>
                  Para solucionar este problema, entre em contato com nossa
                  equipe de suporte para obter um novo link.
                </p>
              </ModalBoxText>
            </ModalContent>
          </ModalContainer>
        </>
      )}
    </>
  );
};

export default ListaDePresenca;


