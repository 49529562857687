import styled from 'styled-components';

export const FormCheck = styled.div`
  margin-left: 20px;
  input {
    margin: 0;
    width: 20px;
    height: 20px;
    left: 0;
  }
  label {
    padding-left: 10px;
  }
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}`;
