export const isProduction = () => {
  const hostName = window.location.hostname;
  let url = false;

  switch (hostName) {
    case 'medway.com.br':
    case 'www.medway.com.br':
      url = true;
      break;
    default:
      break;
  }

  return url;
};
