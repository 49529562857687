import React, { useState, useEffect } from 'react';
// import Cookies from 'js-cookie';

import apiIntensivo from '../../../services/Intensivo/api';

import { Container, Colunas, BoxOferta, BoxTabela } from './styles';

import Button from '../../../components/Button';
import AddressAndPolicies from '../../../components/AddressAndPolicies';

interface Itens {
  turma_e_data: string;
  valor_a_vista: string;
  valor_a_prazo: string;
  desativar_vendas: boolean;
  botao: {
    title: string;
    url: string;
    target: string;
  };
}

interface Coluna {
  tamanho: string;
  background: {
    title: string;
    sizes: {
      '1920x1080': string;
    };
  };
  logo: {
    title: string;
    sizes: {
      large: string;
    };
  };
  texto: string;
  display: string;
  tabela_unica: {
    background: {
      title: string;
      sizes: {
        '1920x1080': string;
      };
    };
    selo: {
      title: string;
      sizes: {
        thumbnail: string;
      };
    };
    valor_total: string;
    valor_com_desconto: string;
    economia: string;
    parcelamento: string;
    botao: {
      title: string;
      url: string;
      target: string;
    };
  };
  tabela_com_linhas: {
    background: {
      title: string;
      sizes: {
        '1920x1080': string;
      };
    };
    nome_do_curso: string;
    itens: [Itens];
  };
}

interface Checkout {
  colunas: [Coluna];
}

const Intensivo: React.FC = () => {
  const [dados, setDados] = useState<Checkout | null>(null);
  const url_traffic_source = window.location.search;

  useEffect(() => {
    // const ID = Cookies.get('pageID');

    const ID = 5337;

    apiIntensivo.get(`pages/${ID}`).then(response => {
      setDados(response.data.acf.checkout);
    });
  }, []);

  return (
    <Container style={{display:'block'}}>
      <Container>
        {dados &&
          dados.colunas.map(coluna => (
            <Colunas
              bg={coluna.background.sizes['1920x1080']}
              size={coluna.tamanho}
              key={coluna.texto}
            >
              <img
                className="logo"
                src={coluna.logo.sizes.large}
                alt={coluna.logo.title}
              />
              <div
                className="titulo"
                dangerouslySetInnerHTML={{
                  __html: coluna.texto,
                }}
              />
              {coluna.display === 'CRTU' ? (
                <>
                  <BoxOferta
                    bg={coluna.tabela_unica.background.sizes['1920x1080']}
                    selo={coluna.tabela_unica.selo.sizes.thumbnail}
                  >
                    <div className="valor-total-branco">
                      <p>De</p>
                      <p>{coluna.tabela_unica.valor_total}</p>
                    </div>
                    <div className="valor-com-desconto">
                      <p>por</p>
                      <p>{coluna.tabela_unica.valor_com_desconto}</p>
                    </div>
                    <div className="economia">
                      Economize {coluna.tabela_unica.economia}
                    </div>
                    <p className="ou">ou</p>
                    <div className="parcelamento">
                      <p>até</p>
                      <p>{coluna.tabela_unica.parcelamento}</p>
                    </div>
                    <div className="valor-total-amarelo">
                      (Total de <p>{coluna.tabela_unica.valor_total}</p>)
                    </div>
                  </BoxOferta>
                  <div className="botao">
                    <Button
                      color="FFF"
                      backgorund="#FFB81C"
                      url={`${coluna.tabela_unica.botao.url}${url_traffic_source}`}
                      target={coluna.tabela_unica.botao.target}
                      title={coluna.tabela_unica.botao.title}
                    />
                  </div>
                </>
              ) : (
                <>
                  <BoxTabela
                    bg={coluna.tabela_com_linhas.background.sizes['1920x1080']}
                  >
                    <div className="nome-do-curso">
                      {coluna.tabela_com_linhas.nome_do_curso}
                    </div>
                    <div className="itens">
                      {coluna.tabela_com_linhas.itens.map((item, key) => (
                        <div className="line" key={key}>
                          <div className="valores">
                            <p
                              className={
                                item.botao.title !== 'ESGOTADO'
                                  ? 'nome-turma'
                                  : 'nome-turma font-size'
                              }
                            >
                              {item.turma_e_data}
                            </p>
                            {item.botao.title !== 'ESGOTADO' && (
                              <>
                                <div className="valor-a-vista">
                                  <p>De</p>
                                  <p>{item.valor_a_vista}</p>
                                </div>
                                <div className="valor-a-prazo">
                                  <p>Por</p>
                                  <p>{item.valor_a_prazo}</p>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="botao">
                            <Button
                              color="FFF"
                              backgorund="#FFB81C"
                              url={`${item.botao.url}${url_traffic_source}`}
                              target={item.botao.target}
                              title={item.botao.title}
                              disable={item.desativar_vendas}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </BoxTabela>
                  {/* <div className="lista-de-espera">
                    <h2>
                      Cadastre-se abaixo para receber o link das novas turmas
                    </h2>
                    <Button
                      color="FFF"
                      backgorund="#FFB81C"
                      url="https://cr.medway.com.br/lista-de-espera/"
                      target="_blank"
                      title="LISTA DE ESPERA"
                      disable={false}
                    />
                  </div> */}
                </>
              )}
            </Colunas>
          ))}
        </Container>
        <AddressAndPolicies background = '#00205B' />
    </Container>
  );
};

export default Intensivo;
