import styled, { css } from 'styled-components';

interface MobileProps {
  mobile: boolean;
}

export const Container = styled.section<MobileProps>`
  display: flex;
  justify-content: space-between;

  padding-top: 80px;
  padding-right: 80px;
  padding-left: 80px;

  max-width: 1366px;

  ${({ mobile }) =>
    mobile &&
    css`
      flex-direction: column;
      align-items: center;

      padding-top: 20px;
      padding-right: 20px;
      padding-left: 20px;
    `}
`;

export const Conteudo = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  padding-right: 100px;

  ${({ mobile }) =>
    mobile &&
    css`
      padding-right: 0;
      padding-bottom: 50px;
    `}
`;

export const Box = styled.div``;

export const BoxLogo = styled.div<MobileProps>`
  ${({ mobile }) =>
    mobile &&
    css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
      padding-top: 20px;
    `}
`;

export const Logo = styled.img``;

export const Title = styled.h1`
  color: #fff;
  max-width: 580px;
  width: 100%;
  font-weight: 800;

  @media (orientation: landscape) {
    font-size: 48px;
  }

  @media (orientation: portrait) {
    font-size: 36px;
  }
`;

export const Describe = styled.p`
  color: #fff;
  max-width: 580px;
  width: 100%;

  @media (orientation: landscape) {
    font-size: 18px;
  }

  @media (orientation: portrait) {
    font-size: 16px;
    margin-bottom: 50px;
  }
`;

export const Photo = styled.img`
  max-width: 480px;
  width: 100%;
`;
