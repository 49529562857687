import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'bootstrap/dist/css/bootstrap.min.css';
import TermoMensageiro from '../../components/TermoMensageiro';
import apiDashboard from '../../services/Dashboard/api';

import { FormCheck, Logo } from './styles.js';

import LogoAzul from '../../assets/medway-logo-horizontal-azul.svg';

let imagens = [];

const TermoDeCompromissoMensageiro = () => {
  const [nome, setNome] = useState('');
  const [rg, setRg] = useState('');
  const [cpf_me, setCpf_me] = useState('');
  const [formatura, setFormatura] = useState('');
  const [data, setData] = useState(null);
  const [formulario, setFormulario] = useState(true);
  const [terms, setTerms] = useState(false);

  const handleTerms = () => {
    setTerms(!terms);
  };

  const handleNext = () => {
    if (nome && rg && cpf_me && formatura) {
      setData({
        nome,
        rg,
        cpf_me,
        formatura,
      });
      setFormulario(false);
    }
  };
  const handlePrev = () => {
    setData(null);
    setFormulario(true);
  };
  const canvasImgs = canvas => {
    imagens.push(canvas);
  };
  const pdf = () => {
    /* eslint new-cap: ["error", { "newIsCap": false }] */
    const doc = new jsPDF('p', 'mm', 'a4');
    console.log(imagens);
    doc.addImage(imagens[0], 'JPEG', 10, 0, 190, 270);
    doc.addPage();
    doc.addImage(imagens[1], 'JPEG', 10, 0, 190, 270);
    doc.addPage();
    doc.addImage(imagens[2], 'JPEG', 10, 0, 190, 270);
    doc.addPage();
    doc.addImage(imagens[3], 'JPEG', 10, 0, 190, 270);
    //
    doc.output('dataurlnewwindow');
    doc.save('download.pdf');
  };
  const printDocument = () => {
    apiDashboard.post('/terms', {
      name: nome,
      rg,
      cpf_me,
      ano: formatura,
      accept_terms: terms,
    });

    imagens = [];
    const input = document.getElementById('page-1');
    const input2 = document.getElementById('page-2');
    const input3 = document.getElementById('page-3');
    const input4 = document.getElementById('page-4');

    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      canvasImgs(imgData);
    });
    html2canvas(input2).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      canvasImgs(imgData);
    });
    html2canvas(input3).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      canvasImgs(imgData);
    });
    html2canvas(input4).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      canvasImgs(imgData);
      pdf();
    });
  };
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Logo>
              <img src={LogoAzul} alt="Logo Medway" />
            </Logo>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">
                Termo de Compromisso Embaixadores Medway
              </Card.Header>
              <Card.Body>
                {formulario && (
                  <>
                    <Form>
                      <Form.Group controlId="formBasicNome">
                        <Form.Label>Nome Completo</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Nome"
                          defaultValue={nome}
                          onChange={e => setNome(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicRG">
                        <Form.Label>RG</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="RG"
                          defaultValue={rg}
                          onChange={e => setRg(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicCPFME">
                        <Form.Label>CPF/ME</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="CPF/ME"
                          defaultValue={cpf_me}
                          onChange={e => setCpf_me(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicFormatura">
                        <Form.Label>Ano de Formatura</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="2021"
                          defaultValue={formatura}
                          onChange={e => setFormatura(e.target.value)}
                        />
                      </Form.Group>
                      <Button variant="primary" onClick={handleNext}>
                        Avançar
                      </Button>
                    </Form>
                  </>
                )}
                {!formulario && (
                  <>
                    <Row>
                      <Col>
                        <Button variant="primary" onClick={handlePrev}>
                          Voltar
                        </Button>
                      </Col>
                      <Col>
                        Dessa até o final, aceite os termos e envie o contrato.
                      </Col>
                    </Row>
                  </>
                )}
                {!formulario && (
                  <FormCheck>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Eu li e concordo com os termos de uso"
                        onChange={handleTerms}
                      />

                      {terms && (
                        <Button
                          variant="primary"
                          onClick={() => printDocument()}
                        >
                          Enviar e baixar contrato
                        </Button>
                      )}
                    </Form.Group>
                    <div className="pdf">
                      <TermoMensageiro dados={data} />
                    </div>
                  </FormCheck>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default TermoDeCompromissoMensageiro;
