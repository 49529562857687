import React from 'react';

import { Container } from './styles';

interface Props {
  background: string;
}

const AddressAndPolicies:React.FC<Props> = ({background}) => {
  return (
    <Container bg={background}>
      <p>
        <a href="https://www.medway.com.br/politica-de-privacidade/">Política de Privacidade</a>
        &nbsp;|&nbsp;
        <a href="https://www.medway.com.br/politica-de-cookies/">Política de Cookies</a>
        <br />
        © Copyright, Todos os direitos reservados.
      </p>
    </Container>
  );
};

export default AddressAndPolicies;
