import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children?: React.ReactNode;
  dangerouslySetInnerHTML?: any;
}

export function BoxVideo({ children, dangerouslySetInnerHTML }: Props) {
  return (
    <Container dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </Container>
  );
}
