import React from "react";

import { Container } from "./styles";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

interface Dados {
  nome: string;
  rg: string;
  cpf_me: string;
  formatura: string;
}

const TermoMensageiro: React.FC<{ dados: Dados | null }> = ({ dados }) => {
  return (
    <Container>
      <div className="c15 c35">
        <div id="page-1">
          <p className="c30 title mt4" id="h.gjdgxs">
            <span className="c16">EMBAIXADORES MEDWAY</span>
          </p>
          <p className="c27 subtitle mt4" id="h.30j0zll">
            <span className="c16">
              Termo de compromisso do Programa Embaixadores Medway
            </span>
          </p>
          <p className="c18">
            <span className="c20">
              O Programa de Embaixadores Medway (&ldquo;Programa&rdquo;) &eacute;
              gerido e idealizado por MEDWAY RESID&Ecirc;NCIA M&Eacute;DICA
              LTDA, com sede na Av. Eng. Luiz Carlos Berrini, n&ordm; 1700,
              15&ordm; andar, Cidade Mon&ccedil;&otilde;es, Cidade e Estado de
              S&atilde;o Paulo, Brasil, CEP 04571-000, inscrita no Cadastro
              Nacional da Pessoa Jur&iacute;dica do Minist&eacute;rio da
              Economia (&ldquo;
            </span>
            <span className="c20 c32">CNPJ/ME</span>
            <span className="c4">
              &rdquo;) sob o n&ordm; 30.222.120/0001-02 (&ldquo;Medway&rdquo;).
            </span>
          </p>
          <p className="c18">
            <span className="c20">
              O presente Termo de Compromisso do Programa (&ldquo;Termo&rdquo;)
              visa regular e reger a rela&ccedil;&atilde;o entre voc&ecirc;,
              Embaixador, abaixo qualificado e a Medway durante a sua
              participa&ccedil;&atilde;o no Programa.
            </span>
          </p>
          <ol className="c1 lst-kix_list_1-0 start">
            <li className="c13 c8 li-bullet-0">
              <h1 id="h.1fob9te">
                <span className="c0">O PROGRAMA</span>
              </h1>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-1 start">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                O Programa tem por objetivo, engajar estudantes de medicina,
                atrav&eacute;s de a&ccedil;&otilde;es promovidas pelos
                Embaixadores em parceria com a Medway. As a&ccedil;&otilde;es
                ocorrer&atilde;o tanto em ambientes f&iacute;sicos dentro ou no
                entorno das faculdades de medicina, quanto em ambiente
                on-line/virtual, de modo a contribuir para maior reconhecimento
                da marca, produtos e servi&ccedil;os da Medway.
              </span>
            </li>
          </ol>
          <p className="c2 c6">
            <span className="c4" />
          </p>

          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                Os Embaixadores s&atilde;o pessoas f&iacute;sicas, maiores,
                estudantes de medicina selecionados por um
                processo de inscri&ccedil;&atilde;o volunt&aacute;ria junto ao
                Programa. A natureza da rela&ccedil;&atilde;o entre o Embaixador
                e a Medway &eacute; estritamente de ordem c&iacute;vel,
                n&atilde;o caracterizando qualquer v&iacute;nculo
                empregat&iacute;cio, de est&aacute;gio,
                representa&ccedil;&atilde;o comercial ou qualquer outro
                v&iacute;nculo que implique na assun&ccedil;&atilde;o de
                responsabilidades de qualquer natureza pela Medway.
              </span>
            </li>
          </ol>
          <p className="c2 c6">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-0">
            <li className="c3 c8 c24 li-bullet-0">
              <h1 id="h.3znysh7">
                <span className="c0">COMPROMISSO DOS EMBAIXADORES</span>
              </h1>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-1 start">
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                Os Embaixadores agir&atilde;o sempre em conformidade com as
                instru&ccedil;&otilde;es fornecidas pela Medway, sendo que suas
                atividades envolver&atilde;o a realiza&ccedil;&atilde;o de
                miss&otilde;es semanais, quinzenais ou mensais, as quais
                ser&atilde;o devidamente explicadas por uma equipe interna da
                Medway.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                As miss&otilde;es, em geral, consistem em a&ccedil;&otilde;es
                que oportunizem &agrave; Medway mostrar-se mais pr&oacute;xima
                do seu p&uacute;blico-alvo (alunos de medicina e m&eacute;dicos). 
                Assim, dentre as atividades, os
                Embaixadores dever&atilde;o: divulgar materiais de estudos
                produzidos pela Medway em suas redes sociais e nos grupos e
                e-mails de faculdade, promover grupos/discuss&otilde;es cuja
                tem&aacute;tica esteja relacionada &agrave;
                prepara&ccedil;&atilde;o para a carreira m&eacute;dica, divulgar
                cupons de descontos, produzir/promover a&ccedil;&otilde;es, distribuir 
                materiais/brindes a alunos de medicina, apoiar pesquisas e a&ccedil;&otilde;es 
                estrat&eacute;gicas de marketing, entre outras.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                O Embaixador tem o compromisso de manter constante
                di&aacute;logo  e de forma peri&oacute;dica (<span className="c20 c32">ao menos</span> uma vez por m&ecirc;s) 
                com a equipe interna da Medway respons&aacute;vel
                pela gest&atilde;o do Programa. O contato com a Medway
                poder&aacute; ocorrer por meio da realiza&ccedil;&atilde;o de
                webconfer&ecirc;ncia (via Google Meet, Skype, Zoom ou outros),
                mensagens por meio de aplicativos, diretamente no contato
                pessoal do Embaixador ou em grupos privados (via WhatsApp,
                Telegram ou outro) e trocas de e-mails. O Embaixador
                dever&aacute; demonstrar regularidade em seu contato com a
                Medway de forma a informar sobre o andamento das atividades
                realizadas, esclarecer d&uacute;vidas sobre as miss&otilde;es,
                entre outras quest&otilde;es.
                &Eacute; responsabilidade do Embaixador responder &agrave;s 
                solicita&ccedil;&otilde;es de atualiza&ccedil;&atilde;o 
                cadastral enviadas pela Medway periodicamente.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                Como forma de comprovar que as atividades/miss&otilde;es foram
                conclu&iacute;das nos termos das diretrizes da Medway, o
                Embaixador dever&aacute; enviar links, prints, registros,
                imagens, entre outros materiais capazes de comprovar a
                a&ccedil;&atilde;o. Al&eacute;m disso, a Medway poder&aacute;
                usar outros recursos tecnol&oacute;gicos (como o Google
                Analytics e planilhas on-line, por exemplo) a fim de aferir as
                informa&ccedil;&otilde;es prestadas pelo Embaixador.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                Ao aderir ao Programa de Embaixadores da Medway, o Embaixador se
                obriga e se compromete a:
              </span>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_3-0 start">
            <li className="c18 c31 li-bullet-1">
              <span className="c4">
                Utilizar corretamente o logos e sinais distintivos da Medway,
                &uacute;nica e exclusivamente em fun&ccedil;&atilde;o da sua
                participa&ccedil;&atilde;o no Programa;
              </span>
            </li>
            <li className="c3 c31 li-bullet-1">
              <span className="c4">
                Respeitar as pol&iacute;ticas de vendas e promocionais da
                Medway, n&atilde;o realizando promessas para a
                aquisi&ccedil;&atilde;o dos produtos e servi&ccedil;os da Medway
                ou propagandas enganosas em nome da MEDWAY;
              </span>
            </li>
            <li className="c3 c31 li-bullet-1">
              <span className="c4">
                N&atilde;o utilizar ou permitir que sejam utilizados quaisquer
                meios e ve&iacute;culos fraudulentos ou artificiais que simulem
                vendas ou leads;
              </span>
            </li>
            <li className="c3 c31 li-bullet-1">
              <span className="c4">
                Cumprir com 70% (setenta por cento) das metas/a&ccedil;&otilde;es 
                estipuladas pela Medway a cada trimestre ou outro per&iacute;odo 
                definido e informado pela MEDWAY.
              </span>
            </li>
          </ol>
          <p className="c2 c21">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
              O Embaixador declara integral ci&ecirc;ncia de que as a&ccedil;&otilde;es desenvolvidas 
              para fins de atingimento das metas definidas pela Medway n&atilde;o s&atilde;o cumulativas. 
              Nesse sentido, as a&ccedil;&otilde;es desenvolvidas em um trimestre 
              (ou qualquer outro per&iacute;odo definido pela Medway) n&atilde;o poder&atilde;o ser 
              cumuladas para o pr&oacute;ximo per&iacute;odo em caso de n&atilde;o atingimento 
              de meta no per&iacute;odo em quest&atilde;o.
              </span>
            </li>
          </ol>
          <p className="c2 c21">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
              As miss&otilde;es/a&ccedil;&otilde;es somente ser&atilde;o contabilizadas para 
              fins de pontua&ccedil;&atilde;o se o Embaixador atingir ou ultrapassar a meta 
              espec&iacute;fica para a a&ccedil;&atilde;o/miss&atilde;o em quest&atilde;o, 
              conforme informado pela MEDWAY. O Embaixador est&aacute; ciente que a mera 
              divulga&ccedil;&atilde;o de eventual a&ccedil;&atilde;o/miss&atilde;o, 
              por&eacute;m sem o correspondente atingimento de meta n&atilde;o ser&aacute; 
              contabilizada/considerada pela MEDWAY.
              </span>
            </li>
          </ol>
        </div>
        <div id="page-2">
        <p className="c2 c21">
            <span className="c4" />
        </p>
        <ol className="c1 lst-kix_list_1-1">
          <li className="c3 c8 c6 li-bullet-0">
            <span className="c4">
              A Medway declara n&atilde;o ter qualquer responsabilidade sobre
              o conte&uacute;do e licitude dos ambientes virtuais, plataformas
              e redes em que os Embaixadores realizarem
              a&ccedil;&otilde;es/miss&otilde;es no &acirc;mbito da sua
              participa&ccedil;&atilde;o no Programa, e se reserva no direito
              de a qualquer momento, invalidar a participa&ccedil;&atilde;o do
              Embaixador no Programa e por consequ&ecirc;ncia, rescindir de
              forma imediata a sua rela&ccedil;&atilde;o com o Embaixador,
              caso entenda que as a&ccedil;&otilde;es dos Embaixadores, bem como
              os ambientes em que divulgou as atividades da Medway, n&atilde;o
              sejam compat&iacute;veis com a imagem, princ&iacute;pios e
              idoneidade da Medway.
            </span>
          </li>
        </ol>
        <p className="c2 c21">
            <span className="c4" />
        </p>
        <ol className="c1 lst-kix_list_1-1">
          <li className="c3 c8 c6 li-bullet-0">
            <span className="c4">
              Nesse sentido, o Embaixador declara que se abster&aacute; de promover 
              coment&aacute;rios, conte&uacute;dos e opini&otilde;es de cunho racista, 
              mis&oacute;gino, preconceituoso, xenof&oacute;bico, entre outros e 
              qualquer outro conte&uacute;do que a Medway entenda estar em desacordo 
              com os seus princ&iacute;pios e diretrizes.
            </span>
          </li>
        </ol>
          <ol className="c1 lst-kix_list_1-0">
            <li className="c13 c8 li-bullet-0">
              <h1 id="h.2et92p0">
                <span className="c0">BONIFICA&Ccedil;&Atilde;O</span>
              </h1>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-1 start">
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                Como contrapartida, ser&aacute; disponibilizado ao Embaixador o
                acesso gratuito a um curso/produto da Medway, conforme for
                previamente combinado entre o Embaixador e a equipe interna de
                gest&atilde;o do Programa, bem como o acesso integral ao curso PSMEDWAY 
                durante no ano em que o Embaixador participar do Programa. 
                O acesso ao curso escolhido ser&aacute; pelo per&iacute;odo em que o 
                Embaixador fizer parte do Programa. A cada ano que o Embaixador 
                completar no Programa, ter&aacute; direito a um curso/produto da Medway pelo
                per&iacute;odo em que continuar no Programa e enquanto o curso
                em quest&atilde;o perdurar.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                Al&eacute;m do acesso ao curso, o Programa poder&aacute;
                oferecer, a exclusivo crit&eacute;rio da Medway, outros
                b&ocirc;nus (n&atilde;o monet&aacute;rio) aos Embaixadores que 
                obtiverem uma performance acima da m&eacute;dia dos demais 
                Embaixadores integrantes no Programa.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                O Embaixador est&aacute; ciente que caso tenha adquirido
                qualquer Curso/produto da Medway antes da sua ades&atilde;o ao
                Programa, n&atilde;o poder&aacute; pedir qualquer reembolso ou
                compensa&ccedil;&atilde;o em fun&ccedil;&atilde;o da sua
                participa&ccedil;&atilde;o no Programa, devendo escolher outro
                Curso/produto para usufruir do acesso gratuito concedido.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                O presente Programa n&atilde;o implica na obriga&ccedil;&atilde;o 
                e/ou assun&ccedil;&atilde;o de qualquer pagamento pecuni&aacute;rio/monet&aacute;rio 
                pela MEDWAY ao Embaixador, tampouco o pagamento de comiss&otilde;es/repasses 
                em fun&ccedil;&atilde;o da eventual venda de cursos ou produtos ou leads 
                adquiridos em fun&ccedil;&atilde;o das a&ccedil;&otilde;es praticadas pelo Embaixador.
              </span>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-0">
            <li className="c13 c8 li-bullet-0">
              <h1 id="h.tyjcwt">
                <span className="c0">
                  CONFIDENCIALIDADE E PROPRIEDADE INTELECTUAL
                </span>
              </h1>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-1 start">
            <li className="c7 c6 c22 li-bullet-0">
              <span className="c4">
                O Embaixador concorda em abster-se de, direta ou indiretamente,
                usar, divulgar, revelar, fornecer ou de qualquer outra forma
                tornar dispon&iacute;veis a pessoas estranhas &agrave; Medway,
                sem a autoriza&ccedil;&atilde;o expressa da Medway, quaisquer
                informa&ccedil;&otilde;es exclusivas, orais ou escritas,
                incluindo mas n&atilde;o se limitando a
                informa&ccedil;&otilde;es e segredos t&eacute;cnicos,
                propriedade intelectual e industrial, grava&ccedil;&otilde;es,
              </span>
              <span className="c20 c26">scripts</span>
              <span className="c4">
                , material did&aacute;tico, institucional, projetos, planos de
                a&ccedil;&atilde;o, pre&ccedil;os, lista de clientes,
                pr&aacute;ticas de mercado, materiais de trabalho, contratos,
                produtos, correspond&ecirc;ncias, memorandos, notas, documentos,
                conte&uacute;dos para redes sociais e
              </span>
              <span className="c26 c20">sites</span>
              <span className="c4">
                &nbsp;e outras informa&ccedil;&otilde;es da ou relacionados
                &agrave; Medway, sua controladora ou outra sociedade do seu
                grupo econ&ocirc;mico, bem como informa&ccedil;&otilde;es de
                seus clientes ou potenciais clientes, ou a qualquer um deles
                relacionadas, fornecidas pela Medway, seus associados ou
                quaisquer terceiros, e recebidas pelo Embaixador em
                fun&ccedil;&atilde;o da sua participa&ccedil;&atilde;o no
                Programa e a qualquer momento, seja antes ou ap&oacute;s
                &agrave; ades&atilde;o ao Programa (&ldquo;
              </span>
              <span className="c12">
                Informa&ccedil;&otilde;es Confidenciais
              </span>
              <span className="c4">&rdquo;).</span>
            </li>
          </ol>
          <p className="c2 c6 c22">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 c22 li-bullet-0">
              <span className="c4">O Embaixador declara desde j&aacute; 
                que se responsabiliza por quaisquer
                preju&iacute;zos advindos do descumprimento das
                obriga&ccedil;&otilde;es previstas neste Termo, implicando
                inclusive no cancelamento da sua participa&ccedil;&atilde;o no
                Programa de forma imediata pela
              </span>
              <span className="c4">Medway</span>
              <span className="c4 c15">
                &nbsp;em caso de infra&ccedil;&atilde;o.
              </span>
            </li>
          </ol>
          <p className="c2 c24">
            <span className="c4" />
          </p>

          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 c22 li-bullet-0">
              <span className="c4">
                As obriga&ccedil;&otilde;es de confidencialidade previstas neste
                Termo vigorar&atilde;o durante todo o per&iacute;odo em que o
                Embaixador tiver contato com as Informa&ccedil;&otilde;es
                Confidenciais, e pelo prazo de 10 (dez) anos, a contar da data
                em que a rela&ccedil;&atilde;o entre as Partes for encerrada.
              </span>
            </li>
          </ol>
          <p className="c2 c24">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 c22 li-bullet-0">
              <span className="c4">
                Todos os direitos de propriedade intelectual, sobre o site e
                aplicativo da Medway, desenvolvimento software, hardware,
                dom&iacute;nio, marcas, logomarcas, sinais distintivos,
                logotipos, desenhos, estrutura, conte&uacute;dos,
                informa&ccedil;&atilde;o, aplicativos e todos os
                cursos/produtos/servi&ccedil;os da Medway, incluindo todo e
                qualquer material disponibilizado ao Embaixador no &acirc;mbito
                da sua participa&ccedil;&atilde;o no Programa, dentre outros,
                s&atilde;o de propriedade &uacute;nica e exclusiva da Medway,
                sendo que o Embaixador n&atilde;o poder&aacute; contestar,
                direta ou indiretamente, a validade e titularidade dos direitos
                da Medway em fun&ccedil;&atilde;o do presente Termos.
              </span>
            </li>
          </ol>
        </div>

        <div id="page-3">
          <ol className="c1 lst-kix_list_1-0">
            <li className="c8 c13 li-bullet-0">
              <h1>
                <span className="c0">DIREITOS AUTORAIS </span>
              </h1>
            </li>
          </ol>
          <p className="c2">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                Ao aderir ao Programa e executar as atividades/miss&otilde;es
                durante a sua participa&ccedil;&atilde;o, o Embaixador
                elaborar&aacute; materiais, textos, artes, obras,
                produ&ccedil;&otilde;es, desenhos, figuras, montagens,
                edi&ccedil;&otilde;es, entre outros
                (&ldquo;Conte&uacute;dos&rdquo;), sendo que o Embaixador declara
                ser exclusivo criador e detentor dos direitos autorais de
                referidos Conte&uacute;dos.
              </span>
            </li>
          </ol>
          <p className="c2 c6">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                O Embaixador, ao aderir ao presente Termo, cede todos e
                quaisquer direitos patrimoniais de autor referentes aos
                Conte&uacute;dos &agrave; Medway, de forma irrevog&aacute;vel,
                irretrat&aacute;vel, gratuita e por prazo indeterminando, sendo
                a Medway a exclusiva titular de referidos direitos.
              </span>
            </li>
          </ol>
          <p className="c2">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                O Embaixador declara que a presente cess&atilde;o n&atilde;o tem
                restri&ccedil;&otilde;es quanto &agrave; forma, tempo, lugar ou
                meio de reprodu&ccedil;&atilde;o, divulga&ccedil;&atilde;o ou
                circula&ccedil;&atilde;o, de modo que a Medway, poder&aacute;
                deliberar a seu exclusivo crit&eacute;rio o modo de
                reprodu&ccedil;&atilde;o, divulga&ccedil;&atilde;o ou
                circula&ccedil;&atilde;o dos Conte&uacute;dos.
              </span>
            </li>
          </ol>
          <p className="c2">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                O Embaixador est&aacute; ciente que os Conte&uacute;dos
                poder&atilde;o ser exibidos, por toda e qualquer modalidade de
                transmiss&atilde;o, veicula&ccedil;&atilde;o e/ou
                divulga&ccedil;&atilde;o, e suportados por quaisquer meios
                existentes, incluindo o site da Medway (
              </span>
              <span className="c12">
                <a
                  className="c9"
                  href="https://www.google.com/url?q=http://www.medway.com.br&amp;sa=D&amp;source=editors&amp;ust=1619522684133000&amp;usg=AOvVaw33Qi-TigthkzP2bOWNIl5_"
                >
                  www.medway.com.br
                </a>
              </span>
              <span className="c4">
                ), blogs, toda e qualquer rede social de titularidade da Medway
                ou de suas parceiras, plataformas de terceiros em que os
                servi&ccedil;os da Medway s&atilde;o comercializados/divulgados,
                ou m&iacute;dia de qualquer natureza, bem como televis&atilde;o
                aberta e por assinatura, r&aacute;dio, internet, home
                v&iacute;deo, e/ou outras m&iacute;dias interativas,
                eletr&ocirc;nicas ou n&atilde;o, no Brasil e no exterior.
              </span>
            </li>
          </ol>
          <p className="c2 c6">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                Os Conte&uacute;dos poder&atilde;o ser explorados, total ou
                parcialmente, em website ou quaisquer outros meios
                virtual/online, redes sociais e meios f&iacute;sicos, com a
                finalidade de divulga&ccedil;&atilde;o, promo&ccedil;&atilde;o
                comercial e/ou promo&ccedil;&atilde;o institucional da Medway e
                suas atividades, sem qualquer limita&ccedil;&atilde;o de tempo,
                territ&oacute;rio e n&uacute;mero de vezes.
              </span>
            </li>
          </ol>
          <p className="c2 c24">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c6 c8 li-bullet-0">
              <span className="c4">
                A Medway, como exclusiva titular dos direitos autorais sobre os
                Conte&uacute;dos poder&aacute; decidir pela sua
                edi&ccedil;&atilde;o, dublagem, ambienta&ccedil;&atilde;o em
                outras cria&ccedil;&otilde;es/conte&uacute;dos derivados ou
                n&atilde;o dos Conte&uacute;dos, a utiliza&ccedil;&atilde;o de
                trechos espec&iacute;ficos, inser&ccedil;&otilde;es, entre
                outros tratamentos e ajustes que entender necess&aacute;rios
                para qualquer tipo de explora&ccedil;&atilde;o e
                utiliza&ccedil;&atilde;o de referidos Conte&uacute;dos.
              </span>
            </li>
          </ol>
          <p className="c2 c24">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                O Embaixador declara que a presente cess&atilde;o n&atilde;o
                viola o direito patrimonial, ou direito personal&iacute;ssimo de
                terceiros e que nenhuma remunera&ccedil;&atilde;o ser&aacute;
                devida pela Medway em decorr&ecirc;ncia de qualquer
                explora&ccedil;&atilde;o ou uso que realizar dos
                Conte&uacute;dos.
              </span>
            </li>
          </ol>
          <p className="c2 c24">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                O Embaixador autoriza a cess&atilde;o e transfer&ecirc;ncia pela
                Medway, dos direitos derivados do presente Termos a qualquer
                t&iacute;tulo.
              </span>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-0">
            <li className="c13 c8 li-bullet-0">
              <h1>
                <span className="c0">
                  UTILIZA&Ccedil;&Atilde;O DE IMAGEM, NOME E VOZ
                </span>
              </h1>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-1 start">
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                Ao aderir ao Programa e executar as atividades/miss&otilde;es
                durante a sua participa&ccedil;&atilde;o, o Embaixador
                est&aacute; ciente que a sua Imagem, Nome e Voz ser&atilde;o
                utilizados durante a grava&ccedil;&atilde;o de v&iacute;deos,
                fotografias e depoimentos orais e escritos, autorizando desde
                j&aacute;, de forma irrevog&aacute;vel e irretrat&aacute;vel
                &nbsp;que a Medway utilize referidos materiais , exibindo-os por
                toda e qualquer modalidade de transmiss&atilde;o,
                veicula&ccedil;&atilde;o e/ou divulga&ccedil;&atilde;o, e
                suportados por quaisquer meios existentes, incluindo o site da
                Medway (
              </span>
              <span className="c12">
                <a
                  className="c9"
                  href="https://www.google.com/url?q=http://www.medway.com.br&amp;sa=D&amp;source=editors&amp;ust=1619522684135000&amp;usg=AOvVaw0nW1RkvyItSlU6upmhto6p"
                >
                  www.medway.com.br
                </a>
              </span>
              <span className="c4">
                ), toda e qualquer rede social de titularidade da Medway ou de
                suas parceiras, plataformas de terceiros em que os
                servi&ccedil;os da Medway s&atilde;o comercializados/divulgados,
                ou m&iacute;dia de qualquer natureza, bem como televis&atilde;o
                aberta e por assinatura, r&aacute;dio, internet, home
                v&iacute;deo, e/ou outras m&iacute;dias interativas,
                eletr&ocirc;nicas ou n&atilde;o, no Brasil e no exterior.
              </span>
            </li>
          </ol>
          <p className="c2 c6">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                A autoriza&ccedil;&atilde;o ora concedida pelo Embaixador
                &eacute; por prazo indeterminado e engloba a
                utiliza&ccedil;&atilde;o dos materiais em que contiverem a sua
                Imagem, Nome e Voz na internet, em website ou quaisquer outros
                meios virtual/online, redes sociais e meios f&iacute;sicos, com
                a finalidade de divulga&ccedil;&atilde;o, promo&ccedil;&atilde;o
                comercial e/ou promo&ccedil;&atilde;o institucional da Medway e
                suas atividades.
              </span>
            </li>
          </ol>
          <p className="c25 c24">
            <span className="c4" />
          </p>
          <p className="c2 c6">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                O Embaixador declara que a presente autoriza&ccedil;&atilde;o
                n&atilde;o viola o direito patrimonial, ou direito
                personal&iacute;ssimo de terceiros e que nenhuma
                remunera&ccedil;&atilde;o ser&aacute; devida pela Medway em
                decorr&ecirc;ncia de qualquer explora&ccedil;&atilde;o ou uso
                que realizar dos materiais em que figuram a Imagem, Nome e Voz
                do Embaixador.
              </span>
            </li>
          </ol>
        </div>

        <div id="page-4">
          <ol className="c1 lst-kix_list_1-0">
            <li className="c13 c8 li-bullet-0">
              <h1>
                <span className="c0">
                  CANCELAMENTO E PERDA DOS BENEF&Iacute;CIOS
                </span>
              </h1>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-1 start">
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                A Medway, na condi&ccedil;&atilde;o de idealizadora e
                mantenedora do projeto, tem o direito de cancelar tal programa
                ou v&iacute;nculo de determinado Embaixador a qualquer momento e
                a seu exclusivo crit&eacute;rio, condicionado a um pr&eacute;vio
                aviso de, 10 (dez) dias corridos, sem a aplica&ccedil;&atilde;o
                de qualquer &ocirc;nus ou penalidades. Nesses casos, a Medway
                definir&aacute; um prazo espec&iacute;fico para que os
                Embaixadores possam ter acesso aos cursos/produtos e
                informar&aacute; a data de suspens&atilde;o de referido acesso
                em fun&ccedil;&atilde;o do cancelamento do Programa ou do
                v&iacute;nculo com determinado Embaixador.
              </span>
            </li>
            <li className="c6 c7 li-bullet-0">
              <span className="c4">
                O Embaixador poder&aacute; ser exclu&iacute;do <span className="c20 c32">de forma imediata</span> do 
                Programa nos seguintes casos: (i) infra&ccedil;&atilde;o a
                qualquer disposi&ccedil;&atilde;o do presente Termo e das
                diretrizes disponibilizadas pela Medway, sem preju&iacute;zo
                ainda de responder por eventuais perdas e danos apurados em
                fun&ccedil;&atilde;o da infra&ccedil;&atilde;o, indenizando a
                Medway por qualquer preju&iacute;zo incorrido, (ii) n&atilde;o
                cumprimento das miss&otilde;es/a&ccedil;&otilde;es nos termos
                solicitados pela Medway no &acirc;mbito do Programa, (iii) perda
                das condi&ccedil;&otilde;es necess&aacute;rias para se
                qualificar como um Embaixador, tais como, incapacidade, deixar de cursar ou perder a
                matr&iacute;cula no curso de medicina, entre outros, (iv) ausentar-se do Programa, n&atilde;o 
                retornar os contatos da equipe da MEDWAY, tornar-se inacess&iacute;vel por um per&iacute;odo 
                consecutivo de 20 (vinte) dias corridos; (v) n&atilde;o cumprir as metas e/ou executar as 
                miss&otilde;es/a&ccedil;&otilde;es por um per&iacute;odo de 4 (quatro) semanas consecutivas 
                sem justo motivo apresentado &agrave; MEDWAY.
              </span>
            </li>
            
            <li className="c6 c7 li-bullet-0">
              <span className="c4">
                O Embaixador que cumprir com todas as condi&ccedil;&otilde;es e metas estipuladas e concluir 
                de forma positiva a sua participa&ccedil;&atilde;o no Programa de Embaixador, receber&aacute; 
                o acesso ao Curso escolhido e poder&aacute; usufru&iacute;-lo at&eacute; o final do prazo do Curso, 
                mesmo que j&aacute; tenha conclu&iacute;do o curso de medicina junto &agrave; Universidade.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                A exclus&atilde;o do Embaixador implicar&aacute; na
                suspens&atilde;o imediata do acesso ao curso/produto escolhido
                pelo Embaixador, bem como do recebimento de qualquer b&ocirc;nus
                ou benef&iacute;cio ao qual faria jus.
              </span>
            </li>
            <li className="c7 c6 li-bullet-0">
              <span className="c4">
                Caso o Embaixador deixe de fazer parte do Programa por ter conclu&iacute;do 
                a faculdade de Medicina, poder&aacute; seguir usufruindo do acesso ao curso 
                escolhido (desde que tenha preenchido todos os requisitos para acesso ao curso) 
                por um per&iacute;odo adicional de 6 (seis) meses ap&oacute;s a conclus&atilde;o da faculdade.
              </span>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-0">
            <li className="c7 c24 li-bullet-0">
              <span className="c0">EXCLUSIVIDADE</span>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-1 start">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                As condi&ccedil;&otilde;es ora pactuadas n&atilde;o t&ecirc;m
                qualquer car&aacute;ter de exclusividade por parte da Medway,
                sendo que o Programa &eacute; composto por diversos outros
                Embaixadores que aderiram ao Programa. No entanto, o Embaixador
                se compromete a manter exclusividade, se abstendo de formalizar
                ou pactuar com empresas concorrentes da Medway, termos ou
                instrumentos que contenham o mesmo objeto do presente Termo,
                concordando em divulgar e promover t&atilde;o somente os Cursos
                e conte&uacute;do da Medway pelo per&iacute;odo em que figurar
                enquanto Embaixador no Programa.
              </span>
            </li>
          </ol>
          <p className="c2 c6">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-0">
            <li className="c3 c8 c24 li-bullet-0">
              <span className="c0">CONDI&Ccedil;&Otilde;ES GERAIS </span>
            </li>
          </ol>
          <ol className="c1 lst-kix_list_1-1 start">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                Se qualquer parte deste Termo for considerada inv&aacute;lida ou
                inexequ&iacute;vel, tal trecho deve ser interpretado de forma
                consistente com a lei aplic&aacute;vel, para refletir, na medida
                do poss&iacute;vel, a inten&ccedil;&atilde;o original da Medway,
                e as demais disposi&ccedil;&otilde;es permanecer&atilde;o em
                pleno vigor e efeito.
              </span>
            </li>
          </ol>
          <p className="c2 c6">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                O presente Termo poder&aacute; ser modificado e/ou alterado
                periodicamente, integralmente ou em parte, pela Medway, mediante
                comunica&ccedil;&atilde;o pr&eacute;via.
              </span>
            </li>
          </ol>
          <p className="c24 c25">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                As assinaturas do presente Termo s&atilde;o realizadas via
                Ferramenta de Assinatura Eletr&ocirc;nica, sendo esse um meio
                efetivo e v&aacute;lido de autentica&ccedil;&atilde;o de autoria
                e integridade de documentos, sem quaisquer ressalvas.
              </span>
            </li>
          </ol>
          <p className="c2">
            <span className="c4" />
          </p>
          <ol className="c1 lst-kix_list_1-1">
            <li className="c3 c8 c6 li-bullet-0">
              <span className="c4">
                Esse Termo &eacute; regido, interpretado e executado de acordo
                com as leis da Rep&uacute;blica Federativa do Brasil, sendo
                competente o Foro Central da Comarca de S&atilde;o Paulo, para
                dirimir qualquer d&uacute;vida decorrente deste Termo.
              </span>
            </li>
          </ol>
          <p className="c18">
            <span className="c0">QUALIFICA&Ccedil;&Atilde;O EMBAIXADOR</span>
          </p>

          <table className="c23">
            <tbody>
              <tr className="c14">
                <td className="c17">
                  <p className="c3">
                    <span className="c4">Nome Completo</span>
                  </p>
                </td>
                <td className="c11">
                  <p className="c2">
                    <span className="c4">{dados?.nome}</span>
                  </p>
                </td>
              </tr>
              <tr className="c14">
                <td className="c17">
                  <p className="c3">
                    <span className="c4">R.G.</span>
                  </p>
                </td>
                <td className="c11">
                  <p className="c2">
                    <span className="c4">{dados?.rg}</span>
                  </p>
                </td>
              </tr>
              <tr className="c14">
                <td className="c17">
                  <p className="c3">
                    <span className="c4">CPF/ME</span>
                  </p>
                </td>
                <td className="c11">
                  <p className="c2">
                    <span className="c4">{dados?.cpf_me}</span>
                  </p>
                </td>
              </tr>
              <tr className="c14">
                <td className="c17">
                  <p className="c3">
                    <span className="c4">Formatura</span>
                  </p>
                </td>
                <td className="c11">
                  <p className="c3">
                    <span className="c4">{dados?.formatura}</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="c18">
            <span className="c4">
            O aceite e ades&atilde;o do presente Termo pelo Embaixador s&atilde;o 
            interpretados como a sua integral anu&ecirc;ncia e concord&acirc;ncia com os seus termos 
            e com as obriga&ccedil;&otilde;es assumidas ao aderir ao Programa de Embaixadores da Medway.
            </span>
          </p>
          <p className="c19">
            <span className="c5 c20">
              S&atilde;o Paulo, {new Date().getDate()} de{" "}
              {months[new Date().getMonth()]} de {new Date().getFullYear()}
            </span>
          </p>
          <p className="c18 c34">
            <span className="c4" />
          </p>
          <p className="c18">
            <span className="c20 assinatura">
              {dados?.nome}
              <br />
            </span>
            <span className="c33">Ass. Embaixador Medway</span>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default TermoMensageiro;
