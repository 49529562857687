import styled, { css } from 'styled-components';

// Images
import MedwayWatermark from '../../assets/medway-watermark-mensageiros.png';
import BrazilMap from '../../assets/brazil-map-mensageiros.png';
import Aspas from '../../assets/aspas.svg';
import Next from '../../assets/next-arrow.svg';
import Prev from '../../assets/prev-arrow.svg';

// Colors
const lightBlue = "#00CFB4";
const darkBlue = "#0F0C6D";
const lightGray = "#707070";
const gray = "#5A5A5A91";

interface OpenProps {
  bg: string;
  logo: string;
}

interface SectionProps {
  bg: string;
}

interface ContentProps {
  colorType: string;
}

export const Container = styled.div`
    max-width: 1140px;
    width: 100%;
    margin: auto;
    padding: 15px;
`;

export const Title = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;

  @media (orientation: portrait) {
    margin-bottom: 24px;
  }

  h2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 42px;
    font-weight: 800;
    justify-content: center;
    margin-bottom: 0;
    max-width: fit-content;
    text-transform: uppercase;
    width: 100%;

    ${
      props => props.colorType === 'base' ? css`
        color: ${darkBlue};
      ` : css`
        color: #ffffff;
      `
    }

    @media (orientation: portrait) {
      font-size: 24px;
    }

    &:after {
      background-color: ${lightBlue};
      content: '';
      height: 10px;
      margin-top: 10px;
      width: 100px;

      @media (orientation: portrait) {
        margin-top: 6px;
        height: 6px;
        width: 40px;
      }
    }
  }
`;

export const SimpleLink = styled.div<ContentProps>`
  display: flex;

  span {
    font-size: 20px;
    font-weight: 700;
    ${
      props => props.colorType === 'base' ? css`
        color: ${lightGray};
      ` : css`
        color: ${lightBlue};
      `
    }

    @media (orientation: portrait) {
      font-size: 16px;
    }
  }
`;

export const Button = styled.div`
  display: flex;
  margin-top: 40px;

  @media (orientation: portrait) {
    margin-top: 16px;
  }

  a, button {
    background-color: ${lightBlue};
    border-radius: 40px;
    border: 2px solid ${lightBlue};
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    padding: 14px 34px;
    text-transform: uppercase;
    transition: background-color ease-in-out .3s, color ease-in-out .3s;

    @media (orientation: portrait) {
      font-size: 16px;
      padding: 12px 16px;
      text-align: center;
    }

    &:hover {
      background-color: transparent;
      color: ${lightBlue};
      text-decoration: none;
      transition: background-color ease-in-out .3s, color ease-in-out .3s;
    }
  }
`;

export const Open = styled.section<OpenProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  ${props => props.bg &&
    css`
      background-image: url(${props.bg});
    `
  }

  @media (orientation: portrait) {
    height: 50vh;
  }

  .box-content {
    .title {
      background-position-y: bottom;
      background-repeat: no-repeat;
      background-size: contain;
      font-size: 0;
      height: calc(90vh - 15px);
      margin: 0 auto;
      width: 60vw;

      ${props => props.bg &&
        css`
          background-image: url(${props.logo});
        `
      }

      @media (orientation: portrait) {
        height: 45vh;
        width: 80vw;
      }
    }
  }
`;

export const Community = styled.section`
  background-color: #fff;
  overflow: hidden;
  padding-bottom: 60px;
  padding-top: 60px;
  position: relative;

  @media (orientation: portrait) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &::after {
    background-color: #fff;
    background-image: url(${MedwayWatermark});
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -300px;
    content: '';
    height: 500px;
    left: -135px;
    opacity: .15;
    position: absolute;
    width: 500px;

    @media (orientation: portrait) {
      display: none;
    }
  }

  &::before {
    background-color: #fff;
    background-image: url(${BrazilMap});
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 450px;
    opacity: .15;
    position: absolute;
    right: 215px;
    top: 100px;
    width: 430px;

    @media (orientation: portrait) {
      top: 25px;
      right: 15px;
      height: 300px;
      width: 286px;
    }
  }

  .box-content {
    color: ${lightGray};
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    text-align: center;
    z-index: 2;

    @media (orientation: portrait) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  .box-links {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    position: relative;
    z-index: 2;

    @media (orientation: portrait) {
      padding-top: 24px;
    }
  }
`;

export const Benefits = styled.section<SectionProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  ${props => props.bg &&
    css`
      background-image: url(${props.bg});
    `
  }

  .box-title {
    padding-right: 40%;

    @media (orientation: portrait) {
      padding-right: 0%;
    }
  }

  .box-content {
    padding-right: 40%;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;

    @media (orientation: portrait) {
      font-size: 16px;
      line-height: 32px;
      padding-right: 0%;
    }

    p {
      margin-bottom: 40px;

      @media (orientation: portrait) {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .box-links {
    padding-right: 40%;
    padding-top: 40px;

    @media (orientation: portrait) {
      padding-right: 0%;
      padding-top: 20px;
    }
  }
`;

export const About = styled.section`
  background-color: ${darkBlue};
  padding-bottom: 80px;
  padding-top: 80px;
  text-align: center;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-image {
    margin-bottom: 64px;

    @media (orientation: portrait) {
      margin-bottom: 32px;
    }

    img {
      border-radius: 56px;
      width: 100%;
    }
  }

  .box-content {
    color: #ffffff;
    font-size: 20px;
    line-height: 40px;
    font-weight: 400;

    @media (orientation: portrait) {
      font-size: 16px;
      line-height: 32px;
    }

    p {
      margin-bottom: 40px;

      @media (orientation: portrait) {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .box-links {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    position: relative;

    @media (orientation: portrait) {
      padding-top: 40px
    }
  }
`;

export const Testemonials = styled.section`
  background-color: #fff;
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-content {
    text-align: center;
    color: ${lightGray};
    font-size: 22px;
    font-weight: 500;
    margin-top: -20px;
    margin-bottom: 100px;

    @media (orientation: portrait) {
      font-size: 18px;
      margin-bottom: 50px;
      margin-top: 0;
    }
  }

  .owl-carousel {
    margin: 0 auto;

    @media (orientation: landscape) {
      width: 60%;
    }

    .owl-dots {
      margin-top: 60px !important;

      @media (orientation: portrait) {
        margin-top: 0px !important;
      }

      .owl-dot {
        span {
          background-color: ${gray};
          border-radius: 8px;
          height: 12px;
          margin-left: 8px;
          margin-right: 8px;
          transition: background-color ease-in-out .3s;
          width: 100px;

          @media (orientation: portrait) {
            width: 60px;
            height: 10px;
          }
        }

        &.active {
          span {
            background-color: ${darkBlue};
            transition: background-color ease-in-out .3s;
          }
        }
      }
    }

    .owl-stage-outer {
      .owl-stage{
        display: flex;
        align-items: stretch;
      }

      .item {
        display: flex;
        align-content: stretch;
        align-items: center;
        border-radius: 60px;
        border: 8px solid #0f247b;
        flex-direction: column;
        height: calc(100% - 125px);
        justify-content: center;
        margin: 125px 25px 0;
        max-width: 1000px;
        padding: 155px 20px 40px 20px;
        position: relative;

        @media (orientation: portrait) {
          margin: 60px 25px 0 !important;
          padding: 125px 20px 80px 20px !important;
        }

        &:before {
          background-color: #ffffff;
          background-image: url(${Aspas});
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 40px;
          left: -32px;
          position: absolute;
          top: 65px;
          width: 54px;
        }

        &:after {
          background-color: #ffffff;
          background-image: url(${Aspas});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 40px;
          content: '';
          display: block;
          height: 40px;
          position: absolute;
          right: -32px;
          transform: rotate(180deg);
          width: 54px;
        }

        img {
          background-color: #f0f0f5;
          border-radius: 50%;
          max-height: 250px;
          max-width: 250px;
          position: absolute;
          top: -125px;

          @media (orientation: portrait) {
            max-height: 130px;
            max-width: 130px;
            top: -65px;
          }
        }

        .paragrafo {
          color: #000000;
          font-size: 20px;
          font-style: italic;
          font-weight: 500;
          line-height: 40px;
          margin-bottom: 15px;
          text-align: center;

          @media (orientation: portrait) {
            font-size: 16px;
            line-height: 32px;
          }
        }

        .nome {
          color: #000000;
          font-size: 30px;
          font-style: italic;
          font-weight: 700;
          text-align: center;

          @media (orientation: portrait) {
            font-size: 18px;
          }
        }
      }
    }

    .owl-nav {
      font-size: 0;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 35px);
      transform: translateY(-50%);



      span {
        display: none;
      }

      .owl-prev {
        height: 70px;
        left: -58px;
        margin: 0;
        opacity: 1;
        position: absolute;
        transition: opacity ease-in-out .3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          left: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: .6;
          transition: opacity ease-in-out .3s;
        }

        &:after {
          background-image: url(${Prev});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }

      .owl-next {
        height: 70px;
        margin: 0;
        opacity: 1;
        position: absolute;
        right: -58px;
        transition: opacity ease-in-out .3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          right: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: .6;
          transition: opacity ease-in-out .3s;
        }

        &:after {
          background-image: url(${Next});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }
    }
  }
`;
