import React from 'react';

// Styles
import { Container, Label } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  label?: string;
  labelColor?: 'blue' | 'green';
  align: 'left' | 'right' | 'center';
  buy?: boolean;
  flag?: boolean;
  height?: string;
}

export function Card({
  children,
  label,
  labelColor,
  align,
  buy,
  flag,
  height,
}: Props) {
  return (
    <Container
      labelColor={labelColor}
      align={align}
      buy={buy}
      flag={flag}
      height={height}
    >
      {label && <Label labelColor={labelColor}>{label}</Label>}
      {children}
    </Container>
  );
}
