import React, { useState, useRef, useEffect } from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'lazysizes';

// import ImgTableMobile from '../../assets/Extensivo/tabela-extensivo-mobile.jpg';

// Components
import { Menu } from '../../components/Extensivo/Menu';
import { Logo } from '../../components/Extensivo/Menu/Logo';
import { BoxMenuMobile } from '../../components/Extensivo/Menu/BoxMenuMobile';
import { BoxMenu } from '../../components/Extensivo/Menu/BoxMenu';
import { Link } from '../../components/Extensivo/Menu/Link';
import { Button } from '../../components/Extensivo/Button';
import { Card } from '../../components/Extensivo/Card';
import { H2 } from '../../components/Extensivo/Card/H2';
import { H3 } from '../../components/Extensivo/Card/H3';
import { H4 } from '../../components/Extensivo/Card/H4';
// import { Message } from '../../components/Extensivo/Card/Message';
import { Price } from '../../components/Extensivo/Card/Price';
import { Paragraph } from '../../components/Extensivo/Card/Paragraph';
import { Separator } from '../../components/Extensivo/Card/Separator';
import { Image } from '../../components/Extensivo/Card/Image';
import { WarrantyLine } from '../../components/Extensivo/WarrantLine';
import { Modal } from '../../components/Extensivo/Modal';
// import Acordiao from '../../components/Extensivo/Acordiao';
// import Testimonial from '../components/Extensivo/Testimonial';
import { Testemonial } from '../../components/Extensivo/Testemonial';
import { BoxItem } from '../../components/Extensivo/Testemonial/BoxItem';
import { BoxVideo } from '../../components/Extensivo/Testemonial/BoxVideo';
import { BoxContent } from '../../components/Extensivo/Testemonial/BoxContent';
import { TestemonialH3 } from '../../components/Extensivo/Testemonial/H3';
import { TestemonialDescription } from '../../components/Extensivo/Testemonial/Description';
import { Banner } from '../../components/Vendas/Banner';
import { Faq } from '../../components/Extensivo/Faq';
import Loading from '../../components/Loading';
import AddressAndPolicies from '../../components/AddressAndPolicies';
import { AdditionalContent } from '../../components/Extensivo/AdditionalContent';

// Services
import apiExtensivo from '../../services/Extensivo/api';

// Assets

import Extensivo from '../../assets/logo-extensivo-base-2023.svg';
import MenuIcon from '../../assets/menu-icon.png';
import CloseMenuIcon from '../../assets/close-menu-icon.png';
import Check from '../../assets/check-table.png';
import Error from '../../assets/error-table.png';

// Styles
import {
  Container,
  BoxHero,
  BoxPillars,
  BoxComparison,
  BoxWaranty,
  BoxBuy,
  BoxVideoTestemonial,
  BoxFaq,
  Footer,
} from './styles';

// Interface

interface Botao {
  title: string;
  url: string;
  target: string;
}

interface PilaresProps {
  imagem_pilar: {
    title: string;
    url: string;
  };
  titulo_pilar: string;
  subtitulo_pilar: string;
  paragrafo_pilar: string;
  conteudo_pilar: string;
  botao_pilar: Botao;
}
interface SubgrupoDuvidas {
  titulo_subgrupo: string;
  texto_subgrupo: string;
}

interface GrupoDuvidas {
  titulo_grupo: string;
  texto_grupo: string;
  subgrupo: [SubgrupoDuvidas];
}

interface Video {
  url_video: string;
  nome_video: string;
  aprovacao_video: string;
}

interface Ano {
  ano: string;
}

interface Comparison {
  titulo_coluna: string;
  icone_coluna: [
    {
      icone_curso: boolean;
    }
  ];
  botao_comparison: Botao;
}

interface CursosComparison {
  linha_curso: [
    {
      nome_curso: string;
    }
  ];
  coluna_curso: [Comparison];
}

interface ExtraContent {
  logo_image: string;
  logo_alt_text: string;
  text_above_button: string;
  modal_text: string;
  button_text: string;
}

interface Dados {
  extensivo: {
    hero: {
      status_hero: boolean;
      titulo_hero: string;
      texto_hero: string;
      botao_hero: Botao;
    };

    pilar: {
      status_pilar: boolean;
      titulo_pilares: string;
      descricao_pilares: string;
      pilares: [PilaresProps];
      extra_content: {
        modal_title: string;
        contents: [ExtraContent]
      };
    };

    garantia: {
      status_garantia: boolean;
      titulo_garantia: string;
      subtitulo_garantia: string;
      data_garantia: string;
      texto_garantia: string;
    };

    sobre: {
      status_sobre: boolean;
      video_sobre: string;
      titulo: string;
    };

    valores: {
      status_valores: boolean;
      promocional_valores: boolean;
      titulo_valores: string;
      subtitulo_valores: string;
      inclusos_valores: boolean;
      mensagem_valores: string;
      preco_anterior_valores: string;
      preco_valores: string;
      parcelas_valores: string;
      boleto_valores: string;
      botao_valores: Botao;
    };

    banner_alume: {
      status_banner: boolean;
      titulo_banner: string;
      subtitulo_banner: string;
      botao_banner: Botao;
    };

    depoimentos: {
      status_depoimentos: boolean;
      titulo_depoimentos: string;
      videos_depoimentos: [Video];
    };

    duvidas: {
      status_duvidas: boolean;
      titulo_duvidas: string;
      grupo_duvidas: [GrupoDuvidas];
    };

    comparacao: {
      status_comparison: boolean;
      titulo_comparison: string;
      descricao_comparison: string;
      cursos_comparison: {
        curso_linha: [CursosComparison];
      };
    };

    cursos: {
      status_cursos: boolean;
      ano_cursos: [Ano];
    };

    footer: {
      status_footer: boolean;
      titulo_footer: string;
      email_footer: Botao;
    };
  };
}

const ExtensivoSP: React.FC = () => {
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState('');
  const [menuMobile, setMenuMobile] = useState(false);
  const [dados, setDados] = useState<Dados | null>(null);
  const hostName = window.location.hostname;
  const url_traffic_source = window.location.search;

  useEffect(() => {
    apiExtensivo
      .get(`pages/${hostName === 'extensivo.medway.com.br' ? `6123` : `8604`}`)
      .then(response => {
        setDados(response.data.acf);
      });
  }, []);

  // RefsToMenu
  const inscrevaBloco = useRef(null);
  const pilaresBloco = useRef(null);
  const garantiaBloco = useRef(null);
  const depoimentosBloco = useRef(null);
  const duvidasBloco = useRef(null);

  return (
    <>
      {dados ? (
        <>
          <Menu>
            <Logo src={Extensivo} alt="Extensivo Medway" />
            <BoxMenuMobile
              onClick={() => {
                setMenuMobile(!menuMobile);
              }}
              src={menuMobile ? CloseMenuIcon : MenuIcon}
            />
            <BoxMenu menuMobile={menuMobile}>
              <Link
                href="https://medway.com.br"
                menuRef={inscrevaBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                O Extensivo Base
              </Link>
              <Link
                href="https://medway.com.br"
                menuRef={pilaresBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Pilares
              </Link>
              <Link
                href="https://medway.com.br"
                menuRef={garantiaBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Garantia
              </Link>
              <Link
                href="https://medway.com.br"
                menuRef={depoimentosBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Depoimentos
              </Link>
              <Link
                href="https://medway.com.br"
                menuRef={duvidasBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Dúvidas
              </Link>
              <Button fill="contained" size="small" pattern="base" href="#Buy">
                Quero Comprar
              </Button>
            </BoxMenu>
          </Menu>

          {dados.extensivo.hero.status_hero && (
            <BoxHero ref={inscrevaBloco}>
              <div className="boxContent">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: dados.extensivo.hero.titulo_hero,
                  }}
                />
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: dados.extensivo.hero.texto_hero,
                  }}
                />
                {/* Desconto de R$ 500 para as primeiras 500 compras! */}

                <Button
                  fill="contained"
                  size="small"
                  pattern="base"
                  href={dados.extensivo.hero.botao_hero.url}
                >
                  {dados.extensivo.hero.botao_hero.title}
                </Button>
              </div>
            </BoxHero>
          )}

          {dados.extensivo.pilar.status_pilar && (
            <BoxPillars ref={pilaresBloco}>
              <div className="box-title">
                <h2 className="title">
                  {dados.extensivo.pilar.titulo_pilares}
                </h2>
                <p className="description">
                  {dados.extensivo.pilar.descricao_pilares}
                </p>
              </div>
              <div className="box-content">
                {dados.extensivo.pilar.pilares.map((item, key) => (
                  <Card align="center" key={key}>
                    <Image
                      className="lazyload"
                      src=""
                      data-srcset={`${item.imagem_pilar.url} 1x`}
                      alt={item.imagem_pilar.title}
                    />
                    <H3 align="center">{item.titulo_pilar}</H3>
                    <H4 align="center">{item.subtitulo_pilar}</H4>
                    <Paragraph align="center" weight={400}>
                      {item.paragrafo_pilar}
                    </Paragraph>
                    <Separator />
                    <Button
                      onClick={() => {
                        setStatusModal(true);
                        setModalContent(item.conteudo_pilar);
                      }}
                      fill="outlined"
                      size="small"
                      pattern="base"
                    >
                      {item.botao_pilar.title}
                    </Button>
                  </Card>
                ))}
              </div>
              {(dados.extensivo.pilar.extra_content.modal_title ||
              dados.extensivo.pilar.extra_content.contents.length > 0) && (
                <section className="callToAction">
                  {dados.extensivo.pilar.extra_content.modal_title && (
                  <div className="box-additional-content">
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: dados.extensivo.pilar.extra_content.modal_title,
                      }}
                    />
                  </div>
                  )}
                  {dados.extensivo.pilar.extra_content.contents.map((item, key) => (
                    <AdditionalContent
                      key={key}
                      logo_image={item.logo_image}
                      logo_alt_text={item.logo_alt_text}
                      text_above_button = {item.text_above_button}
                      modal_text = {item.modal_text}
                      button_text = {item.button_text}
                    />
                  ))}
                </section>
              )}
            </BoxPillars>
          )}

          {dados.extensivo.comparacao.status_comparison && (
            <BoxComparison>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.extensivo.comparacao.titulo_comparison}
                  </h2>
                  <p className="text">
                    {dados.extensivo.comparacao.descricao_comparison}
                  </p>
                </div>
                <div className="box-table-desktop">
                  <div className="table-head">
                    <div className="table-column"></div>
                    {dados.extensivo.comparacao.cursos_comparison.curso_linha.map(
                      item =>
                        item.coluna_curso.map(i => (
                          <div className="table-column">
                            <h4 className="table-title">{i.titulo_coluna}</h4>
                          </div>
                        ))
                    )}
                  </div>

                  {dados.extensivo.comparacao.cursos_comparison.curso_linha.map(
                    result => (
                      <div className="table-content">
                        <div className="table-column table-column__title">
                          {result.linha_curso.map(l => (
                            <div className="table-cell">
                              <h4 className="table-title">{l.nome_curso}</h4>
                            </div>
                          ))}
                          <div className="table-cell"></div>
                        </div>
                        {result.coluna_curso.map(c => (
                          <div className="table-column">
                            {c.icone_coluna.map(icon => (
                              <div className="table-cell">
                                <img
                                  className="lazyload table-icon"
                                  src=""
                                  data-srcset={`${
                                    icon.icone_curso === true ? Check : Error
                                  } 1x`}
                                  alt={
                                    icon.icone_curso === true
                                      ? 'Check'
                                      : 'Error'
                                  }
                                />
                              </div>
                            ))}
                            {c.botao_comparison.url && (
                              <div className="table-cell">
                                <Button
                                  fill="outlined"
                                  size="small"
                                  pattern="variant"
                                  fluid
                                  href={c.botao_comparison.url}
                                  target="_blanck"
                                >
                                  {c.botao_comparison.title}
                                </Button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
                {dados.extensivo.comparacao.cursos_comparison.curso_linha.map(
                  result2 => (
                    <div className="box-table-mobile">
                      <div className="box-table-mobile__1">
                        <div className="table-head">
                          <div className="table-column"></div>
                        </div>
                        <div className="table-content">
                          <div className="table-column">
                            {result2.linha_curso.map(li => (
                              <div className="table-cell">
                                <h4 className="table-title">{li.nome_curso}</h4>
                              </div>
                            ))}
                            <div className="table-cell"></div>
                          </div>
                        </div>
                      </div>
                      <div className="box-table-mobile__2">
                        <OwlCarousel
                          className="owl-theme"
                          responsive={{
                            0: {
                              items: 1,
                              loop: true,
                              autoplay: true,
                              nav: false,
                            },
                          }}
                        >
                          {result2.coluna_curso.map(item2 => (
                            <div className="item">
                              <div className="table-head">
                                <div className="table-column">
                                  <h4 className="table-title">
                                    {item2.titulo_coluna}
                                  </h4>
                                </div>
                              </div>
                              <div className="table-content">
                                <div className="table-column">
                                  {item2.icone_coluna.map(co => (
                                    <div className="table-cell">
                                      <img
                                        className="lazyload table-icon"
                                        src=""
                                        data-srcset={`${
                                          co.icone_curso === true
                                            ? Check
                                            : Error
                                        } 1x`}
                                        alt={
                                          co.icone_curso === true
                                            ? 'Check'
                                            : 'Error'
                                        }
                                      />
                                    </div>
                                  ))}
                                  {item2.botao_comparison.url && (
                                    <div className="table-cell">
                                      <Button
                                        fill="outlined"
                                        size="small"
                                        pattern="variant"
                                        href={item2.botao_comparison.url}
                                        fluid
                                      >
                                        {item2.botao_comparison.title}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      </div>
                    </div>
                  )
                )}
              </Container>
            </BoxComparison>
          )}

          {dados.extensivo.garantia.status_garantia && (
            <BoxWaranty ref={garantiaBloco}>
              <WarrantyLine
                title={dados.extensivo.garantia.titulo_garantia}
                subtitle={dados.extensivo.garantia.subtitulo_garantia}
                date={dados.extensivo.garantia.data_garantia}
                text={dados.extensivo.garantia.texto_garantia}
              />
            </BoxWaranty>
          )}

          {dados.extensivo.depoimentos.status_depoimentos && (
            <BoxVideoTestemonial ref={depoimentosBloco}>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.extensivo.depoimentos.titulo_depoimentos}
                  </h2>
                </div>
                <Testemonial nav dots loop={false}>
                  {dados.extensivo.depoimentos.videos_depoimentos.map(item => (
                    <BoxItem type="video">
                      <BoxVideo
                        dangerouslySetInnerHTML={{
                          __html: item.url_video,
                        }}
                      />
                      <BoxContent>
                        <TestemonialH3>{item.nome_video}</TestemonialH3>
                        <TestemonialDescription type="video">
                          {item.aprovacao_video}
                        </TestemonialDescription>
                      </BoxContent>
                    </BoxItem>
                  ))}
                </Testemonial>
              </Container>
            </BoxVideoTestemonial>
          )}

          {dados.extensivo.valores.status_valores && (
            <BoxBuy id="Buy">
              <Card align="center" buy>
                <H2 align="center" buy>
                  {dados.extensivo.valores.titulo_valores}
                </H2>
                <H4 align="center" buy>
                  {dados.extensivo.valores.subtitulo_valores}
                </H4>
                {/* <Message type="sucess">
                  Desconto especial para as primeiras 500 vagas: R$500,00
                </Message> */}
                {/* <Price type="old">
                  de <span>R$6.497,00</span>
                </Price> */}
                <Price type="current">
                  {dados.extensivo.valores.preco_valores}
                </Price>
                <Price type="instalments">
                  {dados.extensivo.valores.parcelas_valores}
                </Price>
                <H4 align="center" buy>
                  {dados.extensivo.valores.boleto_valores}
                </H4>
                <Button
                  fill="contained"
                  size="big"
                  pattern="base"
                  fluid
                  href={`${dados.extensivo.valores.botao_valores.url}${url_traffic_source}`}
                  target="_blank"
                >
                  {dados.extensivo.valores.botao_valores.title}
                </Button>
              </Card>
            </BoxBuy>
          )}

          {dados.extensivo.banner_alume.status_banner && (
            <Banner
              title={dados.extensivo.banner_alume.titulo_banner}
              subtitle={dados.extensivo.banner_alume.subtitulo_banner}
              button={dados.extensivo.banner_alume.botao_banner}
            />
          )}

          {dados.extensivo.duvidas.status_duvidas && (
            <BoxFaq ref={duvidasBloco}>
              <Faq
                title={dados.extensivo.duvidas.titulo_duvidas}
                data={dados.extensivo.duvidas.grupo_duvidas}
              />
            </BoxFaq>
          )}

          {dados.extensivo.footer.status_footer && (
            <Footer>
              <div className="boxFooter">
                <p className="titleFooter">
                  {dados.extensivo.footer.titulo_footer}{' '}
                  <a
                    className="mailto"
                    href={dados.extensivo.footer.email_footer.url}
                  >
                    {dados.extensivo.footer.email_footer.title}
                  </a>
                </p>
              </div>
              <AddressAndPolicies background = '' />
            </Footer>
          )}
          <Modal
            open={statusModal}
            setStatusModal={setStatusModal}
            modalContent={modalContent}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ExtensivoSP;
