import styled from 'styled-components';


// Interface
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 100px;
  min-width: 80%;
  justify-content: center;
  align-items: center;

  .image-container {
    min-width: 268px;
    img {
      max-width: 268px;
      height: 100%;
    }
  }

  .box-button > a:hover {
    text-decoration: none;
  }

  @media (orientation: portrait) {
    flex-direction: column;
    gap: 26px;

    .image-container {
      min-width: unset;
      img {
        max-width: 220px;
      }
    }

    .box-button > .text > p {
      font-size: 16px;
    }

  }
`;