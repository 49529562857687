import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.div`
  iframe {
    border-radius: 8px;
    height: 184px;
    width: 100%;

    @media (orientation: portrait) {
      height: 210px;
    }
  }
`;
