import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  align: 'left' | 'center' | 'right';
  bottom: string;
  top: string;
  size: string;
  width: string;
}

export function ParagraphTimeline({
  children,
  align,
  bottom,
  top,
  size,
  width,
}: Props) {
  return (
    <Container
      align={align}
      bottom={bottom}
      top={top}
      size={size}
      width={width}
    >
      {children}
    </Container>
  );
}
