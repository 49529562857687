import styled, { css } from 'styled-components';

// Images
import Aspas from '../../assets/aspas.svg';
import Next from '../../assets/green-next-arrow.svg';
import Prev from '../../assets/green-prev-arrow.svg';
import Play from '../../assets/play-yt.png';

// Colors
const verdeMedway = '#00CFB4';
const azulMedway = '#0F0C6D';

interface TitleProps {
  colorType: string;
  type: string | false;
}

interface Background {
  bg: string | false;
}

export const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  padding: 15px;
`;

export const Title = styled.div<TitleProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;

  @media (orientation: portrait) {
    margin-bottom: 24px;
  }

  h2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 42px;
    font-weight: 800;
    justify-content: center;
    margin-bottom: 0;
    max-width: fit-content;
    text-transform: uppercase;
    width: 100%;

    ${props =>
      props.colorType === 'base'
        ? css`
            color: ${azulMedway};
          `
        : css`
            color: #ffffff;
          `}

    ${props =>
      props.type === 'two' &&
      css`
        padding-left: 30px;
        position: relative;
      `}

    ${props =>
      props.colorType === 'base' &&
      props.type === 'two' &&
      css`
        color: ${verdeMedway};
      `}

    @media (orientation: portrait) {
      font-size: 24px;

      ${props =>
        props.type === 'two' &&
        css`
          padding-left: 20px;
        `}
    }

    &:after {
      background-color: ${verdeMedway};
      content: '';

      ${props =>
        props.type === 'one' &&
        css`
          height: 10px;
          margin-top: 10px;
          width: 100px;
        `}

      ${props =>
        props.type === 'two' &&
        css`
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 10px;
        `}

      ${props =>
        props.colorType === 'base' &&
        props.type === 'two' &&
        css`
          background-color: ${azulMedway};
        `}

      @media (orientation: portrait) {

        ${props =>
          props.type === 'one' &&
          css`
            margin-top: 6px;
            height: 6px;
            width: 40px;
          `}

        ${props =>
          props.type === 'two' &&
          css`
            width: 8px;
          `}
      }
    }
  }
`;

export const Button = styled.div`
  display: flex;
  margin-top: 40px;

  @media (orientation: portrait) {
    margin-top: 16px;
  }

  a,
  button {
    background-color: ${verdeMedway};
    border-radius: 40px;
    border: 2px solid ${verdeMedway};
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    padding: 14px 34px;
    text-transform: uppercase;
    transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;

    @media (orientation: portrait) {
      font-size: 16px;
      padding: 12px 16px;
      text-align: center;
    }

    &:hover {
      background-color: transparent;
      color: ${verdeMedway};
      text-decoration: none;
      transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
    }
  }
`;

export const Open = styled.section<Background>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 50vh;
  justify-content: center;

  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}

  @media (orientation: portrait) {
    height: 30vh;
  }

  .box-content {
    color: #ffffff;
    text-align: center;

    h1 {
      font-size: 54px;
      font-weight: bold;
      text-transform: uppercase;

      @media (orientation: portrait) {
        font-size: 32px;
      }
    }
  }
`;

export const Partner = styled.section`
  padding-bottom: 45px;
  padding-top: 45px;

  @media (orientation: portrait) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .box-elements {
    @media (orientation: landscape) {
      display: flex;
      align-items: center;
    }

    .box-image {
      margin-right: 40px;

      @media (orientation: portrait) {
        display: none;
      }
    }

    .box-content {
      @media (orientation: landscape) {
        margin-left: 40px;
      }

      &__no-image {
        text-align: center;
      }

      .title {
        color: ${azulMedway};
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 36px;

        @media (orientation: portrait) {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }

      .box-texts {
        color: #333333;

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          margin-bottom: 26px;

          @media (orientation: portrait) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
          }
        }
      }

      .box-button {
        @media (orientation: portrait) {
          margin-top: 32px;
        }

        &__no-image {
          justify-content: center;
        }

        a {
          @media (orientation: portrait) {
            margin: 0 auto;
          }
        }
      }
    }
  }
`;

export const About = styled.section`
  background-color: ${azulMedway};
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-elements {
    display: flex;

    @media (orientation: portrait) {
      flex-direction: column;
    }

    .box-content {
      flex-basis: 50%;
      margin-right: 20px;

      @media (orientation: portrait) {
        margin-bottom: 20px;
        margin-right: 0;
      }

      .box-title {
        align-items: flex-start;

        @media (orientation: portrait) {
          margin-bottom: 30px;
        }
      }

      .box-texts {
        color: #ffffff;

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;

          @media (orientation: portrait) {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    .box-video {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      margin-left: 20px;

      @media (orientation: portrait) {
        margin-left: 0;
      }

      .image {
        cursor: pointer;
        margin: 0;
        position: relative;

        img {
          border-radius: 30px;
          width: 100%;
        }

        &:before {
          background-color: #1292cb;
          border-radius: 30px;
          content: '';
          height: 100%;
          opacity: 0.7;
          position: absolute;
          width: 100%;
        }

        &:after {
          content: '';
          background-image: url(${Play});
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 25%;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 25%;
        }
      }

      iframe {
        width: 100%;
      }
    }

    .box-image {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      margin-left: 20px;

      @media (orientation: portrait) {
        margin-left: 0;
      }

      .image {
        border-radius: 30px;
        width: 100%;
      }
    }
  }
`;

export const Teachers = styled.section`
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-title {
    h2 {
      @media (orientation: portrait) {
        font-size: 18px;
      }
    }
  }

  .box-slider {
    @media (orientation: landscape) {
      padding-left: 38px;
      padding-right: 38px;
    }

    .item {
      @media (orientation: portrait) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .owl-carousel {
    .owl-dots {
      margin-top: 60px !important;

      @media (orientation: portrait) {
        margin-top: 30px !important;
      }

      .owl-dot {
        span {
          background-color: #5a5a5a91;
          border-radius: 8px;
          height: 12px;
          margin-left: 8px;
          margin-right: 8px;
          transition: background-color ease-in-out 0.3s;
          width: 80px;

          @media (orientation: portrait) {
            height: 8px;
            margin-left: 4px;
            margin-right: 4px;
            width: 32px;
          }
        }

        &.active {
          span {
            background-color: ${verdeMedway};
            transition: background-color ease-in-out 0.3s;
          }
        }
      }
    }

    .owl-nav {
      font-size: 0;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 35px);
      transform: translateY(-50%);

      span {
        display: none;
      }

      .owl-prev {
        height: 70px;
        left: -38px;
        margin: 0;
        opacity: 1;
        position: absolute;
        transition: opacity ease-in-out 0.3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          left: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Prev});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }

      .owl-next {
        height: 70px;
        margin: 0;
        opacity: 1;
        position: absolute;
        right: -38px;
        transition: opacity ease-in-out 0.3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          right: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Next});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }
    }
  }
`;

export const Speeches = styled.section`
  padding-bottom: 60px;
  padding-top: 60px;

  @media (orientation: portrait) {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .box-table {
    border-radius: 20px;
    border: 5px solid ${azulMedway};
    box-shadow: 0px 4px 25px 3px rgba(0, 0, 0, 0.2);

    .line {
      display: flex;
      align-items: center;
      margin-left: 5px;
      margin-right: 5px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;

      @media (orientation: portrait) {
        flex-direction: column;
        padding-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        text-align: center;
      }

      .column {
        flex-basis: 55%;

        &:first-child {
          padding-right: 14px;
        }

        &:last-child {
          padding-left: 14px;
        }

        @media (orientation: portrait) {
          padding-bottom: 10px;

          &:first-child {
            padding-right: 0;
          }

          &:last-child {
            padding-bottom: 0;
            padding-left: 0;
          }
        }

        h3 {
          color: #606060;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          margin: 0;

          @media (orientation: portrait) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        h4 {
          font-size: 30px;
          font-weight: 600;
          margin: 0;

          &.mobile {
            display: none;
          }

          @media (orientation: portrait) {
            display: none;
            font-size: 16px;
            line-height: 18px;

            &.mobile {
              display: initial;
            }
          }
        }

        &__images {
          flex-basis: 45%;

          .image {
            height: 80px;
            margin-right: 18px;

            @media (orientation: portrait) {
              margin-right: 8px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &__title {
        background-color: ${azulMedway};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #ffffff;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 18px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 13px;

        .column {
          @media (orientation: portrait) {
            padding-bottom: 0;
          }
        }
      }

      &__item {
        border-bottom: 2px solid #e2e2e2;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
`;

export const Testemonials = styled.section`
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-slider {
    .item {
      border-radius: 20px;
      border: 2px solid #d6d6d6;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      margin: 15px 0;

      .box-content {
        padding-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30px;

        @media (orientation: portrait) {
          padding-left: 20px;
          padding-right: 20px;
        }

        &:before {
          background-image: url(${Aspas});
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 34px;
          width: 45px;
          margin-bottom: 38px;

          @media (orientation: portrait) {
            margin-bottom: 20px;
          }
        }

        .box-texts {
          color: #000000;
          margin-bottom: 40px;

          @media (orientation: portrait) {
            margin-bottom: 30px;
          }

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;

            @media (orientation: portrait) {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }

        .name {
          color: #000000;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;

          @media (orientation: portrait) {
            font-size: 16px;
            margin-bottom: 14px;
          }
        }

        .event {
          color: #9e9e9e;
          display: block;
          font-size: 16px;
          font-weight: bold;

          @media (orientation: portrait) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .owl-carousel {
    .owl-dots {
      margin-top: 60px !important;

      @media (orientation: portrait) {
        margin-top: 30px !important;
      }

      .owl-dot {
        span {
          background-color: #5a5a5a91;
          border-radius: 8px;
          height: 12px;
          margin-left: 8px;
          margin-right: 8px;
          transition: background-color ease-in-out 0.3s;
          width: 80px;

          @media (orientation: portrait) {
            height: 8px;
            margin-left: 4px;
            margin-right: 4px;
            width: 32px;
          }
        }

        &.active {
          span {
            background-color: ${verdeMedway};
            transition: background-color ease-in-out 0.3s;
          }
        }
      }
    }

    .owl-nav {
      font-size: 0;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 35px);
      transform: translateY(-50%);

      span {
        display: none;
      }

      .owl-prev {
        height: 70px;
        left: -38px;
        margin: 0;
        opacity: 1;
        position: absolute;
        transition: opacity ease-in-out 0.3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          left: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Prev});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }

      .owl-next {
        height: 70px;
        margin: 0;
        opacity: 1;
        position: absolute;
        right: -38px;
        transition: opacity ease-in-out 0.3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          right: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Next});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }
    }
  }
`;

export const CTA = styled.section`
  padding-bottom: 80px;
  padding-top: 20px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
  }

  .box-button {
    margin-top: 0;
    justify-content: center;
  }
`;
