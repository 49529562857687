import styled from 'styled-components';

// Assets
import Next from '../../../assets/white-next-arrow.svg';
import Prev from '../../../assets/white-prev-arrow.svg';

// Interface

export const Container = styled.div`
  .owl-theme {
    .owl-dots {
      margin-top: 0 !important;
      transform: translateY(-48px);
      position: relative;
      z-index: 9999;

      @media (orientation: portrait) {
        transform: translateY(-28px);
        margin-bottom: -23px;
      }

      .owl-dot {
        span {
          background-color: #dadada;
          border-radius: 20px;
          height: 13px;
          margin-left: 8px;
          margin-right: 8px;
          transition: background-color ease-in-out 0.3s;
          width: 13px;

          @media (orientation: portrait) {
            height: 12px;
            margin-left: 6px;
            margin-right: 6px;
            width: 12px;
          }
        }

        &.active {
          span {
            background-color: #00cfb4;
            transition: background-color ease-in-out 0.3s;
          }
        }
      }
    }

    .owl-nav {
      font-size: 0;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 35px);
      transform: translateY(-50%);

      @media (orientation: portrait) {
        display: none;
      }

      span {
        display: none;
      }

      .owl-prev {
        background-color: rgba(0, 207, 179, 0.6);
        height: 68px;
        left: 24px;
        margin: 0;
        position: absolute;
        width: 63px;
        border-radius: 50%;

        @media (orientation: portrait) {
          height: 33px !important;
          left: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: rgba(0, 207, 179, 1);
          cursor: pointer;
        }

        &:after {
          background-image: url(${Prev});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 36px;
          position: absolute;
          right: 0;
          width: 21px;
          margin: 16px 21px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }

      .owl-next {
        background-color: rgba(0, 207, 179, 0.6);
        height: 68px;
        margin: 0;
        position: absolute;
        right: 24px;
        width: 63px;
        border-radius: 50%;

        @media (orientation: portrait) {
          height: 33px !important;
          right: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: rgba(0, 207, 179, 1);
        }

        &:after {
          background-image: url(${Next});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 36px;
          position: absolute;
          right: 0;
          width: 21px;
          margin: 16px 21px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }
    }
  }
`;
