import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children?: React.ReactNode;
  type: 'alert' | 'error' | 'sucess';
  dangerouslySetInnerHTML?: any;
}

export function Message({ children, type, dangerouslySetInnerHTML }: Props) {
  return (
    <Container type={type} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </Container>
  );
}
