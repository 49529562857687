import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-right: 35px;
  opacity: 1;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity ease-in-out 0.3s;

  @media (orientation: portrait) {
    margin-right: 0;
    margin-bottom: 28px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #ffffff;
    opacity: 0.6;
    transition: opacity ease-in-out 0.3s;
  }
`;
