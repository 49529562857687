import styled, { css } from 'styled-components';

// Assets
import CloseIcon from '../../../assets/close-modal.svg';

// Interface
interface Props {
  open: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  background: rgba(0, 1, 34, 0.5);
  bottom: 0;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;

  ${({ open }) =>
    open
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

export const Content = styled.section`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 2px rgba(0, 32, 91, 0.15);
  max-width: 560px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 85px;
  position: relative;

  @media (orientation: portrait) {
    max-width: 280px;
    padding-bottom: 20px;
    padding-top: 65px;
  }
`;

export const BoxText = styled.div`
  max-height: 40vh;
  overflow: scroll;
  padding-right: 28px;

  @media (orientation: portrait) {
    max-height: 50vh;
    padding-right: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track:enabled {
    background-color: #dadada;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb:vertical {
    background-color: #0f0c6d;
    border-radius: 30px;
  }

  p {
    color: #414141;
    font-size: 16px;
    font-weight: 400;

    @media (orientation: portrait) {
      font-size: 14px;
    }
  }
`;

export const Close = styled.button`
  background-repeat: no-repeat;
  background-size: contain;
  background: url(${CloseIcon});
  border: none;
  height: 18px;
  position: absolute;
  right: 28px;
  top: 28px;
  width: 18px;

  @media (orientation: portrait) {
    right: 20px;
    top: 20px;
  }
`;
