import styled from 'styled-components';

// Assets

// Interface
export const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  padding: 15px;
`;

export const BoxHero = styled.section`
  display: flex;
  align-items: center;
  background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%);
  justify-content: center;
  overflow: hidden;
  padding-bottom: 82px;
  padding-top: 152px;

  @media (orientation: portrait) {
  }

  .boxContent {
    text-align: center;
    width: 800px;

    @media (orientation: portrait) {
      width: 272px;
    }

    .title {
      color: #ffffff;
      font-size: 60px;
      font-weight: 400;

      @media (orientation: portrait) {
        font-size: 24px;
      }

      strong {
        font-weight: 700;
      }

      h1 {
        color: #ffffff;
        font-size: 60px;
        font-weight: 400;

        @media (orientation: portrait) {
          font-size: 24px;
        }

        strong {
          color: #00cfb4;
          font-weight: 700;
        } 
      }
    }

    .text {
      color: #ffb81c;
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 36px;
      padding-top: 24px;
    }
  }
`;

export const BoxPillars = styled.section`
  background-color: #ffffff;
  padding-bottom: 84px;
  padding-top: 84px;

  @media (orientation: portrait) {
    padding-bottom: 44px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 44px;
  }

  .box-title {
    text-align: center;

    @media (orientation: portrait) {
      text-align: left;
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      color: #00205b;
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
      }
    }
    .description {
      font-size: 18px;
      font-weight: 400;
      color: #414141;
      margin: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }

  .box-content {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 60px;
    max-width: 1225px;
    width: 100%;
    margin: 60px auto 0;

    @media (orientation: portrait) {
      margin: 50px auto 0;
    }
  }

  .callToAction {
    display: flex;
    align-items: center;
    background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%);
    border-radius: 20px;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    margin: 72px auto 0;
    max-width: 1000px;
    padding-bottom: 48px;
    padding-top: 48px;
    width: 100%;

    @media (orientation: portrait) {
      gap: 38px;
      padding-left: 38px;
      padding-right: 38px;
    }

    .box-additional-content {
      display: flex;
      align-items: center;
      flex-basis: auto;
      flex-direction: column;
      justify-content: center;

      .text {
        color: #ffffff;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;

        @media (orientation: portrait) {
          text-align: center;
        }
      }
      .logo {
        @media (orientation: portrait) {
          max-width: 160px;
        }
      }
    }

    .box-button {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: 360px;
      width: 100%;

      .text {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 16px;
        text-align: center;

        @media (orientation: portrait) {
          font-size: 16px;
          margin-bottom: 26px;
        }
      }
    }
  }
`;

export const BoxComparison = styled.section`
  background-color: #ffffff;
  padding-bottom: 80px;

  @media (orientation: portrait) {
    padding-bottom: 24px;
  }

  .box-title {
    margin-bottom: 52px;
    text-align: center;

    @media (orientation: portrait) {
      text-align: left;
      margin-bottom: 32px;
    }

    .title {
      color: #00205b;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 18px;

      @media (orientation: portrait) {
        font-size: 24px;
        margin-bottom: 22px;
      }
    }

    .text {
      color: #414141;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }

  .box-table-mobile {
    display: flex;

    .box-table-mobile__1 {
      border-radius: 20px 0 0 20px;
      border: 2px solid #0f0c6d;
      border-right: 0;
      width: 50%;

      @media (orientation: landscape) {
        display: none;
      }

      .table-head {
        background-color: #0f0c6d;
        display: grid;
        grid-template-columns: 1fr;
        height: 80px;
        border-radius: 16px 0 0 0;

        .table-column {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 16px;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 16px;

          .table-title {
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0;
            text-align: center;
          }
        }
      }

      .table-content {
        display: grid;
        grid-template-columns: 1fr;
        padding-bottom: 6px;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 6px;

        .table-column {
          display: grid;
          grid-template-rows: 80px 80px 80px 80px 80px 80px;

          .table-cell {
            padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            .table-title {
              color: #00205b;
              font-size: 12px;
              font-weight: 600;
              margin-bottom: 0;
              text-align: left;
            }
          }

          &__title {
            .table-cell {
              flex-direction: row;
              justify-content: flex-start;
            }
          }
        }
      }
    }

    .box-table-mobile__2 {
      border-radius: 0 20px 20px 0;
      border: 2px solid #0f0c6d;
      border-left: 0;
      width: 50%;

      @media (orientation: landscape) {
        display: none;
      }

      .table-head {
        background-color: #0f0c6d;
        display: grid;
        grid-template-columns: 1fr;
        height: 80px;
        border-radius: 0 16px 0 0;

        .table-column {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 16px;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 16px;

          .table-title {
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0;
            text-align: center;
          }
        }
      }

      .table-content {
        display: grid;
        grid-template-columns: 1fr;
        padding-bottom: 6px;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 6px;

        .table-column {
          display: grid;
          grid-template-rows: 80px 80px 80px 80px 80px 80px;

          .table-cell {
            padding-bottom: 20px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .table-icon {
              height: 24px;
              width: 24px;
            }

            .table-label {
              color: #606060;
              display: block;
              font-size: 12px;
              font-weight: 600;
              margin-top: 6px;
            }
          }

          &__title {
            .table-cell {
              flex-direction: row;
              justify-content: flex-start;
            }
          }
        }
      }

      .owl-dots {
        margin-top: 0;
        margin-bottom: 18px;

        .owl-dot {
          span {
            background-color: #dadada;
            border-radius: 8px;
            height: 12px;
            margin-left: 6px;
            margin-right: 6px;
            transition: background-color ease-in-out 0.3s;
            width: 12px;
          }

          &.active {
            span {
              background-color: #00cfb4;
              transition: background-color ease-in-out 0.3s;
            }
          }
        }
      }
    }
  }

  .box-table-desktop {
    border-radius: 20px;
    border: 2px solid #0f0c6d;

    @media (orientation: portrait) {
      display: none;
    }

    .table-head {
      background-color: #0f0c6d;
      border-radius: 16px 16px 0 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .table-column {
        padding-bottom: 24px;
        padding-top: 24px;

        .table-title {
          color: #ffffff;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }

    .table-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;

      .table-column {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;

        .table-cell {
          padding-bottom: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;
          text-align: center;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .table-title {
            color: #00205b;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0;
            text-align: left;
          }

          .table-icon {
          }

          .table-label {
            color: #606060;
            display: block;
            font-size: 14px;
            font-weight: 600;
            margin-top: 6px;
          }
        }

        &__title {
          .table-cell {
            flex-direction: row;
            justify-content: flex-start;
          }
        }
      }
    }
  }
`;

export const BoxWaranty = styled.section``;

export const BoxVideoTestemonial = styled.section`
  background-color: #f5f5fb;
  padding-bottom: 64px;
  padding-top: 64px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-title {
    margin-bottom: 68px;
    text-align: center;

    @media (orientation: portrait) {
      margin-bottom: 44px;
      text-align: left;
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      color: #00205b;
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
      }
    }
    .description {
      font-size: 18px;
      font-weight: 400;
      color: #414141;
      margin: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }
`;

export const BoxTestemonial = styled.section`
  background-color: #fff;
  padding-bottom: 64px;
  padding-top: 64px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
`;

export const BoxBuy = styled.section`
  background: #0f0c6d;
  display: flex;
  justify-content: center;
  padding-bottom: 72px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 72px;

  @media (orientation: portrait) {
    padding-bottom: 48px;
    padding-top: 48px;
  }
`;

export const BoxAcordiao = styled.div`
  background-color: #ffffff;
  min-height: 230px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;

  @media (orientation: portrait) {
  }

  .titleAcordiao {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    margin-bottom: 56px;
    color: #00205b;
    font-weight: 700;

    @media (orientation: portrait) {
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .accordion-button {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0f247b;
    color: #ffffff;

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    &.collapsed {
      color: #00205b;
      background-color: transparent;

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f247b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }
`;

export const BoxFaq = styled.section`
  background-color: #ffffff;
  min-height: 230px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;

  @media (orientation: portrait) {
  }

  .titleAcordiao {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    margin-bottom: 56px;
    color: #00205b;
    font-weight: 700;

    @media (orientation: portrait) {
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .accordion-button {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0f247b;
    color: #ffffff;

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    &.collapsed {
      color: #00205b;
      background-color: transparent;

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f247b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }
`;

export const Footer = styled.section`
  display: block;
  align-items: center;
  background: linear-gradient(90deg,#0f0c6d,#000f50);
  justify-content: center;
  padding-bottom: 48px;
  padding-top: 48px;
  padding-right: 15px;
  padding-left: 15px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .titleFooter {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 0;

    @media (orientation: portrait) {
      font-size: 12px;
      line-height: 1.6;
    }
  }

  && a {
    color: #fff;
    line-height: 1;
  }

  && a:hover {
    color: #ffb81c;

    transform: translate(0%, -30%);
    transition: 0.3s ease-out;
  }
`;
