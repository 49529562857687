import React, { useState } from 'react';

import { Container } from './styles';

import LogoMedway from '../../assets/logo-medway-minificado.png';

const QRCode = require('qrcode-react');

const QRCodeMedway: React.FC = () => {
  const [url, setUrl] = useState('');
  const [size, setSize] = useState<number>(750);

  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const handleChangeSetSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const num: number | any = e.target.value;
    setSize(num);
  };

  return (
    <Container>
      <form>
        <label>
          Url:
          <input type="text" value={url} onChange={handleChangeUrl} />
        </label>
        <label>
          Size:
          <select onChange={handleChangeSetSize}>
            <option value="250">250</option>
            <option value="500">500</option>
            <option value="750" selected>
              750
            </option>
            <option value="1000">1000</option>
            <option value="3000">3000</option>
          </select>
        </label>
      </form>
      <QRCode
        renderAs="svg"
        value={url}
        logo={LogoMedway}
        bgColor="#FFFFFF"
        fgColor="#0f247b"
        size={size}
        logoWidth={size && size * 0.5}
      />
    </Container>
  );
};

export default QRCodeMedway;
