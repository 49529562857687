import styled, { css } from 'styled-components';

// Assets
import Aspas from '../../../../assets/aspas-verdes.svg';

// Interface
interface Props {
  type: 'video' | 'text' | 'teacher';
}

export const Container = styled.div<Props>`
  ${({ type }) =>
    type === 'text' &&
    css`
      display: flex;
      align-content: stretch;
      align-items: center;
      border-radius: 60px;
      border: 4px solid #00cfb4;
      flex-direction: column;
      justify-content: center;
      margin: 60px 30px 0;
      padding: 50px 30px 50px;
      position: relative;

      &:before {
        background-color: #ffffff;
        background-image: url(${Aspas});
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 40px;
        left: -32px;
        position: absolute;
        top: 65px;
        width: 54px;
      }

      &:after {
        background-color: #ffffff;
        background-image: url(${Aspas});
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 40px;
        content: '';
        display: block;
        height: 40px;
        position: absolute;
        right: -32px;
        transform: rotate(180deg);
        width: 54px;
      }
    `}

  ${({ type }) =>
    type === 'teacher' &&
    css`
      height: calc(100% - 50px);
      margin-top: 50px;
      padding: 15px;
      transition: transform ease-in-out 0.2s;

      &:hover {
        transform: translateY(-20px);
        transition: transform ease-in-out 0.2s;
      }
    `}
`;
