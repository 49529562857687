// import ScrollableAnchor from 'react-scrollable-anchor';
import React, { useState, useEffect } from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'lazysizes';

import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  BannerPrincipal,
  Busca,
  Container,
  CardItem,
  BoxCursos,
  Depoimentos,
} from './styles';

interface Botao {
  title: string;
  url: string;
  target: string;
}

interface CardsDepoimentos {
  foto: {
    title: string;
    sizes: {
      '500x100': string;
    };
  };
  depoimento: string;
  aprovacao: string;
}

interface Cards {
  background: {
    title: string;
    sizes: {
      '1920x1080': string;
    };
  };
  logo: {
    title: string;
    sizes: {
      '500x100': string;
    };
  };
  titulo: string;
  descricao: string;
  botao: Botao;
}

interface Dados {
  cursos: {
    apresentacao: {
      background: {
        title: string;
        sizes: {
          '1920x1080': string;
        };
      };
      imagem: {
        title: string;
        sizes: {
          '1920x1080': string;
        };
      };
    };
    cursos: {
      residencia_medica: {
        titulo: string;
        cards: [Cards];
        aprovados: Botao;
      };
      medicina_de_emergencia: {
        titulo: string;
        cards: [Cards];
        aprovados: Botao;
      };
      gratis: {
        titulo: string;
        cards: [Cards];
        aprovados: Botao;
      };
    };
    depoimentos: {
      titulo: string;
      descricao: string;
      cards: [CardsDepoimentos];
    };
  };
}

const Cursos: React.FC = () => {
  const [dados, setDados] = useState<Dados | null>(null);

  useEffect(() => {
    api.get(`acf/v3/pages/6399`).then(response => {
      setDados(response.data.acf);
    });

    // api.get(`acf/v3/pages/1047`).then(response => {
    //   setDados(response.data.acf);
    // });
  }, []);

  return (
    <>
      {dados ? (
        <>
          <BannerPrincipal
            bg={dados.cursos.apresentacao.background.sizes['1920x1080']}
          >
            <img
              src={dados.cursos.apresentacao.imagem.sizes['1920x1080']}
              alt=""
            />
          </BannerPrincipal>
          <Container>
            <div className="box-busca">
              <Busca>
                <h5>Direcione sua busca:</h5>
                <div className="box-button">
                  <button
                    onClick={() => {
                      document
                        .getElementById('residencia-medica')
                        ?.scrollIntoView(true);
                    }}
                    type="button"
                    className="btn-busca todos"
                  >
                    TODOS
                  </button>
                  <button
                    onClick={() => {
                      document
                        .getElementById('residencia-medica')
                        ?.scrollIntoView(true);
                    }}
                    type="button"
                    className="btn-busca residencia"
                  >
                    RESIDÊNCIA
                  </button>
                  <button
                    onClick={() => {
                      document
                        .getElementById('medicina-de-emergencia')
                        ?.scrollIntoView(true);
                    }}
                    type="button"
                    className="btn-busca emergencia"
                  >
                    EMERGÊNCIA
                  </button>
                  <button
                    onClick={() => {
                      document.getElementById('gratis')?.scrollIntoView(true);
                    }}
                    type="button"
                    className="btn-busca gratis"
                  >
                    GRATUITOS
                  </button>
                </div>
              </Busca>
            </div>
          </Container>
          <Container>
            <BoxCursos id="residencia-medica">
              <div className="box-titulo">
                <h2>{dados.cursos.cursos.residencia_medica.titulo}</h2>
              </div>
              {dados.cursos.cursos.residencia_medica.cards.map(item => (
                <CardItem className="item">
                  <div className="bg">
                    <img className="lazyload" src="" data-srcset={`${item.background.sizes['1920x1080']} 1x`} alt="" />
                  </div>
                  <img
                    className="lazyload logo"
                    src=""
                    data-srcset={`${item.logo.sizes['500x100']} 1x`}
                    alt=""
                  />
                  <div className="conteudo">
                    <div className="titulo">
                      <h4>{item.titulo}</h4>
                    </div>
                    <div className="detalhes">{item.descricao}</div>
                    <div className="box-button">
                      <a href={item.botao.url} target={item.botao.target}>
                        {item.botao.title}
                      </a>
                    </div>
                  </div>
                </CardItem>
              ))}
              <a
                href={dados.cursos.cursos.residencia_medica.aprovados.url}
                target={dados.cursos.cursos.residencia_medica.aprovados.target}
                className="box-botao"
              >
                {dados.cursos.cursos.residencia_medica.aprovados.title}
              </a>
            </BoxCursos>

            <BoxCursos
              id="medicina-de-emergencia"
              className="medicina-emergencia"
            >
              <div className="box-titulo">
                <h2>{dados.cursos.cursos.medicina_de_emergencia.titulo}</h2>
              </div>
              {dados.cursos.cursos.medicina_de_emergencia.cards.map(item => (
                <CardItem className="item">
                  <div className="bg">
                    <img className="lazyload" src="" data-srcset={`${item.background.sizes['1920x1080']} 1x`} alt="" />
                  </div>
                  <img
                    className="lazyload logo"
                    src=""
                    data-srcset={`${item.logo.sizes['500x100']} 1x`}
                    alt=""
                  />
                  <div className="conteudo">
                    <div className="titulo">
                      <h4>{item.titulo}</h4>
                    </div>
                    <div className="detalhes">{item.descricao}</div>
                    <div className="box-button">
                      <a href={item.botao.url} target={item.botao.target}>
                        {item.botao.title}
                      </a>
                    </div>
                  </div>
                </CardItem>
              ))}
            </BoxCursos>

            <BoxCursos id="gratis" className="gratis">
              <div className="box-titulo">
                <h2>{dados.cursos.cursos.gratis.titulo}</h2>
              </div>
              {dados.cursos.cursos.gratis.cards.map(item => (
                <CardItem className="item">
                  <div className="bg">
                    <img className="lazyload" src="" data-srcset={`${item.background.sizes['1920x1080']} 1x`} alt="" />
                  </div>
                  <img
                    className="lazyload logo"
                    src=""
                    data-srcset={`${item.logo.sizes['500x100']} 1x`}
                    alt=""
                  />
                  <div className="conteudo">
                    <div className="titulo">
                      <h4>{item.titulo}</h4>
                    </div>
                    <div className="detalhes">{item.descricao}</div>
                    <div className="box-button">
                      <a href={item.botao.url} target={item.botao.target}>
                        {item.botao.title}
                      </a>
                    </div>
                  </div>
                </CardItem>
              ))}
            </BoxCursos>

            <Depoimentos>
              <div className="box-titulo">
                <h2>{dados.cursos.depoimentos.titulo}</h2>
                <p>
                  {dados.cursos.depoimentos.descricao.replace(
                    /<\/?p[^>]*>/g,
                    ''
                  )}
                </p>
              </div>

              <OwlCarousel
                className="owl-theme"
                responsive={{
                  0: {
                    items: 1,
                    margin: 40,
                    loop: true,
                    autoplay: false,
                    autoplayTimeout: 3500,
                    nav: true,
                  },
                  991: {
                    items: 3,
                    margin: 40,
                    loop: true,
                    autoplay: false,
                    autoplayTimeout: 3500,
                    nav: true,
                  },
                }}
              >
                {dados.cursos.depoimentos.cards.map(item => (
                  <div className="item">
                    <img className="lazyload" src="" data-srcset={`${item.foto.sizes['500x100']} 1x`} alt="" />
                    <div className="paragrafo">{item.depoimento}</div>
                    <div className="nome">
                      {item.aprovacao
                        .split('</strong>,')[0]
                        .replace('<p><strong>', '')}
                      ,
                    </div>
                    <div className="aprovacao_ou_ano_atual">
                      {item.aprovacao
                        .split('</strong>,')[1]
                        .replace('</p>', '')}
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </Depoimentos>
          </Container>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Cursos;
