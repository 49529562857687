import styled from 'styled-components';

// Assets

// Interface
interface Props {
  bg: string;
  align: 'left' | 'center' | 'right';
}

export const Container = styled.div<Props>`
  background-color: ${({ bg }) => bg};
  padding-bottom: 80px;
  padding-top: 80px;
  text-align: ${({ align }) => align};

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 40px;
  }
`;
