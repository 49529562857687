import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.span`
  color: #00205b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
`;
