import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ButtonProps {
  color: string;
  bg: string;
  off?: boolean;
}

export const Container = styled.div<ButtonProps>`
  .button {
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 16px 12px;
    justify-content: center;
    text-align:center;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    order: 0;
    flex-grow: 1;
    margin: 10px 0;
    ${props =>
      props.color &&
      props.bg &&
      css`
        background-color: ${props.bg};
        color: ${props.color};
        &:hover {
          background: ${shade(0.2, props.bg)};
        }
      `}
    ${props =>
      props.off &&
      css`
        background-color: #afafaf;
        opacity: 0.5;
        pointer-events: none;
      `}

      @media (orientation: portrait) {
        width: 290px;
        margin: 5px 0;
      }
  
  }
`;
