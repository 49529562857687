import React, { useState, useEffect } from 'react';

import apiCRMedway from '../../../services/CRMedway/api';

import { Container, Colunas, BoxOferta, BoxTabela } from './styles';

import Button from '../../../components/Button';
import AddressAndPolicies from '../../../components/AddressAndPolicies';

interface Itens {
  turma_e_data: string;
  valor_a_vista: string;
  valor_a_prazo: string;
  desativar_vendas: boolean;
  botao: {
    title: string;
    url: string;
    target: string;
  };
}

interface Coluna {
  tamanho: string;
  background: {
    title: string;
    sizes: {
      '1920x1080': string;
    };
  };
  logo: {
    title: string;
    sizes: {
      large: string;
    };
  };
  texto: string;
  display: string;
  tabela_unica: {
    background: {
      title: string;
      sizes: {
        '1920x1080': string;
      };
    };
    selo: {
      title: string;
      sizes: {
        thumbnail: string;
      };
    };
    valor_total: string;
    valor_com_desconto: string;
    economia: string;
    parcelamento: string;
    botao: {
      title: string;
      url: string;
      target: string;
    };
  };
  tabela_com_linhas: {
    background: {
      title: string;
      sizes: {
        '1920x1080': string;
      };
    };
    nome_do_curso: string;
    itens: [Itens];
  };
}

interface Checkout {
  checkout: {
    colunas: [Coluna];
  };
}

interface Utms {
  campaign: string | null;
  source: string | null;
  medium: string | null;
  content: string | null;
}

const CRMedway: React.FC = () => {
const [dados, setDados] = useState<Checkout | null>(null);
const hostName = window.location.hostname;

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  
  const utmsUrl: Utms = {
    campaign: params.get("utm_campaign"),
    source: params.get("utm_source"),
    medium: params.get("utm_medium"),
    content: params.get("utm_content"),
  };

  useEffect(() => {

    apiCRMedway.get(`pages/${hostName === 'cr.medway.com.br' ? `3599` : `10326`}`)
    .then(response => {
      setDados(response.data.acf);
    });
  }, []);

  function createUrlUtms(utms: Utms) {
    
    if (utms.source) {
      return `/?utm_campaign=${utms.campaign}&utm_source=${utms.source}&utm_medium=${utms.medium}&utm_content=${utms.content}`
    }

    return ""

  }

  return (
    <Container style={{display:'block'}}>
      <Container>
        {dados && (
          dados.checkout.colunas.map(coluna => (
            <Colunas
              bg={coluna.background.sizes['1920x1080']}
              size={coluna.tamanho}
              key={coluna.texto}
            >
              <img
                className="logo"
                src={coluna.logo.sizes.large}
                alt={coluna.logo.title}
              />
              <div
                className="titulo"
                dangerouslySetInnerHTML={{
                  __html: coluna.texto,
                }}
              />
              {coluna.display === 'CRTU' ? (
                <>
                  <BoxOferta
                    bg={coluna.tabela_unica.background.sizes['1920x1080']}
                    selo={coluna.tabela_unica.selo.sizes.thumbnail}
                  >
                    <div className="valor-total-branco">
                      <p>De</p>
                      <p>{coluna.tabela_unica.valor_total}</p>
                    </div>
                    <div className="valor-com-desconto">
                      <p>por</p>
                      <p>{coluna.tabela_unica.valor_com_desconto}</p>
                    </div>
                    <div className="economia">
                      Economize {coluna.tabela_unica.economia}
                    </div>
                    <p className="ou">ou</p>
                    <div className="parcelamento">
                      <p>até</p>
                      <p>{coluna.tabela_unica.parcelamento}</p>
                    </div>
                    <div className="valor-total-amarelo">
                      (Total de {coluna.tabela_unica.valor_total})
                    </div>
                  </BoxOferta>
                  <div className="botao">
                    <Button
                      color="FFF"
                      backgorund="#FFB81C"
                      url={coluna.tabela_unica.botao.url + createUrlUtms(utmsUrl)}
                      target={coluna.tabela_unica.botao.target}
                      title={coluna.tabela_unica.botao.title}
                    />
                  </div>
                </>
              ) : (
                <>
                  <BoxTabela
                    bg={coluna.tabela_com_linhas.background.sizes['1920x1080']}
                  >
                    <div className="nome-do-curso">
                      {coluna.tabela_com_linhas.nome_do_curso}
                    </div>
                    <div className="itens">
                      {coluna.tabela_com_linhas.itens.map((item, key) => (
                        <div className="line" key={key}>
                          <div className="valores">
                            <p
                              className={
                                item.botao.title !== 'ESGOTADO' &&
                                item.botao.title !== 'LISTA DE ESPERA'
                                  ? 'nome-turma'
                                  : 'nome-turma font-size'
                              }
                            >
                              {item.turma_e_data}
                            </p>
                            {item.botao.title !== 'ESGOTADO' &&
                              item.botao.title !== 'LISTA DE ESPERA' && (
                                <>
                                  <div className="valor-a-vista">
                                    <p>À vista</p>
                                    <p>{item.valor_a_vista}</p>
                                  </div>
                                  <div className="valor-a-prazo">
                                    <p>A prazo</p>
                                    <p>{item.valor_a_prazo}</p>
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="botao">
                            <Button
                              color="FFF"
                              backgorund="#FFB81C"
                              url={item.botao.url + createUrlUtms(utmsUrl)}
                              target={item.botao.target}
                              title={item.botao.title}
                              disable={item.desativar_vendas}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </BoxTabela>
                </>
              )}
            </Colunas>
        )))}
      </Container>
      <AddressAndPolicies background = '#00205B' />
    </Container>
  );
};

export default CRMedway;
