import React, { useState, useEffect } from 'react';

import CRMedway from './CRMedway';
import Intensivo from './Intensivo';

const Checkouts: React.FC = () => {
  const [hostName, setHostName] = useState('');
  let res = <></>;
  useEffect(() => {
    setHostName(window.location.hostname);
  }, []);

  switch (hostName) {
    case 'mentoria.medway.com.br':
      break;
    case 'extensivo.medway.com.br':
      break;
    case 'cr.medway.com.br':
      res = <CRMedway />
      return res;
    case 'ps.medway.com.br':
      break;
    case 'intensivo.medway.com.br':
      res = <Intensivo />;
      return res;
    default:
      res = <Intensivo />;
      return res;
  }

  return res;
};

export default Checkouts;
