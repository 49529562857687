import styled, { css } from 'styled-components';

// Assets

// Interface
interface Props {
  fill: 'contained' | 'outlined';
  size: 'small' | 'big';
  fluid?: boolean;
  pattern: 'base' | 'variant';
}

export const Container = styled.a<Props>`
  border-radius: 50px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;

  ${({ fill, size }) =>
    fill === 'contained' &&
    css`
      background-color: #ffb81c;
      color: #ffffff;
      padding: ${size === 'big' ? '14px 30px' : '10px 30px'};
      transition: background-color ease-in-out 0.3s;

      &:not([href]) {
        color: #ffffff;

        &:hover {
          color: #ffffff;
        }
      }
    `}

  ${({ fill, size, pattern }) =>
    fill === 'outlined' &&
    css`
      background-color: transparent;
      border: 2px solid ${pattern === 'base' ? '#00cfb4' : '#3b3fb6'};
      color: ${pattern === 'base' ? '#00cfb4' : '#3b3fb6'};
      padding: ${size === 'big' ? '14px 15px' : '10px 15px'};
      transition: background-color ease-in-out 0.3s,
        border-color ease-in-out 0.3s, color ease-in-out 0.3s;

      &:not([href]) {
        color: ${pattern === 'base' ? '#00cfb4' : '#3b3fb6'};

        &:hover {
          color: #ffffff;
        }
      }
    `}

  ${({ fluid }) =>
    fluid === true &&
    css`
      text-align: center;
      width: 100%;
    `}

  &:hover {
    ${({ fill }) =>
      fill === 'contained' &&
      css`
        background-color: #3b3fb6;
        color: #ffffff;
        transition: background-color ease-in-out 0.3s;
      `}

    ${({ fill }) =>
      fill === 'outlined' &&
      css`
        background-color: #3b3fb6;
        border-color: #3b3fb6;
        color: #ffffff;
        transition: background-color ease-in-out 0.3s,
          border-color ease-in-out 0.3s, color ease-in-out 0.3s;
      `}
  }
`;
