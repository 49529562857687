import styled, { css } from 'styled-components';

// Assets

// Interface
interface Props {
  menuMobile: boolean;
}

export const Container = styled.section<Props>`
  @media only screen and (max-width: 1024px) {
    background-color: #0f0c6d;
    flex-direction: column;
    height: 100vh;
    padding-top: 45px;
    position: absolute;
    right: 0;
    top: 100%;
    width: 80vw;
    align-items: center;

    ${({ menuMobile }) =>
      menuMobile
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}
  }
`;
