import axios from 'axios';

const prod = 'https://app.rdstation.com.br/api/1.2';
const dev = 'https://app.rdstation.com.br/api/1.2';

const apiRD = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? dev : prod,
});

export default apiRD;
