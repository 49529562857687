import React, { ButtonHTMLAttributes } from 'react';

// Styles
import { Container } from './styles';

// Interface
interface LinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  href: any;
  menuRef: any;
  setMenuMobile: any;
}

export function Link({ children, href, menuRef, setMenuMobile, ...rest }: LinkProps) {
  const scrollTo = (e: any) => window.scrollTo(0, e.current.offsetTop - 50);

  return (
    <Container
      onClick={() => {
        scrollTo(menuRef);
        setMenuMobile(false);
      }}
      {...rest}
    >
      {children}
    </Container>
  );
}
