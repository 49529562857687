import styled, { css } from 'styled-components';

// Elements
import Aspas from '../../assets/aspas.svg';
import Next from '../../assets/next-arrow.svg';
import Prev from '../../assets/prev-arrow.svg';

// Cores

// Interfaces
interface AberturaProps {
  background: string;
  backgroundMobile: string;
}

interface BaseProps {
  background: string;
}

interface TextProps {
  colorType: string;
}

// Componentes
export const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  padding: 15px;
`;

export const Title = styled.div<TextProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  width: 100%;

  @media (orientation: portrait) {
    margin-bottom: 24px;
  }

  h2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 42px;
    font-weight: 800;
    justify-content: center;
    margin-bottom: 0;
    max-width: fit-content;
    text-transform: uppercase;
    width: 100%;

    ${
      props => props.colorType === 'base' ? css`
        color: #0F0C6D;
      ` : css`
        color: #ffffff;
      `
    }

    @media (orientation: portrait) {
      font-size: 24px;
    }

    &:after {
      background-color: #00CFB4;
      content: '';
      height: 10px;
      margin-top: 10px;
      width: 100px;

      @media (orientation: portrait) {
        margin-top: 6px;
        height: 6px;
        width: 40px;
      }
    }
  }
`;

export const Button = styled.div`
  display: flex;

  a {
    background-color: #00cfb4;
    border-radius: 25px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
    padding: 10px 40px;
    transition: opacity .3s ease-in-out;

    &:hover {
      opacity: .9;
      text-decoration: none;
      transition: opacity .3s ease-in-out;
    }
  }
`;

// Blocos
export const Abertura = styled.section<AberturaProps>`
  display: flex;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  @media (orientation: landscape) {
    ${
      props => props.background &&
      css `
        background-image: url(${props.background});
      `
    }
  }

  @media (orientation: portrait) {
    justify-content: center;

    ${
      props => props.backgroundMobile &&
      css `
        background-image: url(${props.backgroundMobile});
      `
    }
  }

  .box-content {
    display: flex;
    padding-left: 80px;

    @media (orientation: portrait) {
      padding-left: 0;
    }

    &:before {
      background-color: #00cfb4;
      content: "";
      display: block;
      height: 135px;
      margin-right: 30px;
      width: 20px;
      z-index: 1;

      @media (orientation: portrait) {
        display: none;
      }
    }
    .title {
      color: #ffffff;
      font-size: 84px;
      font-weight: 700;
      line-height: 126px;
      margin-bottom: 0;
      max-width: 880px;
      position: relative;
      text-transform: uppercase;

      @media (orientation: portrait) {
        font-size: 35px;
        line-height: 52px;
        max-width: 300px;
        text-align: center;
      }

      .image {
        position: absolute;
        bottom: -70px;
        right: 0;

        @media (orientation: portrait) {
          bottom: -90px;
          left: 50%;
          max-width: 195px;
          right: 0;
          transform: translateX(-50%);
        }
      }
    }
  }

`;

export const Banner = styled.section<BaseProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  padding-top: 50px;

  ${
    props => props.background &&
    css `
      background-image: url(${props.background});
    `
  }

  .box-elements {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (orientation: portrait) {
      flex-direction: column;
    }

    .box-name {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      justify-content: center;
      position: relative;

      &:after {
        background-color: #0f247b;
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        width: 3px;

        @media (orientation: portrait) {
          display: none;
        }
      }

      .logo {
        max-width: 550px;
        padding: 15px;
        width: 100%;

        @media (orientation: portrait) {
          max-width: 200px;
        }
      }
    }

    .box-content {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: center;

      p {
        color: #0f247b;
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        margin-bottom: 32px;
        max-width: 600px;
        text-align: center;
        text-transform: uppercase;

        @media (orientation: portrait) {
          font-size: 16px;
          line-height: 24px;
          max-width: 315px;
        }
      }
    }
  }
`;

export const Cursos = styled.section<BaseProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;

  ${
    props => props.background &&
    css `
      background-image: url(${props.background});
    `
  }

  @media (orientation: portrait) {
    padding: 30px 0;
  }

  .box-content {
    text-align: center;

    .box-text {
      p {
        color: #ffffff;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 28px;

        @media (orientation: portrait) {
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 22px;
        }
      }
    }

  }

  .box-slider {
    position: relative;

    .owl-stage-outer {
      position: relative;
      z-index: 1;
    }

    .box-item {
      padding: 30px 60px 30px 280px;

      @media (orientation: portrait) {
        padding: 130px 15px 15px;
      }

      .item {
        display: flex;
        align-items: flex-start;
        background-color: rgba(4,16,66,.8);
        border-radius: 25px;
        border: 3px solid #1ff;
        flex-direction: column;
        justify-content: center;
        min-height: 415px;
        position: relative;

        @media (orientation: portrait) {
          align-items: center;
          min-height: 160px;
        }

        .box-imagem {
          display: flex;
          align-items: flex-start;
          border-radius: 250px;
          border: 3px solid #1ff;
          justify-content: center;
          left: -225px;
          max-height: 450px;
          max-width: 450px;
          overflow: hidden;
          position: absolute;

          @media (orientation: portrait) {
            top: -130px;
            left: auto;
            max-width: 160px;
            max-height: 160px;
          }

          img {
            border-style: none;
          }
        }

        .box-conteudo {
          padding-left: 255px;

          @media (orientation: portrait) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 40px;
            padding-bottom: 30px;
            padding-left: 0;
          }

          img {
            margin: 0 auto 30px;
            max-width: 360px;
            width: 100%;

            @media (orientation: portrait) {
              margin: 0 auto 10px;
              max-width: 160px;
            }
          }

          p {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            max-width: 460px;
            text-align: center;

            @media (orientation: portrait) {
              font-size: 14px;
              padding: 0 15px;
            }
          }

        }

        .box-botao {
          display: flex;
          align-items: center;
          bottom: -27px;
          justify-content: center;
          margin-bottom: 0;
          margin-left: 255px;
          margin-right: 0;
          margin-top: 0;
          position: absolute;
          width: 460px;

          @media (orientation: portrait) {
            width: 100%;
            margin: 0;
            bottom: -14.5px;
          }

          a {
            background-color: #00cdb2;
            border-radius: 50px;
            color: #0f247b;
            font-size: 18px;
            font-weight: 700;
            padding: 15px 60px;
            transition: background-color .3s ease-in-out;

            @media (orientation: portrait) {
              font-size: 16px;
              padding: 5px 45px;
            }

            &:hover {
              background-color: #00a791;
              text-decoration: none;
              transition: background-color .3s ease-in-out;
            }
          }
        }
      }
    }

    .owl-nav {
      display: flex;
      align-items: center;
      height: calc(100% - 95px);
      justify-content: space-between;
      margin: auto;
      position: absolute;
      top: 0;
      width: 100%;

      @media (orientation: portrait) {
        height: 160px;
        width: 100%;
        padding: 0 50px;
      }

      button {
        height: 75px;
        outline: none;
        width: 40px;
        z-index: 2;

        @media (orientation: portrait) {
          height: 40px;
          width: 20px;
        }
        span {
          display: none;
        }

        &:hover {
          background-color: transparent;
        }
      }

      .owl-prev {
        background-image: url('https://www.medway.com.br/wp-content/themes/medway/dist/bundles/imgs/seta-carousel.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }

      .owl-next {
        background-image: url('https://www.medway.com.br/wp-content/themes/medway/dist/bundles/imgs/seta-carousel.svg');
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(180deg);
      }
    }

    .owl-dots {
      margin-top: 30px;
      position: relative;
      z-index: 3;

      @media (orientation: portrait) {
        margin-top: 10px;
      }

      .owl-dot {
        background-color: transparent;
        border-radius: 40px;
        border: 1px solid #fff;
        height: 30px;
        margin: 5px 15px;
        outline: none;
        transition: background-color .3s ease-in-out, border .3s ease-in-out;
        width: 30px;

        @media (orientation: portrait) {
          height: 15px;
          margin: 5px;
          width: 15px;
        }

        span {
          display: none;
        }

        &.active {
          background-color: #00cfb4;
          border: 1px solid #00cfb4;
          transition: background-color .3s ease-in-out, border .3s ease-in-out;
        }
      }
    }
  }
`;

export const Sobre = styled.section`
  background-color: #0f247b;
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;

  @media (orientation: portrait) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .box-image {
    @media (orientation: portrait) {
      margin-bottom: 20px;
    }

    img {
      border-radius: 56px;
      width: 100%;
    }
  }

  .box-content {
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    line-height: 44px;

    @media (orientation: portrait) {
      font-size: 18px;
      line-height: 34px;
    }

    p {
      margin: 0;
    }
  }

  .box-cta {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    position: relative;
  }
`;

export const Depoimentos = styled.section`
  background-color: #fff;
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-title {
    margin-bottom: 40px;
    margin: 0 auto;
    text-align: center;
    width: 45%;
  }

  .box-slider {
    margin-bottom: 80px;
    margin-top: 80px;
  }

  .owl-carousel {
    margin: 0 auto;

    @media (orientation: landscape) {
      width: 70%;
    }

    .owl-dots {
      margin-top: 60px !important;

      @media (orientation: portrait) {
        margin-top: 0px !important;
      }

      .owl-dot {
        span {
          background-color: #5A5A5A91;
          border-radius: 8px;
          height: 12px;
          margin-left: 8px;
          margin-right: 8px;
          transition: background-color ease-in-out .3s;
          width: 100px;

          @media (orientation: portrait) {
            width: 60px;
            height: 10px;
          }
        }

        &.active {
          span {
            background-color: #0F0C6D;
            transition: background-color ease-in-out .3s;
          }
        }
      }
    }

    .owl-stage-outer {
      .owl-stage{
        display: flex;
        align-items: stretch;
      }

      .box-item {
        display: flex;
        align-content: stretch;
        align-items: center;
        border-radius: 60px;
        border: 8px solid #0f247b;
        flex-direction: column;
        height: calc(100% - 125px);
        justify-content: center;
        margin: 125px 25px 0;
        max-width: 1000px;
        padding: 155px 20px 40px 20px;
        position: relative;

        @media (orientation: portrait) {
          margin: 60px 25px 0 !important;
          padding: 125px 20px 80px 20px !important;
        }

        &:before {
          background-color: #ffffff;
          background-image: url(${Aspas});
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 40px;
          left: -32px;
          position: absolute;
          top: 65px;
          width: 54px;
        }

        &:after {
          background-color: #ffffff;
          background-image: url(${Aspas});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 40px;
          content: '';
          display: block;
          height: 40px;
          position: absolute;
          right: -32px;
          transform: rotate(180deg);
          width: 54px;
        }

        img {
          background-color: #f0f0f5;
          border-radius: 50%;
          box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
          max-height: 250px;
          max-width: 250px;
          position: absolute;
          top: -125px;

          @media (orientation: portrait) {
            max-height: 130px;
            max-width: 130px;
            top: -65px;
          }
        }

        .text {
          color: #000000;
          font-size: 20px;
          font-style: italic;
          font-weight: 500;
          line-height: 40px;
          margin-bottom: 15px;
          text-align: center;

          @media (orientation: portrait) {
            font-size: 16px;
            line-height: 32px;
          }
        }

        .name {
          color: #000000;
          font-size: 30px;
          font-style: italic;
          font-weight: 700;
          text-align: center;

          @media (orientation: portrait) {
            font-size: 18px;
          }
        }

        .approved {
          font-size: 20px;
          line-height: 32px;
          color: #000000;
          font-weight: 400;
          text-align: center;
          margin-top: 10px;

          .university {
            display: block;
          }
        }
      }
    }

    .owl-nav {
      font-size: 0;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 35px);
      transform: translateY(-50%);

      span {
        display: none;
      }

      .owl-prev {
        height: 70px;
        left: -58px;
        margin: 0;
        opacity: 1;
        position: absolute;
        transition: opacity ease-in-out .3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          left: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: .6;
          transition: opacity ease-in-out .3s;
        }

        &:after {
          background-image: url(${Prev});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }

      .owl-next {
        height: 70px;
        margin: 0;
        opacity: 1;
        position: absolute;
        right: -58px;
        transition: opacity ease-in-out .3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          right: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: .6;
          transition: opacity ease-in-out .3s;
        }

        &:after {
          background-image: url(${Next});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }
    }
  }

  .box-cta {
    display: flex;
    justify-content: center;
  }
`;

export const Conteudos = styled.section`
  background-color: #f2f2f2;
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .box-cards {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (orientation: portrait) {
      flex-direction: column;
    }

    .card {
      display: flex;
      align-items: center;
      background-color: #0f247b;
      border-radius: 25px;
      border: none;
      flex-basis: 48%;
      flex-direction: column;
      justify-content: space-between;
      padding: 50px;

      @media (orientation: portrait) {
        margin-bottom: 15px;
        padding: 50px 25px;
      }

      .box-image {
        max-width: 250px;

        @media (orientation: portrait) {
          max-width: 200px;
        }

        .image {
          width: 100%;
        }
      }

      .box-icon {
        margin-bottom: 50px;
        margin-top: 50px;

        @media (orientation: portrait) {
          margin-bottom: 25px;
          margin-top: 25px;
        }

        .icon {
          width: 100%;
        }
      }

      .box-content {
        text-align: center;

        .box-texts {
          p {
            color: #ffffff;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            margin: 10px 0;

            @media (orientation: portrait) {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }


        .box-button {
          display: flex;
          justify-content: center;
          margin-top: 50px;

          @media (orientation: portrait) {
            margin-top: 25px;
          }

          a {
            background-color: #00cfb4;
          }
        }
      }

      &--light {
        background-color: #00cfb4;

        .box-content {
          .box-button {
            a {
              background-color: #0f247b;
            }
          }
        }
      }
    }
  }
`;

export const Destaques = styled.section`
  background-color: #f2f2f2;
  padding: 40px 0;

  @media (orientatio: portrait) {
    padding: 30px 0;
  }

  .box-content {
    .item {
      background-color: #071053;
      border-radius: 36px;
      box-shadow: 0px 5px 6px #00000029;
      padding: 8px;

      @media (orientation: portrait) {
        margin: 0 15px;
      }

      .item-link {
        display: block;
        opacity: 1;
        transition: opacity .3s ease-in-out;

        &:hover {
          opacity: .8;
          text-decoration: none;
          transition: opacity .3s ease-in-out;
        }
      }

      .box-image {
        height: 250px;

        img {
          border-radius: 28px;
          height: 100%;
          object-fit: cover;
        }
      }

      .box-text {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-left: 15px;
        margin-right: 15px;
        min-height: 200px;
        position: relative;

        &:hover {
          text-decoration: none;
        }

        .type {
          background-color: #FFDD03;
          border-radius: 20px;
          color: #071053;
          font-size: 16px;
          font-weight: 700;
          padding: 2px 28px;
          position: absolute;
          text-transform: uppercase;
          top: -15px;
        }

        .title {
          color: #ffffff;
          font-size: 20px;
          font-weight: 600;
          margin: 0;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }

    .owl-dots {
      margin-top: 60px !important;

      @media (orientation: portrait) {
        margin-top: 30px !important;
      }

      .owl-dot {
        span {
          background-color: #5A5A5A91;
          border-radius: 8px;
          height: 12px;
          margin-left: 8px;
          margin-right: 8px;
          opacity: 1;
          transition: background-color ease-in-out .3s;
          width: 85px;

          @media (orientation: portrait) {
            height: 10px;
            width: 45px;
            margin-left: 5px;
            margin-right: 5px;
          }

          &:hover {
            background-color: #5A5A5A;
            transition: background-color ease-in-out .3s;
          }
        }
        &.active {
          span {
            background-color: #0F247B;
            transition: background-color ease-in-out .3s;
          }
        }
      }
    }

  }

`;

export const Parcerias = styled.section`
  background-color: #fff;
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-content {
    text-align: center;

    p {
      color: #707070;
      font-size: 24px;
      line-height: 36px;

      @media (orientation: portrait) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .box-advertising {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    @media (orientation: portrait) {
      flex-direction: column;
      margin-top: 30px;
    }

    .image {
      max-width: 600px;
      width: 100%;
    }

    .box-elements {
      padding-left: 50px;

      @media (orientation: portrait) {
        padding-left: 0;
      }

      .box-text {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;

        @media (orientation: portrait) {
          margin-top: 20px;
          max-width: 600px;
          padding: 0;
        }

        p {
          color: #707070;
          font-size: 22px;
          line-height: 34px;
          margin-bottom: 50px;

          @media (orientation: portrait) {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 20px;
          }
        }
      }

      .box-button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }
`;
