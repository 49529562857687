import styled, { css } from 'styled-components';
import { ReactComponent as ArrowSVG } from '../../assets/Extensivo/arrow.svg';

// Assets
import Next from '../../assets/green-next-arrow.svg';
import Prev from '../../assets/green-prev-arrow.svg';

interface ButtonHeroProps {
  isactive: boolean;
}

interface ArrowProps {
  isactive: boolean;
}

// Interface
export const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  padding: 15px;
`;

export const BoxHero = styled.section`
  display: flex;
  align-items: center;
  background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%);
  min-height: 100vh;
  justify-content: center;
  overflow: hidden;

  @media (orientation: portrait) {
    min-height: 50vh;
    padding: 150px 0;
  }

  .boxContent {
    text-align: center;
    width: 660px;

    @media (orientation: portrait) {
      width: 272px;
    }

    .title {
      color: #ffffff;
      font-size: 60px;
      font-weight: 400;

      @media (orientation: portrait) {
        font-size: 24px;
      }

      strong {
        color: #00cfb4;
        font-weight: 700;
      }
    }

    .line {
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      border-top: 2px solid #00cfb4;
      margin: 44px auto 64px;
      opacity: 1;
      width: 436px;

      @media (orientation: portrait) {
        margin: 20px auto 32px;
        width: 205px;
      }
    }

    .label {
      color: #ffffff;
      font-size: 24px;
      font-weight: 500;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }

    .boxButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      max-width: 300px;
      width: 100%;
      height: 48px;

      background-color: #ffffff;
      border-radius: 30px;
      display: inline-block;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 6px;
      padding-left: 6px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
    }
  }
`;

export const ButtonHero = styled.button<ButtonHeroProps>`
  height: 40px;
  width: 80px;

  border-radius: 30px;
  color: #0f0c6d;
  font-size: 20px;
  font-weight: 500;
  background: transparent;
  border: none;

  transition: all 350ms ease-in-out;

  ${({ isactive }) =>
    isactive &&
    css`
      background-color: #3b3fb6;
      color: #fff;
    `}

  @media (orientation: portrait) {
    font-size: 16px;
  }
`;

export const Arrow = styled(ArrowSVG).attrs(({ isactive }: ArrowProps) => ({
  fill: isactive ? '#FFFFFF' : '#0F0C6D',
}))<ArrowProps>``;

export const BoxCards = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: center;
  margin-top: -10%;

  @media (orientation: portrait) {
    padding-right: 15px;
    padding-left: 15px;
    flex-wrap: wrap;
  }
`;

export const BoxTags = styled.section`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const BoxPillars = styled.section`
  padding-top: 46px;
  padding-bottom: 46px;

  @media (orientation: portrait) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .box-title {
    text-align: center;

    @media (orientation: portrait) {
      text-align: left;
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      color: #00205b;
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
      }
    }
    .description {
      font-size: 18px;
      font-weight: 400;
      color: #414141;
      margin: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }

  .box-content {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 60px;
    max-width: 1225px;
    width: 100%;
    margin: 60px auto 0;

    @media (orientation: portrait) {
      margin: 50px auto 0;
    }
  }
`;

export const BoxWaranty = styled.section``;

export const BoxVideoSobre = styled.section`
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center;

  @media (orientation: portrait) {
    padding-bottom: 32px;
    padding-top: 32px;
    text-align: left;
  }

  .title {
    color: #00205b;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 62px;

    @media (orientation: portrait) {
      font-size: 24px;
      margin-bottom: 36px;
    }
  }

  .video {
    height: 580px;
    width: 100%;

    @media (orientation: portrait) {
      height: 300px;
    }
  }
  
  iframe {
    height: 580px;
    width: 100%;

    @media (orientation: portrait) {
      height: 300px;
    }
  }
`;

export const BoxApp = styled.section`
  background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%);
  padding-bottom: 80px;
  padding-top: 100px;

  @media (orientation: portrait) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .box-title {
    .title {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 32px;
      font-weight: 700;
      position: relative;
      padding-left: 20px;
      height: 72px;
      margin: 0;

      @media (orientation: portrait) {
        font-size: 18px;
        padding-left: 13px;
        height: 56px;
      }

      &::before {
        background-color: #00cfb4;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: 4px;
      }
    }
  }

  .box-carousel {
    padding-top: 76px;

    .item {
      margin: 0 auto;
      text-align: center;
      max-width: 800px;

      @media (orientation: portrait) {
        max-width: 275px;
      }

      .image {
        max-width: 600px;
        margin: 0 auto;

        @media (orientation: portrait) {
          max-width: 275px;
        }
      }

      .text {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 20px;

        @media (orientation: portrait) {
          font-size: 12px;
        }
      }
    }

    .owl-dots {
      margin-top: 60px !important;

      @media (orientation: portrait) {
        margin-top: 20px !important;
      }

      .owl-dot {
        span {
          background-color: #dadada;
          border-radius: 8px;
          height: 16px;
          margin-left: 8px;
          margin-right: 8px;
          transition: background-color ease-in-out 0.3s;
          width: 16px;

          @media (orientation: portrait) {
            height: 8px;
            width: 8px;
          }
        }

        &.active {
          span {
            background-color: #00cfb4;
            transition: background-color ease-in-out 0.3s;
          }
        }
      }
    }

    .owl-nav {
      font-size: 0;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 35px);
      transform: translateY(-50%);

      span {
        display: none;
      }

      .owl-prev {
        height: 70px;
        left: -58px;
        margin: 0;
        opacity: 1;
        position: absolute;
        transition: opacity ease-in-out 0.3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          left: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Prev});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }

      .owl-next {
        height: 70px;
        margin: 0;
        opacity: 1;
        position: absolute;
        right: -58px;
        transition: opacity ease-in-out 0.3s;
        width: 38px;

        @media (orientation: portrait) {
          height: 33px !important;
          right: 0 !important;
          width: 18px !important;
        }

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Next});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;

          @media (orientation: portrait) {
            height: 33px !important;
            width: 18px !important;
          }
        }
      }
    }
  }
`;

export const BoxVideoTestemonial = styled.section`
  background-color: #f5f5fb;
  padding-bottom: 64px;
  padding-top: 64px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-title {
    margin-bottom: 68px;
    text-align: center;

    @media (orientation: portrait) {
      margin-bottom: 44px;
      text-align: left;
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      color: #00205b;
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
      }
    }
    .description {
      font-size: 18px;
      font-weight: 400;
      color: #414141;
      margin: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }
`;

export const BoxTestimonial = styled.section`
  padding-bottom: 100px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 100px;

  @media (orientation: portrait) {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .titleTestimonial {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    margin-bottom: 56px;
    color: #00205b;

    @media (orientation: portrait) {
      font-size: 24px;
      text-align: left;
      margin-bottom: 40px;
    }
  }
`;

export const BoxFaq = styled.section`
  background-color: #ffffff;
  min-height: 230px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;

  @media (orientation: portrait) {
  }

  .titleAcordiao {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    margin-bottom: 56px;
    color: #00205b;
    font-weight: 700;

    @media (orientation: portrait) {
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .accordion-button {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0f247b;
    color: #ffffff;

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    &.collapsed {
      color: #00205b;
      background-color: transparent;

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f247b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }
`;

export const Footer = styled.section`
  display: block;
  align-items: center;
  background: linear-gradient(90deg,#0f0c6d,#000f50);
  justify-content: center;
  padding-bottom: 48px;
  padding-top: 48px;
  padding-right: 15px;
  padding-left: 15px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .titleFooter {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 0;

    @media (orientation: portrait) {
      font-size: 12px;
      line-height: 1.6;
    }
  }

  && a {
    color: #fff;
    line-height: 1;
  }

  && a:hover {
    color: #ffb81c;

    transform: translate(0%, -30%);
    transition: 0.3s ease-out;
  }
`;
