import styled, { css } from 'styled-components';

// Assets

// Interface
interface Props {
  align: 'left' | 'right' | 'center';
  buy?: boolean;
}

export const Container = styled.h4<Props>`
  color: #414141;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: ${({ align }) => align};

  ${({ buy }) =>
    buy &&
    css`
      margin-bottom: 35px;

      @media (orientation: portrait) {
        font-size: 14px;
        margin-bottom: 25px;
      }
    `}
`;
