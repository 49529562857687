import axios from 'axios';

const hostName = window.location.hostname;
let url = 'https://fmedway.com.br/wp-json/';

switch (hostName) {
  case 'mentoria.medway.com.br':
    url = 'https://mentoria.medway.com.br/wp-json/';
    break;
  default:
    break;
}

const apiMentoria = axios.create({
  baseURL: url,
});

export default apiMentoria;
