import styled from 'styled-components';

import ArrowSVG from '../../../assets/Arrow 1.svg';

export const Container = styled.section`
  max-width: 1366px;
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    padding-top: 80px;
    padding-right: 80px;
    padding-left: 80px;
  }

  @media (orientation: portrait) {
    padding-top: 50px;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const Title = styled.h2`
  position: relative;

  align-items: center;
  justify-content: center;
  padding-left: 15px;

  color: #fff;
  max-width: 1000px;
  width: 100%;
  font-weight: 600;

  display: flex;

  margin-bottom: 50px;

  @media (orientation: landscape) {
    font-size: 34px;
  }

  @media (orientation: portrait) {
    font-size: 24px;
  }

  &::before {
    content: '';
    min-height: 100%;
    min-width: 3px;
    background-color: rgb(0, 207, 180);
    position: absolute;
    left: 0;
  }
`;

export const Carousel = styled.div`
  @media (orientation: landscape) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (orientation: portrait) {
    padding-left: 0;
    padding-right: 0;
  }

  .owl-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: calc(100% + 100px);
    top: calc(50% - 48.5px);
    left: -50px;
    .owl-prev {
      height: 47px;
      width: 27px;
      background-position: center;
      background-size: contain;
      color: transparent !important;
      background-color: transparent !important;
      background-image: url('${ArrowSVG}') !important;
      transform: rotate(180deg);
      transition: all 350ms ease-in-out;
      &:hover {
        color: transparent !important;
        background-color: transparent !important;
        background-image: url('${ArrowSVG}') !important;
        opacity: 0.7;
      }
    }
    .owl-next {
      height: 47px;
      width: 27px;
      background-position: center;
      background-size: contain;
      color: transparent !important;
      background-color: transparent !important;
      background-image: url('${ArrowSVG}') !important;
      transition: all 350ms ease-in-out;
      &:hover {
        color: transparent !important;
        background-color: transparent !important;
        background-image: url('${ArrowSVG}') !important;
        opacity: 0.7;
      }
    }
  }
  .owl-dots {
    margin-top: 40px !important;
    margin-bottom: 20px !important;
    .owl-dot {
      &.active {
        span {
          background-color: #00CFB4 !important;
        }
      }
      span {
        width: 50px !important;
        height: 8px !important;
        background-color: #C4C4C4 !important;

        @media (orientation: landscape) {
          width: 50px !important;
        }

        @media (orientation: portrait) {
          width: 8px !important;
          margin: 3px 5px !important;
        }
      }
    }
  }
`;

export const Card = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: 10px;
`;

export const Photo = styled.img`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15px;
`;

export const TitleC = styled.div`
  padding: 15px;
  color: #00205b;
  text-align: center;

  @media (orientation: landscape) {
    font-size: 16px;
  }

  @media (orientation: portrait) {
    font-size: 24px;
  }
`;
