import React from 'react';

import 'lazysizes';

import FacebookSVG from '../../../assets/icone-facebook-redondo-branco.svg';
import InstagramSVG from '../../../assets/icone-instagram-redondo-branco.svg';
import YoutubeSVG from '../../../assets/icone-youtube-redondo-branco.svg';
import LinkedinSVG from '../../../assets/icone-linkedin-redondo-branco.svg';

import {
  Container,
  Icones,
  Link,
  Icone,
} from './styles';

export function Redes() {
  return (
    <Container>
      <Icones>
        <Link
          href="https://www.facebook.com/medwayresidenciamedica/"
          target="_blank"
        >
          <Icone className="lazyload" src="" data-srcset={`${FacebookSVG} 1x`} alt="Facebook" />
        </Link>
        <Link
          href="https://www.instagram.com/medway.residenciamedica/"
          target="_blank"
        >
          <Icone className="lazyload" src="" data-srcset={`${InstagramSVG} 1x`} alt="Instagram" />
        </Link>
        <Link
          href="https://www.youtube.com/channel/UC-iql8FFY14XV4T1q37Olpg"
          target="_blank"
        >
          <Icone className="lazyload" src="" data-srcset={`${YoutubeSVG} 1x`} alt="Youtube" />
        </Link>
        <Link
          href="https://www.linkedin.com/company/medway-educacao-medica/"
          target="_blank"
        >
          <Icone className="lazyload" src="" data-srcset={`${LinkedinSVG} 1x`} alt="Linkedin" />
        </Link>
      </Icones>
    </Container>
  );
}
