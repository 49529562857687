import React from 'react';

// Styles
import { Container, Label } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  label?: string;
  labelColor?: boolean;
  align: 'left' | 'right' | 'center';
  buy?: boolean;
  flag?: boolean;
}

export function Card({ children, label, labelColor, align, buy, flag }: Props) {
  return (
    <Container labelColor={labelColor} align={align} buy={buy} flag={flag}>
      {label && <Label labelColor={labelColor}>{label}</Label>}
      {children}
    </Container>
  );
}
