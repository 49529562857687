import styled, { css } from 'styled-components';
// import { shade } from 'polished';

interface SectionProps {
  bg: string;
  size: string;
}

interface BoxProps {
  bg: string;
  selo?: string;
}

export const Container = styled.section`
  display: flex;
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  min-height: 1700px;
  font-size: 14px;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    height: auto;
    font-size: 12px;
  }
`;

export const Colunas = styled.div<SectionProps>`
  flex: 1;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  border-right: 5px solid #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  &:last-child {
    border: none;
  }
  .titulo {
    color: #fff;
    font-size: 1.6875em;
    margin-bottom: 50px;
  }
  .logo {
    max-width: 350px;
    width: 100%;
    margin-bottom: 50px;
  }
  .lista-de-espera {
    h2 {
      color: #fff;
      margin-bottom: 30px;
      font-size: 30px;
      text-align: center;
      max-width: 600px;
    }
  }
  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}
  ${props =>
    props.size &&
    css`
      max-width: ${props.size}%;
    `}
  @media only screen and (max-width: 991px) {
    max-width: 100%;
    padding: 20px;
    overflow: hidden;
    border: none;
    .logo {
      margin-bottom: 30px;
    }
    .titulo {
      margin-bottom: 30px;
    }
    .botao {
      margin-bottom: 15px;
      padding: 10px 15px;
      font-size: 0.8em;
    }
  }
`;

export const BoxOferta = styled.div<BoxProps>`
  margin-top: 40px;
  position: relative;
  border: 5px solid #ffb81c;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 400px;
  max-width: 300px;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-bottom: 50px;
  .valor-total-branco {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        padding-left: 10px;
        font-weight: 700;
        text-decoration: line-through;
      }
    }
  }
  .valor-com-desconto {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #fff;
      &:last-child {
        color: #ffb81c;
        font-size: 2em;
        font-weight: 600;
        padding-left: 10px;
      }
    }
  }
  .economia {
    color: #00205b;
    font-size: 1.5em;
    font-weight: 700;
    background-color: rgb(255 184 28 / 90%);
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 10px;
  }
  .ou {
    color: #fff;
    text-align: center;
    font-size: 2.1875em;
    font-weight: 700;
    text-transform: uppercase;
  }
  .parcelamento {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6em;
    p {
      &:last-child {
        padding-left: 15px;
        color: #ffb81c;
        font-weight: 700;
      }
    }
  }
  .valor-total-amarelo {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    p {
      padding-left: 10px;
      color: #ffb81c;
      font-weight: 700;
    }
  }
  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}
  &:before {
    content: '';
    width: 133px;
    height: 133px;
    position: absolute;
    top: -40px;
    right: -55px;
    ${props =>
      props.selo &&
      css`
        background-image: url(${props.selo});
      `}
  }
  @media only screen and (max-width: 991px) {
    height: 300px;
    margin-bottom: 30px;
    .parcelamento {
      p {
        &:last-child {
          padding-left: 7px;
        }
      }
    }
  }
`;

export const BoxTabela = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  max-width: 575px;
  width: 100%;
  /* overflow: hidden; */
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  margin-bottom: 50px;
  .nome-do-curso {
    font-size: 1.6875em;
    font-weight: 700;
    color: #fff;
    background-color: #00205b;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border: 5px solid #ffb81c;
    border-bottom: none;
    border-top-left-radius: 25px;
    overflow: hidden;
  }
  .itens {
    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 5px solid #ffb81c;
      border-bottom: none;
      height: 115px;
      &:last-child {
        border-bottom: 5px solid #ffb81c;
        border-bottom-right-radius: 25px;
      }
      .botao {
        .button {
          font-size: 1em;
        }
      }
      p {
        margin: 0;
      }
      .valores {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        color: #fff;
        flex: 1;
        .nome-turma {
          text-align: center;
          font-weight: 700;
          &.font-size {
            font-size: 20px;
          }
        }
        .valor-a-vista,
        .valor-a-prazo {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            &:last-child {
              padding-left: 7px;
              font-weight: 700;
              color: #ffb81c;
            }
          }
        }
      }
    }
  }
  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}

  @media only screen and (max-width: 991px) {
    .itens {
      .line {
        .botao {
          margin-bottom: 0;
          .button {
            padding: 10px 20px;
            font-size: 0.8em;
          }
        }
        .valores {
          .nome-turma {
            &.font-size {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
`;
