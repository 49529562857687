import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';

import apiECG from '../../services/ECG/api';
import apiPSMedway from '../../services/PSMedway/api';
import apiRD from '../../services/RD/api';

import Loading from '../../components/Loading';

import apiAnaliseDeDados from '../../services/Analise-de-dados/api';

import { Contanner } from './styles';

interface Aula {
  background: {
    title: string;
    sizes: {
      '1920x1080': string;
    };
  };
  logo_do_curso: {
    title: string;
    sizes: {
      '1920x1080': string;
    };
  };
  nome: string;
  texto: string;
  curso: string;
  url: string;
  nome_botao: string;
}

const ListaDePresenca: React.FC = () => {
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [aula, setAula] = useState<Aula>();
  const [section, setSection] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangeNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNome(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    apiRD.get(
      `/conversions.json?token_rdstation=c563b3f05f59736e762abdce76235dff&identificador=minicurso-certificado&_is=6&c_utmz=&traffic_source=&client_id=&redirect_to=&_doe=&privacy_data%5Bbrowser%5D=&name=${nome}&email=${email}&custom_fields%5B1435345%5D=${aula?.curso}&custom_fields%5B1435348%5D=${aula?.nome}&emP7yF13ld=&sh0uldN07ch4ng3=should_not_change`
    );

    apiAnaliseDeDados.post('/lista-de-presenca-minicurso', {
      email,
      aula: aula?.nome,
      nome,
      curso: aula?.curso,
    });

    setSection(true);
  };

  useEffect(() => {
    const ID = Cookies.get('pageID');
    const hostName = window.location.hostname;

    switch (hostName) {
      case 'ecg.medway.com.br':
        apiECG.get(`pages/${ID}`).then((response: AxiosResponse) => {
          setAula({
            background: response.data.acf.minicurso_certificado.background,
            logo_do_curso: response.data.acf.minicurso_certificado.logo,
            nome: response.data.acf.minicurso_certificado.nome,
            texto: response.data.acf.minicurso_certificado.texto,
            curso: response.data.acf.minicurso_certificado.curso,
            url: response.data.acf.minicurso_certificado.link,
            nome_botao: response.data.acf.minicurso_certificado.botao,
          });
        });
        break;
      case 'ps.medway.com.br':
        apiPSMedway
          .get(`acf/v3/pages/${ID}`)
          .then((response: AxiosResponse) => {
            setAula({
              background: response.data.acf.minicurso_certificado.background,
              logo_do_curso: response.data.acf.minicurso_certificado.logo,
              nome: response.data.acf.minicurso_certificado.nome,
              texto: response.data.acf.minicurso_certificado.texto,
              curso: response.data.acf.minicurso_certificado.curso,
              url: response.data.acf.minicurso_certificado.link,
              nome_botao: response.data.acf.minicurso_certificado.botao,
            });
          });
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      {aula ? (
        <Contanner bg={aula?.background.sizes['1920x1080']}>
          <img
            src={aula?.logo_do_curso.sizes['1920x1080']}
            alt={aula?.logo_do_curso.title}
          />
          <h1>{aula?.nome}</h1>
          <div
            className="box-text"
            dangerouslySetInnerHTML={{
              __html: aula?.texto,
            }}
          />
          {section ? (
            <>
              <a href={aula?.url} target="_blank" rel="noreferrer">
                {aula?.nome_botao}
              </a>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="InputName">Nome completo</label>
                  <input
                    id="InputName"
                    type="text"
                    name="name"
                    value={nome}
                    onChange={handleChangeNome}
                    placeholder="João Silva"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="InputEmail">E-mail</label>
                  <input
                    id="InputEmail"
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="alguem@exemplo.com"
                    required
                  />
                </div>
                <button type="submit">Enviar</button>
              </form>
            </>
          )}
        </Contanner>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ListaDePresenca;
