import styled from 'styled-components';

// Assets

// Interface
interface SectionMargin {
  margin_bottom_color: string;
}

interface SectionBG {
  activate_background_image: boolean;
  background_image: string;
  background_color: string;
}

interface ButtonProps {
  button_background_color: string;
  button_hover_background_color: string;
  button_text_color: string;
  button_hover_text_color: string;
}

interface TextProps {
  text_color: string;
}

export const Section = styled.section<SectionMargin>`;
  background-color: ${props => props.margin_bottom_color};
  padding-bottom: 60px;
`;

export const Container = styled.section<SectionBG>`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  background-color: ${props => props.background_color};
  background-size: cover;
  ${props => props.activate_background_image && props.background_image ? `
    background-image: url(${props.background_image});
  `:``}
  ${props => props.activate_background_image && !props.background_image ? `
    background-image: url(https://site-medway.s3.sa-east-1.amazonaws.com/wp-content/uploads/sites/5/2022/11/08100509/Frame-584-1.png);
  `:``}
  color: #ffffff;
  gap: 0 80px;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 60px;

  @media (orientation: portrait) {
    padding-top: 28px;
    padding-bottom: 28px;
  }
`;

export const BoxText = styled.div<TextProps>`
  color: ${props => props.text_color};
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  padding: 0 15px;

  @media (orientation: portrait) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 6px;
  padding: 30px 15px;

  p {
    margin-bottom: 5px;

    b, strong {
      font-weight: 800;
    }
  }

  @media (orientation: portrait) {
    p {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const ButtonBox = styled.a<ButtonProps>`
  background: ${props => props.button_background_color};
  border-radius: 50px;
  color: ${props => props.button_text_color};
  font-size: 20px;
  font-weight: 600;
  margin: 0 30px;
  padding: 18px 54px;
  transition: background-color ease-in-out 0.3s;
  text-align: center;
  text-decoration: none;
  text-transform: none;

  :hover {
    color: ${props => props.button_hover_text_color};
    background: ${props => props.button_hover_background_color};
    text-decoration: none;
    text-transform: none;
  }

  @media (orientation: portrait) {
    font-size: 16px;
    padding: 15px 40px;
  }
`;