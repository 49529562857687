import styled from 'styled-components';

// import BG from '../../assets/bg-extensivo.jpg';

export const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  padding: 15px;
`;

export const Button = styled.div`
  display: flex;
  margin-top: 40px;

  @media (orientation: portrait) {
    margin-top: 16px;
  }

  a,
  button {
    background-color: #00cfb4;
    border-radius: 40px;
    border: 2px solid #00cfb4;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    padding: 14px 34px;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;

    @media (orientation: portrait) {
      font-size: 16px;
      padding: 12px 16px;
      text-align: center;
    }

    &:hover {
      background-color: transparent;
      color: #00cfb4;
      text-decoration: none;
      transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
    }
  }
`;

export const Message = styled.section`
  display: flex;
  align-items: center;
  height: 100vh;
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
    height: fit-content;
  }

  @media print {
    display: none;
  }

  .box-content {
    text-align: center;

    .box-logo-curso {
      .logo {
        margin-bottom: 60px;
        width: 600px;

        @media (orientation: portrait) {
          margin-bottom: 30px;
          width: 100%;
        }
      }
    }

    .title {
      font-size: 42px;
      font-weight: 700;
      color: #0f0c6d;
      margin-bottom: 24px;

      @media (orientation: portrait) {
        font-size: 32px;
        margin-bottom: 15px;
      }
    }

    .box-text {
      margin: 0 auto;
      width: 60%;

      @media (orientation: portrait) {
        margin-bottom: 50px;
        width: 100%;
      }

      p {
        font-size: 20px;
        color: #333333;
        font-weight: 500;

        @media (orientation: portrait) {
          font-size: 18px;
        }
      }
    }

    .box-alert {
      background-color: #ffd0d0;
      border-radius: 10px;
      border: 1px solid #c00000;
      color: #c00000;
      margin: 0 auto;
      padding: 14px 40px;
      width: fit-content;

      &__hidden {
        display: none;
      }

      p {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
    }

    .box-button {
      justify-content: center;

      &__hidden {
        display: none;
      }
    }
  }
`;

export const Certificate = styled.section`
  height: 0;
  width: 0;

  @media print {
    visibility: visible;

    @page {
      /* size: landscape; */
      margin: 0px;
    }

    #certificado {
      display: flex;
      align-items: center;
      bottom: 50%;
      /* height: 100%; */
      justify-content: center;
      left: 0;
      position: absolute;
      top: 50%;
      transform: rotate(90deg) scale(1.4); // Regra para pagina na vertical
      width: 100%;
    }
  }

  h2 {
    display: none;
    color: #141d72;
    font-size: 56px;
    font-weight: bold;
    margin-top: 80px;
    position: absolute;

    &.type-MINICURSO_EMERGENCIAS_CARDIOLOGICAS {
      margin-top: 80px;
    }

    &.type-psmedway-gasometria {
      margin-top: -50px;
    }

    &.type-psmedway-semana-ecg {
      margin-top: 40px;
      color: #00cfb4;
    }

    &.type-psmedway-semana-da-iot {
      font-size: 32px;
      margin-top: 70px;
    }

    &.type-psmedway-semana-da-vm-2021 {
      font-size: 32px;
      margin-top: -40px;
    }

    &.type-psmedway-semana-trauma-2021 {
      font-size: 32px;
      margin-top: -16px;
    }

    @media print {
      display: block;
    }
  }

  object {
    width: 98%;
  }
`;
