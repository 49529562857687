import axios from 'axios';

const hostName = window.location.hostname;
let url = 'https://fmedway.com.br/wp-json/acf/v3/';

switch (hostName) {
  case 'r3.medway.com.br':
    url = 'https://r3.medway.com.br/wp-json/acf/v3/';
    break;
  default:
    break;
}

const apiR3 = axios.create({
  baseURL: url,
});

export default apiR3;
