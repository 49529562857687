import styled from 'styled-components';

// Assets

// Interface
interface Props {
  bottom: string;
  top: string;
}

export const Container = styled.h2<Props>`
  color: #00205b;
  font-size: 32px;
  font-weight: 700;
  margin-top: ${({ top }) => top};
  margin-bottom: ${({ bottom }) => bottom};

  @media (orientation: portrait) {
    font-size: 24px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 16px;
  }
`;
