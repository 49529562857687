import styled from 'styled-components';

// Assets

// Interface
interface Props {
  align: 'left' | 'right' | 'center';
  weight: number;
}

export const Container = styled.p<Props>`
  color: #00205b;
  font-size: 18px;
  font-weight: ${({ weight }) => weight};
  line-height: 24px;
  margin-bottom: 8px;
  text-align: ${({ align }) => align};

  &:last-child {
    margin-bottom: 0;
  }
`;
