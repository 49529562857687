import React from 'react';

// Styles
import {
  Container,
  BoxContent,
  Title,
  Separator,
  Subtitle,
  Link,
} from './styles';

// Interface
interface Button {
  url: string;
  title: string;
  target: string;
}

interface Props {
  title: string;
  subtitle: string;
  button: Button;
}

export function Banner({ title, subtitle, button }: Props) {
  return (
    <Container>
      <BoxContent>
        <Title>{title}</Title>
        <Separator />
        <Subtitle>{subtitle}</Subtitle>
        <Link href={button.url} target={button.target}>
          {button.title}
        </Link>
      </BoxContent>
    </Container>
  );
}
