import React from 'react';

import 'lazysizes';

// Components
import { BoxTimeline } from './BoxTimeline';
import { TitleTimeline } from './TitleTimeline';
import { YearTimeline } from './YearTimeline';
import { ParagraphTimeline } from './ParagraphTimeline';
import { Button } from '../Button';
import { CourseDataTimeline } from './CourseDataTimeline';
import { AdditionalContent } from '../AdditionalContent';

// Styles
import { Container } from './styles';

// Interface
// import { TimelineProps } from './interface';

interface Botao {
  title: string;
  url: string;
  target: string;
}
interface BoxOptionsProps {
  bg: string;
  align: 'left' | 'center' | 'right';
}

interface ParagraphOptionsProps {
  align: 'left' | 'center' | 'right';
  bottom: string;
  top: string;
  size: string;
  width: string;
}
interface LogosProps {
  src_logo: {
    title: string;
    url: string;
  };
  alt?: string;
}

interface Data{
  logos: LogosProps[];
  startDate: string;
  endDate: string;
}

interface ExtraContent {
  logo_image: string;
  logo_alt_text: string;
  text_above_button: string;
  modal_text: string;
  button_text: string;
}

interface TimelineProps {
  timelineProps: {
    firstBoxOptions: BoxOptionsProps;
    firstTitle: string;
    yearTimeline: string;
    firstParagraphOptions: ParagraphOptionsProps;
    firstParagraph: string;
    dataCourseTimeline: Data;
    secondBoxOptions: BoxOptionsProps;
    secondParagraphOptions: ParagraphOptionsProps;
    secondParagraph: string;
    buttonOptions: Botao;
    secondTitle: string;
    ctaOptions: {
      modal_title: string;
      contents: [ExtraContent]
    };
  }
}
interface Props {
  boxRef: any;
  data_timeline: TimelineProps;
}

export function Timeline({  data_timeline, boxRef }: Props) {
  return (
    <>
      {data_timeline && (
        <Container>
          <BoxTimeline
            boxRef={boxRef}
            bg={data_timeline.timelineProps.firstBoxOptions.bg}
            align={data_timeline.timelineProps.firstBoxOptions.align}
          >
            {data_timeline.timelineProps.firstTitle && (
              <TitleTimeline bottom="0" top="0">
                {data_timeline.timelineProps.firstTitle}
              </TitleTimeline>
            )}

            {data_timeline.timelineProps.yearTimeline && (
              <YearTimeline>{data_timeline.timelineProps.yearTimeline}</YearTimeline>
            )}
            <ParagraphTimeline
              align={data_timeline.timelineProps.firstParagraphOptions.align}
              bottom={data_timeline.timelineProps.firstParagraphOptions.bottom}
              top={data_timeline.timelineProps.firstParagraphOptions.top}
              size={data_timeline.timelineProps.firstParagraphOptions.size}
              width={data_timeline.timelineProps.firstParagraphOptions.width}
            >
              <div
               dangerouslySetInnerHTML={{
                __html: data_timeline.timelineProps.firstParagraph,
              }}
              />
            </ParagraphTimeline>
          </BoxTimeline>

          <CourseDataTimeline
            logos={data_timeline.timelineProps.dataCourseTimeline.logos}
            startDate={data_timeline.timelineProps.dataCourseTimeline.startDate}
            endDate={data_timeline.timelineProps.dataCourseTimeline.endDate}
          />

          <BoxTimeline
            bg={data_timeline.timelineProps.secondBoxOptions.bg}
            align={data_timeline.timelineProps.secondBoxOptions.align}
          >
            <ParagraphTimeline
              align={data_timeline.timelineProps.secondParagraphOptions.align}
              bottom={data_timeline.timelineProps.secondParagraphOptions.bottom}
              top={data_timeline.timelineProps.secondParagraphOptions.top}
              size={data_timeline.timelineProps.secondParagraphOptions.size}
              width={data_timeline.timelineProps.secondParagraphOptions.width}
            >
              <div
               dangerouslySetInnerHTML={{
                __html: data_timeline.timelineProps.secondParagraph,
              }}
              />
            </ParagraphTimeline>
            {data_timeline.timelineProps.buttonOptions.url &&
             data_timeline.timelineProps.buttonOptions.title && (
            <Button
              fill="outlined"
              size="small"
              pattern="variant"
              href={data_timeline.timelineProps.buttonOptions.url}
              target="_blank"
            >
              {data_timeline.timelineProps.buttonOptions.title}
            </Button>)}
            {data_timeline.timelineProps.secondTitle && (
            <TitleTimeline top="82px" bottom="34px">
              {data_timeline.timelineProps.secondTitle}
            </TitleTimeline>
            )}
            {(data_timeline.timelineProps.ctaOptions.modal_title || 
            data_timeline.timelineProps.ctaOptions.contents.length > 0) && (
              <section className="callToAction">
                {data_timeline.timelineProps.ctaOptions.modal_title && (
                  <div className="box-additional-content">
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: data_timeline.timelineProps.ctaOptions.modal_title,
                      }}
                    />
                  </div>
                )}
                {data_timeline.timelineProps.ctaOptions.contents.length > 0 && (
                  data_timeline.timelineProps.ctaOptions.contents.map((item, key) => (
                    <AdditionalContent
                      key={key}
                      logo_image={item.logo_image}
                      logo_alt_text={item.logo_alt_text}
                      text_above_button={item.text_above_button}
                      modal_text={item.modal_text}
                      button_text={item.button_text}
                    />
                  ))
                )}
              </section>
            )}
          </BoxTimeline>
        </Container>
      )}
    </>
  );
};
