import styled, { css } from 'styled-components';
import { shade } from 'polished';

import Aspas from '../../assets/aspas.svg';

interface SectionProps {
  bg: string;
}

export const PrimeiraSecao = styled.section<SectionProps>`
  min-height: 780px;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  color: #fff;
  padding: 50px 15px;
  background-size: cover;

  .titulo {
    margin-bottom: 25px;
    p {
      text-align: center;
      text-transform: uppercase;
      font-size: 45px;
      font-weight: 900;
    }
  }

  .paragrafo {
    margin-bottom: 25px;
    p {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 900;
      text-align: center;
    }
  }

  .box-video {
    max-width: 50%;
    width: 100%;
    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      iframe,
      img {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: contain;
      }
    }
  }

  .logo {
    max-width: 425px;
    width: 100%;
    margin-bottom: 50px;
  }

  .apresentacao {
    max-width: 700px;
    width: 100%;
  }

  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}

  @media only screen and (max-width: 991px) {
    .box-video {
      max-width: 100%;
    }
    .titulo {
      p {
        font-size: 32px;
      }
    }

    .paragrafo {
      p {
        font-size: 17px;
      }
    }
    .logo {
      max-width: 300px;
      margin-bottom: 20px;
    }
  }
`;

export const SegundaSecao = styled.section<SectionProps>`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  color: #151e71;
  padding: 50px 15px;
  background-size: cover;

  .titulo {
    margin-bottom: 25px;
    p {
      text-align: center;
      text-transform: uppercase;
      font-size: 45px;
      font-weight: 900;
    }
  }

  .paragrafo {
    max-width: 1000px;
    margin-bottom: 25px;
    p {
      font-size: 25px;
      font-weight: 300;
      text-align: center;
      color: #686b6b;
    }
  }

  .box-buttom-download {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      cursor: pointer;
      text-decoration: none;
    }
    .buttom-download {
      border: 4px solid #1c1c7d;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 15px;
      transition: all 350ms ease-in-out;
      img {
        max-width: 100%;
      }
      p {
        font-weight: 600;
        color: #686b6b;
        margin-top: 15px;
      }
      &:hover {
        background-color: #1c1c7d;
        p {
          color: #fff;
        }
      }
    }
  }

  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}

  @media only screen and (max-width: 991px) {
    .titulo {
      p {
        font-size: 32px;
      }
    }

    .paragrafo {
      p {
        font-size: 17px;
      }
    }

    .box-buttom-download {
      .buttom-download {
        border: 2px solid #1c1c7d;
        width: 100px;
        height: 100px;
        margin: 0 5px;
        img {
          max-width: 30px;
        }
        p {
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }

    background-image: none;
    background-color: #fff;
  }
`;

export const TerceiraSecao = styled.section<SectionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;

  .box-conteudo {
    max-width: 50%;
    width: 100%;
    padding-right: 30px;
    .titulo {
      color: #00ffc2;
      p {
        font-size: 35px;
        font-weight: 900;
        margin-bottom: 15px;
      }
    }
    .paragrafo {
      color: #fff;
      p {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 30px;
      }
    }
  }

  .box-video {
    max-width: 50%;
    width: 100%;
    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      iframe,
      img {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: contain;
      }
    }
  }

  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}

  @media only screen and (max-width: 1600px) {
    padding: 50px;
    .box-conteudo {
      max-width: 50%;
      width: 100%;
      .titulo {
        p {
          font-size: 30px;
          margin-bottom: 15px;
        }
      }

      .paragrafo {
        p {
          font-size: 20px;
          margin-bottom: 30px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    padding: 50px 15px;

    text-align: center;

    .box-conteudo {
      max-width: 100%;
      padding-right: 0;
      .titulo {
        p {
          font-size: 30px;
          margin-bottom: 15px;
        }
      }

      .paragrafo {
        p {
          font-size: 20px;
          margin-bottom: 30px;
        }
      }
    }
    .box-video {
      max-width: 100%;
    }
  }
`;

export const QuartaSecao = styled.section`
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #13196b;
  flex-direction: row-reverse;
  .item-img {
    width: 100%;
  }
  .box-conteudo {
    max-width: 850px;
    padding: 15px;
  }
  & > div {
    max-width: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .icone {
      margin-bottom: 50px;
    }
    .titulo {
      width: 100%;
      color: #09ffdf;
      p {
        font-size: 35px;
        font-weight: 900;
        margin-bottom: 15px;
      }
    }
    .paragrafo {
      color: #ffffff;
      p {
        font-weight: 300;
        font-size: 30px;
        margin-bottom: 30px;
      }
    }
  }
  &:nth-child(2n + 1) {
    background-color: #fff;
    flex-direction: row;
    .titulo {
      color: #13196b;
    }
    .paragrafo {
      color: #686b6b;
    }
  }

  @media only screen and (max-width: 1600px) {
    & > div {
      .titulo {
        p {
          font-size: 25px;
          margin-bottom: 15px;
        }
      }
      .paragrafo {
        p {
          font-size: 20px;
          margin-bottom: 30px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    padding: 50px 15px;
    text-align: center;
    & > div {
      max-width: 500px;
      width: 100%;
    }
    .item-img {
      margin-bottom: 30px;
    }
    &:nth-child(2n + 1) {
      flex-direction: column;
    }
  }
`;

export const QuintaSecao = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  a {
    background-color: #161672;
    padding: 15px 50px;
    border-radius: 40px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 15px;
    transition: all 350ms ease-in-out;
    &:hover {
      background: ${shade(0.2, '#161672')};
    }
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    a {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const SextaSecao = styled.section`
  padding: 100px 15px;
  .stars {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    padding: 0 15px;
    img {
      max-width: 400px;
      width: 100%;
    }
  }
  .nota {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    font-size: 40px;
    color: #161672;
    font-weight: 300;
    text-align: center;
    padding: 0 15px;
    .titulo {
      strong {
        font-weight: 900;
      }
    }
  }
  .carousel {
    .control-dots {
      .dot {
        max-width: 90px;
        width: 100%;
        border-radius: 5px;
        background-color: #5a5a5a;
        &.selected {
          background-color: #0f247b;
        }
      }
    }
    .slide {
      padding: 50px;
      padding-top: 125px;
      background-color: transparent;
      .item {
        max-width: 1000px;
        border: 8px solid #0f247b;
        border-radius: 75px;
        margin: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 150px 35px 50px 35px;
        &:before {
          content: '';
          background-image: url(${Aspas});
          width: 100px;
          height: 60px;
          display: block;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: #f0f0f5;
          position: absolute;
          top: 65px;
          left: -50px;
        }
        &:after {
          content: '';
          background-image: url(${Aspas});
          width: 100px;
          height: 60px;
          display: block;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: #f0f0f5;
          bottom: 50px;
          right: -48px;
          position: absolute;
          transform: rotate(180deg);
        }
        img {
          max-width: 250px;
          max-height: 250px;
          border: 8px solid #0f247b;
          border-radius: 50%;
          position: absolute;
          top: -125px;
        }
        .paragrafo {
          font-size: 32px;
          line-height: 1.5;
          font-style: italic;
          margin-bottom: 15px;
        }
        .nome {
          font-size: 40px;
        }
        .aprovacao_ou_ano_atual {
          font-size: 35px;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    padding: 50px 15px;
    .nota {
      font-size: 25px;
    }
    .carousel {
      .slide {
        padding: 25px;
        padding-top: 75px;
        .item {
          padding: 75px 20px 30px 20px;
          border: 4px solid #0f247b;
          &:before {
            width: 50px;
            height: 30px;
            top: 65px;
            left: -25px;
          }
          &:after {
            width: 50px;
            height: 30px;
            bottom: 50px;
            right: -25px;
          }
          img {
            max-width: 100px;
            max-height: 100px;
            border: 4px solid #0f247b;
            top: -50px;
          }
          .paragrafo {
            font-size: 20px;
            margin-bottom: 15px;
          }
          .nome {
            font-size: 25px;
          }
          .aprovacao_ou_ano_atual {
            font-size: 22px;
          }
        }
      }
    }
  }
`;

export const SetimaSecao = styled.section`
  background-color: #0f247b;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 115px;
  img {
    position: relative;
    top: -100px;
    max-width: 1000px;
    width: 100%;
    padding: 0 15px;
  }
  .paragrafo {
    max-width: 1000px;
    width: 100%;
    position: relative;
    top: -90px;
    color: #fff;
    font-size: 20px;
    margin-top: 30px;
    p {
      padding: 0 15px;
      font-size: 20px;
      line-height: 1.5;
      & + p {
        margin-top: 30px;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .paragrafo {
      p {
        font-size: 17px;
      }
    }
  }
`;

export const Diferencas = styled.section<SectionProps>`
  background-size: cover;
  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}
  padding: 100px 15px;
  .titulo-section {
    text-align: center;
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 900;
    color: #fff;
    max-width: 1180px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
  }
  .container {
    max-width: 1180px;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    .owl-theme {
      width: 100%;
    }
    .column {
      width: 300px;
      &:first-child {
        flex: 1;
        width: 100%;
      }
      .logo {
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: transparent;
        pointer-events: none;
        max-width: 300px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-top: 2px solid #fff;
        margin: 0;
        max-width: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55px;
        &:last-child {
          border-bottom: 2px solid #fff;
        }
        .titulo {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        img {
          max-width: 30px;
        }
      }
    }
    .row {
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-top: 2px solid #fff;
      margin: 0;
      justify-content: flex-start;
      padding-left: 15px;
      &:last-child {
        border-bottom: 2px solid #fff;
      }
      .topico {
        flex: 1;
        min-height: 50px;
        display: flex;
        align-items: center;
        font-weight: 700;
        max-width: 580px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .titulo-section {
      font-size: 25px;
    }
    .container {
      .owl-theme {
        width: 50% !important;
      }
      .row-header {
        .hidden {
          min-width: 123px;
        }
        .logo {
          min-width: 100px;
          max-width: 100px;
          img {
            width: 100%;
          }
        }
      }
      .row {
        .topico {
          min-width: 123px;
          font-size: 10px;
        }
        .box {
          min-width: 100px;
          max-width: 100px;
          .titulo {
            font-size: 10px;
          }
          img {
            max-width: 20px;
          }
        }
      }
    }
  }
`;