import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

// APIs
import apiPS from '../../services/PSMedway/api';
import apiAnaliseDeDados from '../../services/Analise-de-dados/api';

// Styles
import { Container, Button, Certificate, Message } from './styles';
import Loading from '../../components/Loading';

// Arquivos
import certificadoCardiologicas from '../../assets/CERTIFICADO-MINICURSO-CARDIOLOGICAS.svg';
import certificadoGasometria from '../../assets/certificado-minicurso-gasometria.svg';
import certificadoECG from '../../assets/certificado-minicurso-ecg.svg';
import certificadoIOT from '../../assets/certificado-semana-iot.svg';
import certificadoVM from '../../assets/certificado-semana-vm.svg';
import certificadoTrauma from '../../assets/certificado-semana-trauma.svg';

interface Botao {
  title: string;
  url: string;
  target: string;
}
interface Dados {
  certificado: {
    logo_certificado_aprovado: {
      title: string;
      sizes: {
        'all-logo': string;
        thumbnail: string;
        quem_somos: string;
      };
    };
    titulo_certificado_aprovado: string;
    texto_certificado_aprovado: string;
    alerta_certificado_aprovado: string;
    botao_certificado_aprovado: Botao;
    logo_certificado_reprovado: {
      title: string;
      sizes: {
        'all-logo': string;
        thumbnail: string;
        quem_somos: string;
      };
    };
    titulo_certificado_reprovado: string;
    texto_certificado_reprovado: string;
    botao_certificado_reprovado: Botao;
  };
}

const Certificado: React.FC = () => {
  const [dados, setDados] = useState<Dados | null>(null);
  const [name, setName] = useState(null);
  const [status, setStatus] = useState(null);
  const ID = Cookies.get('pageID');
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const fakeLink = '';
  // URL Params
  const params = new URLSearchParams(window.location.search);
  const email: string | null = params.get('email');
  const curso: string | null = params.get('curso');

  useEffect(() => {
    apiAnaliseDeDados
      .get(`lista-de-presenca-minicurso/certificado/${curso}/${email}`)
      .then((response: AxiosResponse) => {
        setName(response.data.nome);
        setStatus(response.data.aprovacao);
      });

    apiPS.get(`acf/v3/pages/${ID}`).then(response => {
      setDados(response.data.acf);
    });
  }, []);

  console.log(dados);

  return (
    <>
      {dados ? (
        <>
          <Message>
            <Container>
              <div className="box-content">
                <div className="box-logo-curso">
                  <img
                    src={
                      status === 'Aprovado'
                        ? dados.certificado.logo_certificado_aprovado.sizes[
                            'all-logo'
                          ]
                        : dados.certificado.logo_certificado_reprovado.sizes[
                            'all-logo'
                          ]
                    }
                    alt={
                      status === 'Aprovado'
                        ? dados.certificado.logo_certificado_aprovado.title
                        : dados.certificado.logo_certificado_reprovado.title
                    }
                    className="logo"
                  />
                </div>
                <h1 className="title">
                  {status === 'Aprovado'
                    ? dados.certificado.titulo_certificado_aprovado
                    : dados.certificado.titulo_certificado_reprovado}
                </h1>
                <div
                  className="box-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      status === 'Aprovado'
                        ? dados.certificado.texto_certificado_aprovado
                        : dados.certificado.texto_certificado_reprovado,
                  }}
                />
                {status === 'Aprovado' && (
                  <div
                    className={
                      isMobile ? 'box-alert' : 'box-alert box-alert__hidden'
                    }
                    dangerouslySetInnerHTML={{
                      __html: dados.certificado.alerta_certificado_aprovado,
                    }}
                  />
                )}
                {status === 'Aprovado' ? (
                  <Button
                    className={
                      isMobile ? 'box-button box-button__hidden' : 'box-button'
                    }
                  >
                    <a
                      href={fakeLink}
                      onClick={() => {
                        window.print();
                      }}
                    >
                      {dados.certificado.botao_certificado_aprovado.title}
                    </a>
                  </Button>
                ) : (
                  <Button className="box-button">
                    <a
                      href={dados.certificado.botao_certificado_reprovado.url}
                      target={
                        dados.certificado.botao_certificado_reprovado.target
                      }
                    >
                      {dados.certificado.botao_certificado_reprovado.title}
                    </a>
                  </Button>
                )}
              </div>
            </Container>
          </Message>
          <Certificate>
            {status === 'Aprovado' && (
              <div id="certificado">
                <h2 className={`type-${curso}`}>{name}</h2>
                {curso === 'MINICURSO_EMERGENCIAS_CARDIOLOGICAS' && (
                  <object data={certificadoCardiologicas} type="">
                    <h2 className={`type-${curso}`}>{name}</h2>
                  </object>
                )}
                {curso === 'psmedway-gasometria' && (
                  <object data={certificadoGasometria} type="">
                    <h2 className={`type-${curso}`}>{name}</h2>
                  </object>
                )}
                {curso === 'psmedway-semana-ecg' && (
                  <object data={certificadoECG} type="">
                    <h2 className={`type-${curso}`}>{name}</h2>
                  </object>
                )}
                {curso === 'psmedway-semana-da-iot' && (
                  <object data={certificadoIOT} type="">
                    <h2 className={`type-${curso}`}>{name}</h2>
                  </object>
                )}
                {curso === 'psmedway-semana-da-vm-2021' && (
                  <object data={certificadoVM} type="">
                    <h2 className={`type-${curso}`}>{name}</h2>
                  </object>
                )}
                {curso === 'psmedway-semana-trauma-2021' && (
                  <object data={certificadoTrauma} type="">
                    <h2 className={`type-${curso}`}>{name}</h2>
                  </object>
                )}
              </div>
            )}
          </Certificate>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Certificado;
