import axios from 'axios';

const hostName = window.location.hostname;
let url = 'https://fmedway.com.br/wp-json/acf/v3/';

switch (hostName) {
  case 'cr.medway.com.br':
    url = 'https://cr.medway.com.br/wp-json/acf/v3/';
    break;
  default:
    break;
}

const apiCRMedway = axios.create({
  baseURL: url,
});

export default apiCRMedway;
