import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
// import 'react-owl-carousel2/style.css';

import 'lazysizes';

import { Button } from '../Button';

import {
  Container,
  Title,
  Carousel,
  Card,
  Photo,
  Content,
  TitleC,
} from './styles';


interface ButtonProps {
  title: string;
  url: string;
  target: string;
}

interface ImageProps {
  alt: string;
  url: string;
}

interface CardsProps {
  imagem: ImageProps;
  titulo: string;
  botao: ButtonProps;
}

interface OptionProps  {
  items: number;
  nav: boolean;
  rewind: boolean;
  autoplay: boolean;
  margin: number;
  touchDrag: boolean;
  mouseDrag: boolean;
  stagePadding: number;
}

interface Props extends HTMLDivElement {
  title: string;
  cards: CardsProps[];
  options: OptionProps;
}

export function BoxCards({ title, cards, options, ...rest }: Props) {
  return (
    <Container {...rest}>
      <Title>{title}</Title>
      <Carousel>
        <OwlCarousel options={options}>
          {cards.map((item, key) => (
            <Card key={(`BoxCards_`, key)}>
              <Photo className="lazyload" src="" data-srcset={`${item.imagem.url} 1x`} alt={item.titulo} />
              <Content>
                <TitleC>{item.titulo}</TitleC>
                <Button
                  title={item.botao.title}
                  url={item.botao.url}
                  target={item.botao.target}
                  paddingTopBottom="10"
                  paddingLeftRight="25"
                />
              </Content>
            </Card>
          ))}
        </OwlCarousel>
      </Carousel>
    </Container>
  );
}


