import React, { useState, useEffect } from 'react';

// Components
import HubspotForm from 'react-hubspot-form';
import { Button } from '../../../components/Vendas/Button';
import { Faq } from '../../../components/Vendas/Faq';
import Loading from '../../../components/Loading';

// Services
import api from '../../../services/api';

// Assets

// Styles
import {
  Container,
  Hero,
  Numbers,
  About,
  Subscription,
  BoxFaq,
  CallToAction,
  Contact,
} from './styles';

// Interface
interface Botao {
  title: string;
  url: string;
  target: string;
}

interface SubListaDuvidas {
  titulo_subgrupo: string;
  texto_subgrupo: string;
}

interface ListaDuvidas {
  icone_grupo?: {
    title: string;
    url: string;
  };
  titulo_grupo: string;
  texto_grupo: string;
  subgrupo: [SubListaDuvidas];
}

interface Dados {
  hero: {
    status_hero: boolean;
    titulo_hero: string;
    subtitulo_hero: string;
  };

  numeros: {
    status_numeros: boolean;
    informacoes_numeros: [
      {
        descricao_informacoes: string;
        titulo_informacoes: string;
        subtitulo_informacoes: string;
      }
    ];
  };

  sobre: {
    status_sobre: boolean;
    titulo_sobre: string;
    descricao_sobre: string;
  };

  inscricao: {
    status_inscricao: boolean;
    titulo_inscricao: string;
    descricao_inscricao: string;
    formulario_inscricao: Botao;
    ativar_botao_inscricao: boolean;
    ativar_formulario_hubspot: boolean;
    dados_hubspot: {
      formid: String;
      portalid: String;
    };
  };

  duvidas: {
    status_duvidas: boolean;
    titulo_duvidas: string;
    grupo_duvidas: [ListaDuvidas];
  };

  cta: {
    status_cta: boolean;
    titulo_cta: string;
    botao_cta: Botao;
  };

  rodape: {
    status_rodape: boolean;
    texto_rodape: string;
  };
}

const ECG: React.FC = () => {
  const [dados, setDados] = useState<Dados | null>(null);
  const hostName = window.location.hostname;

  useEffect(() => {
    api
      .get(
        `acf/v3/pages/${hostName === 'www.medway.com.br' ? `14951` : `10479`}`
      )
      .then(response => {
        setDados(response.data.acf);
      });
  }, []);

  return (
    <>
      {dados ? (
        <>
          {dados.hero.status_hero && (
            <Hero>
              <Container>
                <h1 className="title">{dados.hero.titulo_hero}</h1>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: dados.hero.subtitulo_hero,
                  }}
                />
              </Container>
            </Hero>
          )}

          {dados.numeros.status_numeros && (
            <Numbers>
              <Container>
                <div className="box-numbers">
                  {dados.numeros.informacoes_numeros.map((item, key) => (
                    <article className="number" key={key}>
                      <p className="suptitle">{item.descricao_informacoes}</p>
                      <h2
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: item.titulo_informacoes,
                        }}
                      />
                      <p className="subtitle">{item.subtitulo_informacoes}</p>
                    </article>
                  ))}
                </div>
              </Container>
            </Numbers>
          )}

          {dados.sobre.status_sobre && (
            <About>
              <Container>
                <h2 className="title">{dados.sobre.titulo_sobre}</h2>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: dados.sobre.descricao_sobre,
                  }}
                />
              </Container>
            </About>
          )}

          {dados.inscricao.status_inscricao && (
            <Subscription id="subscription">
              <Container>
                <h2 className="title">{dados.inscricao.titulo_inscricao}</h2>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: dados.inscricao.descricao_inscricao,
                  }}
                />
                {dados.inscricao.ativar_botao_inscricao === true &&
                  <div className="box-form">

                    <Button
                      href={dados.inscricao.formulario_inscricao.url}
                      target={dados.inscricao.formulario_inscricao.target}
                      fill="contained"
                      size="big"
                      pattern="variant"
                    >
                      {dados.inscricao.formulario_inscricao.title}
                    </Button>
                  </div>
                }
                {dados.inscricao.ativar_formulario_hubspot && (
                        <div className="box-form-hub-spot-new">
                          <HubspotForm
                            portalId={
                              dados.inscricao.dados_hubspot.portalid
                            }
                            formId={
                              dados.inscricao.dados_hubspot.formid
                            }
                          />
                        </div>
                )}
              </Container>
            </Subscription>
          )}

          {dados.duvidas.status_duvidas && (
            <BoxFaq>
              <Faq
                title={dados.duvidas.titulo_duvidas}
                data={dados.duvidas.grupo_duvidas}
              />
            </BoxFaq>
          )}

          {dados.cta.status_cta && (
            <CallToAction>
              <Container>
                  {dados.inscricao.ativar_botao_inscricao === true &&
                  <div className="box-cta">
                    <h2 className="title">{dados.cta.titulo_cta}</h2>
                    <Button
                      href={dados.cta.botao_cta.url}
                      target={dados.cta.botao_cta.target}
                      fill="contained"
                      size="big"
                      pattern="variant"
                    >
                      {dados.cta.botao_cta.title}
                    </Button>
                     </div>
                  }
              </Container>
            </CallToAction>
          )}

          {dados.rodape.status_rodape && (
            <Contact>
              <Container>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: dados.rodape.texto_rodape,
                  }}
                />
              </Container>
            </Contact>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ECG;
