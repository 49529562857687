import React, { useState, useEffect } from 'react';

import 'lazysizes';

import Loading from '../../components/Loading';

import api from '../../services/api';
import apiWordpress from '../../services/apiWordpress';

import {
  Container,
  Open,
  Plataform,
  Title,
  TitleInverse,
  Button,
  Episodes,
  Presenters,
  AboutMedway,
  SeeAlso,
} from './styles';

const FinalmenteResidente = () => {
  const [dados, setDados] = useState(null);
  const [destaque, setDestaque] = useState(null);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const [list, setList] = useState([]);

  const [shortList, setShortList] = useState(null);
  const [moreEps, setMoreEps] = useState(false);

  useEffect(() => {
    api.get(`acf/v3/pages/6718`).then(response => {
      setDados(response.data.acf);
    });

    // Destaque
    apiWordpress.get(`finalmente-residente`).then(response => {
      const itens = [];
      response.data.map((value, key) => {
        if (key <= 2) {
          itens.push(value);
        }
        return '';
      });
      setShortList(itens);

      setDestaque(
        response.data.find(
          value =>
            value.acf.podcast_finalmente_residente_interna.card.destaque ===
            true
        )
      );
    });
  }, []);

  useEffect(() => {
    apiWordpress.get(`finalmente-residente?page=${page}`).then(response => {
      const itensList = list;
      setList([]);
      response.data.map(value => itensList.push(value));
      setList(itensList);

      if (response.data.length === 10) {
        setNextPage(true);
      } else {
        setNextPage(false);
      }
    });
  }, [page]);

  return (
    <>
      {dados ? (
        <>
          <Open
            bg={dados.podcast_finalmente_residente.capa.background.sizes.large}
          >
            <Container>
              <div className="box-container">
                <div className="box-content">
                  <img
                    className="logo"
                    src={
                      dados.podcast_finalmente_residente.capa.logo.sizes.large
                    }
                    alt={dados.podcast_finalmente_residente.capa.logo.title}
                  />
                  <div
                    className="text page-h1"
                    dangerouslySetInnerHTML={{
                      __html:
                        dados?.podcast_finalmente_residente.capa.paragrafo,
                    }}>
                  </div>
                </div>
                <div className="box-image">
                  <img
                    className="imagem"
                    src={
                      dados.podcast_finalmente_residente.capa.caricaturas.sizes
                        .large
                    }
                    alt={
                      dados.podcast_finalmente_residente.capa.caricaturas.title
                    }
                  />
                </div>
              </div>
              {destaque ? (
                <div className="box-player">
                  <div className="box-image">
                    <img
                      src={
                        destaque.acf.podcast_finalmente_residente_interna.card
                          .thumbnail.sizes.large
                      }
                      alt={
                        destaque.acf.podcast_finalmente_residente_interna.card
                          .thumbnail.title
                      }
                    />
                  </div>

                  <div className="box-content">
                    <h4 className="episode">
                      {`${destaque.acf.podcast_finalmente_residente_interna.card.numero_do_episodio}`}
                    </h4>
                    <h3 className="title">{destaque.title.rendered}</h3>
                    <p className="description">
                      {
                        destaque.acf.podcast_finalmente_residente_interna.card
                          .com
                      }
                    </p>
                  </div>

                  <div className="box-button">
                    <a href={destaque.link}>Ouça Agora!</a>
                  </div>
                </div>
              ) : null}
            </Container>
          </Open>

          <Plataform>
            <Container>
              <div className="box-content">
                <h3 className="title">
                  {dados.podcast_finalmente_residente.plataformas.titulo}
                </h3>
                <section className="box-plataforms">
                  {dados.podcast_finalmente_residente.plataformas.botoes.map(
                    item => {
                      return (
                        <article className="plataform">
                          <a
                            href={item.botao.url}
                            title={item.botao.title}
                            target={item.botao.target}
                            className="link"
                          >
                            <img
                              className="logo"
                              src={item.imagem.sizes.large}
                              alt={item.imagem.title}
                            />
                          </a>
                        </article>
                      );
                    }
                  )}
                </section>
              </div>
            </Container>
          </Plataform>

          <Episodes>
            <Container>
              <Title>
                <h2>{dados.podcast_finalmente_residente.episodio.titulo}</h2>
              </Title>

              {list && shortList ? (
                <>
                  <div className="box-episodes">
                    {moreEps
                      ? list.map((item, key) => {
                          return (
                            <div key={key} className="box-episode">
                              <div className="box-image">
                                <img
                                  className="lazyload"
                                  src=""
                                  data-srcset={`${
                                    item.acf
                                      .podcast_finalmente_residente_interna.card
                                      .thumbnail.sizes.large
                                  } 1x`}
                                  alt={
                                    item.acf
                                      .podcast_finalmente_residente_interna.card
                                      .thumbnail.title
                                  }
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="episode">
                                  {`${item.acf.podcast_finalmente_residente_interna.card.numero_do_episodio}`}
                                </h4>
                                <h3 className="title">{item.title.rendered}</h3>
                                <p className="description">
                                  {
                                    item.acf
                                      .podcast_finalmente_residente_interna.card
                                      .com
                                  }
                                </p>
                              </div>

                              <div className="box-cta">
                                <a href={item.link}>Ouvir Episódio</a>
                              </div>
                            </div>
                          );
                        })
                      : shortList.map((item, key) => {
                          return (
                            <div key={key} className="box-episode">
                              {item.acf.podcast_finalmente_residente_interna
                                .card.thumbnail && (
                                <div className="box-image">
                                  <img
                                    className="lazyload"
                                    src=""
                                    data-srcset={`${
                                      item.acf
                                        .podcast_finalmente_residente_interna
                                        .card.thumbnail.sizes.large
                                    } 1x`}
                                    alt={
                                      item.acf
                                        .podcast_finalmente_residente_interna
                                        .card.thumbnail.title
                                    }
                                  />
                                </div>
                              )}

                              <div className="box-content">
                                <h4 className="episode">
                                  {`${item.acf.podcast_finalmente_residente_interna.card.numero_do_episodio}`}
                                </h4>
                                <h3 className="title">{item.title.rendered}</h3>
                                <p className="description">
                                  {
                                    item.acf
                                      .podcast_finalmente_residente_interna.card
                                      .com
                                  }
                                </p>
                              </div>

                              <div className="box-cta">
                                <a href={item.link}>Ouvir Episódio</a>
                              </div>
                            </div>
                          );
                        })}
                  </div>

                  <div
                    className={
                      nextPage || moreEps === false
                        ? 'box-button'
                        : 'box-button box-button__hidden'
                    }
                  >
                    <Button>
                      <button
                        type="button"
                        onClick={() =>
                          moreEps ? setPage(page + 1) : setMoreEps(true)
                        }
                      >
                        Mais Episódios
                      </button>
                    </Button>
                  </div>
                </>
              ) : null}
            </Container>
          </Episodes>

          <Presenters
            bg={
              dados.podcast_finalmente_residente.apresentadores.background
                .sizes['banner-full']
            }
          >
            <Container>
              <TitleInverse>
                <h2>
                  {dados.podcast_finalmente_residente.apresentadores.titulo}
                </h2>
              </TitleInverse>
              <div className="box-logo">
                {dados.podcast_finalmente_residente.apresentadores.logo && (
                  <img
                    className="lazyload logo"
                    src=""
                    data-srcset={`${
                      dados.podcast_finalmente_residente.apresentadores.logo
                      .sizes.large
                    } 1x`}
                    alt={
                      dados.podcast_finalmente_residente.apresentadores.logo
                        .title
                    }
                  />
                )}
              </div>
              <div className="box-presenters">
                {dados.podcast_finalmente_residente.apresentadores.pessoas.map(
                  pessoa => {
                    return (
                      <article className="presenter">
                        <img
                          className="lazyload image"
                          src=""
                          data-srcset={`${pessoa.imagem.sizes.large} 1x`}
                          alt={pessoa.imagem.title}
                        />
                        <h3 className="title">{pessoa.nome}</h3>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: pessoa.bio }}
                        />
                      </article>
                    );
                  }
                )}
              </div>
            </Container>
          </Presenters>

          <AboutMedway>
            <Container>
              <div className="box-image">
                <img
                  className="lazyload"
                  src=""
                  data-srcset={`${
                    dados.podcast_finalmente_residente.quem_somos.imagem
                    .sizes.large
                  } 1x`}
                  alt={
                    dados.podcast_finalmente_residente.quem_somos.imagem.title
                  }
                />
              </div>
              <div
                className="box-content"
                dangerouslySetInnerHTML={{
                  __html:
                    dados.podcast_finalmente_residente.quem_somos.paragrafos,
                }}
              />
            </Container>
          </AboutMedway>

          {dados.podcast_finalmente_residente.conheca.titulo !== '' && (
            <SeeAlso>
              <Container>
                <Title>
                  <h2>{dados.podcast_finalmente_residente.conheca.titulo}</h2>
                </Title>
                <div className="box-itens">
                  <div className="item">
                    <div className="box-image">
                      <img
                        className="lazyload"
                        src=""
                        data-srcset={`${
                          dados.podcast_finalmente_residente.conheca.card.imagem
                            .sizes.large
                        } 1x`}
                        alt={
                          dados.podcast_finalmente_residente.conheca.card.imagem
                            .title
                        }
                      />
                    </div>

                    <div className="box-content">
                      <h3 className="title">
                        {dados.podcast_finalmente_residente.conheca.card.titulo}
                      </h3>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html:
                            dados.podcast_finalmente_residente.conheca.card
                              .paragrafo,
                        }}
                      />
                      <span className="presenters">
                        {dados.podcast_finalmente_residente.conheca.card.com}
                      </span>
                      <div className="box-cta">
                        <a
                          href={
                            dados.podcast_finalmente_residente.conheca.card
                              .botao.url
                          }
                          title={
                            dados.podcast_finalmente_residente.conheca.card
                              .botao.title
                          }
                          target={
                            dados.podcast_finalmente_residente.conheca.card
                              .botao.target
                          }
                        >
                          Ouvir Episódio
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </SeeAlso>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FinalmenteResidente;
