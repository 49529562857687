import React from 'react';

// Styles
import { Container } from './styles';

// Interface

export function Separator() {
  return <Container />;
}
