import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.span`
  border-radius: 20px;
  border: 1px solid #3b3fb6;
  color: #00205b;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  margin: 4px 8px 4px 0;
  padding: 4px 10px;
`;
