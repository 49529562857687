import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.img`
  border-radius: 50%;
  border: 4px solid #00cfb4;
  max-height: 120px;
  max-width: 120px;
  position: absolute;
  top: -60px;
  background-color: #f0f0f5;
`;
