import styled, { css } from 'styled-components';

// Assets

// Interface
interface Props {
  type: 'old' | 'current' | 'percentage' | 'instalments';
}

export const Container = styled.p<Props>`
  ${({ type }) =>
    type === 'old' &&
    css`
      color: #414141;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;

      @media (orientation: portrait) {
        font-size: 14px;
        margin-bottom: 8px;
      }

      span {
        display: inline-block;
        position: relative;

        &:before {
          background-color: #414141;
          content: '';
          display: block;
          height: 1px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        }
      }
    `}

  ${({ type }) =>
    type === 'current' &&
    css`
      color: #00205b;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 8px;

      @media (orientation: portrait) {
        font-size: 18px;
        margin-bottom: 4px;
      }
    `}

  ${({ type }) =>
    type === 'percentage' &&
    css`
      color: #414141;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 8px;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    `}

  ${({ type }) =>
    type === 'instalments' &&
    css`
      color: #414141;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 32px;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    `}
`;
