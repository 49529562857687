import React from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Icons
import PDF from '../../../assets/icon-pdf.svg';
import Download from '../../../assets/icon-download.svg';
import PSMedwayLogo from '../../../assets/psmedway-verde-branco.svg';

interface AlternativasProps {
  conteudo: string;
  correta: boolean;
}
interface QuestoesProps {
  titulo: string;
  alternativas: AlternativasProps[];
}
interface ApiProps {
  title: {
    rendered: string;
  };
  acf: {
    experimental: {
      video: string;
      materiais: {
        apostila: {
          arquivo: {
            title: string;
            description: string;
            url: string;
          };
          titulo: string;
          descricao: string;
        };
        conteudo: string;
      };
      professor: {
        bio: string;
        especialidade: string;
        imagem: {
          url: string;
        };
        nome_do_professor: string;
        titulo: string;
      };
      sobre: {
        descricao: string;
      };
      teste_seus_conhecimentos: {
        gabarito: {
          arquivo: {
            url: string;
            title: string;
            description: string;
          };
        };
        questoes: QuestoesProps[];
      };
    };
  };
}
interface Props {
  data: ApiProps | null;
  hangleTab?: any;
  openModal?: any;
}
export default function AccordionEurofarma({ data, openModal }: Props) {
  return (
    <div className="box-accordion">
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="icon" />}
          className="accordion-header"
        >
          <h3 className="title">Material</h3>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
          {data?.acf.experimental.materiais.conteudo && (
            <div
              dangerouslySetInnerHTML={{
                __html: data?.acf.experimental.materiais.conteudo,
              }}
            />
          )}
          <div className="box-handout">
            <div className="box-header">
              <img src={PSMedwayLogo} alt="PS Medway" className="image" />
              <h3 className="title">
                {data?.acf.experimental.materiais.apostila.titulo}
              </h3>
              <p className="description">
                {data?.acf.experimental.materiais.apostila.descricao}
              </p>
            </div>

            <div className="box-download">
              <button className="link" type="button" onClick={openModal}>
                <div className="box-icon box-icon__pdf">
                  <img src={PDF} alt="PDF" className="icon" />
                </div>
                <div className="box-name">
                  <h4 className="title">
                    {data?.acf.experimental.materiais.apostila.arquivo.title}
                  </h4>
                  <p className="name">
                    {
                      data?.acf.experimental.materiais.apostila.arquivo
                        .description
                    }
                  </p>
                </div>
                <div className="box-icon box-icon__download">
                  <img src={Download} alt="Download" className="icon" />
                </div>
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="icon" />}
          className="accordion-header"
        >
          <h3 className="title">Sobre</h3>
        </AccordionSummary>
        {data?.acf.experimental.sobre.descricao && (
          <AccordionDetails
            className="accordion-content"
            dangerouslySetInnerHTML={{
              __html: data?.acf.experimental.sobre.descricao,
            }}
          />
        )}
      </Accordion>
    </div>
  );
}
