import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.section`
  display: flex;
  align-items: center;
  background: #e8e8e8;
  color: #0f0c6d;
  gap: 0 80px;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 50px;
  text-align: center;

  @media (orientation: portrait) {
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 40px;
  }
`;

export const BoxContent = styled.div`
  max-width: 600px;
  width: 100%;
`;

export const Title = styled.h3`
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;

  @media (orientation: portrait) {
    font-size: 24px;
  }
`;

export const Separator = styled.div`
  background: #fff;
  height: 2px;
  margin: 35px 0;
  width: 100%;

  @media (orientation: portrait) {
    margin: 15px auto;
    width: 90%;
  }
`;

export const Subtitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;

  @media (orientation: portrait) {
    font-size: 16px;
  }
`;

export const Link = styled.a`
  border-radius: 50px;
  border: 2px solid #0f0c6d;
  color: #0f247b;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background ease-in-out 0.3s, color ease-in-out 0.3s;

  @media (orientation: portrait) {
    font-size: 14px;
    margin-top: 14px;
  }

  &:hover {
    background: #0f0c6d;
    color: #ffffff;
    transition: background ease-in-out 0.3s, color ease-in-out 0.3s;
  }
`;
