import styled from 'styled-components';

export const Container = styled.div`
  @media (orientation: landscape) {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  @media (orientation: portrait) {
    padding-top: 50px;
    padding-bottom: 30px;
  }
`;

export const Icones = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Link = styled.a`
  padding: 15px;
  position: relative;
`;

export const Icone = styled.img`
  @media (orientation: landscape) {
    width: 64px;
    height: 64px;
  }

  @media (orientation: portrait) {
    width: 30px;
    height: 30px;
  }
`;

export const Describe = styled.p`
  color: #fff;
  font-size: 14px;
  text-align: center;

  padding-right: 15px;
  padding-left: 15px;
`;
