import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.hr`
  border: 0;
  border-top: 1px solid #dadada;
  margin-bottom: 24px;
`;
