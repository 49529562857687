import React, { AnchorHTMLAttributes } from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  fill: 'contained' | 'outlined';
  size: 'small' | 'big';
  fluid?: boolean;
  pattern?: 'base' | 'variant';
}

export function Button({
  children,
  fill,
  size,
  fluid,
  pattern,
  ...props
}: Props) {
  return (
    <Container
      fill={fill}
      size={size}
      fluid={fluid}
      pattern={pattern}
      {...props}
    >
      {children}
    </Container>
  );
}
