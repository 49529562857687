import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import api from '../../services/api';
import apiPSMedway from '../../services/PSMedway/api';
import apiMentoria from '../../services/Mentoria/api';
import apiExtensivo from '../../services/Extensivo/api';
import apiCRMedway from '../../services/CRMedway/api';

// Other components
import TabsEurofarma from '../../components/MaterialUi/TabsExperimental';
import AccordionEurofarma from '../../components/MaterialUi/AccordionExperimental';

// Images
import MedwayLogo from '../../assets/medway-branco-verde.svg';
import PSMedwayLogo from '../../assets/psmedway-verde-branco.svg';
import Close from '../../assets/close-light-icon.svg';

// Icons
import PDF from '../../assets/icon-pdf.svg';
import Download from '../../assets/icon-download.svg';

// Styles
import {
  Content,
  Container,
  Header,
  Titulo,
  Video,
  Main,
  Footer,
  BoxMain,
  Modal,
} from './styles';
import Loading from '../../components/Loading';

interface ParansProps {
  aula: string;
}
interface AlternativasProps {
  conteudo: string;
  correta: boolean;
}
interface QuestoesProps {
  titulo: string;
  alternativas: AlternativasProps[];
}
interface ApiProps {
  title: {
    rendered: string;
  };
  acf: {
    experimental: {
      video: string;
      materiais: {
        apostila: {
          arquivo: {
            title: string;
            description: string;
            url: string;
          };
          titulo: string;
          descricao: string;
        };
        conteudo: string;
      };
      professor: {
        bio: string;
        especialidade: string;
        imagem: {
          url: string;
        };
        nome_do_professor: string;
        titulo: string;
      };
      sobre: {
        descricao: string;
      };
      modal: {
        descricao: string;
      };
      teste_seus_conhecimentos: {
        gabarito: {
          arquivo: {
            url: string;
            title: string;
            description: string;
          };
        };
        questoes: QuestoesProps[];
      };
    };
  };
}

const Experimental: React.FC = () => {
  const { aula } = useParams<ParansProps>();
  const [isMobile, setIsMobile] = useState(false);
  const [modal, setModal] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [apiData, setApiData] = useState<ApiProps | null>(null);
  const cookiesValue = Cookies.get('ExperimentalLead');

  // choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const hangleTab = (n: number) => {
    setTabActive(n);
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    const hostName = window.location.hostname;
    switch (hostName) {
      case 'mentoria.medway.com.br':
        apiMentoria.get(`wp/v2/experimental?slug=${aula}`).then(response => {
          setApiData(response.data[0]);
        });
        break;
      case 'extensivo.medway.com.br':
        apiExtensivo.get(`wp/v2/experimental?slug=${aula}`).then(response => {
          setApiData(response.data[0]);
        });
        break;
      case 'cr.medway.com.br':
        apiCRMedway.get(`wp/v2/experimental?slug=${aula}`).then(response => {
          setApiData(response.data[0]);
        });
        break;
      case 'ps.medway.com.br':
        apiPSMedway.get(`wp/v2/experimental?slug=${aula}`).then(response => {
          setApiData(response.data[0]);
        });
        break;
      default:
        api.get(`wp/v2/experimental?slug=${aula}`).then(response => {
          setApiData(response.data[0]);
        });
        break;
    }

    const scriptForm = document.createElement('script');

    // Form
    const scriptLoadForm = document.createElement('script');
    window.onload = () => {
      scriptForm.type = 'text/javascript';
      scriptForm.src =
        'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js';
      scriptForm.async = false;
      document.body.appendChild(scriptForm);

      setTimeout(() => {
        scriptLoadForm.type = 'text/javascript';
        scriptLoadForm.innerHTML =
          "new RDStationForms('formulario-teste-experimentacao-psmedway-3e2c020511ffbe3c439c', 'UA-109257624-1').createForm();";
        scriptLoadForm.async = false;

        document.body.appendChild(scriptLoadForm);
      }, 1200);
    };

    return () => {
      document.body.removeChild(scriptForm);
      document.body.removeChild(scriptLoadForm);
    };
  }, []);

  setTimeout(() => {
    if (modal) {
      const formElement = document.querySelector<HTMLFormElement>('form');
      formElement?.addEventListener('submit', () => {
        const name = document.querySelector<HTMLInputElement>(
          '#rd-text_field-ks7pbujf'
        )?.value;
        const email = document.querySelector<HTMLInputElement>(
          '#rd-email_field-ks7pbujg'
        )?.value;
        const area = document.querySelector<HTMLSelectElement>(
          '#rd-select_field-ks7pbujh'
        )?.value;
        const ano = document.querySelector<HTMLSelectElement>(
          '#rd-number_field-ks7pbuji'
        )?.value;

        if (name && email && area && ano) {
          Cookies.set('ExperimentalLead', email, { expires: 1 });
          window.open(apiData?.acf.experimental.materiais.apostila.arquivo.url);
        }
      });
    }
  });

  function openModal(e: any) {
    e.preventDefault();

    if (cookiesValue) {
      window.open(apiData?.acf.experimental.materiais.apostila.arquivo.url);
    } else {
      setModal(true);
    }
  }

  return (
    <>
      {apiData ? (
        <>
          <Header>
            <Container>
              <div className="box-logos">
                <img src={MedwayLogo} alt="Medway" className="medway" />
              </div>
            </Container>
          </Header>
          <Content>
            <Titulo>
              <Container>
                <div className="box-content">
                  <h2 className="sub-title">PS Medway</h2>
                  <h1 className="title">{apiData?.title.rendered}</h1>
                </div>
              </Container>
            </Titulo>
            <Video isMobile={isMobile}>
              <Container>
                {apiData?.acf.experimental.video && (
                  <div
                    className="box-video"
                    dangerouslySetInnerHTML={{
                      __html: apiData.acf.experimental.video,
                    }}
                  />
                )}
              </Container>
            </Video>
            <Main>
              <Container>
                <BoxMain className="box-main" isMobile={isMobile}>
                  {isMobile ? (
                    <AccordionEurofarma
                      data={apiData}
                      hangleTab={hangleTab}
                      openModal={openModal}
                    />
                  ) : (
                    <TabsEurofarma data={apiData} hangleTab={hangleTab} />
                  )}

                  <div className="box-aboutTeacher">
                    <h3 className="title">
                      {apiData?.acf.experimental.professor.titulo}
                    </h3>
                    <div className="box-image">
                      {apiData?.acf.experimental.professor.imagem.url && (
                        <img
                          src={apiData.acf.experimental.professor.imagem.url}
                          alt={
                            apiData.acf.experimental.professor.nome_do_professor
                          }
                          className="image"
                        />
                      )}
                    </div>
                    <article className="box-content">
                      <h1 className="name">
                        {apiData?.acf.experimental.professor.nome_do_professor}
                      </h1>
                      <h2 className="specialty">
                        {apiData?.acf.experimental.professor.especialidade}
                      </h2>
                      <div className="box-description">
                        <p>{apiData?.acf.experimental.professor.bio}</p>
                      </div>
                    </article>

                    {tabActive === 0 && (
                      <div className="box-handout">
                        <div className="box-header">
                          <img
                            src={PSMedwayLogo}
                            alt="PS Medway"
                            className="image"
                          />
                          <h3 className="title">
                            {
                              apiData?.acf.experimental.materiais.apostila
                                .titulo
                            }
                          </h3>
                          <p className="description">
                            {
                              apiData?.acf.experimental.materiais.apostila
                                .descricao
                            }
                          </p>
                        </div>

                        <div className="box-download">
                          <button
                            type="button"
                            onClick={openModal}
                            className="link"
                          >
                            <div className="box-icon box-icon__pdf">
                              <img src={PDF} alt="PDF" className="icon" />
                            </div>
                            <div className="box-name">
                              <h4 className="title">
                                {
                                  apiData?.acf.experimental.materiais.apostila
                                    .arquivo.title
                                }
                              </h4>
                              <p className="name">
                                {
                                  apiData?.acf.experimental.materiais.apostila
                                    .arquivo.description
                                }
                              </p>
                            </div>
                            <div className="box-icon box-icon__download">
                              <img
                                src={Download}
                                alt="Download"
                                className="icon"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </BoxMain>
              </Container>
            </Main>
            <Footer>
              <Container>
                <div className="box-content">
                  <p className="text">Copyright - Medway Residência Médica</p>
                </div>
              </Container>
            </Footer>
          </Content>
          <Modal status={modal}>
            <section className="modal-content">
              <div className="box-title">
                <p
                  dangerouslySetInnerHTML={{
                    __html: apiData?.acf.experimental.modal.descricao,
                  }}
                />
              </div>
              <div className="box-content">
                <div
                  role="main"
                  id="formulario-teste-experimentacao-psmedway-3e2c020511ffbe3c439c"
                ></div>
              </div>
              <div className="close">
                <button
                  className="close_button"
                  onClick={() => setModal(false)}
                  type="button"
                >
                  <img src={Close} alt="Fechar" />
                </button>
              </div>
            </section>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Experimental;
