import React, { useState, useRef, useEffect } from 'react';
import HubspotForm from 'react-hubspot-form';

import 'lazysizes';

// Components
import { Menu } from '../../../components/Vendas/Menu';
import { Logo } from '../../../components/Vendas/Menu/Logo';
import { BoxMenuMobile } from '../../../components/Vendas/Menu/BoxMenuMobile';
import { BoxMenu } from '../../../components/Vendas/Menu/BoxMenu';
import { Anchor } from '../../../components/Vendas/Menu/Anchor';
import { Button } from '../../../components/Vendas/Button';
import { Card } from '../../../components/Vendas/Card';
import { H2 } from '../../../components/Vendas/Card/H2';
import { H3 } from '../../../components/Vendas/Card/H3';
import { Message } from '../../../components/Vendas/Card/Message';
import { Price } from '../../../components/Vendas/Card/Price';
import { Paragraph } from '../../../components/Vendas/Card/Paragraph';
import { Image } from '../../../components/Vendas/Card/Image';
import { Modal } from '../../../components/Vendas/Modal';
import { BoxItem } from '../../../components/Vendas/Testemonial/BoxItem';
import { Teachers } from '../../../components/Vendas/Teachers';
import { TeachersBoxContent } from '../../../components/Vendas/Teachers/BoxContent';
import { TeachersBoxImage } from '../../../components/Vendas/Teachers/BoxImage';
import { TeachersDescription } from '../../../components/Vendas/Teachers/Description';
import { TeachersH3 } from '../../../components/Vendas/Teachers/H3';
import { TeachersOffice } from '../../../components/Vendas/Teachers/Office';
import { TeachersImage } from '../../../components/Vendas/Teachers/Image';
import { Testemonial } from '../../../components/Vendas/Testemonial';
import { TestemonialH3 } from '../../../components/Vendas/Testemonial/H3';
import { TestemonialDescription } from '../../../components/Vendas/Testemonial/Description';
import { BoxVideo } from '../../../components/Vendas/Testemonial/BoxVideo';
import { BoxContent } from '../../../components/Vendas/Testemonial/BoxContent';
import { Warranty } from '../../../components/Vendas/Waranty';
import { Banner } from '../../../components/Vendas/Banner';
import { Faq } from '../../../components/Vendas/Faq';
import Loading from '../../../components/Loading';
import AddressAndPolicies from '../../../components/AddressAndPolicies';
import { FreeTrialBanner } from '../../../components/FreeTrialBanner';

// Services
import apiR3 from '../../../services/R3/api';

// Assets
import ExtensivoR3Logo from '../../../assets/logo-extensivo-r3-clinica-medica.svg';
import MenuIcon from '../../../assets/menu-icon.png';
import CloseMenuIcon from '../../../assets/close-menu-icon.png';

// Styles
import {
  Container,
  LogoContainer,
  BoxHero,
  BoxPillars,
  BoxTeachers,
  BoxVideoTestemonial,
  BoxWaranty,
  BoxBuy,
  BoxNews,
  BoxFaq,
  BoxFooter,
} from './styles';

// Interface
interface Botao {
  title: string;
  url: string;
  target: string;
}

interface Pilar {
  icone_pilar: {
    title: string;
    url: string;
  };
  titulo_pilar: string;
  texto_pilar: string;
  conteudo_pilar: string;
  botao_pilar: Botao;
}

interface Depoimento {
  video_depoimento: string;
  nome_depoimento: string;
  aprovacao_depoimento: string;
}

interface Professores {
  imagem_professor: {
    title: string;
    url: string;
  };
  nome_professor: string;
  funcao_professor: string;
  descricao_professor: string;
}

interface SubListaDuvidas {
  titulo_subgrupo: string;
  texto_subgrupo: string;
}

interface ListaDuvidas {
  titulo_grupo: string;
  texto_grupo: string;
  subgrupo: [SubListaDuvidas];
}

interface Cronograma {
  titulo_cronograma: string;
  arquivo_cronograma: {
    url: string;
  };
  botao_cronograma: Botao;
}

interface CabecalhoTabela {
  titulo_cabecalho: string;
}

interface ColunaConteudoTabela {
  texto_coluna: string;
}

interface ConteudoTabela {
  coluna_conteudo: [ColunaConteudoTabela];
}

interface Dados {
  vendas_r3: {
    hero: {
      status_hero: boolean;
      titulo_hero: string;
      texto_hero: string;
      botao_hero: Botao;
      video_hero: string;
    };

    sobre: {
      status_sobre: boolean;
      titulo_sobre: string;
      pilares: [Pilar];
      botao_sobre: Botao;
      cronograma_sobre: Cronograma;
    };

    professores: {
      status_professores: boolean;
      titulo_professores: string;
      lista_professores: [Professores];
    };

    depoimentos: {
      status_depoimentos: boolean;
      titulo_depoimentos: string;
      lista_depoimentos: [Depoimento];
    };

    garantia: {
      status_garantia: boolean;
      titulo_garantia: string;
      subtitulo_garantia: string;
      data_garantia: string;
      texto_garantia: string;
    };

    valores: {
      status_valores: boolean;
      promocional_valores: boolean;
      titulo_valores: string;
      subtitulo_valores: string;
      mensagem_valores: string;
      preco_valores: string;
      desconto_valores: string;
      parcelas_valores: string;
      botao_valores: Botao;
    };

    banner: {
      status_banner: boolean;
      titulo_banner: string;
      subtitulo_banner: string;
      botao_banner: Botao;
    };

    grupo: {
      status_grupo: boolean;
      titulo_grupo: string;
      subtitulo_grupo: string;
      tabela_grupo: {
        cabecalho_tabela: [CabecalhoTabela];
        conteudo_tabela: [ConteudoTabela];
      };
      observacoes_grupo: string;
      formulario_grupo: {
        portalid_formulario: string;
        formid_formulario: string;
      };
    };

    duvidas: {
      status_duvidas: boolean;
      titulo_duvidas: string;
      grupo_duvidas: [ListaDuvidas];
    };
  };

  banner_free_trial: {
    activate_free_trial: boolean;
    navigation_title: string;
    background_color: string;
    activate_background_image: boolean;
    background_image: string;
    margin_bottom_color: string;
    text_color: string;
    title: string;
    subtitle: string;
    activate_button: boolean;
    button_text_color: string;
    button_hover_text_color: string;
    button_background_color: string;
    button_hover_background_color: string;
    button_text: string;
    button_link: string;
  };
}

const R3Clinica: React.FC = () => {
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState('');
  const [menuMobile, setMenuMobile] = useState(false);
  const [pilarShow, setPilarShow] = useState(3);
  const [dados, setDados] = useState<Dados | null>(null);
  const hostName = window.location.hostname;
  const url_traffic_source = window.location.search;

  useEffect(() => {
    apiR3
      .get(`pages/${hostName === 'r3.medway.com.br' ? `7` : `9458`}`)
      .then(response => {
        setDados(response.data.acf);
      });
  }, []);

  // Refs to Menu
  const scrollTo = (e: any) => window.scrollTo(0, e.current.offsetTop - 50);
  const boxHeroRef = useRef(null);
  const boxPillarsRef = useRef(null);
  const freeTrialBloco = useRef(null);
  const boxTeachersRef = useRef(null);
  const boxTestemonialRef = useRef(null);
  const boxWarantyRef = useRef(null);
  const boxBuyRef = useRef(null);
  const boxFaqRef = useRef(null);

  return (
    <>
      {dados ? (
        <>
          <Menu>
            <LogoContainer>
              <Logo src={ExtensivoR3Logo} alt="Extensivo Medway" />
            </LogoContainer>
            <BoxMenuMobile
              onClick={() => {
                setMenuMobile(!menuMobile);
              }}
              src={menuMobile ? CloseMenuIcon : MenuIcon}
            />
            <BoxMenu menuMobile={menuMobile}>
              <Anchor
                menuRef={boxHeroRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                O R3 de Clínica
              </Anchor>
              <Anchor
                menuRef={boxPillarsRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Metodologia
              </Anchor>
              {dados.banner_free_trial
              && dados.banner_free_trial.activate_free_trial
              && dados.banner_free_trial.navigation_title && (
                <Anchor
                  menuRef={freeTrialBloco}
                  setMenuMobile={setMenuMobile}
                  type="button"
                >
                  {dados.banner_free_trial.navigation_title}
                </Anchor>
              )}
              <Anchor
                menuRef={boxTeachersRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Professores
              </Anchor>
              <Anchor
                menuRef={boxTestemonialRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Depoimentos
              </Anchor>
              <Anchor
                menuRef={boxWarantyRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Garantia
              </Anchor>
              <Anchor
                menuRef={boxFaqRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Dúvidas
              </Anchor>
              <Button
                fill="contained"
                size="small"
                pattern="base"
                onClick={() => {
                  scrollTo(boxBuyRef);
                  setMenuMobile(false);
                }}
              >
                COMPRE AGORA
              </Button>
            </BoxMenu>
          </Menu>

          {dados.vendas_r3.hero.status_hero && (
            <BoxHero ref={boxHeroRef}>
              {/* <section className="callToAction"> */}
                <div className="boxContent">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas_r3.hero.titulo_hero,
                    }}
                  />
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas_r3.hero.texto_hero,
                    }}
                  />
                  <Button
                    fill="contained"
                    size="small"
                    pattern="base"
                    className="buttonHero"
                    onClick={() => {
                      scrollTo(boxBuyRef);
                    }}
                  >
                    {dados.vendas_r3.hero.botao_hero.title}
                  </Button>
                </div>
                {/* <div
                  className="boxVideoHero"
                  dangerouslySetInnerHTML={{
                    __html: dados.vendas_r3.hero.video_hero,
                  }}
                />
              </section> */}
            </BoxHero>
          )}

          {dados.vendas_r3.sobre.status_sobre && (
            <BoxPillars ref={boxPillarsRef}>
              <div className="box-title">
                <h2 className="title">{dados.vendas_r3.sobre.titulo_sobre}</h2>
              </div>
              <div className="box-content">
                {dados.vendas_r3.sobre.pilares
                  .slice(0, pilarShow)
                  .map((item, key) => (
                    <Card align="center" key={key}>
                      <Image
                        className="lazyload"
                        src=""
                        data-srcset={`${item.icone_pilar.url} 1x`}
                        alt={item.icone_pilar.title}
                      />
                      <H3 align="center">{item.titulo_pilar}</H3>
                      <Paragraph align="center" weight={400}>
                        {item.texto_pilar}
                      </Paragraph>
                      <Button
                        onClick={() => {
                          setStatusModal(true);
                          setModalContent(item.conteudo_pilar);
                        }}
                        fill="outlined"
                        size="small"
                        pattern="base"
                      >
                        {item.botao_pilar.title}
                      </Button>
                    </Card>
                  ))}
              </div>
              <div className="box-buttons">
                {dados.vendas_r3.sobre.pilares.length >= pilarShow && (
                  <Button
                    fill="outlined"
                    size="small"
                    pattern="base"
                    onClick={() => {
                      setPilarShow(pilarShow + 3);
                    }}
                  >
                    {dados.vendas_r3.sobre.botao_sobre.title}
                  </Button>
                )}
              </div>

              <div className="box-dowload">
                <span className="title">
                  {dados.vendas_r3.sobre.cronograma_sobre.titulo_cronograma}
                </span>
                <Button
                  href={
                    dados.vendas_r3.sobre.cronograma_sobre.arquivo_cronograma
                      .url
                  }
                  target="_blank"
                  fill="contained"
                  size="small"
                  pattern="variant"
                >
                  {
                    dados.vendas_r3.sobre.cronograma_sobre.botao_cronograma
                      .title
                  }
                </Button>
              </div>
            </BoxPillars>
          )}

          {dados.banner_free_trial && dados.banner_free_trial.activate_free_trial && (
            <FreeTrialBanner
              reference={freeTrialBloco}
              free_trial_info = {dados.banner_free_trial}/>
          )}

          {dados.vendas_r3.professores.status_professores && (
            <BoxTeachers ref={boxTeachersRef}>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.vendas_r3.professores.titulo_professores}
                  </h2>
                </div>

                <Teachers dots nav={false} loop={false}>
                  {dados.vendas_r3.professores.lista_professores.map(
                    (item, key) => (
                      <BoxItem type="teacher" key={key}>
                        <Card align="center" height="100%">
                          <TeachersBoxImage>
                            <TeachersImage
                              className="lazyload"
                              src=""
                              data-srcset={`${item.imagem_professor.url} 1x`}
                              alt={item.imagem_professor.title}
                            />
                          </TeachersBoxImage>
                          <TeachersBoxContent>
                            <TeachersH3>{item.nome_professor}</TeachersH3>
                            <TeachersOffice>
                              {item.funcao_professor}
                            </TeachersOffice>
                            <TeachersDescription
                              dangerouslySetInnerHTML={{
                                __html: item.descricao_professor,
                              }}
                            />
                          </TeachersBoxContent>
                        </Card>
                      </BoxItem>
                    )
                  )}
                </Teachers>
              </Container>
            </BoxTeachers>
          )}

          {dados.vendas_r3.depoimentos.status_depoimentos && (
            <BoxVideoTestemonial ref={boxTestemonialRef}>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.vendas_r3.depoimentos.titulo_depoimentos}
                  </h2>
                </div>
                <Testemonial nav dots loop={false}>
                  {dados.vendas_r3.depoimentos.lista_depoimentos.map(
                    (item, key) => (
                      <BoxItem type="video" key={key}>
                        <BoxVideo
                          dangerouslySetInnerHTML={{
                            __html: item.video_depoimento,
                          }}
                        />
                        <BoxContent>
                          <TestemonialH3>{item.nome_depoimento}</TestemonialH3>
                          <TestemonialDescription type="video">
                            {item.aprovacao_depoimento}
                          </TestemonialDescription>
                        </BoxContent>
                      </BoxItem>
                    )
                  )}
                </Testemonial>
              </Container>
            </BoxVideoTestemonial>
          )}

          {dados.vendas_r3.garantia.status_garantia && (
            <BoxWaranty ref={boxWarantyRef}>
              <Warranty
                title={dados.vendas_r3.garantia.titulo_garantia}
                subtitle={dados.vendas_r3.garantia.subtitulo_garantia}
                date={dados.vendas_r3.garantia.data_garantia}
                text={dados.vendas_r3.garantia.texto_garantia}
              />
            </BoxWaranty>
          )}

          {dados.vendas_r3.valores.status_valores && (
            <BoxBuy ref={boxBuyRef} id="buy">
              <Card
                align="center"
                buy
                flag={dados.vendas_r3.valores.promocional_valores}
              >
                <H2 align="center" buy>
                  {dados.vendas_r3.valores.titulo_valores}
                </H2>
                <H3 align="center" buy>
                  {dados.vendas_r3.valores.subtitulo_valores}
                </H3>
                {dados.vendas_r3.valores.mensagem_valores && (
                  <Message
                    type="sucess"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas_r3.valores.mensagem_valores,
                    }}
                  />
                )}
                <Price type="current">
                  {dados.vendas_r3.valores.preco_valores}
                </Price>
                <Price type="percentage">
                  {dados.vendas_r3.valores.desconto_valores}
                </Price>
                <Price type="instalments">
                  {dados.vendas_r3.valores.parcelas_valores}
                </Price>
                <Button
                  href={`${dados.vendas_r3.valores.botao_valores.url}${url_traffic_source}`}
                  fill="contained"
                  target={dados.vendas_r3.valores.botao_valores.target}
                  size="big"
                  pattern="base"
                  fluid
                >
                  {dados.vendas_r3.valores.botao_valores.title}
                </Button>
              </Card>
            </BoxBuy>
          )}

          {dados.vendas_r3.banner.status_banner && (
            <Banner
              title={dados.vendas_r3.banner.titulo_banner}
              subtitle={dados.vendas_r3.banner.subtitulo_banner}
              button={dados.vendas_r3.banner.botao_banner}
            />
          )}

          {dados.vendas_r3.grupo.status_grupo && (
            <BoxNews>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.vendas_r3.grupo.titulo_grupo}
                  </h2>
                  <p className="description">
                    {dados.vendas_r3.grupo.subtitulo_grupo}
                  </p>
                </div>
                <div className="box-table">
                  <div className="table-head">
                    {dados.vendas_r3.grupo.tabela_grupo.cabecalho_tabela.map(
                      (item, key) => (
                        <div className="table-column" key={key}>
                          <h4 className="table-title">
                            {item.titulo_cabecalho}
                          </h4>
                        </div>
                      )
                    )}
                  </div>

                  <div className="table-content">
                    {dados.vendas_r3.grupo.tabela_grupo.conteudo_tabela.map(
                      (item, key) => (
                        <div className="table-column" key={key}>
                          {item.coluna_conteudo.map((item2, key2) => (
                            <div className="table-cell" key={key2}>
                              <h4 className="table-title">
                                {item2.texto_coluna}
                              </h4>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="box-message">
                  <div
                    className="message"
                    dangerouslySetInnerHTML={{
                      __html: dados.vendas_r3.grupo.observacoes_grupo,
                    }}
                  />
                </div>
                <div className="box-form">
                  <HubspotForm
                    portalId={
                      dados.vendas_r3.grupo.formulario_grupo.portalid_formulario
                    }
                    formId={
                      dados.vendas_r3.grupo.formulario_grupo.formid_formulario
                    }
                  />
                </div>
              </Container>
            </BoxNews>
          )}

          {dados.vendas_r3.duvidas.status_duvidas && (
            <BoxFaq ref={boxFaqRef}>
              <Faq
                title={dados.vendas_r3.duvidas.titulo_duvidas}
                data={dados.vendas_r3.duvidas.grupo_duvidas}
              />
            </BoxFaq>
          )}

          <BoxFooter>
            <div className="boxFooter">
              <p className="titleFooter">
                Ainda tem dúvidas? Entre em contato com a gente{' '}
                <a className="mailto" href="mailto:duvidas@medway.com.br">
                  duvidas@medway.com.br
                </a>
              </p>
            </div>
            <AddressAndPolicies background = '' />
          </BoxFooter>

          <Modal
            open={statusModal}
            setStatusModal={setStatusModal}
            modalContent={modalContent}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default R3Clinica;
