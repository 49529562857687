import styled from "styled-components";

export const Container = styled.div`
  ol.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }
  ol.lst-kix_list_9-0.start {
    counter-reset: lst-ctn-kix_list_9-0 5;
  }
  .lst-kix_list_6-1 > li {
    counter-increment: lst-ctn-kix_list_6-1;
  }
  ol.lst-kix_list_7-4.start {
    counter-reset: lst-ctn-kix_list_7-4 0;
  }
  .lst-kix_list_5-0 > li {
    counter-increment: lst-ctn-kix_list_5-0;
  }
  .lst-kix_list_9-0 > li {
    counter-increment: lst-ctn-kix_list_9-0;
  }
  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }
  ol.lst-kix_list_7-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-8.start {
    counter-reset: lst-ctn-kix_list_11-8 0;
  }
  ol.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0;
  }
  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3;
  }
  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0;
  }
  ol.lst-kix_list_8-8.start {
    counter-reset: lst-ctn-kix_list_8-8 0;
  }
  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2;
  }
  ol.lst-kix_list_10-4.start {
    counter-reset: lst-ctn-kix_list_10-4 0;
  }
  .lst-kix_list_7-2 > li {
    counter-increment: lst-ctn-kix_list_7-2;
  }
  .lst-kix_list_5-0 > li:before {
    content: "" counter(lst-ctn-kix_list_5-0, lower-roman) ". ";
  }
  ol.lst-kix_list_6-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-1 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li {
    counter-increment: lst-ctn-kix_list_5-4;
  }
  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  .lst-kix_list_5-3 > li:before {
    content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
  }
  ol.lst-kix_list_9-5.start {
    counter-reset: lst-ctn-kix_list_9-5 0;
  }
  .lst-kix_list_5-2 > li:before {
    content: "" counter(lst-ctn-kix_list_5-2, decimal) ". ";
  }
  .lst-kix_list_8-3 > li {
    counter-increment: lst-ctn-kix_list_8-3;
  }
  .lst-kix_list_5-1 > li:before {
    content: "" counter(lst-ctn-kix_list_5-1, decimal) ". ";
  }
  .lst-kix_list_5-7 > li:before {
    content: "" counter(lst-ctn-kix_list_5-7, decimal) ". ";
  }
  .lst-kix_list_5-6 > li:before {
    content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
  }
  .lst-kix_list_5-8 > li:before {
    content: "" counter(lst-ctn-kix_list_5-8, decimal) ". ";
  }
  .lst-kix_list_9-4 > li {
    counter-increment: lst-ctn-kix_list_9-4;
  }
  ol.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-7 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: "" counter(lst-ctn-kix_list_5-4, decimal) ". ";
  }
  ol.lst-kix_list_6-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-5 > li:before {
    content: "" counter(lst-ctn-kix_list_5-5, decimal) ". ";
  }
  ol.lst-kix_list_6-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-5 {
    list-style-type: none;
  }
  .lst-kix_list_6-1 > li:before {
    content: "" counter(lst-ctn-kix_list_6-1, decimal) ". ";
  }
  .lst-kix_list_6-3 > li:before {
    content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
  }
  .lst-kix_list_6-5 > li {
    counter-increment: lst-ctn-kix_list_6-5;
  }
  .lst-kix_list_6-8 > li {
    counter-increment: lst-ctn-kix_list_6-8;
  }
  .lst-kix_list_6-0 > li:before {
    content: "" counter(lst-ctn-kix_list_6-0, lower-roman) ". ";
  }
  .lst-kix_list_6-4 > li:before {
    content: "" counter(lst-ctn-kix_list_6-4, decimal) ". ";
  }
  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6;
  }
  .lst-kix_list_6-2 > li:before {
    content: "" counter(lst-ctn-kix_list_6-2, decimal) ". ";
  }
  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }
  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }
  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0;
  }
  .lst-kix_list_6-8 > li:before {
    content: "" counter(lst-ctn-kix_list_6-8, decimal) ". ";
  }
  .lst-kix_list_6-5 > li:before {
    content: "" counter(lst-ctn-kix_list_6-5, decimal) ". ";
  }
  .lst-kix_list_6-7 > li:before {
    content: "" counter(lst-ctn-kix_list_6-7, decimal) ". ";
  }
  .lst-kix_list_6-6 > li:before {
    content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
  }
  ol.lst-kix_list_10-6.start {
    counter-reset: lst-ctn-kix_list_10-6 0;
  }
  .lst-kix_list_7-4 > li:before {
    content: "" counter(lst-ctn-kix_list_7-4, decimal) ". ";
  }
  .lst-kix_list_7-6 > li:before {
    content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
  }
  ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0;
  }
  .lst-kix_list_7-2 > li:before {
    content: "" counter(lst-ctn-kix_list_7-2, decimal) ". ";
  }
  .lst-kix_list_7-6 > li {
    counter-increment: lst-ctn-kix_list_7-6;
  }
  .lst-kix_list_8-6 > li {
    counter-increment: lst-ctn-kix_list_8-6;
  }
  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0;
  }
  ol.lst-kix_list_9-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0;
  }
  ol.lst-kix_list_9-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-4 {
    list-style-type: none;
  }
  .lst-kix_list_5-7 > li {
    counter-increment: lst-ctn-kix_list_5-7;
  }
  ol.lst-kix_list_9-5 {
    list-style-type: none;
  }
  .lst-kix_list_7-8 > li:before {
    content: "" counter(lst-ctn-kix_list_7-8, decimal) ". ";
  }
  ol.lst-kix_list_9-6 {
    list-style-type: none;
  }
  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7;
  }
  ol.lst-kix_list_9-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }
  .lst-kix_list_9-8 > li {
    counter-increment: lst-ctn-kix_list_9-8;
  }
  .lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-1, decimal) ". ";
  }
  .lst-kix_list_4-3 > li:before {
    content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
  }
  .lst-kix_list_4-5 > li:before {
    content: "" counter(lst-ctn-kix_list_4-5, decimal) ". ";
  }
  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }
  .lst-kix_list_10-5 > li {
    counter-increment: lst-ctn-kix_list_10-5;
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5;
  }
  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }
  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0;
  }
  .lst-kix_list_6-4 > li {
    counter-increment: lst-ctn-kix_list_6-4;
  }
  ol.lst-kix_list_9-2.start {
    counter-reset: lst-ctn-kix_list_9-2 0;
  }
  .lst-kix_list_9-3 > li {
    counter-increment: lst-ctn-kix_list_9-3;
  }
  .lst-kix_list_11-2 > li {
    counter-increment: lst-ctn-kix_list_11-2;
  }
  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }
  ol.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-6.start {
    counter-reset: lst-ctn-kix_list_7-6 0;
  }
  ol.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-3 {
    list-style-type: none;
  }
  .lst-kix_list_10-4 > li {
    counter-increment: lst-ctn-kix_list_10-4;
  }
  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0;
  }
  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }
  ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 2;
  }
  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2;
  }
  ol.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0;
  }
  .lst-kix_list_5-1 > li {
    counter-increment: lst-ctn-kix_list_5-1;
  }
  ol.lst-kix_list_3-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-8 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }
  .lst-kix_list_7-1 > li {
    counter-increment: lst-ctn-kix_list_7-1;
  }
  ol.lst-kix_list_11-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }
  .lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, lower-roman) ") ";
  }
  ol.lst-kix_list_11-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-1 {
    list-style-type: none;
  }
  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0;
  }
  .lst-kix_list_8-0 > li {
    counter-increment: lst-ctn-kix_list_8-0;
  }
  .lst-kix_list_10-0 > li {
    counter-increment: lst-ctn-kix_list_10-0;
  }
  .lst-kix_list_3-4 > li:before {
    content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
  }
  .lst-kix_list_3-3 > li:before {
    content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
  }
  ol.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: "" counter(lst-ctn-kix_list_8-0, lower-roman) ". ";
  }
  ol.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: "" counter(lst-ctn-kix_list_8-7, decimal) ". ";
  }
  .lst-kix_list_3-8 > li:before {
    content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
  }
  ol.lst-kix_list_10-7.start {
    counter-reset: lst-ctn-kix_list_10-7 0;
  }
  .lst-kix_list_8-3 > li:before {
    content: "" counter(lst-ctn-kix_list_8-3, decimal) ". ";
  }
  .lst-kix_list_3-7 > li:before {
    content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
  }
  .lst-kix_list_8-4 > li:before {
    content: "" counter(lst-ctn-kix_list_8-4, decimal) ". ";
  }
  .lst-kix_list_10-2 > li {
    counter-increment: lst-ctn-kix_list_10-2;
  }
  ol.lst-kix_list_8-5.start {
    counter-reset: lst-ctn-kix_list_8-5 0;
  }
  .lst-kix_list_11-1 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) "."
      counter(lst-ctn-kix_list_11-1, decimal) ". ";
  }
  .lst-kix_list_11-0 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) ". ";
  }
  ol.lst-kix_list_9-3.start {
    counter-reset: lst-ctn-kix_list_9-3 0;
  }
  .lst-kix_list_8-8 > li:before {
    content: "" counter(lst-ctn-kix_list_8-8, decimal) ". ";
  }
  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-2.start {
    counter-reset: lst-ctn-kix_list_7-2 0;
  }
  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }
  .lst-kix_list_4-8 > li:before {
    content: "" counter(lst-ctn-kix_list_4-8, decimal) ". ";
  }
  .lst-kix_list_4-7 > li:before {
    content: "" counter(lst-ctn-kix_list_4-7, decimal) ". ";
  }
  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0;
  }
  .lst-kix_list_8-4 > li {
    counter-increment: lst-ctn-kix_list_8-4;
  }
  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0;
  }
  ol.lst-kix_list_10-8.start {
    counter-reset: lst-ctn-kix_list_10-8 0;
  }
  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }
  .lst-kix_list_11-3 > li {
    counter-increment: lst-ctn-kix_list_11-3;
  }
  ol.lst-kix_list_7-1.start {
    counter-reset: lst-ctn-kix_list_7-1 0;
  }
  ol.lst-kix_list_8-6.start {
    counter-reset: lst-ctn-kix_list_8-6 0;
  }
  ol.lst-kix_list_8-0.start {
    counter-reset: lst-ctn-kix_list_8-0 4;
  }
  .lst-kix_list_7-0 > li:before {
    content: "" counter(lst-ctn-kix_list_7-0, lower-roman) ". ";
  }
  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2;
  }
  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0;
  }
  ol.lst-kix_list_5-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7;
  }
  ol.lst-kix_list_5-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-7.start {
    counter-reset: lst-ctn-kix_list_9-7 0;
  }
  ol.lst-kix_list_5-2 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
  }
  .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
  }
  .lst-kix_list_6-6 > li {
    counter-increment: lst-ctn-kix_list_6-6;
  }
  .lst-kix_list_7-3 > li:before {
    content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
  }
  .lst-kix_list_10-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_10-0, lower-roman) ") ";
  }
  .lst-kix_list_9-7 > li {
    counter-increment: lst-ctn-kix_list_9-7;
  }
  ol.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-3 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li {
    counter-increment: lst-ctn-kix_list_8-7;
  }
  ol.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0;
  }
  ol.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: "" counter(lst-ctn-kix_list_7-7, decimal) ". ";
  }
  ol.lst-kix_list_8-1.start {
    counter-reset: lst-ctn-kix_list_8-1 0;
  }
  .lst-kix_list_9-5 > li {
    counter-increment: lst-ctn-kix_list_9-5;
  }
  .lst-kix_list_5-8 > li {
    counter-increment: lst-ctn-kix_list_5-8;
  }
  .lst-kix_list_10-4 > li:before {
    content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". ";
  }
  .lst-kix_list_10-8 > li:before {
    content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
  }
  .lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, lower-roman) ". ";
  }
  .lst-kix_list_4-4 > li:before {
    content: "" counter(lst-ctn-kix_list_4-4, decimal) ". ";
  }
  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }
  .lst-kix_list_9-3 > li:before {
    content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
  }
  ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 3;
  }
  ol.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-3 {
    list-style-type: none;
  }
  .lst-kix_list_9-7 > li:before {
    content: "" counter(lst-ctn-kix_list_9-7, decimal) ". ";
  }
  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4;
  }
  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0;
  }
  .lst-kix_list_11-4 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) "."
      counter(lst-ctn-kix_list_11-1, decimal) "."
      counter(lst-ctn-kix_list_11-2, decimal) "."
      counter(lst-ctn-kix_list_11-3, decimal) "."
      counter(lst-ctn-kix_list_11-4, decimal) ". ";
  }
  .lst-kix_list_5-3 > li {
    counter-increment: lst-ctn-kix_list_5-3;
  }
  ol.lst-kix_list_4-8 {
    list-style-type: none;
  }
  .lst-kix_list_7-4 > li {
    counter-increment: lst-ctn-kix_list_7-4;
  }
  .lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
  }
  ol.lst-kix_list_4-4 {
    list-style-type: none;
  }
  .lst-kix_list_11-8 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) "."
      counter(lst-ctn-kix_list_11-1, decimal) "."
      counter(lst-ctn-kix_list_11-2, decimal) "."
      counter(lst-ctn-kix_list_11-3, decimal) "."
      counter(lst-ctn-kix_list_11-4, decimal) "."
      counter(lst-ctn-kix_list_11-5, decimal) "."
      counter(lst-ctn-kix_list_11-6, decimal) "."
      counter(lst-ctn-kix_list_11-7, decimal) "."
      counter(lst-ctn-kix_list_11-8, decimal) ". ";
  }
  ol.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }
  ol.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-4.start {
    counter-reset: lst-ctn-kix_list_8-4 0;
  }
  .lst-kix_list_1-4 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) ". ";
  }
  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0;
  }
  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }
  .lst-kix_list_10-7 > li {
    counter-increment: lst-ctn-kix_list_10-7;
  }
  .lst-kix_list_2-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-0, lower-roman) ") ";
  }
  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }
  ol.lst-kix_list_8-3.start {
    counter-reset: lst-ctn-kix_list_8-3 0;
  }
  .lst-kix_list_11-5 > li {
    counter-increment: lst-ctn-kix_list_11-5;
  }
  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5;
  }
  ol.lst-kix_list_9-8.start {
    counter-reset: lst-ctn-kix_list_9-8 0;
  }
  .lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) "."
      counter(lst-ctn-kix_list_1-5, decimal) "."
      counter(lst-ctn-kix_list_1-6, decimal) "."
      counter(lst-ctn-kix_list_1-7, decimal) "."
      counter(lst-ctn-kix_list_1-8, decimal) ". ";
  }
  .lst-kix_list_8-2 > li {
    counter-increment: lst-ctn-kix_list_8-2;
  }
  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1;
  }
  .lst-kix_list_8-1 > li {
    counter-increment: lst-ctn-kix_list_8-1;
  }
  ol.lst-kix_list_8-2.start {
    counter-reset: lst-ctn-kix_list_8-2 0;
  }
  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0;
  }
  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0;
  }
  .lst-kix_list_7-0 > li {
    counter-increment: lst-ctn-kix_list_7-0;
  }
  .lst-kix_list_11-0 > li {
    counter-increment: lst-ctn-kix_list_11-0;
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  ol.lst-kix_list_9-6.start {
    counter-reset: lst-ctn-kix_list_9-6 0;
  }
  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3;
  }
  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0;
  }
  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }
  ol.lst-kix_list_11-2.start {
    counter-reset: lst-ctn-kix_list_11-2 0;
  }
  .lst-kix_list_5-2 > li {
    counter-increment: lst-ctn-kix_list_5-2;
  }
  .lst-kix_list_9-2 > li {
    counter-increment: lst-ctn-kix_list_9-2;
  }
  ol.lst-kix_list_8-7.start {
    counter-reset: lst-ctn-kix_list_8-7 0;
  }
  .lst-kix_list_10-3 > li {
    counter-increment: lst-ctn-kix_list_10-3;
  }
  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }
  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0;
  }
  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0;
  }
  ol.lst-kix_list_11-7.start {
    counter-reset: lst-ctn-kix_list_11-7 0;
  }
  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }
  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
  }
  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
  }
  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-3.start {
    counter-reset: lst-ctn-kix_list_10-3 0;
  }
  ol.lst-kix_list_9-4.start {
    counter-reset: lst-ctn-kix_list_9-4 0;
  }
  .lst-kix_list_10-1 > li:before {
    content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". ";
  }
  .lst-kix_list_7-7 > li {
    counter-increment: lst-ctn-kix_list_7-7;
  }
  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  .lst-kix_list_10-7 > li:before {
    content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". ";
  }
  .lst-kix_list_7-8 > li {
    counter-increment: lst-ctn-kix_list_7-8;
  }
  .lst-kix_list_10-5 > li:before {
    content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
  }
  li.li-bullet-1:before {
    margin-left: -36pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 36pt;
  }
  .lst-kix_list_10-3 > li:before {
    content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
  }
  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }
  ol.lst-kix_list_7-3.start {
    counter-reset: lst-ctn-kix_list_7-3 0;
  }
  .lst-kix_list_11-7 > li {
    counter-increment: lst-ctn-kix_list_11-7;
  }
  .lst-kix_list_9-2 > li:before {
    content: "" counter(lst-ctn-kix_list_9-2, decimal) ". ";
  }
  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0;
  }
  .lst-kix_list_5-5 > li {
    counter-increment: lst-ctn-kix_list_5-5;
  }
  .lst-kix_list_9-0 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, lower-roman) ". ";
  }
  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4;
  }
  ol.lst-kix_list_10-7 {
    list-style-type: none;
  }
  .lst-kix_list_9-6 > li:before {
    content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
  }
  ol.lst-kix_list_10-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-3 {
    list-style-type: none;
  }
  .lst-kix_list_9-4 > li:before {
    content: "" counter(lst-ctn-kix_list_9-4, decimal) ". ";
  }
  ol.lst-kix_list_10-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-5 {
    list-style-type: none;
  }
  .lst-kix_list_11-3 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) "."
      counter(lst-ctn-kix_list_11-1, decimal) "."
      counter(lst-ctn-kix_list_11-2, decimal) "."
      counter(lst-ctn-kix_list_11-3, decimal) ". ";
  }
  ol.lst-kix_list_10-6 {
    list-style-type: none;
  }
  .lst-kix_list_6-3 > li {
    counter-increment: lst-ctn-kix_list_6-3;
  }
  ol.lst-kix_list_10-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }
  ol.lst-kix_list_10-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }
  .lst-kix_list_11-5 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) "."
      counter(lst-ctn-kix_list_11-1, decimal) "."
      counter(lst-ctn-kix_list_11-2, decimal) "."
      counter(lst-ctn-kix_list_11-3, decimal) "."
      counter(lst-ctn-kix_list_11-4, decimal) "."
      counter(lst-ctn-kix_list_11-5, decimal) ". ";
  }
  ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0;
  }
  .lst-kix_list_9-8 > li:before {
    content: "" counter(lst-ctn-kix_list_9-8, decimal) ". ";
  }
  .lst-kix_list_1-1 > li:before {
    /* content: '' counter(lst-ctn-kix_list_1-0, decimal) '.'
    counter(lst-ctn-kix_list_1-1, decimal) '. '; */
  }
  .lst-kix_list_11-7 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) "."
      counter(lst-ctn-kix_list_11-1, decimal) "."
      counter(lst-ctn-kix_list_11-2, decimal) "."
      counter(lst-ctn-kix_list_11-3, decimal) "."
      counter(lst-ctn-kix_list_11-4, decimal) "."
      counter(lst-ctn-kix_list_11-5, decimal) "."
      counter(lst-ctn-kix_list_11-6, decimal) "."
      counter(lst-ctn-kix_list_11-7, decimal) ". ";
  }
  .lst-kix_list_8-5 > li {
    counter-increment: lst-ctn-kix_list_8-5;
  }
  .lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) ". ";
  }
  ol.lst-kix_list_10-5.start {
    counter-reset: lst-ctn-kix_list_10-5 0;
  }
  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8;
  }
  .lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) "."
      counter(lst-ctn-kix_list_1-5, decimal) "."
      counter(lst-ctn-kix_list_1-6, decimal) "."
      counter(lst-ctn-kix_list_1-7, decimal) ". ";
  }
  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }
  .lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) "."
      counter(lst-ctn-kix_list_1-5, decimal) ". ";
  }
  ol.lst-kix_list_9-1.start {
    counter-reset: lst-ctn-kix_list_9-1 0;
  }
  .lst-kix_list_5-6 > li {
    counter-increment: lst-ctn-kix_list_5-6;
  }
  ol.lst-kix_list_7-5.start {
    counter-reset: lst-ctn-kix_list_7-5 0;
  }
  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
  }
  .lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
  }
  .lst-kix_list_11-8 > li {
    counter-increment: lst-ctn-kix_list_11-8;
  }
  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1;
  }
  .lst-kix_list_9-1 > li {
    counter-increment: lst-ctn-kix_list_9-1;
  }
  ol.lst-kix_list_7-7.start {
    counter-reset: lst-ctn-kix_list_7-7 0;
  }
  .lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
  }
  .lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
  }
  .lst-kix_list_8-1 > li:before {
    content: "" counter(lst-ctn-kix_list_8-1, decimal) ". ";
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  .lst-kix_list_8-2 > li:before {
    content: "" counter(lst-ctn-kix_list_8-2, decimal) ". ";
  }
  .lst-kix_list_6-0 > li {
    counter-increment: lst-ctn-kix_list_6-0;
  }
  .lst-kix_list_3-5 > li:before {
    content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
  }
  ol.lst-kix_list_11-5.start {
    counter-reset: lst-ctn-kix_list_11-5 0;
  }
  .lst-kix_list_8-5 > li:before {
    content: "" counter(lst-ctn-kix_list_8-5, decimal) ". ";
  }
  .lst-kix_list_11-1 > li {
    counter-increment: lst-ctn-kix_list_11-1;
  }
  .lst-kix_list_8-6 > li:before {
    content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
  }
  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }
  .lst-kix_list_3-6 > li:before {
    content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
  }
  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 1;
  }
  .lst-kix_list_11-2 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) "."
      counter(lst-ctn-kix_list_11-1, decimal) "."
      counter(lst-ctn-kix_list_11-2, decimal) ". ";
  }
  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0;
  }
  ol.lst-kix_list_11-6.start {
    counter-reset: lst-ctn-kix_list_11-6 0;
  }
  ol.lst-kix_list_10-1.start {
    counter-reset: lst-ctn-kix_list_10-1 0;
  }
  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4;
  }
  ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0;
  }
  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0;
  }
  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0;
  }
  .lst-kix_list_7-3 > li {
    counter-increment: lst-ctn-kix_list_7-3;
  }
  ol.lst-kix_list_7-8.start {
    counter-reset: lst-ctn-kix_list_7-8 0;
  }
  ol.lst-kix_list_11-0.start {
    counter-reset: lst-ctn-kix_list_11-0 0;
  }
  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3;
  }
  ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0;
  }
  ol.lst-kix_list_10-2.start {
    counter-reset: lst-ctn-kix_list_10-2 0;
  }
  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0;
  }
  .lst-kix_list_6-2 > li {
    counter-increment: lst-ctn-kix_list_6-2;
  }
  .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
  }
  .lst-kix_list_7-1 > li:before {
    content: "" counter(lst-ctn-kix_list_7-1, decimal) ". ";
  }
  .lst-kix_list_7-5 > li:before {
    content: "" counter(lst-ctn-kix_list_7-5, decimal) ". ";
  }
  .lst-kix_list_9-6 > li {
    counter-increment: lst-ctn-kix_list_9-6;
  }
  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0;
  }
  ol.lst-kix_list_11-1.start {
    counter-reset: lst-ctn-kix_list_11-1 0;
  }
  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0;
  }
  .lst-kix_list_6-7 > li {
    counter-increment: lst-ctn-kix_list_6-7;
  }
  .lst-kix_list_10-6 > li {
    counter-increment: lst-ctn-kix_list_10-6;
  }
  .lst-kix_list_11-6 > li {
    counter-increment: lst-ctn-kix_list_11-6;
  }
  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }
  ol.lst-kix_list_10-0.start {
    counter-reset: lst-ctn-kix_list_10-0 0;
  }
  .lst-kix_list_7-5 > li {
    counter-increment: lst-ctn-kix_list_7-5;
  }
  .lst-kix_list_11-4 > li {
    counter-increment: lst-ctn-kix_list_11-4;
  }
  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8;
  }
  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0;
  }
  .lst-kix_list_10-2 > li:before {
    content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
  }
  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6;
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }
  ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0;
  }
  .lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-2, decimal) ". ";
  }
  .lst-kix_list_4-6 > li:before {
    content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
  }
  .lst-kix_list_10-8 > li {
    counter-increment: lst-ctn-kix_list_10-8;
  }
  .lst-kix_list_10-6 > li:before {
    content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
  }
  .lst-kix_list_9-1 > li:before {
    content: "" counter(lst-ctn-kix_list_9-1, decimal) ". ";
  }
  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0;
  }
  .lst-kix_list_9-5 > li:before {
    content: "" counter(lst-ctn-kix_list_9-5, decimal) ". ";
  }
  .lst-kix_list_11-6 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) "."
      counter(lst-ctn-kix_list_11-1, decimal) "."
      counter(lst-ctn-kix_list_11-2, decimal) "."
      counter(lst-ctn-kix_list_11-3, decimal) "."
      counter(lst-ctn-kix_list_11-4, decimal) "."
      counter(lst-ctn-kix_list_11-5, decimal) "."
      counter(lst-ctn-kix_list_11-6, decimal) ". ";
  }
  /* ol.lst-kix_list_11-3.start {
  counter-reset: lst-ctn-kix_list_11-3 0;
}
.lst-kix_list_1-2 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.'
    counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) '. ';
}
.lst-kix_list_10-1 > li {
  counter-increment: lst-ctn-kix_list_10-1;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
} */
  .lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) "."
      counter(lst-ctn-kix_list_1-5, decimal) "."
      counter(lst-ctn-kix_list_1-6, decimal) ". ";
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  ol.lst-kix_list_11-4.start {
    counter-reset: lst-ctn-kix_list_11-4 0;
  }
  .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
  }
  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }

  .c11 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 354.4pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c17 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 113.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c12 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Quattrocento Sans";
    font-style: normal;
  }
  .c29 {
    padding-top: 20pt;
    padding-bottom: 0pt;
    line-height: 1.3;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Quattrocento Sans";
    font-style: normal;
  }
  .c13 {
    margin-left: 36pt;
    padding-top: 24pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt;
  }
  .c10 {
    color: #353744;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Proxima Nova";
    font-style: normal;
  }
  .c25 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.3;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c16 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: "Quattrocento Sans";
    font-style: normal;
  }
  .c0 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Quattrocento Sans";
    font-style: normal;
  }
  .c7 {
    padding-top: 10pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c30 {
    padding-top: 16pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c19 {
    padding-top: 10pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c27 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c18 {
    padding-top: 10pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c28 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.3;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c23 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
    margin: auto;
    margin-top: 10px !important;
  }
  .c26 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: italic;
  }
  .c20 {
    font-family: "Quattrocento Sans";
    color: #000000;
    font-weight: 400;
  }
  .c33 {
    font-family: "Quattrocento Sans";
    color: #000000;
    font-weight: 700;
  }
  .c32 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c9 {
    color: inherit;
    text-decoration: inherit;
  }
  .c31 {
    margin-left: 90pt;
    padding-left: 18pt;
  }
  .c1 {
    padding: 0;
    margin: 0;
  }
  .c35 {
    max-width: 750pt;
    padding: 54pt 72pt 54pt 72pt;
    margin: auto;
  }
  .c8 {
    padding-left: 0pt;
  }
  .c6 {
    margin-left: 72pt;
  }
  .c34 {
    height: 11pt;
  }
  .c24 {
    margin-left: 36pt;
  }
  .c14 {
    height: 0pt;
  }
  .c22 {
    page-break-after: avoid;
  }
  .c21 {
    margin-left: 108pt;
  }
  .c5 {
    /* background-color: #ffff00; */
  }
  .c15 {
    background-color: #ffffff;
  }
  .title {
    padding-top: 16pt;
    color: #353744;
    font-size: 36pt;
    padding-bottom: 0pt;
    font-family: "Proxima Nova";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 13pt;
    padding-bottom: 0pt;
    font-family: "Proxima Nova";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #353744;
    font-size: 11pt;
    font-family: "Proxima Nova";
  }
  p {
    margin: 0;
    color: #353744;
    font-size: 11pt;
    font-family: "Proxima Nova";
  }
  h1 {
    padding-top: 24pt;
    color: #353744;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Proxima Nova";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 16pt;
    color: #00ab44;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Proxima Nova";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 10pt;
    color: #353744;
    font-size: 13pt;
    padding-bottom: 0pt;
    font-family: "Proxima Nova";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 8pt;
    -webkit-text-decoration-skip: none;
    color: #666666;
    text-decoration: underline;
    font-size: 11pt;
    padding-bottom: 0pt;
    line-height: 1.3;
    page-break-after: avoid;
    text-decoration-skip-ink: none;
    font-family: "Trebuchet MS";
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 8pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Trebuchet MS";
    line-height: 1.3;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 8pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Trebuchet MS";
    line-height: 1.3;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .assinatura {
    font-family: "Dancing Script";
    font-size: 28px;
  }

  @media only screen and (max-width: 991px) {
  }
`;
