import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children?: React.ReactNode;
  type: 'old' | 'current' | 'percentage' | 'instalments';
  dangerouslySetInnerHTML?: any;
}

export function Price({ children, type, dangerouslySetInnerHTML }: Props) {
  return (
    <Container type={type} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </Container>
  );
}
