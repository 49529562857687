import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  align: 'left' | 'right' | 'center';
  buy?: boolean;
}

export function H3({ children, align, buy, ...rest }: Props) {
  return (
    <Container align={align} buy={buy} {...rest}>
      {children}
    </Container>
  );
}
