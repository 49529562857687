import React from 'react';
// @ts-ignore
import { Accordion } from 'react-bootstrap';

import { Container, Box, AC } from './styles';

interface subAccordionProps {
  titulo_subgrupo: string;
  texto_subgrupo: string;
}

interface DuvidasProps {
  titulo_grupo: string;
  texto_grupo: string;
  subgrupo: [subAccordionProps];
}

interface Props {
  title: string;
  data: [DuvidasProps];
}

export function Faq({ title, data }: Props) {
  return (
    <>
      <div className="titleAcordiao">{title}</div>

      <Container>
        <div className="accordion">
          {data.map((item, key) => (
            <AC>
              <Accordion>
                <Accordion.Item eventKey={`primary-${key}`}>
                  <Accordion.Header>{item.titulo_grupo}</Accordion.Header>
                  <Accordion.Body>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: item.texto_grupo,
                      }}
                    />
                    {item.subgrupo &&
                      item.subgrupo.map((subItem, key2) => (
                        <AC>
                          <Accordion>
                            <Accordion.Item
                              eventKey={`primary-${key}-subItem-${key2}`}
                            >
                              <Accordion.Header>
                                {subItem.titulo_subgrupo}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: subItem.texto_subgrupo,
                                  }}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </AC>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </AC>
          ))}
        </div>
      </Container>
    </>
  );
}
