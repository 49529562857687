import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
