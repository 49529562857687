import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #fbfbfb;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  img {
    max-width: 300px;
  }

  @media only screen and (max-width: 991px) {
  }
`;
