import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
  form {
    display: flex;
    flex-direction: column;
    padding: 50px;
  }
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    height: auto;
    font-size: 12px;
  }
`;
