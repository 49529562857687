import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background: rgb(0, 32, 91);
  background: linear-gradient(
    180deg,
    rgba(0, 32, 91, 1) 0%,
    rgba(64, 126, 201, 1) 100%
  );
`;
