import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.img`
  width: 24px;

  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;
