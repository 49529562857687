import React, { useState} from 'react';

import { Button } from '../Button';
import { Modal } from '../Modal';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  logo_image: string;
  logo_alt_text: string;
  text_above_button: string;
  modal_text: string;
  button_text: string;
}

export function AdditionalContent({logo_image, logo_alt_text, text_above_button, modal_text, button_text }: Props) {
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState('');

  return (
    <>
      {(logo_image || text_above_button || (modal_text && button_text)) && (
        <Container>
          {logo_image && (
            <div className="image-container">
              <img className="lazyload logo" src="" data-srcset={`${logo_image} 1x`} alt={logo_alt_text} />
            </div>
          )}
          {(text_above_button || (modal_text && button_text)) && (
            <div className="box-button">
              {text_above_button && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: text_above_button,
                  }}
                />
              )}
              {(modal_text && button_text) && (
              <Button
                onClick={() => {
                  setStatusModal(true);
                  setModalContent(
                    modal_text
                  );
                }}
                fill="outlined"
                size="small"
                pattern="base"
              >
                {button_text}
              </Button>
              )}
            </div>
          )}
          <Modal
            open={statusModal}
            setStatusModal={setStatusModal}
            modalContent={modalContent}
          />
        </Container>
      )}
    </>
  );
}
