import React from 'react';

import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <Container className="loading">
      <img src="https://www.medway.com.br/wp-content/themes/medway/dist/assets/loading.gif" alt="Carregando" />
    </Container>
  );
};

export default Loading;
