import styled, { css } from 'styled-components';
import { shade } from 'polished';

// import BG from '../../assets/bg-extensivo.jpg';
interface SectionProps {
  bg?: string;
}

export const Contanner = styled.section<SectionProps>`
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 60px;
  padding-top: 60px;
  padding: 15px;
  width: 100%;

  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}

  img {
    margin-bottom: 30px;
    max-width: 500px;
    width: 100%;
  }
  h1 {
    font-size: 40px;
    font-weight: 700;
    margin-top: 25px;
    max-width: 900px;
    text-align: center;
    width: 100%;
  }
  .box-text {
    font-size: 20px;
    margin-top: 25px;

    p {
      font-weight: 500;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin-top: 50px;
    align-items: center;

    div {
      width: 100%;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
    label {
      width: 100%;
    }

    input {
      background-color: transparent;
      border: none;
      border-bottom: 5px solid rgba(255, 255, 255, 0.25);
      height: 50px;
      color: #fff;
      font-size: 30px;
      transition: all 350ms ease-in-out;
      width: 100%;
      ::-webkit-input-placeholder {
        /* Edge */
        color: rgba(255, 255, 255, 0.25);
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, 0.25);
      }

      ::placeholder {
        color: rgba(255, 255, 255, 0.25);
      }
      &:focus,
      &:active {
        ::-webkit-input-placeholder {
          /* Edge */
          color: rgba(255, 255, 255, 1);
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(255, 255, 255, 1);
        }

        ::placeholder {
          color: rgba(255, 255, 255, 1);
        }
        border-bottom: 5px solid rgba(255, 255, 255, 1);
      }
    }
  }
  button,
  a {
    margin-top: 30px;
    background-color: #ffb100;
    height: 50px;
    max-width: 250px;
    width: 100%;
    border: none;
    box-shadow: none;
    border-radius: 50px;
    font-weight: 700;
    color: #fff;
    transition: all 350ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      background-color: ${shade(0.2, '#ffb100')};
    }
  }
  @media only screen and (max-width: 991px) {
    h1 {
      font-size: 25px;
    }
    form {
      label {
        font-size: 14px;
      }
      input {
        font-size: 17px;
      }
    }
  }
`;
