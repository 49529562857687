import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  type: 'video' | 'text';
}

export function TestemonialDescription({ children, type }: Props) {
  return <Container type={type}>{children}</Container>;
}
