import React, { useState, useEffect, useRef } from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'lazysizes';

import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Title,
  Button,
  Open,
  Partner,
  About,
  Teachers,
  Speeches,
  Testemonials,
  CTA,
} from './styles';

interface Botao {
  title: string;
  url: string;
  target: string;
}

interface Professor {
  imagem_professores: {
    title: string;
    sizes: {
      'all-logo': string;
    };
  };
}

interface iconProfessor {
  imagem_palestra_professor: {
    title: string;
    sizes: {
      'all-logo': string;
    };
  };
}

interface Palestra {
  titulo_palestra: string;
  professores_palestra: [iconProfessor];
}

interface Depoimento {
  texto_depoimento: string;
  nome_depoimento: string;
  evento_depoimento: string;
}
interface Dados {
  eventos_v2: {
    inicial: {
      status_open: boolean;
      background_open: {
        title: string;
        sizes: {
          '1920x1080': string;
        };
      };
      titulo_open: string;
    };
    parceria: {
      status_partner: boolean;
      imagem_partner: {
        title: string;
        sizes: {
          'all-logo': string;
        };
      };
      titulo_partner: string;
      texto_partner: string;
      botao_partner: Botao;
    };
    sobre: {
      status_about: boolean;
      titulo_about: string;
      texto_about: string;
      status_video_about: boolean;
      video_about: string;
      imagem_about: {
        title: string;
        sizes: {
          '1920x1080': string;
        };
      };
    };
    professores: {
      status_teachers: boolean;
      titulo_teachers: string;
      slider_teachers: [Professor];
    };
    palestras: {
      status_palestras: boolean;
      itens_palestras: [Palestra];
    };
    depoimento: {
      status_depoimento: boolean;
      titulo_depoimento: string;
      depoimentos: [Depoimento];
    };
    cta: {
      status_cta: boolean;
      botao_cta: Botao;
    };
  };
}

const Eventos: React.FC = () => {
  const [dados, setDados] = useState<Dados | null>(null);
  const CTARef = useRef();

  useEffect(() => {
    api.get(`acf/v3/pages/7899`).then(response => {
      setDados(response.data.acf);
    });
  }, []);

  function handleScroll() {
    CTARef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      {dados ? (
        <>
          {dados.eventos_v2.inicial.status_open && (
            <Open
              bg={dados.eventos_v2.inicial.background_open.sizes['1920x1080']}
            >
              <Container>
                <div className="box-content">
                  <h1>{dados.eventos_v2.inicial.titulo_open}</h1>
                </div>
              </Container>
            </Open>
          )}

          {dados.eventos_v2.parceria.status_partner && (
            <Partner>
              <Container>
                <div className="box-elements">
                  {dados.eventos_v2.parceria.imagem_partner && (
                    <div className="box-image">
                      <img
                        src={
                          dados.eventos_v2.parceria.imagem_partner.sizes[
                            'all-logo'
                          ]
                        }
                        alt={dados.eventos_v2.parceria.imagem_partner.title}
                        className="image"
                      />
                    </div>
                  )}
                  <div
                    className={
                      dados.eventos_v2.parceria.imagem_partner
                        ? 'box-content'
                        : 'box-content box-content__no-image'
                    }
                  >
                    <h2 className="title">
                      {dados.eventos_v2.parceria.titulo_partner}
                    </h2>
                    <div
                      className="box-texts"
                      dangerouslySetInnerHTML={{
                        __html: dados.eventos_v2.parceria.texto_partner,
                      }}
                    />
                    <Button
                      className={
                        dados.eventos_v2.parceria.imagem_partner
                          ? 'box-button'
                          : 'box-button box-button__no-image'
                      }
                    >
                      <button
                        onClick={handleScroll}
                        type="button"
                        title={dados.eventos_v2.parceria.botao_partner.title}
                      >
                        {dados.eventos_v2.parceria.botao_partner.title}
                      </button>
                    </Button>
                  </div>
                </div>
              </Container>
            </Partner>
          )}

          {dados.eventos_v2.sobre.status_about && (
            <About>
              <Container>
                <div className="box-elements">
                  <div className="box-content">
                    <Title className="box-title" colorType="dark" type="one">
                      <h2>{dados.eventos_v2.sobre.titulo_about}</h2>
                    </Title>
                    <div
                      className="box-texts"
                      dangerouslySetInnerHTML={{
                        __html: dados.eventos_v2.sobre.texto_about,
                      }}
                    />
                  </div>
                  {dados.eventos_v2.sobre.status_video_about ? (
                    <div
                      className="box-video"
                      dangerouslySetInnerHTML={{
                        __html: dados.eventos_v2.sobre.video_about,
                      }}
                    />
                  ) : (
                    <div className="box-image">
                      <img
                        className="lazyload image"
                        src=""
                        data-srcset={`${
                          dados.eventos_v2.sobre.imagem_about.sizes['1920x1080']
                        } 1x`}
                        alt={dados.eventos_v2.sobre.imagem_about.title}
                      />
                    </div>
                  )}
                </div>
              </Container>
            </About>
          )}

          {dados.eventos_v2.professores.status_teachers && (
            <Teachers>
              <Container>
                <Title colorType="base" type="two" className="box-title">
                  <h2>{dados.eventos_v2.professores.titulo_teachers}</h2>
                </Title>

                <div className="box-slider">
                  <OwlCarousel
                    className="owl-theme"
                    responsive={{
                      0: {
                        items: 1,
                        margin: 50,
                        loop: true,
                        autoplay: false,
                        autoplayTimeout: 3500,
                        nav: true,
                      },
                      991: {
                        items: 3,
                        margin: 15,
                        loop: true,
                        autoplay: false,
                        autoplayTimeout: 3500,
                        nav: true,
                      },
                    }}
                  >
                    {dados.eventos_v2.professores.slider_teachers.map(item => (
                      <div className="item">
                        <img
                          className="lazyload image"
                          src=""
                          data-srcset={`${item.imagem_professores.sizes['all-logo']} 1x`}
                          alt={item.imagem_professores.title}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </Container>
            </Teachers>
          )}

          {dados.eventos_v2.palestras.status_palestras && (
            <Speeches>
              <Container>
                <div className="box-table">
                  <div className="line line__title">
                    <div className="column">
                      <h4>Temas de Palestra</h4>
                      <h4 className="mobile">Temas de Palestra / Professor</h4>
                    </div>
                    <div className="column column__images">
                      <h4>Professor</h4>
                    </div>
                  </div>
                  {dados.eventos_v2.palestras.itens_palestras.map(item => (
                    <div className="line line__item">
                      <div className="column">
                        <h3>{item.titulo_palestra}</h3>
                      </div>
                      <div className="column column__images">
                        {item.professores_palestra.map(teacher => (
                          <img
                            className="lazyload image"
                            src=""
                            data-srcset={`${
                              teacher.imagem_palestra_professor.sizes[
                                'all-logo'
                              ]
                            } 1x`}
                            alt={teacher.imagem_palestra_professor.title}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Container>
            </Speeches>
          )}

          {dados.eventos_v2.depoimento.status_depoimento && (
            <Testemonials>
              <Container>
                <Title colorType="base" type="one">
                  <h2>{dados.eventos_v2.depoimento.titulo_depoimento}</h2>
                </Title>

                <div className="box-slider">
                  <OwlCarousel
                    className="owl-theme"
                    responsive={{
                      0: {
                        items: 1,
                        margin: 50,
                        loop: true,
                        autoplay: false,
                        autoplayTimeout: 3500,
                      },
                      991: {
                        items: 2,
                        margin: 50,
                        loop: true,
                        autoplay: false,
                        autoplayTimeout: 3500,
                      },
                    }}
                  >
                    {dados.eventos_v2.depoimento.depoimentos.map(item => (
                      <div className="item">
                        <div className="box-content">
                          <div
                            className="box-texts"
                            dangerouslySetInnerHTML={{
                              __html: item.texto_depoimento,
                            }}
                          />
                          <h3 className="name">{item.nome_depoimento}</h3>
                          <span className="event">
                            {item.evento_depoimento}
                          </span>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </Container>
            </Testemonials>
          )}

          {dados.eventos_v2.cta.status_cta && (
            <CTA ref={CTARef}>
              <Container>
                <Button className="box-button">
                  <a
                    href={dados.eventos_v2.cta.botao_cta.url}
                    target={dados.eventos_v2.cta.botao_cta.target}
                    title={dados.eventos_v2.cta.botao_cta.title}
                  >
                    {dados.eventos_v2.cta.botao_cta.title}
                  </a>
                </Button>
              </Container>
            </CTA>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Eventos;
