import axios from 'axios';

const hostName = window.location.hostname;
let url = 'https://fmedway.com.br/wp-json/';

switch (hostName) {
  case 'medway.com.br':
  case 'www.medway.com.br':
    url = 'https://medway.com.br/wp-json/';
    break;
  default:
    break;
}

const api = axios.create({
  baseURL: url,
});

export default api;
