import React, { ButtonHTMLAttributes } from 'react';

// Styles
import { Container } from './styles';

// Interface
interface AnchorProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  menuRef: any;
  setMenuMobile: any;
}

export function Anchor({
  children,
  menuRef,
  setMenuMobile,
  ...rest
}: AnchorProps) {
  const scrollTo = (e: any) => window.scrollTo(0, e.current.offsetTop - 50);

  return (
    <Container
      onClick={() => {
        scrollTo(menuRef);
        setMenuMobile(false);
      }}
      {...rest}
    >
      {children}
    </Container>
  );
}
