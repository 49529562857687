import styled, { css } from 'styled-components';
import { shade } from 'polished';

// Assets

// import BG from '../../assets/bg-extensivo.jpg';

// Interface

interface SectionProps {
  bg?: string;
}

interface ModalProps {
  open: boolean;
}

export const Container = styled.section<SectionProps>`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  color: #fff;
  padding: 15px;

  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}

  img {
    max-width: 500px;
    width: 100%;
  }
  h1 {
    font-size: 40px;
    max-width: 900px;
    width: 100%;
    text-align: center;
  }
  form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin-top: 50px;
    align-items: center;

    label {
      width: 100%;
    }

    input {
      background-color: transparent;
      border: none;
      border-bottom: 5px solid rgba(255, 255, 255, 0.25);
      height: 50px;
      color: #fff;
      font-size: 30px;
      transition: all 350ms ease-in-out;
      width: 100%;
      ::-webkit-input-placeholder {
        /* Edge */
        color: rgba(255, 255, 255, 0.25);
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, 0.25);
      }

      ::placeholder {
        color: rgba(255, 255, 255, 0.25);
      }
      &:focus,
      &:active {
        ::-webkit-input-placeholder {
          /* Edge */
          color: rgba(255, 255, 255, 1);
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(255, 255, 255, 1);
        }

        ::placeholder {
          color: rgba(255, 255, 255, 1);
        }
        border-bottom: 5px solid rgba(255, 255, 255, 1);
      }
    }
  }
  button,
  a {
    margin-top: 30px;
    background-color: #ffb100;
    height: 50px;
    max-width: 250px;
    width: 100%;
    border: none;
    box-shadow: none;
    border-radius: 50px;
    font-weight: 700;
    color: #fff;
    transition: all 350ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      background-color: ${shade(0.2, '#ffb100')};
    }
  }
  @media only screen and (max-width: 991px) {
    h1 {
      font-size: 25px;
    }
    form {
      label {
        font-size: 14px;
      }
      input {
        font-size: 17px;
      }
    }
  }
`;

export const ModalContainer = styled.div<ModalProps>`
  display: flex;
  align-items: center;
  background: rgba(0, 1, 34, 0.5);
  bottom: 0;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;

  ${({ open }) =>
    open
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

export const ModalContent = styled.section`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 2px rgba(0, 32, 91, 0.15);
  max-width: 560px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  position: relative;

  @media (orientation: portrait) {
    max-width: 280px;
    padding-bottom: 20px;
    padding-top: 65px;
  }
`;

export const ModalBoxText = styled.div`
  max-height: 40vh;
  /* overflow: scroll; */
  padding-right: 0;

  @media (orientation: portrait) {
    max-height: 50vh;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track:enabled {
    background-color: #dadada;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb:vertical {
    background-color: #0f0c6d;
    border-radius: 30px;
  }

  h2 {
    color: #d70000;
    font-weight: bold;
    text-align: center;
  }

  p {
    color: #414141;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 8px;

    @media (orientation: portrait) {
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
