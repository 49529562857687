import React, { useState, useEffect } from 'react';

import { BoxCards } from '../../components/Guides/BoxCards';
import { BoxChamadaPrincipal } from '../../components/Guides/BoxChamadaPrincipal';

import { Container } from './styles';
import { Redes } from '../../components/Guides/Redes';

import api from '../../services/api';
import { isProduction } from '../../hook/isProduction';
import Loading from '../../components/Loading';
import AddressAndPolicies from '../../components/AddressAndPolicies';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

interface ButtonProps {
  title: string;
  url: string;
  target: string;
}

interface ImageProps {
  alt: string;
  url: string;
}

interface CardsProps {
  botao: ButtonProps;
  imagem: ImageProps;
  titulo: string;
}

interface DataProps {
  botao: ButtonProps;
  paragrafo: string;
  titulo: string;
  titulo_2: string;
  cards: CardsProps[];
  mockup: ImageProps;
}

export function Guides() {
  const [data, setData] = useState<DataProps | null>();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    api.get(`/wp/v2/pages/${isProduction() ? 10117 : 8461}`).then(response => {
      setData(response.data.acf.guias);
    });

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return data ? (
    <Container>
      <BoxChamadaPrincipal
        title={data?.titulo}
        describe={data?.paragrafo}
        button={data?.botao}
        photo={data?.mockup.url}
        mobile={windowDimensions.width <= 992}
      />
      <BoxCards
        id="ebooks"
        title={data?.titulo_2}
        cards={data?.cards}
        options={{
          items: windowDimensions.width <= 992 ? 1 : 4,
          nav: windowDimensions.width > 992,
          rewind: true,
          autoplay: true,
          margin: 15,
          touchDrag: true,
          mouseDrag: true,
          stagePadding: windowDimensions.width <= 992 ? 50 : 0,
        }}
      />

      <Redes />
      <AddressAndPolicies background = "transparent"/>
    </Container>
  ) : (
    <Loading />
  );
}
