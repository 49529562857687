import React from 'react';

// Assets
import { Container } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  bottom: string;
  top: string;
}

export function TitleTimeline({ children, bottom, top }: Props) {
  return (
    <Container bottom={bottom} top={top}>
      {children}
    </Container>
  );
}
