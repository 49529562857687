
import React from 'react';
// @ts-ignore
import OwlCarousel from 'react-owl-carousel2';

import 'lazysizes';

import { Container } from './styles';

interface App {
  imagem_app: {
    title: string;
    url: string;
  };
  descricao_app: string;
}

interface Props {
  carouselApp: [App];
}

const CarouselAplicativo: React.FC<Props> = ({ carouselApp }: Props) => {
  return (
    <Container>
      {carouselApp && (
        <OwlCarousel
          options={{
            items: 1,
            nav: false,
            rewind: true,
            autoplay: true,
            loop: true,
          }}
        >
          {carouselApp.map((item, key) => {
            return (
              <div className="item" key={`carouselApp-${key}`}>
                <img
                  className="lazyload image"
                  src=""
                  data-srcset={`${item.imagem_app.url} 1x`}
                  alt={item.imagem_app.title}
                />
                <p className="text">{item.descricao_app}</p>
              </div>
            );
          })}
        </OwlCarousel>
      )}
    </Container>
  );
};

export default CarouselAplicativo;
