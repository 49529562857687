import styled from 'styled-components';

// Assets

// Interface
interface Props {
  align: 'left' | 'center' | 'right';
  bottom: string;
  top: string;
  size: string;
  width: string;
}

export const Container = styled.p<Props>`
  color: #414141;
  font-size: ${({ size }) => size};
  font-weight: 500;
  margin: ${({ top }) => top} auto ${({ bottom }) => bottom};
  max-width: ${({ width }) => width};
  padding-left: 80px;
  padding-right: 80px;
  text-align: ${({ align }) => align};
  width: 100%;

  @media (orientation: portrait) {
    font-size: 14px;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto 40px;
  }
`;
