import React from 'react';

import 'lazysizes';

// Styles
import {
  Container,
  BoxContent,
  BoxLogos,
  Logo,
  BoxDates,
  StartDate,
  EndDate,
} from './styles';

// Interface
interface LogosProps {
  src_logo: {
    title: string;
    url: string;
  };
  alt?: string;
}

interface Props {
  logos: LogosProps[];
  startDate: string;
  endDate: string;
}

export function CourseDataTimeline({ logos, startDate, endDate }: Props) {
  return (
    <Container>
      <BoxContent>
        <BoxLogos>
          {logos.map((logo, key) => (
            <Logo className="lazyload" src="" data-srcset={`${logo.src_logo.url} 1x`} alt={logo.alt} key={key} />
          ))}
        </BoxLogos>
        <BoxDates>
          <StartDate
              dangerouslySetInnerHTML={{
                __html: startDate,
            }}
            />

          <EndDate
              dangerouslySetInnerHTML={{
                __html: endDate,
              }}
            />
        </BoxDates>
      </BoxContent>
    </Container>
  );
}
