import styled, { css } from 'styled-components';

// Assets
import flagPromo from '../../../assets/flag-promo.svg';

// Interface
interface Props {
  labelColor?: 'blue' | 'green';
  align: 'left' | 'right' | 'center';
  buy?: boolean;
  flag?: boolean;
  height?: string;
}
interface LabelProps {
  labelColor?: 'blue' | 'green';
}

export const Container = styled.article<Props>`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 2px rgba(59, 63, 182, 0.4);
  height: ${({ height }) => height};
  max-width: 395px;
  padding: 28px;
  position: relative;
  text-align: ${({ align }) => align};

  ${({ labelColor }) =>
    labelColor &&
    css`
      padding-top: 58px;
    `}

  ${({ buy, flag }) =>
    buy &&
    css`
      min-width: 600px;
      padding-bottom: 48px;
      padding-left: 84px;
      padding-right: 84px;
      padding-top: ${flag ? '128px' : '48px'};

      @media (orientation: portrait) {
        min-width: 280px;
        padding-bottom: 30px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: ${flag ? '60px' : '30px'};
      }
    `}

  ${({ flag }) =>
    flag &&
    css`
      position: relative;

      &:before {
        background-image: url(${flagPromo});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 80px;
        left: -33px;
        position: absolute;
        top: 30px;
        width: 260px;

        @media (orientation: portrait) {
          height: 43px;
          left: -25px;
          top: 20px;
          width: 154px;
        }
      }
    `}
`;

export const Label = styled.span<LabelProps>`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  left: 0;
  padding-bottom: 6px;
  padding-top: 6px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;

  ${({ labelColor }) =>
    labelColor === 'blue'
      ? css`
          background-color: #3b3fb6;
        `
      : css`
          background-color: #00cfb4;
        `}

  @media (orientation: portrait) {
    font-size: 12px;
  }
`;
