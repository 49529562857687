import ScrollableAnchor from 'react-scrollable-anchor';
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'lazysizes';

import api from '../../services/api';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import AddressAndPolicies from '../../components/AddressAndPolicies';

import {
  PrimeiraSecao,
  SegundaSecao,
  TerceiraSecao,
  QuartaSecao,
  QuintaSecao,
  SextaSecao,
  SetimaSecao,
  Diferencas,
} from './styles';

import Stars from '../../assets/stars.svg';
import SimCheck from '../../assets/check.svg';
import Nao_Close from '../../assets/close.svg';

interface Conteudos {
  titulo: string;
  paragrafo: string;
}

interface Botao {
  title: string;
  url: string;
  target: string;
}

interface Downloads {
  icone: {
    title: string;
    sizes: {
      thumbnail: string;
    };
  };
  botao: Botao;
}

interface Itens {
  icone: {
    title: string;
    sizes: {
      thumbnail: string;
    };
  };
  titulo: string;
  paragrafo: string;
  imagem: {
    title: string;
    sizes: {
      '1000x700': string;
    };
  };
}

interface Depoimentos {
  avatar: {
    title: string;
    sizes: {
      thumbnail: string;
    };
  };
  paragrafo: string;
  nome: string;
  aprovacao_ou_ano_atual: string;
}

interface Diferenca {
  topico: string;
  intensivo: {
    ativar_intensivo: boolean;
    tipo_de_campo: string;
    sim_ou_nao: [] | string;
    texto: string;
  };
  extensivo: {
    ativar_extensivo: boolean;
    tipo_de_campo: string;
    sim_ou_nao: [] | string;
    texto: string;
  };
  versao_gratuita: {
    ativar_versao_gratuita: boolean;
    tipo_de_campo: string;
    sim_ou_nao: [] | string;
    texto: string;
  };
}

interface Dados {
  primeira_secao: {
    ativar: boolean;
    background: {
      title: string;
      sizes: {
        '1920x1080': string;
      };
    };
    logo: {
      title: string;
      sizes: {
        '500x100': string;
      };
    };
    ativarVideoImagem2: string;
    video: string;
    imagem: {
      title: string;
      sizes: {
        '1000x700': string;
      };
    };
    titulo: string;
    paragrafo: string;
  };
  segunda_secao: {
    ativar: boolean;
    background: {
      title: string;
      sizes: {
        '1920x1080': string;
      };
    };
    titulo: string;
    paragrafo: string;
    botoes: [Downloads];
  };
  terceira_secao: {
    background: {
      title: string;
      sizes: {
        '1920x1080': string;
      };
    };
    ativar: boolean;
    conteudos: [Conteudos];
    ativarVideoImagem: string;
    video: string;
    imagem: {
      title: string;
      sizes: {
        '1000x700': string;
      };
    };
  };
  quarta_secao: {
    ativar: boolean;
    itens: [Itens];
  };
  quinta_secao: {
    ativar: boolean;
    botoes: [
      {
        botao: Botao;
      }
    ];
  };
  sexta_secao: {
    ativar: boolean;
    nota_do_app: string;
    paragrafo: string;
    depoimentos: [Depoimentos];
  };
  setima_secao: {
    ativar: boolean;
    imagem: {
      title: string;
      sizes: {
        '1000x700': string;
      };
    };
    paragrafo: string;
  };
  oitava_secao: {
    ativar: boolean;
    botao: {
      title: string;
      url: string;
      target: string;
    };
  };
  diferencas: {
    ativar: boolean;
    titulo: string;
    background: {
      title: string;
      sizes: {
        '1920x1080': string;
      };
    };
    logo_extensivo: {
      title: string;
      sizes: {
        '1000x700': string;
      };
    };
    logo_intensivo: {
      title: string;
      sizes: {
        '1000x700': string;
      };
    };
    logo_versao_gratuita: {
      title: string;
      sizes: {
        '1000x700': string;
      };
    };
    itens: [Diferenca];
  };
}

const getItensCarousel = (dados: any) => {
  let PX: number = 900;
  let itens: number = 3;

  const column1 = dados?.diferencas.logo_extensivo;
  const column2 = dados?.diferencas.logo_intensivo;
  const column3 = dados?.diferencas.logo_versao_gratuita;

  if (column1 === false) {
    PX -= 300;
    itens -= 1;
  }

  if (column2 === false) {
    PX -= 300;
    itens -= 1;
  }

  if (column3 === false) {
    PX -= 300;
    itens -= 1;
  }

  return { PX, itens };
};

const getConfigurableProps = () => ({
  showArrows: false,
  showStatus: false,
  showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: false,
  emulateTouch: true,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 7000,
  transitionTime: 150,
  swipeScrollTolerance: 5,
});

const Aplicativo: React.FC = () => {
  const [dados, setDados] = useState<Dados | null>(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    setSearch(queryString);

    api.get(`acf/v3/pages/5370`).then(response => {
      setDados(response.data.acf);
    });

    // api.get(`acf/v3/pages/4164`).then(response => {
    //   setDados(response.data.acf);
    // });
  }, []);

  return (
    <>
      {dados && (
        <>
          {dados.primeira_secao.ativar && (
            <PrimeiraSecao
              bg={dados?.primeira_secao.background.sizes['1920x1080']}
            >
              <img
                className="logo"
                src={dados?.primeira_secao.logo.sizes['500x100']}
                alt={dados?.primeira_secao.logo.title}
              />
              <div
                className="titulo"
                dangerouslySetInnerHTML={{
                  __html: dados?.primeira_secao.titulo,
                }}
              />
              <div
                className="paragrafo"
                dangerouslySetInnerHTML={{
                  __html: dados?.primeira_secao.paragrafo,
                }}
              />
              <div className="box-video">
                {dados?.primeira_secao.ativarVideoImagem2 === 'video' ? (
                  <div
                    className="video-responsive"
                    dangerouslySetInnerHTML={{
                      __html: dados?.primeira_secao?.video,
                    }}
                  />
                ) : (
                  <div className="video-responsive">
                    <img
                      src={dados?.primeira_secao.imagem.sizes['1000x700']}
                      alt={dados?.primeira_secao.imagem.title}
                    />
                  </div>
                )}
              </div>
            </PrimeiraSecao>
          )}

          {dados.segunda_secao.ativar && (
            <ScrollableAnchor id="download">
              <SegundaSecao
                bg={dados?.segunda_secao.background.sizes['1920x1080']}
              >
                <div
                  className="titulo"
                  dangerouslySetInnerHTML={{
                    __html: dados?.segunda_secao.titulo,
                  }}
                />
                <div
                  className="paragrafo"
                  dangerouslySetInnerHTML={{
                    __html: dados?.segunda_secao.paragrafo,
                  }}
                />
                <div className="box-buttom-download">
                  {dados.segunda_secao.botoes.map(botao => (
                    <a
                      key={botao.botao.title}
                      href={botao.botao.url + search}
                      target={botao.botao.target}
                    >
                      <div className="buttom-download">
                        <img
                          className="lazyload"
                          src=""
                          data-srcset={`${botao.icone.sizes.thumbnail} 1x`}
                          alt={botao.icone.title}
                        />
                        <p>{botao.botao.title}</p>
                      </div>
                    </a>
                  ))}
                </div>
              </SegundaSecao>
            </ScrollableAnchor>
          )}

          {dados.terceira_secao.ativar && (
            <TerceiraSecao
              bg={dados?.terceira_secao.background.sizes['1920x1080']}
            >
              <div className="box-conteudo">
                {dados.terceira_secao.conteudos.map(conteudo => (
                  <div>
                    <div
                      className="titulo"
                      dangerouslySetInnerHTML={{
                        __html: conteudo.titulo,
                      }}
                    />
                    <div
                      className="paragrafo"
                      dangerouslySetInnerHTML={{
                        __html: conteudo.paragrafo,
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="box-video">
                {dados?.terceira_secao.ativarVideoImagem === 'video' ? (
                  <div
                    className="video-responsive"
                    dangerouslySetInnerHTML={{
                      __html: dados?.terceira_secao?.video,
                    }}
                  />
                ) : (
                  <div className="video-responsive">
                    <img
                      className="lazyload"
                      src=""
                      data-srcset={`${dados?.terceira_secao.imagem.sizes['1000x700']} 1x`}
                      alt={dados?.terceira_secao.imagem.title}
                    />
                  </div>
                )}
              </div>
            </TerceiraSecao>
          )}

          {dados.diferencas.ativar && (
            <Diferencas bg={dados?.diferencas.background.sizes['1920x1080']}>
              <div
                className="titulo-section"
                dangerouslySetInnerHTML={{
                  __html: dados?.diferencas.titulo,
                }}
              />
              <div className="container">
                <div className="column">
                  <div className="logo">a</div>
                  {dados?.diferencas.itens.map(item => (
                    <div className="row">
                      <div className="topico">{item.topico}</div>
                    </div>
                  ))}
                </div>
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  responsive={{
                    0: {
                      items: 1,
                      margin: 30,
                      loop: true,
                      autoplay: true,
                      autoplayTimeout: 2000,
                    },
                    991: {
                      items: getItensCarousel(dados).itens,
                    },
                  }}
                  style={{ maxWidth: getItensCarousel(dados).PX }}
                >
                  {dados?.diferencas.logo_extensivo && (
                    <div className="column">
                      <div className="logo">
                        <img
                          className="lazyload"
                          src=""
                          data-srcset={`${dados?.diferencas.logo_extensivo.sizes['1000x700']} 1x`}
                          alt=""
                        />
                      </div>
                      {dados?.diferencas.itens.map(item => (
                        <>
                          {item.extensivo.ativar_extensivo && (
                            <div className="box">
                              {item.extensivo.tipo_de_campo === 'texto' ? (
                                <div
                                  className="titulo"
                                  dangerouslySetInnerHTML={{
                                    __html: item.extensivo.texto,
                                  }}
                                />
                              ) : (
                                <>
                                  {item.extensivo.sim_ou_nao === 'sim' ? (
                                    <img className="lazyload" src="" data-srcset={`${SimCheck} 1x`} alt="" />
                                  ) : (
                                    <img className="lazyload" src="" data-srcset={`${Nao_Close} 1x`} alt="" />
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                  {dados?.diferencas.logo_intensivo && (
                    <div className="column">
                      <div className="logo">
                        <img
                          className="lazyload"
                          src=""
                          data-srcset={`${dados?.diferencas.logo_intensivo.sizes['1000x700']} 1x`}
                          alt=""
                        />
                      </div>
                      {dados?.diferencas.itens.map(item => (
                        <>
                          {item.intensivo.ativar_intensivo && (
                            <div className="box">
                              {item.intensivo.tipo_de_campo === 'texto' ? (
                                <div
                                  className="titulo"
                                  dangerouslySetInnerHTML={{
                                    __html: item.intensivo.texto,
                                  }}
                                />
                              ) : (
                                <>
                                  {item.intensivo.sim_ou_nao === 'sim' ? (
                                    <img className="lazyload" src="" data-srcset={`${SimCheck} 1x`} alt="" />
                                  ) : (
                                    <img className="lazyload" src="" data-srcset={`${Nao_Close} 1x`} alt="" />
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                  {dados?.diferencas.logo_versao_gratuita && (
                    <div className="column">
                      <div className="logo">
                        <img
                          className="lazyload"
                          src=""
                          data-srcset={`${dados?.diferencas.logo_versao_gratuita.sizes['1000x700']} 1x`}
                          alt=""
                        />
                      </div>
                      {dados?.diferencas.itens.map(item => (
                        <>
                          {item.versao_gratuita.ativar_versao_gratuita && (
                            <div className="box">
                              {item.versao_gratuita.tipo_de_campo ===
                              'texto' ? (
                                <div
                                  className="titulo"
                                  dangerouslySetInnerHTML={{
                                    __html: item.versao_gratuita.texto,
                                  }}
                                />
                              ) : (
                                <>
                                  {item.versao_gratuita.sim_ou_nao === 'sim' ? (
                                    <img className="lazyload" src="" data-srcset={`${SimCheck} 1x`} alt="" />
                                  ) : (
                                    <img className="lazyload" src="" data-srcset={`${Nao_Close} 1x`} alt="" />
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </OwlCarousel>
              </div>
            </Diferencas>
          )}

          {dados.quarta_secao.ativar && (
            <section className="pilar">
              {dados.quarta_secao.itens.map(item => (
                <QuartaSecao key={item.titulo}>
                  <div>
                    <img
                      className="lazyload item-img"
                      src=""
                      data-srcset={`${item.imagem.sizes['1000x700']} 1x`}
                      alt={item.imagem.title}
                    />
                  </div>
                  <div className="box-conteudo">
                    <img
                      className="lazyload icone"
                      src=""
                      data-srcset={`${item.icone.sizes.thumbnail} 1x`}
                      alt={item.icone.title}
                    />
                    <div
                      className="titulo"
                      dangerouslySetInnerHTML={{
                        __html: item.titulo,
                      }}
                    />
                    <div
                      className="paragrafo"
                      dangerouslySetInnerHTML={{
                        __html: item.paragrafo,
                      }}
                    />
                  </div>
                </QuartaSecao>
              ))}
            </section>
          )}

          {dados.quinta_secao.ativar && (
            <QuintaSecao>
              {dados.quinta_secao.botoes.map(botao => (
                <a
                  className="btn-primario"
                  href={botao.botao.url + search}
                  target={botao.botao.target}
                >
                  {botao.botao.title}
                </a>
              ))}
            </QuintaSecao>
          )}

          {dados.sexta_secao.ativar && (
            <SextaSecao>
              <div className="stars">
                <img className="lazyload" src="" data-srcset={`${Stars} 1x`} alt="" />
              </div>
              <div className="nota">
                <div
                  className="titulo"
                  dangerouslySetInnerHTML={{
                    __html: dados.sexta_secao.nota_do_app,
                  }}
                />
                <p>{dados.sexta_secao.paragrafo}</p>
              </div>

              <Carousel {...getConfigurableProps()}>
                {dados.sexta_secao.depoimentos.map(depoimento => (
                  <div className="item">
                    <img
                      className="lazyload"
                      src=""
                      data-srcset={`${depoimento.avatar.sizes.thumbnail} 1x`}
                      alt={depoimento.avatar.title}
                    />
                    <div
                      className="paragrafo"
                      dangerouslySetInnerHTML={{
                        __html: depoimento.paragrafo,
                      }}
                    />
                    <div
                      className="nome"
                      dangerouslySetInnerHTML={{
                        __html: depoimento.nome,
                      }}
                    />
                    <div
                      className="aprovacao_ou_ano_atual"
                      dangerouslySetInnerHTML={{
                        __html: depoimento.aprovacao_ou_ano_atual,
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </SextaSecao>
          )}

          {dados.setima_secao.ativar && (
            <SetimaSecao>
              <img
                className="lazyload"
                src=""
                data-srcset={`${dados.setima_secao.imagem.sizes['1000x700']} 1x`}
                alt={dados.setima_secao.imagem.title}
              />
              <div
                className="paragrafo"
                dangerouslySetInnerHTML={{
                  __html: dados.setima_secao.paragrafo,
                }}
              />
            </SetimaSecao>
          )}

          {dados.oitava_secao.ativar && (
            <QuintaSecao>
              <a
                className="btn-primario"
                href={dados.oitava_secao.botao.url + search}
                target={dados.oitava_secao.botao.target}
              >
                {dados.oitava_secao.botao.title}
              </a>
            </QuintaSecao>
          )}
          <AddressAndPolicies background = '#0f247b' />
        </>
      )}
    </>
  );
};

export default Aplicativo;
