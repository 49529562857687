import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface MenuProps {
  children: React.ReactNode;
}

export function Menu({ children }: MenuProps) {
  return <Container>{children}</Container>;
}
