import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  padding: 15px;
`;

export const Hero = styled.section`
  background-color: #ffffff;
  padding-bottom: 62px;
  padding-top: 138px;

  @media (orientation: portrait) {
    padding-bottom: 15px;
    padding-top: 118px;
  }

  .title {
    color: #0f247b;
    font-size: 50px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 12px;

    @media (orientation: portrait) {
      font-size: 28px;
      margin-bottom: 8px;
    }
  }

  .description {
    color: #414141;
    font-size: 24px;
    font-weight: 400;
    margin: 0;

    @media (orientation: portrait) {
      font-size: 14px;
    }
  }
`;

export const Numbers = styled.section`
  background-color: #ffffff;

  .box-numbers {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #c4c4c4;
    justify-content: space-between;
    padding-bottom: 48px;

    @media (orientation: portrait) {
      flex-direction: column;
      padding-bottom: 16px;
    }

    .number {
      position: relative;

      @media (orientation: portrait) {
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 16px;
        padding-top: 16px;
        width: 100%;
      }

      &:after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #c4c4c4;
        position: absolute;
        top: 0;
        right: -140px;

        @media (orientation: portrait) {
          content: none;
        }
      }

      &:first-child {
        @media (orientation: portrait) {
          border-top: 1px solid #c4c4c4;
        }
      }

      &:last-child {
        @media (orientation: portrait) {
          padding-bottom: 0;
          border-bottom: 0;
        }

        &:after {
          content: none;
        }
      }

      .suptitle {
        color: #0f247b;
        font-size: 30px;
        font-weight: 400;
        margin: 0;
      }

      .title {
        color: #0f247b;
        font-size: 50px;
        font-weight: 300;
        margin: 0;

        strong {
          font-weight: 800;
        }
      }

      .subtitle {
        color: #414141;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 8px;
      }
    }
  }
`;

export const About = styled.section`
  background-color: #ffffff;
  padding-bottom: 16px;
  padding-top: 32px;

  @media (orientation: portrait) {
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .title {
    color: #0f247b;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 32px;

    @media (orientation: portrait) {
      font-size: 30px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
  }

  .content {
    color: #414141;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;

    @media (orientation: portrait) {
      font-size: 14px;
      line-height: 20px;
    }

    p {
      margin-bottom: 32px;

      @media (orientation: portrait) {
        margin-bottom: 16px;
      }
    }
  }
`;

export const Subscription = styled.section`
  background-color: #0f247b;
  padding-bottom: 60px;
  padding-top: 60px;

  @media (orientation: portrait) {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .title {
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 32px;

    @media (orientation: portrait) {
      font-size: 30px;
      font-weight: 800;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
  }

  .content {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    margin: 0;

    @media (orientation: portrait) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .box-form-hub-spot-new {
    background: rgba(0, 50, 109, 1.0);
    border-radius: 18px;
    padding: 45px 45px;
    @media (orientation: portrait) {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }
  
  .box-form {
    padding-bottom: 60px;
    padding-top: 60px;

    @media (orientation: portrait) {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }
`;

export const BoxFaq = styled.section`
  background-color: #ffffff;
  min-height: 230px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 60px;

  @media (orientation: portrait) {
    padding-bottom: 20px;
    padding-top: 40px;
  }

  .titleAcordiao {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    margin-bottom: 56px;
    color: #00205b;
    font-weight: 700;

    @media (orientation: portrait) {
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .accordion-button {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0f247b;
    color: #ffffff;

    img {
      margin-right: 12px;
      max-height: 24px;
      max-width: 24px;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    &.collapsed {
      color: #00205b;
      background-color: transparent;

      img {
        margin-right: 12px;
        max-height: 24px;
        max-width: 24px;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f247b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }
`;

export const CallToAction = styled.section`
  background: #ffffff;
  padding-bottom: 40px;
  padding-top: 40px;

  @media (orientation: portrait) {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .box-cta {
    text-align: center;

    .title {
      color: #00205b;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 60px;

      @media (orientation: portrait) {
        font-size: 30px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
    }
  }
`;

export const Contact = styled.section`
  background: #c4c4c4;
  padding-bottom: 60px;
  padding-top: 60px;

  @media (orientation: portrait) {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .title {
    color: #0f247b;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 0;

    a {
      color: #0f247b;
      font-weight: 900;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
