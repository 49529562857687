import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.section`
  display: flex;
  align-items: center;
  background-color: #0f0c6d;
  justify-content: space-around;
  padding-bottom: 16px;
  padding-top: 16px;
  position: fixed;
  width: 100%;
  z-index: 9999;
`;
