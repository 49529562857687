
import styled from 'styled-components';

// Assets
import CloseIcon from '../../../assets/close.png';


export const Container = styled.div`
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    background: rgba(33, 33, 33, 0.3);
    transition: all 350ms ease-in-out;



`;


export const Content = styled.section`
  box-shadow: 0px 0px 10px #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 50px 15px;
  border-radius: 25px;
  background: #303db3;
  background: -moz-linear-gradient(left, #303db3 0, #0097a4 100%);
  background: -webkit-linear-gradient(left, #303db3, #0097a4);
  background: linear-gradient(90deg, #303db3 0, #0097a4);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#303db3",endColorstr="#0097a4",GradientType=1);
  opacity: 1;
  transition: all 350ms ease-in-out;
  text-align: center;

  .button-link {
    padding: 10px 30px;
    background-color: #ffb100;
    font-weight: 800;
    color: #fff;
    border-radius: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer!important;
    text-align: center;
  
    a {
      text-align: center !important;
      text-transform: uppercase;
      text-decoration: none;
      z-index: 25;
    }
  }

  @media (orientation: portrait) {
    max-width: 280px;
    padding-bottom: 20px;
    padding-top: 65px;
  }
`;

export const BoxText = styled.div`
  max-height: 40vh;
  padding-right: 28px;

  @media (orientation: portrait) {
    max-height: 50vh;
    padding-right: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track:enabled {
    background-color: #dadada;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb:vertical {
    background-color: #0f0c6d;
    border-radius: 30px;
  }

  h3 {
    font-size: 1.875em;
  }

  h4 {
    font-size: 1.375em;
  }

  span {
    color: #ffb100;
  }

  h1 {
    font-family: Montserrat, sans-serif;
    font-style: italic;
    font-weight: 800;
    color: #fff;
    text-align: center;
  }

  p {
    font-family: Montserrat, sans-serif;
    color: #fff;
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
    iframe {
      max-width: 100%;
    }

    @media (orientation: portrait) {
      font-size: 14px;
    }
  }
`;
export const Close = styled.button`
  background: url(${CloseIcon}) no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  height: 30px;
  position: absolute;
  right: -28px;
  top: -28px;
  width: 30px;
  cursor: pointer;

  @media (orientation: portrait) {
    right: 20px;
    top: 20px;
  }
`;

export const Button = styled.button`
  padding: 10px 30px;
  background-color: #ffb100;
  font-weight: 800;
  color: #fff;
  border-radius: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  cursor: pointer!important;

  a {
    text-transform: uppercase;
    text-decoration: none;
    z-index: 25;
  }
`;