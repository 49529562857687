import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.span`
  color: #343434;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 18px;
`;
