import React from 'react';

import { Container } from './styles';

interface Anchor1 {
  url: string;
  target: string;
  src: string;
  alt: string;
}

const SocialIcon: React.FC<Anchor1> = ({
  url,
  target,
  src,
  alt
}) => {
  return (
    <Container>
        <a href={url} target={target}>
          <img src={src} alt={alt} />
        </a>
    </Container>
  );
};

export default SocialIcon;
