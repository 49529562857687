import styled, { css } from 'styled-components';

// Assets

// Interface
interface Props {
  align: 'left' | 'right' | 'center';
  buy?: boolean;
}

export const Container = styled.h2<Props>`
  color: #00205b;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: ${({ align }) => align};

  ${({ buy }) =>
    buy &&
    css`
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
        margin-bottom: 4px;
      }
    `}
`;
