import React, { useState, useEffect, useRef } from 'react';

// Plugins

// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'lazysizes';

// Components
import { Menu } from '../../components/Extensivo/Menu';
import { Logo } from '../../components/Extensivo/Menu/Logo';
import { BoxMenuMobile } from '../../components/Extensivo/Menu/BoxMenuMobile';
import { BoxMenu } from '../../components/Extensivo/Menu/BoxMenu';
import { Link } from '../../components/Extensivo/Menu/Link';
import { Button } from '../../components/Extensivo/Button';
import { Card } from '../../components/Extensivo/Card';
import { H2 } from '../../components/Extensivo/Card/H2';
import { H3 } from '../../components/Extensivo/Card/H3';
import { H4 } from '../../components/Extensivo/Card/H4';
import { Paragraph } from '../../components/Extensivo/Card/Paragraph';
import { Span } from '../../components/Extensivo/Card/Span';
import { Tag } from '../../components/Extensivo/Card/Tag';
import { Separator } from '../../components/Extensivo/Card/Separator';
import { Image } from '../../components/Extensivo/Card/Image';
import { WarrantyLine } from '../../components/Extensivo/WarrantLine';
import { Modal } from '../../components/Extensivo/Modal';
import CarouselAplicativo from '../../components/Extensivo/CarouselApp';
import { Testemonial } from '../../components/Extensivo/Testemonial';
import { BoxItem } from '../../components/Extensivo/Testemonial/BoxItem';
import { BoxVideo } from '../../components/Extensivo/Testemonial/BoxVideo';
import { BoxContent } from '../../components/Extensivo/Testemonial/BoxContent';
import { TestemonialH3 } from '../../components/Extensivo/Testemonial/H3';
import { TestemonialDescription } from '../../components/Extensivo/Testemonial/Description';
import { Faq } from '../../components/Extensivo/Faq';
import Loading from '../../components/Loading';
import AddressAndPolicies from '../../components/AddressAndPolicies';
import { FreeTrialBanner } from '../../components/FreeTrialBanner';

// import {Acordiao} from '../../components/Extensivo/acordiao';

// Services
import apiExtensivo from '../../services/Extensivo/api';

// Assets
import Extensivo from '../../assets/logo-extensivos-2023.svg';
import MenuIcon from '../../assets/menu-icon.png';
import CloseMenuIcon from '../../assets/close-menu-icon.png';


// import ImgTableDesktop from '../../assets/Extensivo/tabela-extensivo-desktop.jpg';
// import ImgTableMobile from '../../assets/Extensivo/tabela-extensivo-mobile.jpg';

// Styles
import {
  Container,
  BoxHero,
  BoxCards,
  BoxTags,
  BoxPillars,
  BoxWaranty,
  BoxVideoTestemonial,
  BoxVideoSobre,
  BoxFaq,
  BoxApp,
  Footer,
  ButtonHero,
  Arrow,
} from './styles';


// Interface
interface Botao {
  title: string;
  url: string;
  target: string;
}

interface TagProps {
  value_tag: string;
}

interface CourseProps {
  ano_courses: string[];
  label_courses: string;
  cor_courses: boolean;
  titulo_courses: string;
  descricao_courses: string;
  tags_courses: [TagProps];
  link_courses: Botao;
}

interface SubgrupoDuvidas {
  titulo_subgrupo: string;
  texto_subgrupo: string;
}

interface GrupoDuvidas {
  titulo_grupo: string;
  texto_grupo: string;
  subgrupo: [SubgrupoDuvidas];
}

interface Video {
  url_video: string;
  nome_video: string;
  aprovacao_video: string;
}

interface App {
  imagem_app: {
    title: string;
    url: string;
  };
  descricao_app: string;
}

interface Dados {
  extensivo_hub:{
    hero: {
      status_hero: boolean;
      video_hero: string;
      titulo_hero: string;
      texto_hero: string;
      botao_hero: Botao;
      courses: [CourseProps];
    };

    pilar: {
      status_pilar: boolean;
      titulo_pilares: string;
      descricao_pilares: string;
      pilares: [{
        imagem_pilar: {
          title: string;
          url: string;
        };
        titulo_pilar: string;
        subtitulo_pilar: string;
        paragrafo_pilar: string;
        conteudo_pilar: string;
        botao_pilar: Botao;
      }];
    };

    garantia: {
      status_garantia: boolean;
      titulo_garantia: string;
      subtitulo_garantia: string;
      data_garantia: string;
      texto_garantia: string;
    };

    sobre: {
      status_sobre: boolean;
      video_sobre: string;
      titulo: string;
    };

    aplicativo: {
      status_aplicativo: boolean;
      titulo_aplicativo: string;
      app: [App];
   
    };

    depoimentos: {
      status_depoimentos: boolean;
      titulo_depoimentos: string;
      videos_depoimentos: [Video];
    };

    duvidas: {
      status_duvidas: boolean;
      titulo_duvidas: string;
      grupo_duvidas: [GrupoDuvidas];
    };

    footer: {
      status_footer: boolean;
      titulo_footer: string;
      email_footer: Botao;
    };
  };

  banner_free_trial: {
    activate_free_trial: boolean;
    navigation_title: string;
    background_color: string;
    activate_background_image: boolean;
    background_image: string;
    margin_bottom_color: string;
    text_color: string;
    title: string;
    subtitle: string;
    activate_button: boolean;
    button_text_color: string;
    button_hover_text_color: string;
    button_background_color: string;
    button_hover_background_color: string;
    button_text: string;
    button_link: string;
  };
}

const ExtensivoHub: React.FC = () => {
  const [yearCourse, setYearCourse] = useState<String>("2022");
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState('');
  const [menuMobile, setMenuMobile] = useState(false);
  const [dados, setDados] = useState<Dados | null>(null);
  const hostName = window.location.hostname;
  const url_traffic_source = window.location.search;

  useEffect(() => {
    apiExtensivo
      .get(
        `pages/${
          hostName === 'extensivo.medway.com.br' ? `5213` : `8602`
        }`
      )
      .then(response => {
        setDados(response.data.acf);
      });
  }, []);

  // RefsToMenu
  const inscrevaBloco = useRef(null);
  const pilaresBloco = useRef(null);
  const freeTrialBloco = useRef(null);
  const lancamentoBloco = useRef(null);
  const aplicativoBloco = useRef(null);
  const boxTestemonialRef = useRef(null);
  const garantiaBloco = useRef(null);
  const boxFaqRef = useRef(null);

  return (
    <>
      {dados ? (
        <>
          <Menu>
            <Logo src={Extensivo} alt="Extensivo Medway" />
            <BoxMenuMobile
              onClick={() => {
                setMenuMobile(!menuMobile);
              }}
              src={menuMobile ? CloseMenuIcon : MenuIcon}
            />
            <BoxMenu menuMobile={menuMobile}>
              {dados.extensivo_hub.hero.status_hero && (
              <Link
                href="https://medway.com.br"
                menuRef={inscrevaBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Inscreva-se
              </Link>)}
              {dados.extensivo_hub.pilar.status_pilar && (
              <Link
                href="https://medway.com.br"
                menuRef={pilaresBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Pilares
              </Link>)}
              {dados.banner_free_trial
              && dados.banner_free_trial.activate_free_trial
              && dados.banner_free_trial.navigation_title && (
              <Link
                href="https://medway.com.br"
                menuRef={freeTrialBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                {dados.banner_free_trial.navigation_title}
              </Link>)}
              {dados.extensivo_hub.sobre.status_sobre && (
              <Link
                href="https://medway.com.br"
                menuRef={lancamentoBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Lançamento
              </Link>)}
              {dados.extensivo_hub.aplicativo.status_aplicativo && (
              <Link
                href="https://medway.com.br"
                menuRef={aplicativoBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Aplicativo
              </Link>)}
              {dados.extensivo_hub.depoimentos.status_depoimentos && (
              <Link
                href="https://medway.com.br"
                menuRef={boxTestemonialRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Depoimentos
              </Link>)}
              {dados.extensivo_hub.garantia.status_garantia && (
              <Link
                href="https://medway.com.br"
                menuRef={garantiaBloco}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Garantia
              </Link>)}
              {dados.extensivo_hub.duvidas.status_duvidas && (
              <Link
                href="https://medway.com.br"
                menuRef={boxFaqRef}
                setMenuMobile={setMenuMobile}
                type="button"
              >
                Dúvidas
              </Link>)}
            </BoxMenu>
          </Menu>
          {dados.extensivo_hub.hero.status_hero && (
            <BoxHero ref={inscrevaBloco}>
              <div className="boxContent">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: dados.extensivo_hub.hero.titulo_hero
                  }}
                
                />
                <hr className="line" />
                <p
                  className="label"
                  dangerouslySetInnerHTML={{
                    __html: dados.extensivo_hub.hero.texto_hero
                  }}
                />
                <div className="boxButtons">
                  <ButtonHero
                    onClick={() => {
                      setYearCourse("2022");
                    }}
                    isactive={yearCourse === "2022"}
                    type="button"
                    className="button"
                  >
                    <Arrow isactive={yearCourse === "2022"} />
                  </ButtonHero>
                  <ButtonHero
                    onClick={() => {
                      setYearCourse("2023");
                    }}
                    isactive={yearCourse === "2023"}
                    type="button"
                    className="button"
                  >
                    2023
                  </ButtonHero>
                  <ButtonHero
                    onClick={() => {
                      setYearCourse("2024");
                    }}
                    isactive={yearCourse === "2024"}
                    type="button"
                    className="button"
                  >
                    2024
                  </ButtonHero>
                </div>
              </div>
            </BoxHero>
          )}
          
          {dados.extensivo_hub.hero.status_hero && (
            <BoxCards>
              {dados.extensivo_hub.hero.courses
                .filter(course =>
                  yearCourse !== "" ? course.ano_courses.find(e => e === yearCourse) : course
                )
                .map((course, key) => (
                  <Card
                    align="left"
                    label={course.label_courses}
                    labelColor={course.cor_courses}
                    key={key}
                  >
                    <H2 align="left">{course.titulo_courses}</H2>
                    <Paragraph align="left" weight={400}>
                      {course.descricao_courses}
                    </Paragraph>
                    {course.tags_courses.length > 0 && (
                      <BoxTags>
                        <Span>Incluso</Span>
                        {course.tags_courses.map((tag, tagKey) => (
                          <Tag key={tagKey}>{tag.value_tag}</Tag>
                        ))}
                      </BoxTags>
                    )}
                    <Separator />
                    <Button
                      href={`${course.link_courses.url}${url_traffic_source}`}
                      fill="contained"
                      size="small"
                      pattern="base"
                      fluid
                    >
                      {course.link_courses.title}
                    </Button>
                  </Card>
                ))}
            </BoxCards>
          )}

          {dados.extensivo_hub.pilar.status_pilar && (
            <BoxPillars ref={pilaresBloco}>
              <div className="box-title">
                <h2 className="title">{dados.extensivo_hub.pilar.titulo_pilares}</h2>
                <p className="description">
                  {dados.extensivo_hub.pilar.descricao_pilares}
                </p>
              </div>
              <div className="box-content">
                {dados.extensivo_hub.pilar.pilares.map((item, key) => (
                  <Card align="center" key={key}>
                    <Image
                      className="lazyload"
                      src=""
                      data-srcset={`${item.imagem_pilar.url} 1x`}
                      alt={item.imagem_pilar.title} 
                    />
                    <H3 align="center">{item.titulo_pilar}</H3>
                    <H4 align="center">{item.subtitulo_pilar}</H4>
                    <Paragraph align="center" weight={400}>
                      {item.paragrafo_pilar}
                    </Paragraph>
                    <Separator />
                    <Button
                      onClick={() => {
                        setStatusModal(true);
                        setModalContent(item.conteudo_pilar);
                      }}
                      fill="outlined"
                      size="small"
                      pattern="base"
                    >
                      {item.botao_pilar.title}
                    </Button>
                  </Card>
                ))}
              </div>
            </BoxPillars>
          )}

          {dados.banner_free_trial && dados.banner_free_trial.activate_free_trial && (
          <FreeTrialBanner
            reference={freeTrialBloco}
            free_trial_info = {dados.banner_free_trial}/>
          )}

          {dados.extensivo_hub.sobre.status_sobre && (
            <BoxVideoSobre ref={lancamentoBloco}>
              <Container>
                <h2 className="title">{dados.extensivo_hub.sobre.titulo}</h2>
                <div
                  className="video"
                  dangerouslySetInnerHTML={{
                    __html: dados.extensivo_hub.sobre.video_sobre,
                  }}
                />
              </Container>
            </BoxVideoSobre>
          )}

          {dados.extensivo_hub.aplicativo.status_aplicativo && (
            <BoxApp ref={aplicativoBloco}>
              <Container>
                <div className="box-title">
                  <h2 className="title">{dados.extensivo_hub.aplicativo.titulo_aplicativo}</h2>
                </div>
                <div className="box-carousel">
                  <CarouselAplicativo carouselApp={dados.extensivo_hub.aplicativo.app} />
                </div>
              </Container>
            </BoxApp>
          )}

          {dados.extensivo_hub.depoimentos.status_depoimentos && (
            <BoxVideoTestemonial ref={boxTestemonialRef}>
              <Container>
                <div className="box-title">
                  <h2 className="title">
                    {dados.extensivo_hub.depoimentos.titulo_depoimentos}
                  </h2>
                </div>
                <Testemonial nav dots loop={false}>
                  {dados.extensivo_hub.depoimentos.videos_depoimentos.map(item => (
                    <BoxItem type="video">
                      <BoxVideo
                        dangerouslySetInnerHTML={{
                          __html: item.url_video,
                        }}
                      />
                      <BoxContent>
                        <TestemonialH3>{item.nome_video}</TestemonialH3>
                        <TestemonialDescription type="video">
                          {item.aprovacao_video}
                        </TestemonialDescription>
                      </BoxContent>
                    </BoxItem>
                  ))}
                </Testemonial>
              </Container>
            </BoxVideoTestemonial>
          )}

          {dados.extensivo_hub.garantia.status_garantia && (
            <BoxWaranty ref={garantiaBloco}>
              <WarrantyLine
                title={dados.extensivo_hub.garantia.titulo_garantia}
                subtitle={dados.extensivo_hub.garantia.subtitulo_garantia}
                date={dados.extensivo_hub.garantia.data_garantia}
                text={dados.extensivo_hub.garantia.texto_garantia}
              />
            </BoxWaranty>
          )}

          {dados.extensivo_hub.duvidas.status_duvidas && (
            <BoxFaq ref={boxFaqRef}>
              <Faq
                title={dados.extensivo_hub.duvidas.titulo_duvidas}
                data={dados.extensivo_hub.duvidas.grupo_duvidas}
              />
            </BoxFaq>
          )}

          {dados.extensivo_hub.footer.status_footer && (
            <Footer>
              <div className="boxFooter">
                <p className="titleFooter">
                  {dados.extensivo_hub.footer.titulo_footer}{' '}
                  <a className="mailto" href={dados.extensivo_hub.footer.email_footer.url}>
                  {dados.extensivo_hub.footer.email_footer.title}
                  </a>
                </p>
              </div>
              <AddressAndPolicies background = '' />
            </Footer>
          )}
          <Modal
            open={statusModal}
            setStatusModal={setStatusModal}
            modalContent={modalContent}
          />
        </>
      ) : (
        <Loading />
      )}
      
    </>
  );
};

export default ExtensivoHub;
