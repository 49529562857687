import React from 'react';

// Styles
import { Container, Content, Close, BoxText } from './styles';

// Interface
interface ModalProps {
  open: boolean;
  setStatusModal: any;
  modalContent: string;
}

export function Modal({ open, setStatusModal, modalContent }: ModalProps) {
  return (
    <Container open={open}>
      <Content>
        <Close
          onClick={() => {
            setStatusModal(false);
          }}
          type="button"
        />
        <BoxText
          dangerouslySetInnerHTML={{
            __html: modalContent,
          }}
        />
      </Content>
    </Container>
  );
}
