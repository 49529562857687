import styled from 'styled-components';

// Images
// import OpenBackground from '../../assets/bg-podcast-projeto-r1-sp.jpg';
import MedwayWatermark from '../../assets/medway-watermark.svg';
import Microfone from '../../assets/microfone.svg';

// Colors
const base = '#00CFB4';
const secondary = '#0F247B';
const titles = '#333333';
const texts = '#F7F4FF';
const white = '#ffffff';

interface OpenProps {
  background: string;
}

export const Container = styled.section`
  margin: auto;
  max-width: 1140px;
  padding: 15px;
  width: 100%;
`;

export const Open = styled.section<OpenProps>`
  background-image: url(${({ background }) => background && background});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80px;
  padding-top: 20px;

  @media (orientation: portrait) {
    padding-bottom: 46px;
    padding-top: 46px;
  }

  .box-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-bottom: 40px;

    @media (orientation: portrait) {
      flex-direction: column;
      margin-bottom: 48px;
    }

    .box-content {
      flex-basis: 40%;

      @media (orientation: portrait) {
        flex-basis: 100%;
      }

      .logo {
        margin-bottom: 20px;
        width: 100%;

        @media (orientation: portrait) {
          margin-bottom: 0;
        }
      }

      .page-h1 h1 {
        color: ${white};
        font-size: 26px;
        font-weight: 400;
        margin: 0;
      }

      .text {
        color: ${white};
        font-size: 26px;
        font-weight: 400;
        margin: 0;

        @media (orientation: portrait) {
          display: none;
        }
      }
    }

    .box-image {
      flex-basis: 60%;
      
      @media (orientation: portrait) {
        flex-basis: 100%;
      }

      .imagem {
        width: 100%;
      }
    }
  }

  .box-player {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    border-radius: 20px;
    border: 2px solid #890fd5;
    justify-content: space-between;
    position: relative;

    @media (orientation: portrait) {
      flex-direction: column;
    }

    .box-image {
      flex-grow: 0;

      img {
        margin: 20px 24px;
        border-radius: 8px;
        max-width: 180px;
      }
    }
    .box-content {
      flex-grow: 3;

      @media (orientation: portrait) {
        padding-right: 16px;
        padding-left: 16px;
        width: 100%;
      }

      .episode {
        color: ${base};
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
        text-transform: uppercase;

        @media (orientation: portrait) {
          font-size: 18px;
          margin-bottom: 8px;
        }
      }

      .title {
        color: ${titles};
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 8px;

        @media (orientation: portrait) {
          font-size: 22px;
          margin-bottom: 12px;
        }
      }

      .description {
        color: #000000;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;

        @media (orientation: portrait) {
          font-size: 14px;
        }
      }
    }
    .box-button {
      flex-grow: 2;
      text-align: center;
      flex-basis: 75%;

      @media (orientation: portrait) {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      a {
        background-color: ${base};
        border-radius: 40px;
        border: 2px solid ${base};
        color: ${white};
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 16px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 16px;
        text-transform: uppercase;
        transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;

        @media (orientation: portrait) {
          font-size: 16px;
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 60px;
          padding-right: 60px;
        }

        &:hover {
          background-color: transparent;
          color: ${base};
          text-decoration: none;
          transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
        }
      }
    }
  }
`;

export const Plataform = styled.section`
  background-color: ${secondary};
  background-image: url(${MedwayWatermark});
  background-repeat: no-repeat;
  background-position: right center;
  padding-bottom: 50px;
  padding-top: 50px;

  @media (orientation: portrait) {
    background-position: right bottom;
    background-size: 25%;
    padding-bottom: 34px;
    padding-top: 34px;
  }

  .box-content {
    .title {
      color: ${white};
      font-size: 44px;
      font-weight: 700;
      margin-bottom: 40px;

      @media (orientation: portrait) {
        font-size: 18px;
        text-align: center;
      }
    }

    .box-plataforms {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (orientation: portrait) {
        flex-direction: column;
      }

      .plataform {
        &:last-child {
          @media (orientation: portrait) {
            margin-bottom: 0;
          }
        }

        @media (orientation: portrait) {
          margin-bottom: 24px;
        }

        .link {
          opacity: 1;
          transition: opacity ease-in-out 0.3s;

          &:hover {
            opacity: 0.8;
            transition: opacity ease-in-out 0.3s;
          }
        }
        .logo {
        }
      }
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;

  h2 {
    display: flex;
    align-items: flex-start;
    color: ${titles};
    flex-direction: column;
    font-size: 42px;
    font-weight: 700;
    justify-content: center;
    margin-bottom: 0;
    max-width: fit-content;
    text-transform: uppercase;
    width: 100%;

    @media (orientation: portrait) {
      font-size: 24px;
    }

    &:after {
      background-color: ${base};
      content: '';
      height: 10px;
      margin-top: 10px;
      width: 100px;

      @media (orientation: portrait) {
        margin-top: 6px;
        height: 6px;
        width: 40px;
      }
    }
  }
`;

export const TitleInverse = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;

  h2 {
    display: flex;
    align-items: flex-start;
    color: ${white};
    flex-direction: column;
    font-size: 42px;
    font-weight: 700;
    justify-content: center;
    margin-bottom: 0;
    max-width: fit-content;
    text-transform: uppercase;
    width: 100%;

    @media (orientation: portrait) {
      font-size: 24px;
    }

    &:after {
      content: '';
      width: 100px;
      height: 10px;
      margin-top: 10px;
      background-color: ${base};

      @media (orientation: portrait) {
        margin-top: 6px;
        height: 6px;
        width: 40px;
      }
    }
  }
`;

export const Button = styled.div`
  display: flex;

  a,
  button {
    background-color: ${base};
    border-radius: 40px;
    border: 2px solid ${base};
    color: ${white};
    font-size: 20px;
    font-weight: 700;
    padding: 14px 62px;
    text-transform: uppercase;
    transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;

    @media (orientation: portrait) {
      padding: 8px 62px;
      font-size: 16px;
    }

    &:hover {
      background-color: transparent;
      color: ${base};
      text-decoration: none;
      transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
    }
  }
`;

export const Episodes = styled.section`
  background-color: ${texts};
  padding-bottom: 40px;
  padding-top: 40px;

  .box-episodes {
    .box-episode {
      display: grid;
      align-items: center;
      background-color: #001466;
      border-radius: 20px;
      grid-template-columns: 1fr 3fr 2fr;
      margin-bottom: 50px;

      @media (orientation: portrait) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      .box-image {
        flex-grow: 0;

        @media (orientation: portrait) {
          margin-bottom: 30px;
          width: 100%;
        }

        img {
          margin: 20px 24px;
          border-radius: 8px;
          max-width: 180px;

          @media (orientation: portrait) {
            margin: 0;
            border-radius: 0;
            width: 100%;
            max-width: 100%;
          }
        }
      }

      .box-content {
        flex-grow: 3;

        @media (orientation: portrait) {
          padding-right: 16px;
          padding-left: 16px;
          width: 100%;
        }

        .episode {
          color: ${base};
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
          text-transform: uppercase;

          @media (orientation: portrait) {
            font-size: 18px;
            margin-bottom: 8px;
            align-items: flex-start;
          }
        }

        .title {
          color: ${white};
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 8px;

          @media (orientation: portrait) {
            font-size: 22px;
            margin-bottom: 12px;
          }
        }

        .description {
          color: ${white};
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 0;

          @media (orientation: portrait) {
            font-size: 14px;
          }
        }
      }

      .box-cta {
        flex-grow: 2;
        text-align: center;
        flex-basis: 75%;

        @media (orientation: portrait) {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
          margin-top: 20px;
        }

        a,
        button {
          background-color: ${base};
          border-radius: 40px;
          border: 2px solid ${base};
          color: ${white};
          font-size: 16px;
          font-weight: 700;
          padding-bottom: 16px;
          padding-left: 40px;
          padding-right: 40px;
          padding-top: 16px;
          text-decoration: none;
          text-transform: uppercase;
          transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;

          @media (orientation: portrait) {
            font-size: 16px;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 60px;
            padding-right: 60px;
          }

          &:hover {
            background-color: transparent;
            color: ${base};
            transition: background-color ease-in-out 0.3s,
              color ease-in-out 0.3s;
          }
        }
      }
    }
  }

  .box-button {
    display: flex;
    align-items: center;
    justify-content: center;

    &__hidden {
      display: none;
    }
  }
`;

export const Presenters = styled.section<OpenProps>`
  background-image: url(${({ background }) => background && background});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-logo {
    text-align: center;
    width: 100% !important;
    position: relative !important;
  }

  .box-presenters {
    display: flex;
    justify-content: space-between;

    @media (orientation: portrait) {
      flex-direction: column;
    }

    .presenter {
      position: relative;
      text-align: center;

      &:nth-child(1) {
        margin-right: 164px;

        @media (orientation: portrait) {
          margin-right: 0;
        }

        &:after {
          background-image: url(${Microfone});
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 250px;
          position: absolute;
          right: -164px;
          top: calc(50% - 125px);
          width: 164px;

          @media (orientation: portrait) {
            display: none;
          }
        }
      }

      .image {
        height: 460px;
        margin-bottom: 15px;

        @media (orientation: portrait) {
          height: auto;
          width: 100%;
        }
      }

      .title {
        color: ${white};
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 32px;
        text-transform: uppercase;

        @media (orientation: portrait) {
          margin-bottom: 8px;
        }
      }

      .text {
        color: ${white};
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 0;

        @media (orientation: portrait) {
          font-size: 16px;
        }
      }
    }
  }
`;

export const AboutMedway = styled.section`
  background-color: ${secondary};
  padding-bottom: 80px;
  padding-top: 80px;
  text-align: center;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-image {
    margin-bottom: 64px;

    @media (orientation: portrait) {
      margin-bottom: 32px;
    }

    img {
      border-radius: 56px;

      @media (orientation: portrait) {
        width: 100%;
      }
    }
  }

  .box-content {
    color: ${white};
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;

    @media (orientation: portrait) {
      font-size: 16px;
      line-height: 28px;
    }

    p {
      margin-bottom: 42px;

      @media (orientation: portrait) {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const SeeAlso = styled.section`
  background-color: ${white};
  padding-bottom: 80px;
  padding-top: 80px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-itens {
    .item {
      display: flex;
      align-items: center;
      background-color: #000d43;
      border-radius: 20px;

      @media (orientation: portrait) {
        flex-direction: column;
        overflow: hidden;
      }

      .box-image {
        margin: 40px;

        @media (orientation: portrait) {
          margin: 0 0 30px 0;
          width: 100%;
        }

        img {
          @media (orientation: portrait) {
            width: 100%;
          }
        }
      }

      .box-content {
        padding-bottom: 40px;
        padding-right: 84px;
        padding-top: 40px;

        @media (orientation: portrait) {
          padding-right: 16px;
          padding-left: 16px;
          padding-top: 0;
          width: 100%;
        }

        .title {
          color: ${base};
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 20px;
          text-transform: uppercase;

          @media (orientation: portrait) {
            font-size: 20px;
            margin-bottom: 12px;
          }
        }

        .description {
          color: ${white};
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 12px;

          @media (orientation: portrait) {
            font-size: 16px;
          }
        }

        .presenters {
          color: ${white};
          display: block;
          font-size: 16px;
          font-weight: 400;

          @media (orientation: portrait) {
            font-size: 14px;
          }
        }

        .box-cta {
          display: flex;
          justify-content: flex-end;

          @media (orientation: portrait) {
            justify-content: center;
          }

          a {
            background-color: ${base};
            border-radius: 40px;
            border: 2px solid ${base};
            color: ${white};
            display: inline-block;
            font-size: 18px;
            font-weight: 700;
            margin-top: 24px;
            padding: 20px 46px;
            text-transform: uppercase;
            transition: background-color ease-in-out 0.3s,
              color ease-in-out 0.3s;

            @media (orientation: portrait) {
              font-size: 16px;
              padding-top: 12px;
              padding-bottom: 12px;
              padding-left: 60px;
              padding-right: 60px;
            }

            &:hover {
              background-color: transparent;
              color: ${base};
              text-decoration: none;
              transition: background-color ease-in-out 0.3s,
                color ease-in-out 0.3s;
            }
          }
        }
      }
    }
  }
`;
