import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  align: 'left' | 'right' | 'center';
  weight: number;
}

export function Paragraph({ children, align, weight, ...rest }: Props) {
  return (
    <Container align={align} weight={weight} {...rest}>
      {children}
    </Container>
  );
}
