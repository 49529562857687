import React, { useState, useEffect } from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'lazysizes';

import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Title,
  Button,
  Abertura,
  Banner,
  Cursos,
  Sobre,
  Depoimentos,
  Conteudos,
  Destaques,
  Parcerias,
} from './styles';

interface Botao {
  title: string;
  url: string;
  target: string;
}

interface Imagem {
  title: string;
  sizes: {
    'all-logo': string;
    '1920x1080': string;
  };
}

interface Dados {
  home_v2: {
    abertura: {
      status_abertura: boolean;
      background_desktop_abertura: Imagem;
      background_mobile_abertura: Imagem;
      texto_abertura: string;
      palavra_destaque_abertura: Imagem;
    };
    banner: {
      status_banner: boolean;
      background_banner: Imagem;
      logo_banner: Imagem;
      texto_banner: string;
      botao_banner: Botao;
    };
    cursos: {
      status_cursos: boolean;
      background_cursos: Imagem;
      titulo_cursos: string;
      descricao_cursos: string;
      carousel_cursos: [
        {
          imagem_carousel: Imagem;
          logo_carousel: Imagem;
          descricao_carousel: string;
          botao_carousel: Botao;
        }
      ];
    };
    quem_somos: {
      status_about: boolean;
      titulo_about: string;
      imagem_about: Imagem;
      descricao_about: string;
      botao_about: Botao;
    };
    depoimentos: {
      status_depoimentos: boolean;
      titulo_depoimentos: string;
      carousel_depoimentos: [
        {
          imagem_carousel_depoimentos: Imagem;
          texto_carousel_depoimentos: string;
          nome_carousel_depoimentos: string;
          aprovado_carousel_depoimentos: string;
          aprovacao_carousel_depoimentos: string;
        }
      ];
      botao_depoimentos: Botao;
    };
    conteudos: {
      status_conteudos: boolean;
      titulo_conteudos: string;
      itens_conteudos: [
        {
          logo_itens_conteudos: Imagem;
          icone_itens_conteudos: Imagem;
          descricao_itens_conteudos: string;
          botao_itens_conteudos: Botao;
        }
      ];
    };
    destaques: {
      status_destaques: boolean;
      titulo_destaques: string;
    };
    parcerias: {
      status_parcerias: boolean;
      titulo_parcerias: string;
      descricao_parcerias: string;
      imagem_parcerias: Imagem;
      texto_parcerias: string;
      botao_parcerias: Botao;
    };
  };
}

const NewHome: React.FC = () => {
  const [dados, setDados] = useState<Dados | null>(null);

  useEffect(() => {
    api.get(`acf/v3/pages/7220`).then(response => {
      setDados(response.data.acf);
    });
  }, []);

  return (
    <>
      {dados ? (
        <>
          {dados.home_v2.abertura.status_abertura && (
            <Abertura
              background={
                dados.home_v2.abertura.background_desktop_abertura.sizes[
                  '1920x1080'
                ]
              }
              backgroundMobile={
                dados.home_v2.abertura.background_mobile_abertura.sizes[
                  '1920x1080'
                ]
              }
            >
              <div className="box-content">
                <h1 className="title">
                  {dados.home_v2.abertura.texto_abertura}
                  <img
                    className="image"
                    src={
                      dados.home_v2.abertura.palavra_destaque_abertura.sizes[
                        'all-logo'
                      ]
                    }
                    alt={dados.home_v2.abertura.palavra_destaque_abertura.title}
                  />
                </h1>
              </div>
            </Abertura>
          )}

          {dados.home_v2.banner.status_banner && (
            <Banner
              background={
                dados.home_v2.banner.background_banner.sizes['1920x1080']
              }
            >
              <div className="box-elements">
                <div className="box-name">
                  <img
                    src={dados.home_v2.banner.logo_banner.sizes['all-logo']}
                    className="logo"
                    alt={dados.home_v2.banner.logo_banner.title}
                  />
                </div>
                <div className="box-content">
                  <p>{dados.home_v2.banner.texto_banner}</p>
                  <Button>
                    <a
                      href={dados.home_v2.banner.botao_banner.url}
                      target={dados.home_v2.banner.botao_banner.target}
                    >
                      {dados.home_v2.banner.botao_banner.title}
                    </a>
                  </Button>
                </div>
              </div>
            </Banner>
          )}

          {dados.home_v2.cursos.status_cursos && (
            <Cursos
              background={
                dados.home_v2.cursos.background_cursos.sizes['1920x1080']
              }
            >
              <Container>
                <div className="box-content">
                  <Title colorType="inverse">
                    <h2>{dados.home_v2.cursos.titulo_cursos}</h2>
                  </Title>
                  <div
                    className="box-text"
                    dangerouslySetInnerHTML={{
                      __html: dados.home_v2.cursos.descricao_cursos,
                    }}
                  />
                </div>
                <div className="box-slider">
                  <OwlCarousel
                    className="owl-theme"
                    responsive={{
                      0: {
                        loop: false,
                        margin: 10,
                        nav: true,
                        dots: true,
                        items: 1,
                      },
                    }}
                  >
                    {dados.home_v2.cursos.carousel_cursos.map(item => (
                      <div className="box-item">
                        <div className="item">
                          <div className="box-imagem">
                            <img
                              className="lazyload"
                              src=""
                              data-srcset={`${item.imagem_carousel.sizes['all-logo']} 1x`}
                              alt={item.imagem_carousel.title}
                            />
                          </div>
                          <div className="box-conteudo">
                            <img
                              className="lazyload"
                              src=""
                              data-srcset={`${item.logo_carousel.sizes['all-logo']} 1x`}
                              alt={item.logo_carousel.title}
                            />
                            <p>{item.descricao_carousel}</p>
                          </div>
                          <div className="box-botao">
                            <a
                              href={item.botao_carousel.url}
                              target={item.botao_carousel.target}
                              rel="noreferrer"
                            >
                              {item.botao_carousel.title}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </Container>
            </Cursos>
          )}

          {dados.home_v2.quem_somos.status_about && (
            <Sobre>
              <Container>
                <Title colorType="inverse">
                  <h2>{dados.home_v2.quem_somos.titulo_about}</h2>
                </Title>
                <div className="box-image">
                  <img
                    className="lazyload"
                    src=""
                    data-srcset={`${
                      dados.home_v2.quem_somos.imagem_about.sizes['1920x1080']
                    } 1x`}
                    alt={dados.home_v2.quem_somos.imagem_about.title}
                  />
                </div>
                <div
                  className="box-content"
                  dangerouslySetInnerHTML={{
                    __html: dados.home_v2.quem_somos.descricao_about,
                  }}
                />
                <div className="box-cta">
                  <Button>
                    <a
                      href={dados.home_v2.quem_somos.botao_about.url}
                      target={dados.home_v2.quem_somos.botao_about.target}
                    >
                      {dados.home_v2.quem_somos.botao_about.title}
                    </a>
                  </Button>
                </div>
              </Container>
            </Sobre>
          )}

          {dados.home_v2.depoimentos.status_depoimentos && (
            <Depoimentos>
              <Container>
                <Title colorType="base" className="box-title">
                  <h2>{dados.home_v2.depoimentos.titulo_depoimentos}</h2>
                </Title>
                <div className="box-slider">
                  <OwlCarousel
                    className="owl-theme"
                    responsive={{
                      0: {
                        loop: false,
                        margin: 10,
                        nav: true,
                        dots: true,
                        items: 1,
                      },
                    }}
                  >
                    {dados.home_v2.depoimentos.carousel_depoimentos.map(
                      item => (
                        <div className="box-item">
                          <img
                            className="lazyload image"
                            src=""
                            data-srcset={`${
                              item.imagem_carousel_depoimentos.sizes[
                                '1920x1080'
                              ]
                            } 1x`}
                            alt={item.imagem_carousel_depoimentos.title}
                          />
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: item.texto_carousel_depoimentos,
                            }}
                          />
                          <div className="name">
                            {item.nome_carousel_depoimentos}
                          </div>
                          <div className="approved">
                            {item.aprovado_carousel_depoimentos} em{' '}
                            <span className="university">
                              {item.aprovacao_carousel_depoimentos}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                  </OwlCarousel>
                </div>

                <div className="box-cta">
                  <Button>
                    <a
                      href={dados.home_v2.depoimentos.botao_depoimentos.url}
                      target={
                        dados.home_v2.depoimentos.botao_depoimentos.target
                      }
                    >
                      {dados.home_v2.depoimentos.botao_depoimentos.title}
                    </a>
                  </Button>
                </div>
              </Container>
            </Depoimentos>
          )}

          {dados.home_v2.conteudos.status_conteudos && (
            <Conteudos>
              <Container>
                <Title colorType="base">
                  <h2>{dados.home_v2.conteudos.titulo_conteudos}</h2>
                </Title>
                <div className="box-cards">
                  {dados.home_v2.conteudos.itens_conteudos.map((item, i) => (
                    <div className={`card ${i === 0 ? `card--light` : ``}`}>
                      <div className="box-image">
                        <img
                          className="lazyload image"
                          src=""
                          data-srcset={`${item.logo_itens_conteudos.sizes['1920x1080']} 1x`}
                          alt={item.logo_itens_conteudos.title}
                        />
                      </div>
                      <figure className="box-icon">
                        <img
                          className="lazyload icon"
                          src=""
                          data-srcset={`${item.icone_itens_conteudos.sizes['1920x1080']} 1x`}
                          alt={item.icone_itens_conteudos.title}
                        />
                      </figure>
                      <div className="box-content">
                        <div
                          className="box-texts"
                          dangerouslySetInnerHTML={{
                            __html: item.descricao_itens_conteudos,
                          }}
                        />
                        <Button className="box-button">
                          <a
                            href={item.botao_itens_conteudos.url}
                            target={item.botao_itens_conteudos.target}
                          >
                            {item.botao_itens_conteudos.title}
                          </a>
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </Container>
            </Conteudos>
          )}

          {dados.home_v2.destaques.status_destaques && (
            <Destaques>
              <Container>
                <Title colorType="base">
                  <h2>{dados.home_v2.destaques.titulo_destaques}</h2>
                </Title>

                <div className="box-content">
                  <OwlCarousel
                    className="owl-theme"
                    responsive={{
                      0: {
                        autoplay: true,
                        autoplayHoverPause: true,
                        center: true,
                        dots: true,
                        items: 1,
                        loop: true,
                        margin: 30,
                      },
                      911: {
                        items: 3,
                        margin: 50,
                      },
                    }}
                  >
                    <div className="item">
                      <a href="https://medway.com.br" className="item-link">
                        <div className="box-image">
                          <img
                            className="lazyload"
                            src=""
                            data-srcset="https://storage.googleapis.com/imgs.medway.com.br/2021/04/0243db4a-enxaqueca-vestibular-caio-neuro.jpg 1x"
                            alt=""
                          />
                        </div>
                        <div className="box-text">
                          <span className="type">Artigo</span>
                          <h3 className="title">
                            Enxaqueca Vestibular: O que é, sintomas e
                            tratamentos.
                          </h3>
                        </div>
                      </a>
                    </div>

                    <div className="item">
                      <a href="https://medway.com.br" className="item-link">
                        <div className="box-image">
                          <img
                            className="lazyload"
                            src=""
                            data-srcset="https://storage.googleapis.com/imgs.medway.com.br/2021/03/a76c7ce8-pneumonia-hospitalar--scaled.jpg 1x"
                            alt=""
                          />
                        </div>
                        <div className="box-text">
                          <span className="type">Artigo</span>
                          <h3 className="title">
                            Pneumonia hospitalar: o que é, diagnóstico e
                            tratamento
                          </h3>
                        </div>
                      </a>
                    </div>

                    <div className="item">
                      <a href="https://medway.com.br" className="item-link">
                        <div className="box-image">
                          <img
                            className="lazyload"
                            src=""
                            data-srcset="https://storage.googleapis.com/imgs.medway.com.br/2021/02/62555c41-como-estudar-por-questoes-para-a-residencia-scaled.jpg 1x"
                            alt=""
                          />
                        </div>
                        <div className="box-text">
                          <span className="type">Artigo</span>
                          <h3 className="title">
                            Como estudar por questões para a residência
                          </h3>
                        </div>
                      </a>
                    </div>

                    <div className="item">
                      <a href="https://medway.com.br" className="item-link">
                        <div className="box-image">
                          <img
                            className="lazyload"
                            src=""
                            data-srcset="https://storage.googleapis.com/imgs.medway.com.br/2020/10/daa2765a-disseccao-aorta.jpg 1x"
                            alt=""
                          />
                        </div>
                        <div className="box-text">
                          <span className="type">Artigo</span>
                          <h3 className="title">
                            Dissecção de aorta: o que é, quadro clínico e
                            tratamento
                          </h3>
                        </div>
                      </a>
                    </div>
                  </OwlCarousel>
                </div>
              </Container>
            </Destaques>
          )}

          {dados.home_v2.parcerias.status_parcerias && (
            <Parcerias>
              <Container>
                <Title colorType="base">
                  <h2>{dados.home_v2.parcerias.titulo_parcerias}</h2>
                </Title>
                <div
                  className="box-content"
                  dangerouslySetInnerHTML={{
                    __html: dados.home_v2.parcerias.descricao_parcerias,
                  }}
                />
                <div className="box-advertising">
                  <img
                    className="lazyload image"
                    src=""
                    data-srcset={`${
                      dados.home_v2.parcerias.imagem_parcerias.sizes[
                        '1920x1080'
                      ]
                    } 1x`}
                    alt={dados.home_v2.parcerias.imagem_parcerias.title}
                  />
                  <div className="box-elements">
                    <div
                      className="box-text"
                      dangerouslySetInnerHTML={{
                        __html: dados.home_v2.parcerias.texto_parcerias,
                      }}
                    />
                    <div className="box-button">
                      <Button>
                        <a
                          href={dados.home_v2.parcerias.botao_parcerias.url}
                          target={
                            dados.home_v2.parcerias.botao_parcerias.target
                          }
                        >
                          {dados.home_v2.parcerias.botao_parcerias.title}
                        </a>
                      </Button>
                    </div>
                  </div>
                </div>
              </Container>
            </Parcerias>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default NewHome;
