import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const Course = styled.p`
  color: #00205b;
  font-size: 18px;
  font-weight: 600;
  margin: 0;

  @media (orientation: portrait) {
    font-size: 14px;
  }
`;

export const Value = styled.p`
  color: #414141;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  position: relative;

  &:before {
    background-color: #414141;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  @media (orientation: portrait) {
    font-size: 14px;
  }
`;
