import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'lazysizes';

import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Title,
  SimpleLink,
  Button,
  Open,
  Community,
  Benefits,
  About,
  Testemonials,
} from './styles';

interface Botao {
  title: string;
  url: string;
  target: string;
}

interface CardsDepoimentos {
  foto: {
    title: string;
    sizes: {
      'all-logo': string;
      thumbnail: string;
      quem_somos: string;
    };
  };
  depoimento: string;
  nome: string;
}

interface Dados {
  mensageiros: {
    apresentacao: {
      status: boolean;
      background: {
        title: string;
        sizes: {
          '1920x1080': string;
        };
      };
      logo: {
        title: string;
        sizes: {
          'all-logo': string;
        };
      };
    };
    comunidade: {
      status: boolean;
      titulo: string;
      texto: string;
      chamada: string;
      botao: Botao;
    };
    vantagens: {
      status: boolean;
      background: {
        title: string;
        sizes: {
          '1920x1080': string;
        };
      };
      titulo: string;
      texto: string;
      botao: Botao;
    };
    quem_somos: {
      status: boolean;
      titulo: string;
      imagem: {
        title: string;
        sizes: {
          '1920x1080': string;
        };
      };
      descricao: string;
      chamada: string;
      botao: Botao;
    };
    depoimentos: {
      status: boolean;
      titulo: string;
      descricao: string;
      cards: [CardsDepoimentos];
    };
  };
}

const SejaNossoParceiro: React.FC = () => {
  const [dados, setDados] = useState<Dados | null>(null);
  
  useEffect(() => {
    api.get(`acf/v3/pages/7054`).then(response => {
      setDados(response.data.acf);
    });
  }, []);
  
  return (
    <>
      {dados ? (
        <>
          {dados.mensageiros.apresentacao.status && (
            <Open
              bg={dados.mensageiros.apresentacao.background.sizes['1920x1080']}
              logo={dados.mensageiros.apresentacao.logo.sizes['all-logo']}
            >
              <div className="box-content">
                <h1 className="title">
                  {dados.mensageiros.apresentacao.logo.title}
                </h1>
              </div>
            </Open>
          )}

          {dados.mensageiros.comunidade.status && (
            <Community>
              <Container>
                <Title colorType="base">
                  <h2>{dados.mensageiros.comunidade.titulo}</h2>
                </Title>
                <div
                  className="box-content"
                  dangerouslySetInnerHTML={{
                    __html: dados.mensageiros.comunidade.texto,
                  }}
                />
                <div className="box-links">
                  <SimpleLink colorType="base">
                    <span>{dados.mensageiros.comunidade.chamada}</span>
                  </SimpleLink>
                  <Button>
                    <a
                      href={dados.mensageiros.comunidade.botao.url}
                      target={dados.mensageiros.comunidade.botao.target}
                    >
                      {dados.mensageiros.comunidade.botao.title}
                    </a>
                  </Button>
                </div>
              </Container>
            </Community>
          )}

          {dados.mensageiros.vantagens.status && (
            <Benefits
              bg={dados.mensageiros.vantagens.background.sizes['1920x1080']}
            >
              <Container>
                <Title className="box-title" colorType="inverse">
                  <h2>{dados.mensageiros.vantagens.titulo}</h2>
                </Title>
                <div
                  className="box-content"
                  dangerouslySetInnerHTML={{
                    __html: dados.mensageiros.vantagens.texto,
                  }}
                />
                <div className="box-links">
                  <Button>
                    <a
                      href={dados.mensageiros.vantagens.botao.url}
                      target={dados.mensageiros.vantagens.botao.target}
                    >
                      {dados.mensageiros.vantagens.botao.title}
                    </a>
                  </Button>
                </div>
              </Container>
            </Benefits>
          )}

          {dados.mensageiros.quem_somos.status && (
            <About>
              <Container>
                <Title colorType="inverse">
                  <h2>{dados.mensageiros.quem_somos.titulo}</h2>
                </Title>
                <div className="box-image">
                  <img
                    className="lazyload"
                    src=""
                    data-srcset={`${dados.mensageiros.quem_somos.imagem.sizes['1920x1080']} 1x`}
                    alt={dados.mensageiros.quem_somos.imagem.title}
                  />
                </div>
                <div
                  className="box-content"
                  dangerouslySetInnerHTML={{
                    __html: dados.mensageiros.quem_somos.descricao,
                  }}
                />
                <div className="box-links">
                  <SimpleLink colorType="inverse">
                    <span>{dados.mensageiros.quem_somos.chamada}</span>
                  </SimpleLink>
                  <Button>
                    <a
                      href={dados.mensageiros.quem_somos.botao.url}
                      target={dados.mensageiros.quem_somos.botao.target}
                    >
                      {dados.mensageiros.quem_somos.botao.title}
                    </a>
                  </Button>
                </div>
              </Container>
            </About>
          )}

          {dados.mensageiros.depoimentos.status && (
            <Testemonials>
              <Container>
                <Title colorType="base">
                  <h2>{dados.mensageiros.depoimentos.titulo}</h2>
                </Title>
                <div
                  className="box-content"
                  dangerouslySetInnerHTML={{
                    __html: dados.mensageiros.depoimentos.descricao,
                  }}
                />
                <OwlCarousel
                  className="owl-theme"
                  responsive={{
                    0: {
                      items: 1,
                      margin: 40,
                      loop: true,
                      autoplay: false,
                      autoplayTimeout: 3500,
                      nav: true,
                    },
                    991: {
                      items: 1,
                      margin: 40,
                      loop: true,
                      autoplay: false,
                      autoplayTimeout: 3500,
                      nav: true,
                    },
                  }}
                >
                  {dados.mensageiros.depoimentos.cards.map(item => (
                    <div className="item">
                      <img
                        className="lazyload"
                        src=""
                        data-srcset={`${item.foto.sizes.quem_somos} 1x`}
                        alt={item.foto.title}
                      />
                      <div
                        className="paragrafo"
                        dangerouslySetInnerHTML={{ __html: item.depoimento }}
                      />
                      <div className="nome">{item.nome}</div>
                    </div>
                  ))}
                </OwlCarousel>
              </Container>
            </Testemonials>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default SejaNossoParceiro;
