import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import 'lazysizes';

import Loading from '../../components/Loading';

import api from '../../services/api';
import apiWordpress from '../../services/apiWordpress';

import {
  Container,
  Open,
  Title,
  Button,
  Episodes,
  Introduction,
} from './styles';

const ProjetoR1 = () => {
  const [dados, setDados] = useState(null);
  const [lista, setLista] = useState(null);
  const [listaCurta, setListaCurta] = useState(null);
  const [moreEps, setMoreEps] = useState(false);

  useEffect(() => {
    const ID = Cookies.get('pageID');
    api.get(`acf/v3/pages/${ID}`).then(response => {
      setDados(response.data.acf);
    });

    // api.get(`acf/v3/pages/1047`).then(response => {
    //   setDados(response.data.acf);
    // });

    apiWordpress.get(`projeto-r1-sp`).then(response => {
      setLista(response.data);

      const itens = [];
      response.data.map((value, key) => {
        if (key <= 3) {
          itens.push(value);
        }
        return '';
      });
      setListaCurta(itens);
    });
  }, []);

  return (
    <>
      {dados ? (
        <>
          <Open>
            <Container>
              <div className="box-container">
                <div className="box-content-2">
                  <p>Ouvindo agora</p>
                </div>
              </div>
              <div className="box-player">
                <div className="box-image">
                  <img
                    src={
                      dados.podcast_r1_sao_paulo_interna.card.thumbnail.sizes
                        .large
                    }
                    alt={
                      dados.podcast_r1_sao_paulo_interna.card.thumbnail.title
                    }
                  />
                </div>

                <div className="box-content">
                  <h4 className="episode">
                    Episódio{' '}
                    {`#${dados.podcast_r1_sao_paulo_interna.card.numero_do_episodio}`}
                  </h4>
                  <h3 className="title">
                    {dados.podcast_r1_sao_paulo_interna.card.nome_do_episodio}
                  </h3>
                  <p className="description">
                    {dados.podcast_r1_sao_paulo_interna.card.com}
                  </p>
                  <div
                    className="player"
                    dangerouslySetInnerHTML={{
                      __html: dados.podcast_r1_sao_paulo_interna.page.player,
                    }}
                  />
                </div>
              </div>
            </Container>
          </Open>

          <Introduction>
            <Container>
              <div
                className="box-content"
                dangerouslySetInnerHTML={{
                  __html: dados.podcast_r1_sao_paulo_interna.page.introducao,
                }}
              />
            </Container>
          </Introduction>

          <Episodes>
            <Container>
              <Title>
                <h2>EPISÓDIOS</h2>
              </Title>

              {lista && listaCurta ? (
                <div className="box-episodes">
                  {moreEps
                    ? lista.map(item => {
                        return (
                          <div className="episode">
                            <div className="box-image">
                              <img
                                className="lazyload"
                                src=""
                                data-srcset={`${
                                  item.acf.podcast_r1_sao_paulo_interna.card
                                    .thumbnail.sizes.large
                                } 1x`}
                                alt={
                                  item.acf.podcast_r1_sao_paulo_interna.card
                                    .thumbnail.title
                                }
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="episode">
                                Episódio{' '}
                                {`#${item.acf.podcast_r1_sao_paulo_interna.card.numero_do_episodio}`}
                              </h4>
                              <h3 className="title">{item.title.rendered}</h3>
                              <p className="description">
                                {item.acf.podcast_r1_sao_paulo_interna.card.com}
                              </p>
                            </div>

                            <div className="box-cta">
                              <a href={item.link}>Ouvir Episódio</a>
                            </div>
                          </div>
                        );
                      })
                    : listaCurta.map(item => {
                        return (
                          <div className="episode">
                            <div className="box-image">
                              <img
                                className="lazyload"
                                src=""
                                data-srcset={`${
                                  item.acf.podcast_r1_sao_paulo_interna.card
                                    .thumbnail.sizes.large
                                } 1x`}
                                alt={
                                  item.acf.podcast_r1_sao_paulo_interna.card
                                    .thumbnail.title
                                }
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="episode">
                                Episódio{' '}
                                {`#${item.acf.podcast_r1_sao_paulo_interna.card.numero_do_episodio}`}
                              </h4>
                              <h3 className="title">{item.title.rendered}</h3>
                              <p className="description">
                                {item.acf.podcast_r1_sao_paulo_interna.card.com}
                              </p>
                            </div>

                            <div className="box-cta">
                              <a href={item.link}>Ouvir Episódio</a>
                            </div>
                          </div>
                        );
                      })}
                </div>
              ) : null}
              <div
                className={
                  moreEps ? 'box-button box-button__hidden' : 'box-button'
                }
              >
                <Button>
                  <button
                    type="button"
                    onClick={() => {
                      setMoreEps(true);
                    }}
                  >
                    Mais Episódios
                  </button>
                </Button>
              </div>
            </Container>
          </Episodes>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ProjetoR1;
