import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  padding: 15px;
`;

export const LogoContainer = styled.div`
  img {
    max-height: 48px;
  }
`;

export const BoxHero = styled.section`
  display: flex;
  align-items: center;
  background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%);
  justify-content: center;
  overflow: hidden;
  padding-bottom: 82px;
  padding-top: 152px;

  @media (orientation: portrait) {
  }

  .boxContent {
    text-align: center;
    width: 800px;

    @media (orientation: portrait) {
      width: 272px;
    }

    .title {
      color: #ffffff;
      font-size: 60px;
      font-weight: 400;

      @media (orientation: portrait) {
        font-size: 24px;
      }

      strong {
        font-weight: 700;
      }

      h1 {
        color: #ffffff;
        font-size: 60px;
        font-weight: 400;

        @media (orientation: portrait) {
          font-size: 24px;
        }

        strong {
          color: #00cfb4;
          font-weight: 700;
        } 
      }
    }

    .text {
      color: #ffb81c;
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 36px;
      padding-top: 24px;
    }
  }
`;

// export const BoxHero = styled.section`
//   display: flex;
//   align-items: center;
//   background: linear-gradient(167.15deg, #0f0c6d 8.94%, #000f50 90.34%);
//   justify-content: center;
//   overflow: hidden;
//   padding-bottom: 82px;
//   padding-top: 72px;

//   @media (orientation: portrait) {
//     padding-bottom: 20px;
//   }

//   .callToAction {
//     display: flex;
//     align-items: center;
//     gap: 40px;
//     justify-content: left;
//     margin: 72px auto 0;
//     max-width: 1210px;
//     padding-bottom: 48px;
//     padding-top: 48px;

//     @media (orientation: portrait) {
//       align-items: unset;
//       flex-direction: column;
//       gap: 38px;
//       margin: 36px auto 0;
//       position: relative;
//       padding-right: 0;
//       padding-bottom: 0;
//       padding-top: 0;
//     }

//     .boxContent {
//       align-items: center;
//       flex-basis: auto;
//       flex-direction: column;
//       height: fit-content;
//       justify-content: center;
//       width: 100%;

//       @media (orientation: portrait) {
//         padding: 0 15px;
//       }

//       .title {
//         color: #ffffff;
//         text-align: left;

//         @media (orientation: portrait) {
//           font-size: 24px;
//           width: 100%;
//           margin-left: 0;
//         }

//         p {
//           font-size: 40px;
//           font-weight: 500;

//           @media (orientation: portrait) {
//             font-size: 24px;
//             font-weight: 700;
//           }
//         }

//         strong {
//           color: #00cfb4;
//           font-weight: 700;
//         }

//         h1 {
//           color: #ffffff;
//           text-align: left;
  
//           @media (orientation: portrait) {
//             font-size: 24px;
//             width: 100%;
//             margin-left: 0;
//           }
  
//           p {
//             font-size: 40px;
//             font-weight: 500;
  
//             @media (orientation: portrait) {
//               font-size: 24px;
//               font-weight: 700;
//             }
//           }
  
//           strong {
//             color: #00cfb4;
//             font-weight: 700;
//           }
//         }
//       }

//       .text {
//         color: #ffffff;
//         margin-bottom: 16px;
//         margin-top: 24px;

//         @media (orientation: portrait) {
//           width: 100%;
//         }

//         p {
//           font-size: 16px;
//           font-style: normal;
//           font-weight: normal;
//           line-height: 24px;
//         }
//       }

//       .textSale {
//         color: #ffb81c;
//         font-weight: 600;

//         @media (orientation: portrait) {
//           font-size: 16px;
//           text-align: center;
//         }
//       }

//       .buttonHero {
//         margin-bottom: 16px;
//       }
//     }

//     .boxVideoHero {
//       align-items: center;
//       flex-direction: column;
//       justify-content: center;
//       max-width: 810px;

//       iframe {
//         border: 0;
//         width: 650px;
//         height: 340px;

//         @media (orientation: portrait) {
//           width: 100%;
//           padding: 0 15px;
//         }
//       }

//       .text {
//         font-size: 18px;
//         font-weight: 400;
//         color: #ffffff;
//         margin-bottom: 16px;
//         text-align: center;

//         @media (orientation: portrait) {
//           font-size: 16px;
//           margin-bottom: 26px;

//           max-width: 100%;
//           width: 100%;
//           padding: 0 15px;
//         }
//       }
//     }
//   }
// `;

export const BoxPillars = styled.section`
  background-color: #f5f5fb;
  padding-bottom: 44px;
  padding-top: 84px;

  @media (orientation: portrait) {
    padding-bottom: 44px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 44px;
  }

  .box-title {
    text-align: center;

    @media (orientation: portrait) {
      text-align: left;
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      color: #00205b;
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
      }
    }
    .description {
      font-size: 18px;
      font-weight: 400;
      color: #414141;
      margin: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }

  .box-content {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 60px;
    max-width: 1225px;
    width: 100%;
    margin: 60px auto 0;

    @media (orientation: portrait) {
      margin: 50px auto 0;
    }
  }

  .box-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 56px;

    a {
      padding: 10px 48px;
    }
  }

  .box-dowload {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 2px rgba(59, 63, 182, 0.4);
    margin: 0 auto;
    padding: 20px 56px;
    width: fit-content;
    transform: translateY(64px);

    @media (orientation: portrait) {
      flex-direction: column;
      padding: 36px 64px;
      width: auto;
      transform: translateY(60px);
    }

    .title {
      color: #0f0c6d;
      font-size: 18px;
      font-weight: 600;
      margin-right: 54px;
      text-transform: uppercase;

      @media (orientation: portrait) {
        margin-right: 0;
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }
`;

export const BoxTeachers = styled.section`
  background-color: #ffffff;
  padding-bottom: 48px;
  padding-top: 84px;

  @media (orientation: portrait) {
    padding-bottom: 24px;
    padding-top: 84px;
  }

  .box-title {
    margin-bottom: 68px;
    text-align: center;

    @media (orientation: portrait) {
      margin-bottom: 44px;
      text-align: left;
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      color: #00205b;
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
      }
    }
  }
`;

export const BoxVideoTestemonial = styled.section`
  background-color: #f5f5fb;
  padding-bottom: 64px;
  padding-top: 64px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .box-title {
    margin-bottom: 68px;
    text-align: center;

    @media (orientation: portrait) {
      margin-bottom: 44px;
      text-align: left;
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      color: #00205b;
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
      }
    }
    .description {
      font-size: 18px;
      font-weight: 400;
      color: #414141;
      margin: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }
`;

export const BoxWaranty = styled.section``;

export const BoxBuy = styled.section`
  background: #0f0c6d;
  display: flex;
  justify-content: center;
  padding-bottom: 72px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 72px;

  @media (orientation: portrait) {
    padding-bottom: 48px;
    padding-top: 48px;
  }
`;

export const BoxNews = styled.section`
  background-color: #f5f5fb;
  padding-bottom: 64px;
  padding-top: 64px;

  @media (orientation: portrait) {
    padding-bottom: 32px;
    padding-top: 32px;
  }

  .box-title {
    text-align: center;

    @media (orientation: portrait) {
      text-align: left;
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      color: #00205b;
      margin-bottom: 16px;

      @media (orientation: portrait) {
        font-size: 24px;
      }
    }
    .description {
      font-size: 24px;
      font-weight: 500;
      color: #414141;
      margin: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }

  .box-table {
    border-radius: 20px;
    border: 2px solid #0f0c6d;
    background-color: #ffffff;
    margin-top: 68px;
    margin-bottom: 45px;

    @media (orientation: portrait) {
    }

    .table-head {
      background-color: #0f0c6d;
      border-radius: 16px 16px 0 0;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .table-column {
        padding-bottom: 24px;
        padding-top: 24px;
        padding-right: 8px;
        padding-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .table-title {
          color: #ffffff;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }

    .table-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;

      .table-column {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;

        .table-cell {
          padding-bottom: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;
          text-align: center;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .table-title {
            color: #00205b;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0;
          }

          .table-label {
            color: #606060;
            display: block;
            font-size: 14px;
            font-weight: 600;
            margin-top: 6px;
          }
        }
      }
    }
  }

  .box-message {
    display: block;
    .message {
      font-size: 16px;
      font-weight: 600;
      color: #00205b;
    }
  }

  .box-form {
    margin-top: 56px;
  }
`;

export const BoxFaq = styled.section`
  background-color: #ffffff;
  min-height: 230px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;

  @media (orientation: portrait) {
  }

  .titleAcordiao {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    margin-bottom: 56px;
    color: #00205b;
    font-weight: 700;

    @media (orientation: portrait) {
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .accordion-button {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0f247b;
    color: #ffffff;

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    &.collapsed {
      color: #00205b;
      background-color: transparent;

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f247b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }
`;

export const BoxFooter = styled.footer`
  display: block;
  align-items: center;
  background-color: #0f0c6d;
  justify-content: center;
  padding-bottom: 48px;
  padding-top: 48px;
  padding-right: 15px;
  padding-left: 15px;

  @media (orientation: portrait) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .titleFooter {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 0;

    @media (orientation: portrait) {
      font-size: 12px;
      line-height: 1.6;
    }
  }

  && a {
    color: #fff;
    line-height: 1;
  }

  && a:hover {
    color: #ffb81c;

    transform: translate(0%, -30%);
    transition: 0.3s ease-out;
  }
`;
