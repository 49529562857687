import styled, { css } from 'styled-components';
// import { shade } from 'polished';

import Arrow from '../../assets/arrow.svg';
import Aspas from '../../assets/aspas.svg';
import Next from '../../assets/next-arrow.svg';
import Prev from '../../assets/prev-arrow.svg';

interface SectionProps {
  bg: string;
}

export const Container = styled.section`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  padding: 15px;
  .box-busca {
    padding-bottom: calc(259px / 2 + 50px);
    /* padding-left: 100px; */
    position: relative;
  }
  @media only screen and (max-width: 991px) {
    .box-busca {
      padding: 0;
    }
  }
`;

export const BannerPrincipal = styled.section<SectionProps>`
  height: 100vh;
  min-height: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-position: center;
  padding-top: 98px;
  padding-bottom: 140px;
  background-size: cover;
  img {
    width: 100%;
    max-width: 1550px;
  }
  ${props =>
    props.bg &&
    css`
      background-image: url(${props.bg});
    `}

  @media only screen and (max-width: 991px) {
    max-height: 350px;
    height: 350px;
    padding-top: 57px;
    padding-bottom: 20px;
    min-height: 450px;
  }
`;

export const Busca = styled.div`
  transform: translateY(-50%);
  position: absolute;
  background-color: #050051;
  border: 2px solid #00cfb4;
  border-radius: 20px;
  padding: 35px 50px;
  max-width: 521px;
  h5 {
    color: #fff;
    font-size: 30px;
    margin-bottom: 10px;
    padding: 0;
  }
  .box-button {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button {
      background: transparent;
      border: 3px solid #fff;
      padding: 12px 27px;
      background-color: #fff;
      border-radius: 30px;
      margin-right: 10px;
      max-width: calc(50% - 10px);
      width: 100%;
      margin-top: 20px;
      color: #050051;
      font-weight: bold;
      box-shadow: 3px 7px 12px #00000029;
      transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
      &:hover {
        background-color: transparent;
        color: #fff;
        transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
      }
      &.residencia {
        border-color: #15317a;
        background-color: #15317a;
        color: #fff;
        transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
          border-color ease-in-out 0.3s;
        &:hover {
          border-color: #fff;
          background-color: #fff;
          color: #15317a;
          transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
            border-color ease-in-out 0.3s;
        }
      }
      &.emergencia {
        border-color: #00cfb4;
        background-color: #00cfb4;
        color: #fff;
        transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
          border-color ease-in-out 0.3s;
        &:hover {
          border-color: #fff;
          background-color: #fff;
          color: #00cfb4;
          transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
            border-color ease-in-out 0.3s;
        }
      }
      &.gratis {
        border-color: #2da4d7;
        background-color: #2da4d7;
        color: #fff;
        transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
          border-color ease-in-out 0.3s;
        &:hover {
          border-color: #fff;
          background-color: #fff;
          color: #2da4d7;
          transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
            border-color ease-in-out 0.3s;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    padding: 25px 25px;
    h5 {
      font-size: 20px;
    }
    .box-button {
      button {
        font-size: 12px;
        padding: 15px 15px;
      }
    }
  }
`;

export const CardItem = styled.div`
  max-width: calc(50% - 10px);
  margin-bottom: 30px;
  border: 2px solid #00cfb4;
  border-radius: 34px;
  height: 500px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 7px 12px #00000029;
  margin-right: 10px;
  overflow: hidden;
  &:nth-child(2n + 1) {
    margin-left: 10px;
    margin-right: 0;
  }
  .logo {
    opacity: 1;
    pointer-events: all;
    position: absolute;
    width: 100%;
    max-width: 350px;
    transition: all 350ms ease-in-out;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 34px;
    overflow: hidden;
    transition: all 350ms ease-in-out;
    img {
      object-fit: cover;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  .conteudo {
    opacity: 0;
    pointer-events: none;
    z-index: 5;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;
    color: #fff;
    transition: all 350ms ease-in-out;
    .titulo {
      position: relative;
      h4 {
        font-size: 40px;
        font-weight: 900;
        position: relative;
        display: inline-block;
        &:after {
          content: '';
          width: calc(100% + 75px);
          height: 3px;
          background-color: #fff;
          position: relative;
          display: block;
          left: -50px;
        }
      }
    }
    .detalhes {
      font-size: 25px;
      line-height: 30px;
    }
    .box-button {
      position: relative;
      display: inline-block;
      a {
        background-color: transparent;
        border-radius: 50px;
        border: 1px solid #fff;
        color: #fff;
        padding: 10px 10px;
        text-decoration: none;
        transition: background-color ease-in-out 0.3s;
        &:hover {
          background-color: #fff;
          color: #050051;
          transition: background-color ease-in-out 0.3s;
        }
      }
    }
  }
  &:hover {
    .logo {
      opacity: 0;
      pointer-events: none;
    }
    .bg {
      filter: blur(5px);
    }
    .conteudo {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media only screen and (max-width: 1366px) {
    .conteudo {
      .titulo {
        h4 {
          font-size: 40px;
          &:after {
            content: '';
          }
        }
      }
      .detalhes {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    align-items: center;
    max-width: calc(100%);
    margin-right: 0;
    justify-content: flex-start;
    height: auto;
    max-height: 150px;
    transition: all 350ms ease-in-out;
    &:after {
      content: '';
      background-image: url(${Arrow});
      width: 32px;
      height: 32px;
      position: absolute;
      right: 30px;
      bottom: 30px;
    }
    &:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: 0;
    }
    &:hover {
      align-items: flex-start;
      height: 460px;
      max-height: 10000px;
      &:after {
        top: 30px;
        transform: rotateZ(90deg);
      }
    }
    .logo {
      padding: 25px;
    }
    .conteudo {
      height: 460px;
      flex-direction: column;
      transition: all 350ms ease-in-out;
      .titulo {
        h4 {
          font-size: 40px;
          &:after {
            content: '';
          }
        }
      }
      .detalhes {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
`;

export const BoxCursos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
  &.medicina-emergencia {
    .box-titulo {
      h2 {
        max-width: fit-content;
        &:after {
          background-color: #00cfb4;
        }
      }
    }
  }
  &.gratis {
    .box-titulo {
      h2 {
        max-width: fit-content;
        &:after {
          background-color: #2da4d7;
        }
      }
    }
  }
  .box-titulo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      width: 100%;
      margin-bottom: 60px;
      color: #050051;
      font-size: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      max-width: 565px;
      &:after {
        content: '';
        width: 160px;
        height: 15px;
        background-color: #15317a;
      }
    }
  }

  .box-botao {
    background-color: #00cfb4;
    border-radius: 38px;
    border: 4px solid #00cfb4;
    color: #ffffff;
    font-size: 32px;
    font-weight: 600;
    margin-top: 60px;
    padding-bottom: 20px;
    padding-left: 34px;
    padding-right: 34px;
    padding-top: 20px;
    text-transform: uppercase;
    transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
    &:hover {
      background-color: #fff;
      color: #00cfb4;
      text-decoration: none;
      transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
    }
  }

  @media only screen and (max-width: 1366px) {
    .box-titulo {
      h2 {
        display: grid;
        font-size: 50px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding-top: 100px;
    margin-bottom: 30px;
    &.medicina-emergencia {
      padding-top: 0;
    }

    .box-titulo {
      h2 {
        display: flex;
        font-size: 30px;
        margin-bottom: 30px;
        margin-top: 30px;
        width: 216px;
        &:after {
          content: '';
          width: 60px;
          height: 5px;
          background-color: #15317a;
        }
      }
    }

    .box-botao {
      border-radius: 28px;
      font-size: 14px;
      font-weight: 700;
      margin-top: 15px;
      padding-bottom: 14px;
      padding-top: 14px;
    }
  }
`;

export const Depoimentos = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 80px;

  .box-titulo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h2 {
      align-items: center;
      align-items: flex-start;
      color: #050051;
      display: flex;
      flex-direction: column;
      font-size: 50px;
      justify-content: center;
      margin-bottom: 60px;
      max-width: 690px;
      width: 100%;

      &:after {
        background-color: #00cfb4;
        content: '';
        height: 15px;
        width: 160px;
      }
    }
    p {
      color: #707070;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 60px;
      text-align: center;

      .break {
        display: block;
      }
    }
  }

  .owl-carousel {
    .owl-dots {
      margin-top: 60px !important;
      .owl-dot {
        span {
          background-color: #5a5a5a91;
          border-radius: 8px;
          height: 12px;
          margin-left: 8px;
          margin-right: 8px;
          transition: background-color ease-in-out 0.3s;
          width: 100px;
        }
        &.active {
          span {
            background-color: #00cfb4;
            transition: background-color ease-in-out 0.3s;
          }
        }
      }
    }
    .owl-stage-outer {
      .owl-stage {
        display: flex;
        align-items: stretch;
      }
      .item {
        display: flex;
        align-content: stretch;
        align-items: center;
        border-radius: 60px;
        border: 8px solid #0f247b;
        flex-direction: column;
        height: calc(100% - 60px);
        justify-content: center;
        margin: 60px 25px 0;
        max-width: 1000px;
        padding: 90px 20px 40px 20px;
        position: relative;
        &:before {
          background-color: #ffffff;
          background-image: url(${Aspas});
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 40px;
          left: -32px;
          position: absolute;
          top: 65px;
          width: 54px;
        }
        &:after {
          background-color: #ffffff;
          background-image: url(${Aspas});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 40px;
          content: '';
          display: block;
          height: 40px;
          position: absolute;
          right: -32px;
          transform: rotate(180deg);
          width: 54px;
        }
        img {
          background-color: #f0f0f5;
          border-radius: 50%;
          border: 8px solid #0f247b;
          max-height: 120px;
          max-width: 120px;
          position: absolute;
          top: -60px;
        }
        .paragrafo {
          font-size: 16px;
          font-style: italic;
          margin-bottom: 15px;
          text-align: center;
        }
        .nome {
          font-size: 18px;
          font-weight: 700;
          text-align: center;
        }
        .aprovacao_ou_ano_atual {
          font-size: 16px;
          text-align: center;
        }
      }
    }
    .owl-nav {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 35px);
      transform: translateY(-50%);
      font-size: 0;
      margin: 0;

      span {
        display: none;
      }

      .owl-prev {
        height: 70px;
        left: -58px;
        margin: 0;
        opacity: 1;
        position: absolute;
        transition: opacity ease-in-out 0.3s;
        width: 38px;

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Prev});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;
        }
      }

      .owl-next {
        height: 70px;
        margin: 0;
        opacity: 1;
        position: absolute;
        right: -58px;
        transition: opacity ease-in-out 0.3s;
        width: 38px;

        &:hover {
          background-color: transparent;
          opacity: 0.6;
          transition: opacity ease-in-out 0.3s;
        }

        &:after {
          background-image: url(${Next});
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: '';
          height: 70px;
          position: absolute;
          right: 0;
          width: 38px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;

    .box-titulo {
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
        width: 213px;

        &::after {
          height: 5px;
          width: 60px;
        }
      }

      p {
        font-size: 18px;
        margin-bottom: 30px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .owl-carousel {
      .owl-dots {
        margin-top: 30px !important;

        .owl-dot {
          span {
            width: 60px;
            height: 10px;
          }
        }
      }
    }

    .owl-stage-outer {
      .item {
        margin: 60px 50px 0 !important;
        padding: 65px 20px 40px 20px !important;
      }
    }

    .owl-nav {
      .owl-prev {
        left: 0 !important;
        height: 50px !important;
        width: 28px !important;

        &:after {
          height: 50px !important;
          width: 28px !important;
        }
      }
      .owl-next {
        right: 0 !important;
        height: 50px !important;
        width: 28px !important;

        &:after {
          height: 50px !important;
          width: 28px !important;
        }
      }
    }
  }
`;
