import styled from 'styled-components';

interface Props {
  bg: string;
}

export const Container = styled.div<Props>`
  background: ${props => props.bg};
  padding: 30px;
  width: 100%;

  & p {
    color: hsla(0,0%,100%,.5);
    font-size: 1em;
    text-align: center;
    margin: 0;

    @media only screen and (max-width: 991px) {
      font-size: 12px; 
    }

    & a {
      color: white;
      font-size: 1em;
      font-weight: 300;
      text-decoration: none;
      text-transform: inherit;

      :hover{
        text-decoration: underline;
      }

      @media only screen and (max-width: 991px) {
        font-size: 12px; 
      }
    }
  }
`;