import styled from 'styled-components';

// Assets

// Interface

export const Container = styled.div`
  color: #343434;
  font-size: 14px;
  font-weight: 400;
`;
