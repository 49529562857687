import styled, { css } from 'styled-components';

import bg from '../../assets/background-experimental.jpg';

interface PropsMobile {
  isMobile: boolean;
}

interface PropsPDFMaterial {
  showResponse: boolean;
}

interface PropsModal {
  status: boolean;
}

export const Content = styled.div`
  background-color: #0a1529;
  background-image: url(${bg});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-top: 26px;
`;

export const Container = styled.div`
  margin: auto;
  max-width: 1140px;
  padding: 15px;
  width: 100%;
`;

export const Header = styled.header`
  background-color: #00205b;

  .box-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 6px;
    padding-top: 6px;

    .medway {
      height: 22px;
      margin-left: 18px;

      @media (orientation: landscape) {
        height: 40px;
      }
    }
  }
`;

export const Titulo = styled.section`
  .box-content {
    border-left: 2px solid #01cfb5;
    padding-left: 12px;

    .sub-title {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 6px;
    }

    .title {
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
    }
  }
`;

export const Video = styled.section<PropsMobile>`
  .box-video {
    max-height: 540px;
    height: 100%;
    width: 100%;
    position: relative;
    ${({ isMobile }) =>
      isMobile &&
      css`
        max-height: 280px;
      `}
    iframe {
      width: 100%;
      height: 100%;
      min-height: 540px;

      ${({ isMobile }) =>
        isMobile &&
        css`
          min-height: 280px;
        `}
    }
  }
`;

export const Main = styled.section`
  .box-accordion {
    .accordion-header {
      .title {
        color: #343434;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .icon {
        color: #01cfb5;
        height: 36px;
        width: 36px;
      }
    }

    .accordion-content {
      display: block;

      p {
        color: #343434;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 24px;

        &::after {
          margin-bottom: 0;
        }

        a {
          color: #0040b5;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      .image {
        width: 100%;
      }

      .box-handout {
        background: linear-gradient(180deg, #0a1e3c 52.51%, #124e8a 100%);
        border-radius: 10px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
        display: inline-block;
        margin-top: 26px;

        .box-header {
          color: #ffffff;
          padding: 24px;
          text-align: center;

          .image {
            margin-bottom: 20px;
            width: auto;
          }

          .title {
            font-size: 16px;
            font-weight: 800;
            line-height: 22px;
            margin-bottom: 14px;
          }

          .description {
            color: #ffffff;
            font-size: 10px;
            font-weight: 500;
            line-height: 13px;
            margin-bottom: 0;
          }
        }

        .box-download {
          background-color: #ffffff;
          border-radius: 4px;
          margin-bottom: 26px;
          margin-left: 16px;
          margin-right: 16px;
          padding: 26px 16px;

          @media (orientation: landscape) {
            margin-left: 26px;
            margin-right: 26px;
          }

          .link {
            display: flex;
            align-items: center;
            background: transparent;
            border: none;
            justify-content: space-between;
            text-decoration: none;
          }

          .box-icon {
            &__pdf {
              .icon {
                width: 42px;
              }
            }

            &__download {
              .icon {
                width: 32px;
              }
            }
          }

          .box-name {
            padding: 0 8px;

            @media (orientation: landscape) {
              padding: 0;
            }

            .title {
              color: #00205b;
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 6px;
            }

            .name {
              color: #343434;
              font-size: 12px;
              font-weight: 400;
              margin: 0;
            }
          }
        }
      }

      .box-question {
        display: inline-block;
        margin: 0 16px;
        border-bottom: 1px solid #c1c1c1;
        margin-bottom: 26px;

        .title {
          color: #343434;
          font-size: 18px;
          font-weight: 700;
          line-height: 1.5;
          margin-bottom: 20px;
        }

        .group {
          display: flex;
          align-items: center;
          margin-bottom: 26px;

          .selector {
          }

          .question {
            margin-left: 20px;
            color: #343434;
            font-size: 16px;
            line-height: 1.5;
            max-width: 90%;
          }
        }
      }
    }

    .Mui-expanded.MuiPaper-elevation1 {
      border-bottom: 1px solid #c1c1c1;
      box-shadow: none;
    }
  }

  .box-aboutTeacher {
    /* border-top: 2px solid #EDEDED; */
    padding-bottom: 36px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 36px;
    text-align: center;

    .title {
      color: #343434;
      font-size: 20px;
      font-weight: 700;
    }

    .box-image {
      margin-bottom: 20px;
      margin-top: 20px;

      .image {
      }
    }

    .box-content {
      .name {
        color: #343434;
        font-size: 16px;
        font-weight: 700;
      }

      .specialty {
        color: #343434;
        font-size: 16px;
        font-weight: 500;
        margin-top: 6px;
        margin-bottom: 20px;
      }

      .box-description {
        color: #343434;

        p {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .box-download-2 {
      margin-bottom: 26px;
      text-align: left;
      margin-top: 26px;

      .title {
        color: #343434;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .link {
        border: 1px solid #d2d2d2;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        align-items: center;
        padding: 28px 14px;
      }

      .box-icon {
        &__pdf {
          .icon {
            width: 42px;
          }
        }

        &__download {
          .icon {
            width: 32px;
          }
        }
      }

      .box-name {
        .title {
          color: #00205b;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 6px;
        }

        .name {
          color: #343434;
          font-size: 12px;
          font-weight: 400;
          margin: 0;
        }
      }
    }

    .box-handout {
      background: linear-gradient(180deg, #0a1e3c 52.51%, #124e8a 100%);
      border-radius: 10px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
      display: inline-block;
      margin-top: 26px;

      .box-header {
        color: #ffffff;
        padding: 24px;
        text-align: center;

        .image {
          margin-bottom: 20px;
          width: auto;
        }

        .title {
          font-size: 16px;
          font-weight: 800;
          line-height: 22px;
          margin-bottom: 14px;
          color: #ffffff;
        }

        .description {
          color: #ffffff;
          font-size: 10px;
          font-weight: 500;
          line-height: 13px;
          margin-bottom: 0;
        }
      }

      .box-download {
        background-color: #ffffff;
        border-radius: 4px;
        margin-bottom: 26px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 26px;

        .link {
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
          justify-content: space-between;
          text-decoration: none;
          text-transform: initial;
          width: 100%;
        }

        .box-icon {
          &__pdf {
            .icon {
              width: 42px;
            }
          }

          &__download {
            .icon {
              width: 32px;
            }
          }
        }

        .box-name {
          text-align: left;
          .title {
            color: #00205b;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 6px;
          }

          .name {
            color: #343434;
            font-size: 12px;
            font-weight: 400;
            margin: 0;
          }
        }
      }
    }
  }

  .tab-title {
    font-weight: 700;
    color: #6b6b6b;
  }
`;

export const Footer = styled.section`
  .box-content {
    padding-bottom: 15px;
    text-align: center;

    .text {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;

      @media (orientation: portrait) {
        font-size: 14px;
      }
    }
  }
`;

export const BoxMain = styled.div<PropsMobile>`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      display: flex;
    `}
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  background-color: #f5f5fa;
  justify-content: space-between;
  .box-aboutTeacher {
    max-width: 35%;
    width: 100%;
    ${({ isMobile }) =>
      isMobile &&
      css`
        max-width: 100%;
      `}

    .box-handout {
      ${({ isMobile }) =>
        isMobile &&
        css`
          display: none;
        `}
    }

    .box-download-2 {
      ${({ isMobile }) =>
        isMobile &&
        css`
          display: none;
        `}
    }
  }

  p {
    font-family: 'Montserrat';
    font-size: 14px;
    color: #343434;
    line-height: 1.57;
    margin-bottom: 30px;
    &::last-child {
      margin-bottom: 0;
    }
  }

  .tabs-content {
    max-width: 100%;

    img {
      max-width: 100%;
      height: auto;
    }

    .box-footer {
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-between;

      ${({ isMobile }) =>
        isMobile &&
        css`
          flex-direction: column-reverse;
        `}

      .responder {
        background: linear-gradient(
          180.17deg,
          #00cfb4 -19.74%,
          #003acf 99.86%
        ) !important;
        border-radius: 30px;
        border: none !important;
        color: #ffffff !important;
        font-size: 18px !important;
        font-weight: 800;
        line-height: 1.5;
        padding: 14px 46px !important;
        text-transform: uppercase;

        &:hover {
          background: linear-gradient(
            180.17deg,
            #00205b -19.74%,
            #003acf 99.86%
          ) !important;
        }

        ${({ isMobile }) =>
          isMobile &&
          css`
            margin-bottom: 25px;
          `}
      }
      .placar {
        max-width: 315px;
        width: 100%;

        background: #caddff;
        border: 1px solid #00205b;
        box-sizing: border-box;
        border-radius: 10px;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;

        padding: 15px;

        ${({ isMobile }) =>
          isMobile &&
          css`
            margin-bottom: 25px;
          `}

        b {
          font-weight: bold;
        }
      }
    }
  }
`;

export const PDFMaterial = styled.div<PropsPDFMaterial>`
  /* margin-bottom: 26px; */
  /* margin-left: 26px; */
  /* margin-right: 26px; */
  text-align: left;

  ${({ showResponse }) =>
    showResponse === false &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `};

  .title {
    color: #343434;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .link {
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    align-items: center;
    padding: 28px 14px;
  }

  .box-icon {
    &__pdf {
      .icon {
        width: 42px;
      }
    }

    &__download {
      .icon {
        width: 32px;
      }
    }
  }

  .box-name {
    .title {
      color: #00205b;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 6px;
    }

    .name {
      color: #343434;
      font-size: 12px;
      font-weight: 400;
      margin: 0;
    }
  }
`;

export const Modal = styled.div<PropsModal>`
  background-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  ${({ status }) =>
    status === false &&
    css`
      display: none;
    `};

  .modal-content {
    background-color: #00102e;
    border-radius: 20px;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 500px;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 70px;
    width: 100%;

    @media (orientation: portrait) {
      padding-bottom: 30px;
      padding-top: 30px;
      width: 90%;
    }

    .box-title {
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 30px;

      @media (orientation: portrait) {
        font-size: 16px;
        margin-bottom: 15px;
        margin-right: 30px;
      }

      p {
        margin-bottom: 0;

        strong {
          font-weight: 700;
        }
      }
    }

    .box-content {
      .rd-section {
        background-color: #00102e !important;

        .rd-column {
          div {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }

          .rd-form {
            margin-bottom: 0 !important;
            margin-top: 0 !important;

            .bricks-form__fieldset {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }

            .rd-button {
              background: linear-gradient(
                180.17deg,
                #00cfb4 -19.74%,
                #003acf 99.86%
              );
              border-radius: 5px !important;
              border: none !important;
              transition: all 0.3s ease-in-out;

              &:hover {
                background: linear-gradient(
                  180.17deg,
                  #00205b -19.74%,
                  #003acf 99.86%
                );
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }

    .close {
      opacity: 1;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      transition: opacity ease-in-out 0.3s;

      @media (orientation: portrait) {
        top: 30px;
      }

      &:hover {
        opacity: 0.75;
        transition: opacity ease-in-out 0.3s;
      }

      &_button {
        border: none;
        background-color: transparent;
      }

      img {
        width: 30px;
      }
    }
  }
`;
