import axios from 'axios';

let url = '';
const { hostname } = window.location;

switch (hostname) {
  case 'intensivo.medway.com.br':
  case 'www.intensivo.medway.com.br':
    url = 'https://intensivo.medway.com.br/wp-json/acf/v3/';
    break;
  case 'fmedway.com.br':
    url = 'https://fmedway.com.br/wp-json/acf/v3/';
    break;
  case 'localhost':
    url = 'https://fmedway.com.br/wp-json/acf/v3/';
    break;
  default:
    url = 'https://intensivo.medway.com.br/wp-json/acf/v3/';
    break;
}

const apiIntensivo = axios.create({
  baseURL: url,
});

export default apiIntensivo;
