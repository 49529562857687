import React from 'react';

// Styles
import { Container } from './styles';

// Interface
interface Props {
  children: React.ReactNode;
  boxRef?: any;
  bg: string;
  align: 'left' | 'center' | 'right';
}

export function BoxTimeline({ children, boxRef, bg, align, ...rest }: Props) {
  return (
    <Container ref={boxRef} bg={bg} align={align} {...rest}>
      {children}
    </Container>
  );
}
