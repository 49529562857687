import React from 'react';

import { Button } from '../Button';

import LogoSVG from '../../../assets/Logo-Medway.svg';

import {
  Container,
  Conteudo,
  BoxLogo,
  Logo,
  Title,
  Describe,
  Box,
  Photo,
} from './styles';

interface PropsButton {
  title: string;
  url: string;
  color?: string;
  background?: string;
  paddingTopBottom?: number;
  paddingLeftRight?: number;
}

interface Props {
  title: string;
  describe: string;
  button: PropsButton;
  photo: string;
  mobile: boolean;
}

export function BoxChamadaPrincipal({
  title,
  describe,
  button,
  photo,
  mobile,
}: Props) {
  return (
    <Container mobile={mobile}>
      <Conteudo mobile={mobile}>
        <BoxLogo mobile={mobile}>
          <Logo src={LogoSVG} />
        </BoxLogo>
        <Title>{title}</Title>
        <Describe>{describe}</Describe>
        <Button
          title={button.title}
          url={button.url}
          color={button.color}
          background={button.background}
          paddingTopBottom={button.paddingTopBottom}
          paddingLeftRight={button.paddingLeftRight}
        />
      </Conteudo>
      <Box>
        <Photo src={photo}></Photo>
      </Box>
    </Container>
  );
}
